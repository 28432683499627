import React from "react";
import { Range, getTrackBackground } from "react-range";
import { progressBarValues } from "./ScreenLayoutConfig";

const MIN = 6;
const MAX = 48;

const ScreenLayoutRange = ({ screenLayoutTiles, setScreenLayoutTiles }) => {
  const root = document.documentElement;
  const secondaryColor = getComputedStyle(root).getPropertyValue('--meetroom-secondary-color');
  
  return (

    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        margin: "0em"
      }}
    >
      <Range
        values={[screenLayoutTiles]}
        min={progressBarValues[0]}
        max={progressBarValues[progressBarValues.length - 1]}
        onChange={(values) => setScreenLayoutTiles(values[0])}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: "36px",
              display: "flex",
                
              width: "100%"
            }}
          >
            <div
              ref={props.ref}
              className="range-container"
              style={{
                background: getTrackBackground({
                  values: [screenLayoutTiles],
                  colors: [secondaryColor, secondaryColor],
                  min: MIN,
                  max: MAX
                })
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: "10px",
              width: "10px",
              borderRadius: "50%",
              backgroundColor: "#FFF",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <div
              style={{
                height: "10px",
                width: "10px",
                borderRadius: "50%",
                background: secondaryColor,
              }}
            />
          </div>
        )}
        renderMark={({ props, index }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: "2px",
              width: "2px",
              marginTop: "0px",
            }}
          />
        )}
      />
    </div>)
};

export default ScreenLayoutRange;