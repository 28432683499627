import Peter from '../assets/avatars/Peter-Sheff-0.svg';
import Sophie from '../assets/avatars/Sophie-Marshall-1.svg';
import Helen from '../assets/avatars/Helen-Amio-2.svg';
import Mary from '../assets/avatars/Mary-Karton-3.svg';
import Colin from '../assets/avatars/Colin-Klein-4.svg'
import Jim from '../assets/avatars/Jim-Thor-5.svg'


import SophieScreen from '../assets/pictures/screen.jpg';
import PeterScreen from '../assets/pictures/screen-small-1.jpg';
import HelenScreen from '../assets/pictures/screen-small-2.jpg';
import JimScreen from '../assets/pictures/screen-small-3.jpg';
import ColinScreen from '../assets/pictures/screen-small-4.jpg';
import MaryScreen from '../assets/pictures/screen-small-5.jpg';

const fakeParticipants = [
    {
        username: "Peter Sheff",
        role: "host",
        type: "external",
        isPinned: true,
        showOptionMenu: false,
        jotTitle: 'CTO',
        fake_picture: Peter,
        screen: PeterScreen,
        showJobTitle: true
    },
    {
        username: "Sophie Marshall",
        role: "guest",
        type: "external",
        showOptionMenu: false,
        isTalking: true,
        jotTitle: 'Business developper',
        fake_picture: Sophie,
        screen: SophieScreen,
        showJobTitle: true
    },
    {
        username: "Helen Amio",
        role: "guest",
        showOptionMenu: false,
        isRaisingHand: true,
        jotTitle: 'Product marketing',
        fake_picture: Helen,
        screen: HelenScreen,
        showJobTitle: true
    },
    {
        username: "Mary Karton",
        role: "animator",
        isMuted: true,
        showOptionMenu: true,
        jotTitle: 'UX-UI',
        fake_picture: Mary,
        screen: MaryScreen,
        showJobTitle: true
    }, 
    {
        username: "Colin Klein",
        role: "guest",
        isMuted: true,
        showOptionMenu: false,
        jotTitle: 'Product Owner',
        fake_picture: Colin,
        screen: ColinScreen,
        showJobTitle: true
    },
    {
        username: "Jim Thor",
        role: "guest",
        isMuted: true,
        showOptionMenu: false,
        jotTitle: 'Motion designer',
        fake_picture: Jim,
        screen: JimScreen,
        showJobTitle: true
    }
];
export default fakeParticipants