import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import useAuthContext from '../hooks/useContext';
import useMediaQuery from '../hooks/useMediaQuery';
import * as Auth from '../helpers/auth';
import UserModal from './UserModal';
import LoginNotification from './LoginNotification';
import VideoAudioControls from './SharedComponents/VideoAudioControls';

import profilSvg from '../assets/icons/profil-photo-nav.svg';
import LogoSvg from '../assets/icons/logo.svg';
import CalendarSvg from '../assets/icons/schedule.svg';
import GridSvg from '../assets/icons/grid.svg';
import { SMALL_SCREEN } from '../utils/breackpoints';
import IconSvg from './IconSvg';

export const HomeTabOptions = {
	Meeting: 'meeting',
	Themes: 'themes',
}

const Navbar = ({ hasControls = false, showAvatar = true, tabSelected = '', setTabSelected }) => {

	const { eventUUID: eventUUIDFromUrl } = useParams();
	const location = useLocation();
	const [showUserModal, setShowUserModal] = useState(false);
	const [showUserLogin, setshowUserLogin] = useState(false);
	let timer;
    const showDuration = 1000 * 6;
	const avatarRef = useRef();
	const { translateJSON, loading, userAvatar, teamID, userID} = useAuthContext();
	const isPhoneScreen = useMediaQuery(SMALL_SCREEN);
	const handleLogout = () => Auth.logOut();

	const handleHiddenUserModal = () => setShowUserModal(false);
	
	const hasTokensInLocalStorage = localStorage.getItem('id_token') && localStorage.getItem('access_token');

	const eventUUID = location?.state?.eventUUID ?? eventUUIDFromUrl;
	const userLoggedIn = () => {
		if (eventUUID) {
			localStorage.setItem('authpath', eventUUID);
		}
			
		Auth.oauth_auth();
	};
	const handleClickAvatar = () => {
		if (hasTokensInLocalStorage) {
			setShowUserModal(true);
		} else {
			userLoggedIn()
		}
	}

	useEffect(() => {
		if (avatarRef.current) {
			avatarRef.current.src = userAvatar ?? profilSvg;
		}
		
	}, [userAvatar, avatarRef.current]);

	const meetroomControls = (hasControls && isPhoneScreen) ? <VideoAudioControls /> : null;
	const hiddenLoginNotification = () => setshowUserLogin(prev => !prev);
	
	useEffect(() => {
		if (loading && !hasTokensInLocalStorage) {
			setshowUserLogin(true);
		}
	}, [loading, hasTokensInLocalStorage]);

	useEffect(() => {
		if (showUserLogin) {
			timer = setTimeout(() => setshowUserLogin(false), showDuration);
		} else {
			if (timer) {
				clearTimeout(timer);
			}
		}
	}, [showUserLogin]);

	const isLoggedIn = !!teamID && !!userID;
	return (
		<>
			<div className="navbar" id="main-navbar">
				<div className='navbar__logo'>
					<IconSvg svg={LogoSvg} />
					<span>{translateJSON['home-title-meet']}</span>
				</div>

				{tabSelected && <div className='navbar__customization'>
					<button
						className={`${tabSelected === HomeTabOptions.Meeting ? 'selected' : ''}`}
						onClick={() => setTabSelected(HomeTabOptions.Meeting)}>
							<IconSvg svg={CalendarSvg} />
							
					</button>
					<button
						title={!isLoggedIn ? translateJSON['rooms-access-sign-in-warning']:'Themes'}
						className={`${tabSelected === HomeTabOptions.Themes ? 'selected' : ''}`}
						onClick={() => {
							if (!isLoggedIn) {
								window.bus.publish("alert", translateJSON['rooms-access-sign-in-warning']);
								return
							}
							setTabSelected(HomeTabOptions.Themes);
					}}>
						<IconSvg svg={GridSvg} />
					</button>
				</div>
				}
				
				<div className="navbar__options">
					{meetroomControls}
					{showAvatar && <img onMouseDown={handleClickAvatar} src={avatarRef.current?.src ?? profilSvg} ref={avatarRef} alt="portrait" />}
				</div>
				
				{/* <RoLauncher/> */}
			</div>
			
			{(showUserLogin && loading) && <LoginNotification closeModal={hiddenLoginNotification} userLoggedIn={userLoggedIn} />}
			{showUserModal && <UserModal src={avatarRef.current?.src} closeModal={handleHiddenUserModal} disconnect={handleLogout} />}
		</>
	);

};


export default Navbar;