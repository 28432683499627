const getParticipantTileStyle = ({
	width: containerWidth,
	height: containerHeight,
	screenLayoutTiles,
	lengthTracks,
	isPhoneScreen
}) => {
	if (!containerWidth || !containerHeight) return;

	const maxInPage = Math.min(screenLayoutTiles, lengthTracks);
	const sqrtTotal = Math.ceil(Math.sqrt(maxInPage));
	const startIndex = maxInPage > 7 ? 2 : 1;

	const combinations = [];
	for (let i = startIndex; i <= sqrtTotal; i++) {
		const prime = Math.ceil(maxInPage / i);
		combinations.push([i, prime], [prime, i]);
	}
	
	const uniqueCombinations = Array.from(new Set(combinations.map(JSON.stringify))).map(JSON.parse);
	const tiles = uniqueCombinations.map(([row, column]) => {
		const flexGap = 10; 
		const minScreenHeight = 150;
		const minScreenWidth = 200; 
		const minScreenHeightMobile = 100; 
		const minScreenWidthMobile = 150; 

		let width = (containerWidth - (column - 1) * flexGap) / column;
		let height = (containerHeight - (row - 1) * flexGap) / row;
		let widthAspectRatio = (height / 9) * 16;
		let heightAspectRatio = (width / 16) * 9;

		if (widthAspectRatio * column + (column - 1) * flexGap > containerWidth) {
			widthAspectRatio = null;
		}
		if (heightAspectRatio * row + (row - 1) * flexGap > containerHeight) {
			heightAspectRatio = null;
		}

		if (height > width) height = width;

		let found;
		if (heightAspectRatio && widthAspectRatio) {
			found = { width: Math.min(width, widthAspectRatio), height: Math.max(height, heightAspectRatio) };
		} else if (widthAspectRatio) {
			found = { width: Math.min(width, widthAspectRatio), height };
		} else if (heightAspectRatio) {
			found = { width, height: Math.min(Math.max(heightAspectRatio, height), width) };
		} else {
			found = { width, height };
		}

		const maxHeight = Math.max(found.height, isPhoneScreen ? minScreenHeightMobile : minScreenHeight);
		const maxWidth = Math.max(found.width, isPhoneScreen ? minScreenWidthMobile : minScreenWidth);
		found = { height: maxHeight, width: maxWidth };

		const totalWidth = (column - 1) * flexGap + found.width * column;
		const totalHeight = (row - 1) * flexGap + found.height * row;
		const extraWidth = containerWidth - totalWidth;
		const extraHeight = containerHeight - totalHeight;
		const extra = Math.abs(extraWidth) + Math.abs(extraHeight);

		return { ...found, row, column, totalWidth, totalHeight, extra, extraWidth, extraHeight };
	});

	// Sort tiles by the least extra space
	tiles.sort((a, b) => a.extra - b.extra);

	return tiles[0];
	
}
export default getParticipantTileStyle;