import { useRef, useState , useEffect, useMemo} from "react";
import { Track, createLocalAudioTrack, createLocalVideoTrack, facingModeFromLocalTrack } from "livekit-client";
import BtnRegular from "../BtnRegular";
import testAudioSource from '../../assets/sounds/test-bell-two.mp3';
import CameraFailedMessage from "./CameraFailedMessage";
import useAuthContext, { useAudioVideoContext } from "../../hooks/useContext";
import { PermissionStatus } from "../../context/AudioVideoContextProvider";
import MediaDeviceInputSelect from "./MediaDeviceInputSelect";

import CheckWiredSvg from '../../assets/icons/check-wired.svg';
import CheckCheckedSvg  from '../../assets/icons/check-checked.svg';
import IconSvg from "../IconSvg";
import { handleCameraError, handleMicroError } from "../../utils/errorHandler";

window.AudioContext = window.AudioContext || window.webkitAudioContext;
window.requestAnimationFrame = (function () {
    return window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        function (callback, element) {
            window.setTimeout(callback, 1000 / 60);
        };
})();

const AudioVideoConfig = (props) => {

    const {
        isInMeetRoom = false,
        inputAudioUpdate, setInputAudioUpdate,
        outputAudioUpdate, setOutputAudioUpdate,
        setCameraUpdate,
        isActiveNoiseCancellationUpdate, setActiveNoiseCancellationUpdate,
        isActiveEchoCancellationUpdate, setActiveEchoCancellationUpdate,
    } = props;
    
    const { translateJSON } = useAuthContext();
    const { audioDeviceId, videoDeviceId, outputAudioSelected, setMicroPermission, setCameraPermission } = useAudioVideoContext();

    const [isTestMicOn, setTestMicOn] = useState(false);
    const isTestMicOnRef = useRef(isTestMicOn);
    
    const [isTestAudioOn, setTestAudioOn] = useState(false);
    const audioContext = new AudioContext({ sinkId: { type: 'none' } }); // setsinkid in firefox not works { sinkId: "none" }
    const musicContext = new AudioContext({ sinkId: { type: 'none' } });

    const canvasRef = useRef();
    const testAudioRef = useRef();
    const audioBufferRef = useRef();
    const sourceNode = useRef();
    const requestId = useRef();
 
    const audioAnalyzer = useRef();
    const micSource = useRef();
    const micAnalyzer = useRef();
  
    const toggleTestMicOn = () => setTestMicOn(oldValue => !oldValue);
    const toggleTestAudioOn = () => setTestAudioOn(oldValue => !oldValue);
    const handleAudioPaused = () => { if (isTestAudioOn) setTestAudioOn(false); }
   
    const [cameraError, setCameraError]= useState();
    const [video, setVideoTrack] = useState();
    const videoElemRef = useRef(null);
    const [audio, setAudioTrack] = useState();

    useEffect(()=>{
        createLocalVideoTrack()
        .then(track => {
            setVideoTrack(prev=>{
                prev?.stop();
                return track
            });
        })
        .catch(error => handleCameraError(error, setCameraPermission, (hasError) => setCameraError(hasError)))
    },[]);
    
    useEffect(()=>{
        isTestMicOnRef.current = isTestMicOn;
        if(isTestMicOn){
            createLocalAudioTrack({ 
                deviceId: inputAudioUpdate, 
                echoCancellation: isActiveEchoCancellationUpdate, 
                noiseSuppression: isActiveNoiseCancellationUpdate 
            })
            .then(track =>{
                if(isTestMicOnRef.current){
                    setAudioTrack(prev=>{
                        prev?.stop();
                        return track
                    });
                  }else{
                    track?.stop();
                }
            })
            .catch(error => handleMicroError(error, setMicroPermission))
        }else{
            setAudioTrack(prev=>{
                prev?.stop();
                return null
            });
        }
    },[isTestMicOn, isActiveEchoCancellationUpdate, isActiveNoiseCancellationUpdate]);

    useEffect(() => {
        if(video){
            setCameraPermission(PermissionStatus.GRANTED);
            setCameraError(null);
        }
        if (videoElemRef.current) {
            video?.attach(videoElemRef.current);
        } else {
            video?.detach();
        }
        return () => {
            video?.stop();
            video?.detach();
        }
        
    }, [!!video, videoElemRef.current]);
    
    const audioRef = useRef();
    useEffect(() => {
        if(audio){
            setMicroPermission(PermissionStatus.GRANTED)
        }
        return () => {
            audio?.stop();
        };
    }, [audio, audioRef]);
    
    const micVisualizeData = () => {
        requestId.current = requestAnimationFrame(micVisualizeData);
        if (!micAnalyzer.current || !canvasRef.current || !isTestMicOn || !isTestMicOnRef.current) {
            clearCanvas();
            return cancelAnimationFrame(requestId.current);
        }
        const songData = new Uint8Array(140);
        micAnalyzer.current.getByteFrequencyData(songData);
        draw(songData);
    };

    const audioVisualizeData = () => {
        if (!audioAnalyzer.current || !canvasRef.current) {
            clearCanvas();
            return cancelAnimationFrame(requestId.current);
        }
        requestId.current = requestAnimationFrame(audioVisualizeData);
        const songData = new Uint8Array(140);
        audioAnalyzer.current.getByteFrequencyData(songData);
        draw(songData);
    };

    const draw = (songData) => {
        if (!canvasRef.current) return;
        const bar_width = 3;
        let start = 0;
        const ctx = canvasRef.current.getContext("2d");
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        for (let i = 0; i < songData.length; i++) {
            start = i * 4; let gradient = ctx.createLinearGradient(0, 0,
                canvasRef.current.width, canvasRef.current.height); gradient.addColorStop(1, "#2392f5"); ctx.fillStyle = gradient;
            ctx.fillRect(start, canvasRef.current.height, bar_width, -songData[i]);
        }
    }
    
    const clearCanvas = () => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        const context = canvas.getContext('2d');
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.beginPath();
    }
    
    useEffect(() => {
        
        if (outputAudioUpdate) {
            if (videoElemRef.current && typeof videoElemRef.current?.sinkId !== 'undefined' && isTestMicOn) {
                //should add isTestMicOn = true, otherwise it will be failed, may be because of lack of audio track
                videoElemRef.current.setSinkId(outputAudioUpdate)
                    .catch((e) => { 
                        console.error('setSinkId',e);
                        // window.bus.publish("alert", { message: e.message });
                    });
            }

            if (testAudioRef.current && typeof testAudioRef.current?.sinkId !== 'undefined') {
                testAudioRef.current.setSinkId(outputAudioUpdate)
                    .catch((e) => { 
                        console.error('setSinkId',e);
                        // window.bus.publish("alert", { message: e.message });
                    });
            } else {
                console.warn('Browser does not support output device selection.');
            }
        }
    }, [outputAudioUpdate]);

    useEffect(() => {
        setCameraUpdate(videoDeviceId)
    }, [videoDeviceId]);
    
    useEffect(() => {
        setInputAudioUpdate(audioDeviceId)
    }, [audioDeviceId]);

    useEffect(() => {
        
        if (!isTestMicOn) {
            // micAnalyzer.current = null;
        } else {
            const stream = audio?.mediaStream;
            if (!stream) return;
            micSource.current = musicContext.createMediaStreamSource(stream);
            micAnalyzer.current = musicContext.createAnalyser();
            micSource.current.connect(micAnalyzer.current);
            micVisualizeData();
        }
    }, [isTestMicOn, inputAudioUpdate, audio?.mediaStreamID,  audio?.streamState, audio?.selectedDevice?.deviceId, audio?.isMuted ]);
    
    useEffect(() => {
        if (isTestMicOn) {
            setTestMicOn(prev=>!prev);
        }
    }, [inputAudioUpdate]);

    useEffect(() => {
        if (isTestAudioOn) {
            if (sourceNode?.current) {
                sourceNode.current.stop(0);
                sourceNode.current = null;
                audioAnalyzer.current = null;
            }
            sourceNode.current = audioContext.createBufferSource();
            sourceNode.current.buffer = audioBufferRef.current;
            sourceNode.current.loop = true;

            audioAnalyzer.current = audioContext.createAnalyser();
            audioAnalyzer.current.fftSize = 2048;
            sourceNode.current.connect(audioAnalyzer.current);
            //remove analyzer connect to destination, so that can play audio not by AudioContext( audioContext setsinkid is not compatible on firefox)
            // audioAnalyzer.current.connect(audioContext.destination);
            sourceNode.current.start(0);

            audioVisualizeData();
            testAudioRef.current.play();
        } else {
            sourceNode?.current?.stop(0);
            sourceNode.current = null;
            audioAnalyzer.current = null;

            testAudioRef.current.currentTime = 0;
            testAudioRef.current.pause();
        }
    }, [isTestAudioOn]);

    useEffect(() => {
        const sound = new URL('../../assets/sounds/test-bell-two.mp3', import.meta.url);
        const request = new XMLHttpRequest();
        request.open("GET", sound.href, true);
        request.responseType = "arraybuffer";
        request.onload = () => {
            audioContext.decodeAudioData(request.response, (buffer) => audioBufferRef.current = buffer);
        }
        request.send();

        return () => {
            videoElemRef.current?.getTracks()?.forEach(track => track.stop());
            videoElemRef.current = null;

            if (requestId.current) {
                cancelAnimationFrame(requestId.current);
                requestId.current = null;
            }

            if (sourceNode?.current) {
                sourceNode.current.stop(0);
                sourceNode.current = null;
                audioAnalyzer.current = null;
            }
        }
    }, []);
    
    return (
        <div className={`audio-video-box ${isInMeetRoom? 'is-in-meetroom':''}`}>
            <div className='screen-frame'>
                <div className="screen-frame__left">
                    <video ref={videoElemRef} muted playsInline />
                    <CameraFailedMessage isSmall={true} cameraError={cameraError}/>
                </div>
                
                
                <div className='screen-frame__right'>
                    <canvas ref={canvasRef} width="300" height="300" />
                </div>
            </div>
            
            <div className="parent">
                <label className="div1" htmlFor='camera'>{translateJSON['audiovideo-camera']}</label>
            
                <MediaDeviceInputSelect
                    classNameAdded="div2"
                    initialSelection={videoDeviceId}
                    kind="videoinput"
                    cameraError={cameraError}
                    onActiveDeviceChange={(id) => setCameraUpdate(id)}
                />
                <label className="div3" htmlFor='audio-output'>{translateJSON['audiovideo-output']}</label>
                <MediaDeviceInputSelect 
                    classNameAdded="div4"
                    kind="audiooutput" 
                    initialSelection={outputAudioSelected}
                    onActiveDeviceChange={(id) => setOutputAudioUpdate(id)}
                />
                <BtnRegular
                    className='btn-regular div5'
                    disabled={isTestMicOn}
                    content='Test your audio'
                    translate={isTestAudioOn ? 'record-btn-stop' : 'audio-btn-test'}
                    event={toggleTestAudioOn} />

                <label className='div6' htmlFor='microphone'>{translateJSON['audiovideo-mic']}</label>
                <MediaDeviceInputSelect
                    classNameAdded="div7"
                    kind="audioinput"
                    initialSelection={audioDeviceId}
                    onActiveDeviceChange={(id) => setInputAudioUpdate(id)} 
                />
                <BtnRegular
                    className='btn-regular div8'
                    disabled={isTestAudioOn}
                    content={isTestMicOn ? 'Stop test mic' : 'Test your mic'}
                    translate={isTestMicOn ? 'record-btn-stop' : 'mic-btn-test'}
                    event={toggleTestMicOn} />

                    <div className="div9 check-frame" onMouseDown={() => setActiveNoiseCancellationUpdate(!isActiveNoiseCancellationUpdate)}>
                        <IconSvg svg={isActiveNoiseCancellationUpdate ? CheckCheckedSvg : CheckWiredSvg} />
                        <div className='btn-check__right' >
                            <span>{translateJSON['audiovideo-noise']}</span>
                        </div>
                    </div>
                    <div className="div10 check-frame" onMouseDown={() => setActiveEchoCancellationUpdate(!isActiveEchoCancellationUpdate)}>

                        <IconSvg svg={isActiveEchoCancellationUpdate ? CheckCheckedSvg : CheckWiredSvg} />
                        <div className='btn-check__right' >
                            <span>{translateJSON['audiovideo-echo']}</span>
                        </div>
                    </div>
            </div>
            <audio ref={testAudioRef} src={testAudioSource} onPause={handleAudioPaused} autoPlay={false} />
        </div>
    )
};

export default AudioVideoConfig
