import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from 'uuid';

import ArrowBackSvg from '../../assets/icons/arrow-back.svg';
import LogotypeSvg from '../../assets/icons/logotype-prejoin.svg';

import useMediaQuery from "../../hooks/useMediaQuery";
import { SMALL_SCREEN } from "../../utils/breackpoints";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useAuthContext, { useGeneralContext } from "../../hooks/useContext";
import { checkAttended, getEventData, postEventToken } from "../../helpers/eventApi";
import { MeetEnterMode } from "../MeetRoom/Settings/AdminConfig";
import BtnIcon from "../BtnIcon";
import PreviewWithJoinButton from "./withJoinButton";
import PreviewKnockToEnter from "./withKnockToEnter";

const PreviewLeftContainer = () =>{

	const navigate = useNavigate();
	const location = useLocation();

	const { eventUUID: eventUUIDFromURL } = useParams();
	const { eventUUID: eventUUIDFromState, configuration: configurationFromState } = location?.state ?? {};
    const isPhoneScreen = useMediaQuery(SMALL_SCREEN);

	const { currentEventUUID, setCurrentEventUUID, currentParticipantUUID, setCurrentParticipantUUID} = useGeneralContext();
	const { teamID, userID, userName, translateJSON, userLang } = useAuthContext();	
	
	const [configuration, setConfiguration]= useState(configurationFromState);
	const [enterMode, setEnterMode] = useState(configuration?.settings?.mode ?? MeetEnterMode.NONE);
	const [participantsJoined, setParticipantsJoined] = useState([]);
	const isAlreadySendRequestRef = useRef(false);
	const [allowJoinMeeting, setAllowJoinMeeting] = useState(true);
	const [skipKnockToEnter, setSkipKnockToEnter]= useState(false);
	const isLoggedIn = !!teamID && !!userID;

	const onJoinMeet = async (e) => {
		e?.preventDefault();

		// Avoid fetch multiple times when click multiple times quickly
		if (!allowJoinMeeting) return;
		setAllowJoinMeeting(false);

		//Avoid go to meetroom when pressed Enter without username input
		
		if (!isLoggedIn && !userName) return;
		
		if (!currentEventUUID) return;
		try {
			const json = await postEventToken({ eventUUID: currentEventUUID, participantUUID: currentParticipantUUID, userName, teamID, userID })
			if (json === 'Waiting') {
				isAlreadySendRequestRef.current = true;
			} else {
				if (!json?.token) return;
				navigate(`/event/${currentEventUUID}`, {
					state: {
						token: json.token,
						isHost: json.is_admin ?? false,
						isFromPreview: true
					}
				});
			}
			
		} catch (error) {
			window.bus.publish("alert");
			navigate('/');
		} finally {
			setAllowJoinMeeting(true);
		}
	};

	const onJoinMeetWithToken = async ({ token }) => {
		if (!currentEventUUID || !token) return;

		if (!allowJoinMeeting) return;
		setAllowJoinMeeting(false);
		
		try {
			
			navigate(`/event/${currentEventUUID}`, {
				state: {
					token,
					isHost: false,
					isFromPreview: true
				}
			});
		} catch (error) {
			console.error(error);
			window.bus.publish("alert");
			navigate('/');
		} finally {
			setAllowJoinMeeting(true);
		}
	}


    const greetingsBox = () => {
		let namesString, joinedNames, remainingNames;
		switch (participantsJoined.length) {
			case 0:
				{/* Il n'y a pas personne pour le moment */ }
				namesString = translateJSON['prejoin-no-one'];
				break;
			case 1:
				{/* XXX a déjà rejoint la réunion */ }
				namesString = `${participantsJoined} ${translateJSON['prejoin-oneperson-name']}`;
				break;
			case 2:
				{/* XXX and ZZZ are already in the meeting */ }
				namesString = `${participantsJoined.join(` ${translateJSON['prejoin-and']} `)} ${translateJSON['prejoin-multiplenames']}`;
				break;
			case 3:
				{/* XXX, YYY, and ZZZ are already in the meeting */ }
				{/* XXX, YYY et ZZZ ont déjà rejoint la réunion */ }
				joinedNames = participantsJoined.slice(-2).join(`${userLang === 'en' ? ',' : ''} ${translateJSON['prejoin-and']} `);
				remainingNames = participantsJoined.slice(0, -2).join(', ');
				namesString = remainingNames + ', ' + joinedNames + ' ' + translateJSON['prejoin-multiplenames'];
				break;
			case 4:
				{/* XXX, YYY, ZZZ, and 1 other participant are already in the meeting */ }
				{/* XXX, YYY, ZZZ et 1 other participant ont déjà rejoint la réunion */ }
				remainingNames = participantsJoined.slice(0, 3).join(', ');
				namesString = remainingNames + `${userLang === 'en' ? ',' : ''}  ${translateJSON['prejoin-and']} ` + translateJSON['prejoin-oneother'];
				break;
			default:
				{/* XXX, YYY, ZZZ, and 2 others participants are already in the meeting */ }
				{/* XXX, YYY, ZZZ et 2 autres participants ont déjà rejoint la réunion */ }
				
				remainingNames = participantsJoined.slice(0, 3).join(', ');
				namesString = remainingNames + `${userLang === 'en' ? ',' : ''}  ${translateJSON['prejoin-and']}` + ` ${participantsJoined.length - 3} ` + translateJSON['prejoin--multiple-nameandcount'];
				break;
		}

		return (
			<div className='preview-greetings-box'>
				<h2>
					{translateJSON['prejoin-signedin-welcome']}{' '}
					<span>{userName}</span>
				</h2>
				<p>{namesString}</p>
			</div>
		);
	}

	const handleBackToHome = () => {
		navigate('/');
	};

	useEffect(()=>{
		if(!currentEventUUID || !teamID || !userID) return;
		checkAttended({eventUUID: currentEventUUID, teamID, userID}).then((value)=>setSkipKnockToEnter(value))
	},[currentEventUUID, teamID, userID]);

	useEffect(()=>{
		if(!currentEventUUID) return;

		if (currentEventUUID && !currentParticipantUUID) {
			setCurrentParticipantUUID(uuidv4());
		}

		if(!!configurationFromState) return;

		 getEventData({eventUUID: currentEventUUID, teamID, userID})
		 .then(res=>{
			setConfiguration(res?.configuration);
			setEnterMode(res?.configuration?.settings?.mode);
			const allJoinedParticipants = (res?.participants ?? []).filter(item => item.status === 'JOINED');
			const listName = allJoinedParticipants.map(item => item.name);
			setParticipantsJoined(listName);
		 })
		 .catch(err=>{
			console.error(err);
			if(err){
				window.bus.publish("alert");
				navigate('/');
			}
		 })
		 
	},[currentEventUUID, configurationFromState])


	useEffect(() => {
		
		setCurrentEventUUID(eventUUIDFromState ?? eventUUIDFromURL);

		const handleVisibilityChange = () => {
			if (document.visibilityState === 'visible') {
				if(!currentEventUUID) return;

				getEventData({ eventUUID: currentEventUUID, teamID, userID })
				.then((res) => {
					const allJoinedParticipants = (res?.participants ?? []).filter(item => item.status === 'JOINED');
					const listName = allJoinedParticipants.map(item => item.name);
					setParticipantsJoined(listName);
				}).catch(error => {
					console.error(error?.message)
					window.bus.publish("alert");
					navigate('/');
				})
			}
		};
	
		document.addEventListener('visibilitychange', handleVisibilityChange);
		return () => {
			document.removeEventListener('visibilitychange', handleVisibilityChange);
		}
	}, []);

	const showKnockToEnter = enterMode === MeetEnterMode.KNOCK && !skipKnockToEnter;

    return (
        <>
        	{isPhoneScreen && greetingsBox()}
			<div className='preview__body__left'>
				{!isPhoneScreen && (
					<>
						<BtnIcon
							content='Back to Home'
							icon={ArrowBackSvg}
							translate='button-prejoin-back-to-home'
							event={handleBackToHome}
						/>
						{greetingsBox()}
					</>
				)}
				{!showKnockToEnter && <PreviewWithJoinButton onJoinMeet={onJoinMeet} setEnterMode={setEnterMode} skipKnockToEnter={skipKnockToEnter} />}
				{showKnockToEnter && 
					<PreviewKnockToEnter 
						onJoinMeet={onJoinMeet} 
						onJoinMeetWithToken={onJoinMeetWithToken} 
						setEnterMode={setEnterMode} 
						isAlreadySendRequest={isAlreadySendRequestRef.current} 
					/>}
			</div>				
        </>
    )
};

export default PreviewLeftContainer;