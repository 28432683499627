const formatterTimestampWithAMPM = (timestamp) =>{
  const date = new Date(timestamp);

  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'p.m.' : 'a.m.';

  hours = hours % 12 || 12; // Convert 0 to 12 for midnight/midday

  const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

  return `${hours}:${formattedMinutes} ${ampm}`;
}

export default formatterTimestampWithAMPM;