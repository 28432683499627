import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SendArrowSvg from '../../../assets/icons/send-arrow.svg';
import MoveSvg from '../../../assets/icons/move.svg';

import { selectLocalParticipant } from '../../../redux/participantsSlice';
import IconSvg from '../../IconSvg';
import AutoResizeTextarea from '../AutoResizeTextarea';
import useAuthContext from '../../../hooks/useContext';

const InputWithSuffix = ({id, value, setValue}) => {
	const [focused, setFocused] = useState(false);
  
	const handleFocus = () => setFocused(true);
	const handleBlur = () => setFocused(false);
	
	const handleChange = (newVal) => {
		const filteredValue = newVal.replace(/[^0-9]/g, '');
		const newValue = Math.min(Math.max(filteredValue, 1), 24 * 60)
		setValue(newValue);
	};
  
	const displayValue = focused ? value : `${value} min${value > 1 ? 's':''}`
	return (
		<AutoResizeTextarea
			tag='input'
			id={id}
			autoComplete='off'
			type="text"
			value={displayValue}
			onFocus={handleFocus}
			onBlur={handleBlur}
			onChange={newVal => handleChange(newVal)}
		/>
	);
  };

  
const CreatePoll = ({ pollToCreated, setPollToCreated, setInputFocus, setShowPollConfirmartion })=>{

	const { translateJSON } = useAuthContext();
	const currentParticipant = useSelector(selectLocalParticipant);
	const [pollQuestion, setQuestion] = useState(pollToCreated?.question);
	const [listOptions, setListOptions] = useState(pollToCreated?.options ||  [
		{ id: '1', value: '' },
		{ id: '2', value: '' }
	]);
	const [pollShowResults, setShowResults] = useState(pollToCreated?.showResults ?? true);
	const [pollAddTimer, setPollAddTimer] = useState(pollToCreated?.addTimer);
	const [pollTimer, setPollTimer] = useState(pollToCreated?.duration ?? 10); //10minutes?


	const handleInputChange = (newValue, index) => {
		const newInputs = [...listOptions];
		newInputs[index].value = newValue;
		setListOptions(newInputs);
	};
	
	const postNewPoll = ()=>{
		const filterOptions = listOptions.filter(item=>!!item?.value).map(item=>item?.value);
		const newPoll = {
			creator: currentParticipant,
			question: pollQuestion,
			options: filterOptions,
			showResults: pollShowResults,
			addTimer: pollAddTimer,
			duration: pollTimer
		}
		setPollToCreated(newPoll);
		setShowPollConfirmartion(true);
	}

	useEffect(()=>{
		const hasValues = listOptions?.filter(item=>item.value !== '');
		
		const emptyList = [];
		for (let i = listOptions.length - 1; i >= 0; i--) {
			if (!listOptions[i].value) {
				emptyList.unshift(listOptions[i]);
			} else {
				break;
			}
		}
		
		if(hasValues.length >= 2 && emptyList.length >= 2){
			setListOptions(prev=> prev.slice(0,-emptyList.length+1));
		}

		const lastValue = listOptions?.slice(-1)?.[0]?.value;
		if(lastValue && listOptions.length <= 9){
			setListOptions(prev=>[...prev, {id: prev.length+1, value:''}]);
		}
	},[listOptions]);

	  const getItemStyle = (draggableStyle, isDragging) => ({
		userSelect: 'none',
		...draggableStyle
	  });
    const handleOnDragEnd = (result) => {
      if (!result.destination) return;
  
      const items = Array.from(listOptions);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      setListOptions(items);
    };
	const postEnabled = pollQuestion && listOptions?.filter(item=>item.value).length >= 2;
	
	
	return (
		<>
			<div className='poll-creator-container'>
				<AutoResizeTextarea
					placeholder={translateJSON['placeholder-question'] || 'Type your question...'}  
					value={pollQuestion} 
					defaultHeight={42}
					onChange={newValue => setQuestion(newValue)} 
					onFocus={() => setInputFocus(true)}
					onBlur={() => setInputFocus(false)} 
				/>
				<div className="container">
				
					<DragDropContext onDragEnd={handleOnDragEnd}>
						<Droppable droppableId="droppable">
							{(provided, snapshot) => (
							<div {...provided.droppableProps} 
								ref={provided.innerRef} >
								{listOptions.map(({ id, value }, index) => (
								<Draggable key={id} draggableId={`item-${id}`} index={index}>
									{(provided, snapshot) => (
										<div
											className={`draggable ${snapshot.isDragging ?'isDragging':''}`}
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
											style={getItemStyle(
												provided.draggableProps.style,
												snapshot.isDragging
											  )}>
											<AutoResizeTextarea 
												placeholder={`${translateJSON['poll-option']} ${index + 1}`}
												value={value} 
												defaultHeight={42}
												onChange={(newValue) => handleInputChange(newValue, index)} 
												onFocus={() => setInputFocus(true)}
												onBlur={() => setInputFocus(false)}
											/>
											<IconSvg svg={MoveSvg} />
										</div>
									)}
								</Draggable> ))}
								{provided.placeholder}
							</div>
							)}
						</Droppable>
					</DragDropContext>

				
				</div>

				<div className='check-frames'>
					<div className='check-frame' onMouseDown={()=>setShowResults(prev=>!prev)}>
						<input type="checkbox" id='show_result' checked={pollShowResults} />
						<label htmlFor='show_result'>Toggle</label>
						<span>{translateJSON['poll-show-results'] || 'Show results to everyone'}</span>
					</div>

					<div className='check-frame' onMouseDown={()=>setPollAddTimer(prev=>!prev)}>
						<input type="checkbox" id='add_timer' checked={pollAddTimer} />
						<label htmlFor='add_timer'>Toggle</label>
						<span>{translateJSON['poll-add-timer'] || 'Add timer'}</span>
					</div>
					{pollAddTimer && <InputWithSuffix id='poll-timer' value={pollTimer} setValue={setPollTimer}/>}
				</div>
				
			</div>
			
			<button
				className={'btn-square'}
				disabled={!postEnabled}
				onClick={postNewPoll}
				data-size={'large'}>
					<IconSvg svg={SendArrowSvg} />
			</button>
        </>
	)
}


export default CreatePoll;