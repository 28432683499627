export async function getTranslationData(lang) {
  let newUrl = `https://webcdn.ringover.com/app/lang/meet_v3/locales_${lang}.json`;
  const response = await fetch(newUrl);
  const data = await response.json();

  // Vérifier si la réponse est vide ou n'a pas de valeur pour la langue demandée
  if (!data || Object.keys(data).length === 0) {
    // Charger les données de langue par défaut en français
    const defaultUrl = `https://webcdn.ringover.com/app/lang/meet_v3/locales_fr.json`;
    const defaultResponse = await fetch(defaultUrl);
    const defaultData = await defaultResponse.json();

    // Traduire les clés manquantes en français
    const translatedData = {};
    Object.keys(defaultData)?.forEach(key => {
      translatedData[key] = defaultData[key] || `FR:${key}`;
    });

    return translatedData;
  }

  return data;
}