
import ThumbsUpSvg from '../assets/icons/thumbs-up.svg';
import ThumbsDownSvg from '../assets/icons/thumbs-down.svg';
import ClappingHandsSvg from '../assets/icons/clapping-hands.svg';
import ILoveItSvg from '../assets/icons/i-love-it.svg';
import SoFunnySvg from '../assets/icons/so-funny.svg';

const reactionsList = [
    { src: ThumbsUpSvg, key: "thumbs-up", emoji: '👍' },
    { src: ThumbsDownSvg, key: "thumbs-down", emoji: '👎' },
    { src: ClappingHandsSvg, key: "clapping-hands", emoji: '👋' },
    { src: SoFunnySvg, key: "so-funny", emoji: '😂' },
    { src: ILoveItSvg, key: "i-love-it", emoji: '❤️' }
];

export default reactionsList;