import React, { useEffect, useRef, useState } from "react";
import ThreeDotsSvg from '../../assets/icons/three-dots.svg';
import SearchSvg from '../../assets/icons/search.svg';
import MicroSvg from '../../assets/icons/micro.svg';
import MicroBlueSvg from '../../assets/icons/micro-blue.svg';
import MicroMuteSvg from '../../assets/icons/micro-mute.svg';
import PinTwoSvg from '../../assets/icons/pin-two.svg';
import CrossSvg from '../../assets/icons/cross.svg';

import InfoSvg from '../../assets/icons/info-icon.svg';

import ScreenSmall1JPG from '../../assets/pictures/screen-small-1.jpg';
import ScreenSmall2JPG from '../../assets/pictures/screen-small-2.jpg';
import ScreenSmall3JPG from '../../assets/pictures/screen-small-3.jpg';
import ScreenSmall4JPG from '../../assets/pictures/screen-small-4.jpg';
import ScreenSmall5JPG from '../../assets/pictures/screen-small-5.jpg';
import ScreenJPG from '../../assets/pictures/screen.jpg';
import CutMicroSvg from '../../assets/icons/cut-micro-in-screen.svg';
import VolumeSvg from '../../assets/icons/medium-volume.svg';
import HostSvg from '../../assets/icons/crown-icon.svg';
import RaisingHandSvg from '../../assets/icons/raise-hand.svg';

import BGSVG from '../../assets/icons/bg.svg';
import MicroOnSvg from '../../assets/icons/micro-on.svg';
import CameraOnSvg from '../../assets/icons/cam-on.svg';
import RecordSvg from '../../assets/icons/record-icon.svg';

import fakeParticipants from '../../constants/fakeParticipants';
import RaiseHand from '../../assets/icons/raise-hand-24.svg';
import AddUserSvg from '../../assets/icons/add-user-icon-24.svg';
import RaiseHandSvg from '../../assets/icons/raise-hand-white.svg';
import UploadFolderSvg from '../../assets/icons/upload-folder.svg';
import SettingsSvg from '../../assets/icons/settings-icon.svg';
import HangUpSvg from '../../assets/icons/hang-up-icon.svg';
import UserGroupSvg from '../../assets/icons/user-group-icon.svg';
import ChatSvg from '../../assets/icons/chat-icon.svg';
import EmpowerSvg from '../../assets/icons/empower.svg';
import IntegrationSvg from '../../assets/icons/integration.svg';
import Input from '../Input';
import OptionMenu from '../MeetRoom/OptionMenu';
import useAuthContext from "../../hooks/useContext";
import IconSvg from "../IconSvg";

const PreviewInEditPanel = ({ themeEdit, scaleZoom, left, logo, backgroundImage: backgroundImageFromEdit }) => {

    const [isDragging, setIsDragging] = useState(false);
    const draggleRef = useRef();
    const [offset, setOffset] = useState([0, 0]);
    const { translateJSON } = useAuthContext();
    const containerStyle = backgroundImageFromEdit && themeEdit?.use_background_image ? 
        { backgroundImage: backgroundImageFromEdit} : 
        { backgroundImage: 'unset', background: themeEdit?.background_primary_color};
    const nameRoleBackground = themeEdit?.name_role_background;
    const participantItem = (backgroundImage, name, jobTitle, isHost, isRaisingHand, isPinned, showJobTitle) => 
        <div style={{ backgroundImage: `url(${backgroundImage})` }} className={`item ${nameRoleBackground}`}>
                    
            <img className="micro" src={CutMicroSvg} />
            <div className="name-role">
                <div className="left-side" />
                <div className="top">
                    {isRaisingHand && <img src={RaisingHandSvg} />}
                </div>

                <div className="right">
                    {isPinned && <img src={PinTwoSvg} />}
                </div>
                <div className="name-role__top">
                    {isHost && <img src={HostSvg} />}
                    <span className="name">{name}</span>
                </div>
                {showJobTitle && <div className="name-role__bottom">
                    <span className="role">{jobTitle}</span>
                </div>}
            </div>
        </div>
    
    const handleMouseDown = (e)=>{
        setIsDragging(true);
        setOffset([
            draggleRef.current.offsetLeft - e.clientX,
            draggleRef.current.offsetTop - e.clientY
        ]);
    }
    const handleMouseUp = ()=>{
        setIsDragging(false);
    }
    const handleMouseMove = (event)=>{
        event.preventDefault();
        if(isDragging){
            const position = {
                x : event.clientX,
                y : event.clientY
        
            };
            draggleRef.current.style.left = (position.x + offset[0]) + 'px';
            draggleRef.current.style.top  = (position.y + offset[1]) + 'px';
        }
        
    }
    return (
        <div className='preview-container'
            style={{
                cursor: isDragging ? 'grabbing' : 'default',
                userSelect: 'none',
                ...containerStyle,
                transform: `scale(${scaleZoom})`, left,
            }} 
            
            ref={draggleRef}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            >
            <div className='ornament-frame'>
                <div style={{
                    WebkitMaskImage: `url(${BGSVG})`,
                    maskImage: `url(${BGSVG})`,
                }} />
            </div>
            <div className='preview-container__notif'>
                <div className='preview-container__notif__header'>
                    <div className='preview-container__notif__title'>
                        <IconSvg svg={AddUserSvg} />
                        <span>{translateJSON['request-join-popup']}</span>
                    </div>
                    <div className='preview-container__notif__close'>
                        <IconSvg svg={CrossSvg} />
                    </div>
                    <div className='preview-container__notif__text'>
                        <span>{ `Clarens V. ${translateJSON['request-popup-desc']}`}</span>
                    </div>
                    
                </div>
                <div className='preview-container__notif__footer'>
                    <button className='btn-secondary'><span>{translateJSON['btn-deny']}</span></button>
                    <button className='btn-primary'><span>{translateJSON['btn-accept']}</span></button>
                </div>
            </div>
            <div className='preview-container__main__top'>
                <div className='preview-container__main__top__feature'>
                    <div style={{ backgroundImage: `url(${ScreenJPG})` }} className={`item ${nameRoleBackground}`}>
                            
                        <img className="micro" src={VolumeSvg} />
                        <div className="name-role">
                            <div className="left-side" />
                            <div className="name-role__top">
                                <span className="name">Sophie Marshall</span>
                            </div>
                            <div className="name-role__bottom">
                                <span className="role">Business developper</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='preview-container__main__top__list'>
                    {participantItem(ScreenSmall1JPG, 'peter sheff', 'CTO', true, false, true, true)}
                    {participantItem(ScreenSmall2JPG, 'Helen Amio', 'Product marketing', false, true, false, true)}
                    {participantItem(ScreenSmall3JPG, 'Jim Turner', 'Motion designer', false, false, false, true)}
                    {participantItem(ScreenSmall4JPG, 'Colin klein', 'Product owner', false, false, false, true)}
                    {participantItem(ScreenSmall5JPG, 'Mary Karton', 'UX-UI', false, false, false, true)}
                </div>
                <div className='preview-container__main__top__side-panel'>

                    <div className='preview-container__main__top__side-panel__heading'>
                        <h2>{translateJSON['meeting-participants']}</h2>
                        <div className='exit-frame' data-size='medium'>
                            <IconSvg svg={CrossSvg} />
                        </div>
                    </div>
                    <div className='preview-container__main__top__side-panel__body'>
                        <div className='search-frame searchframe'>
                            <Input
                                className='input-frame input-searchbar'
                                icon={SearchSvg}
                                placeholder={translateJSON['search-participants']}
                            />
                            <button className='btn-square selected'>
                                <IconSvg svg={ThreeDotsSvg} />
                            </button>
                        </div>

                        <div className='participant-panel__frame is-in-preview'>
                            <ul>
                                <li>{translateJSON["participants-downloadlist"]}</li>
                                <li>{translateJSON["add-participant"]}</li>
                                <li>{translateJSON["share-link-title"]}</li>
                                <li>{translateJSON["participants-mute-all"]}</li>
                            </ul>
                        </div>
											

                        <ul className='participant-list'>
                            {fakeParticipants.map((participant, index) =>
                                <li className={`participant-list__item is-in-preview ${participant.isTalking ? '--talking' : ''}`} key={index}>
                                    <div className='participant-list__item__no-options'>
                                        <div className='participant-list__item__content'>
                                            <div className='participant-list__item__avatar round-pill avatar'>
                                                <img src={participant.fake_picture} alt='portrait' className='portrait' draggable={false} />
                                            </div>
                                            <div className='participant-list__item__label'>
                                                <p className='username'>{participant.username}</p>
                                                {participant.role === 'host' && <p>{translateJSON['participants-host']}</p>}
                                            </div>
                                        </div>
                                        <div className='participant-list__item__options' >
                                            <div className='option-frame' data-interactive={true} data-size="small">
                                                
                                                {participant.isRaisingHand &&
                                                    <img className='opaque' src={RaiseHand} alt="raise hand" id="raiseHand" />}
                                                <IconSvg svg={participant.isTalking ? MicroBlueSvg : participant.isMuted ? MicroMuteSvg : MicroSvg}
                                                    classAdd={`${participant.isTalking ? 'is-talking-blue' : ''}`} />
                                                {participant.isPinned && <IconSvg svg={PinTwoSvg}/>}
                                                <IconSvg svg={ThreeDotsSvg} alt="three dots" classAdd={`${participant.showOptionMenu ? 'show-option-menu-in-panel' : ''}`} />
                                            </div>
                                        </div>

                                    </div>
                                    {participant.showOptionMenu && (
                                        <OptionMenu
                                            className={'participant-list__more-options'}
                                            isPinned={participant.isPinned}
                                            isHost={participant.role === 'host'}
                                        />
                                    )}
                                </li>
                            )}
                        </ul>
                    </div>
									
                </div>
            </div>
            <div className='preview-container__main__bottom'>
                <div className='preview-container__main__bottom__left'>
                    <div className="logo" style={{ backgroundImage: logo }} />
                    <hr />
                    <span>Monthly partners meeting</span>
                    <IconSvg svg={InfoSvg} />
                </div>
                <div className='preview-container__main__bottom__center'>
                    <IconSvg svg={MicroOnSvg} />
                    <IconSvg svg={CameraOnSvg} />
                    <IconSvg svg={RecordSvg} />
                    <IconSvg svg={RaiseHandSvg} />
                    <IconSvg svg={UploadFolderSvg} />
                    <IconSvg svg={SettingsSvg} />
                    <hr />
                    <IconSvg svg={HangUpSvg} classAdd={'red'} />
										
                </div>
                <div className='preview-container__main__bottom__right'>
                    <div className='preview-container__main__bottom__right__duration'>
                        <span>00:12:54</span>
                    </div>
				
                    <hr />
                    <div>
                        <div className={`icon-svg__usergroup active`}>
                            <div
                                style={{
                                    width: '24px',
                                    height: '24px',
                                    WebkitMaskImage: `url(${UserGroupSvg})`,
                                    maskImage: `url(${UserGroupSvg})`
                                }}
                            />
                            <span id='participant-btn'>{fakeParticipants.length}</span>
                        </div>
                        <IconSvg svg={ChatSvg} />
                        <IconSvg svg={EmpowerSvg} />
                        <IconSvg svg={IntegrationSvg} />
                    </div>
									
                </div>
            </div>
        </div>
    );
};

export default PreviewInEditPanel;