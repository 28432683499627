import { useEffect, useRef, useState } from "react";
import ArrowDownSvg from "../../assets/icons/arrow-down.svg";
import ArrowUpSvg from "../../assets/icons/arrow-up.svg";
import { useMaybeRoomContext, useMediaDeviceSelect } from "@livekit/components-react";
import { PermissionStatus } from "../../context/AudioVideoContextProvider";
import useAuthContext, { useAudioVideoContext } from "../../hooks/useContext";
import IconSvg from "../IconSvg";

function MediaDeviceInputSelect({
  kind,
  initialSelection,
  onActiveDeviceChange,
  type,
  cameraError,
  classNameAdded = ''
}) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  let defaultOption;
  const { translateJSON } = useAuthContext();    
  const { microPermission, setMicroPermission, cameraPermission, setCameraPermission } = useAudioVideoContext();

  const [selectedOption, setSelectedOption] = useState(initialSelection);
  const room = useMaybeRoomContext();
  const [devicesFromMedia, setDevicesFromMedia] = useState([]);
  const needRequest = (kind === 'videoinput' && cameraPermission) || (kind !== 'videoinput' && microPermission);
  const { devices, activeDeviceId } = useMediaDeviceSelect({
    kind,
    room,
    track: undefined,
    requestPermissions: needRequest,
    onError: null
  });
  
  const handleOptionClick = (option) => {
    setSelectedOption(option?.label);
    setIsOpen(false);
    onActiveDeviceChange(option?.deviceId)
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(()=>{
    if(devices.length > 0 && kind === 'videoinput') {
      setCameraPermission(PermissionStatus.GRANTED);
    }
    if(devices.length > 0 && kind !== 'videoinput') {
      setMicroPermission(PermissionStatus.GRANTED)
    }
  },[`${devices}`])

  useEffect(()=>{
    if(devices.length === 0 && kind === 'videoinput' && Object.keys(translateJSON).length > 0){
      if( cameraPermission === PermissionStatus.PROMPT ){
        setSelectedOption(translateJSON['allow-cam-title']);
      }
      if( cameraPermission === PermissionStatus.DENIED ){
        setSelectedOption(translateJSON['blocked-cam-title']);
      }
      if(cameraError){
        setSelectedOption(translateJSON['cam-failed-title']);
      }
    }
  },[kind, cameraPermission, cameraError, translateJSON]);

  useEffect(()=>{
    if(devices.length === 0 && (kind === 'audioinput' || kind === 'audiooutput') && Object.keys(translateJSON).length > 0){
      if( microPermission === PermissionStatus.PROMPT ){
        setSelectedOption(translateJSON['allow-mic-title']);
      }
      if( microPermission === PermissionStatus.DENIED ){
        setSelectedOption(translateJSON['blocked-mic-title']);
      }      
    }
  },[kind, microPermission, translateJSON]);

  useEffect(()=>{
    if(devices.length){
      if(initialSelection){
        const item = devices.find(e=>e.deviceId === initialSelection);
        if(item){
          setSelectedOption(item?.label);
        }else{
          setSelectedOption(devices?.[0]?.label);
        }
      }else{
        setSelectedOption(devices?.[0]?.label);
      }
    }
    
  },[initialSelection, `${devices}`]);

  useEffect(()=>{
    if(kind === 'audiooutput' && !devices.length && devicesFromMedia.length){
      
      if(initialSelection){
        const item = devicesFromMedia.find(e=>e.deviceId === initialSelection);
        if(item){
          setSelectedOption(item?.label);
          return;
        }
      }
      setSelectedOption(devicesFromMedia?.[0]?.label);
    }
    
  },[initialSelection, devicesFromMedia, kind]);

  useEffect(()=>{
    const fetchAudioMediaDevices = async () => {
			let stream = null;
			const result = await navigator.mediaDevices.getUserMedia({ audio: true })
				.then((s) => { stream = s; return navigator.mediaDevices.enumerateDevices() });
			stream?.getTracks()?.forEach((track) => track.stop());
			
			const outputs = result.filter(item => item.kind === 'audiooutput' && item.deviceId.trim().length > 0);
			// Check if every device has a non-empty label
			setDevicesFromMedia(outputs);
	  };

    if(kind === 'audiooutput' && !devices.length ){//&& microPermission === PermissionStatus.GRANTED
      //firefox not works at the first time
      fetchAudioMediaDevices()
    }
  },[kind,`${devices}`]);

  useEffect(() => {
    if (activeDeviceId && activeDeviceId !== '') {
      onActiveDeviceChange?.(activeDeviceId);
    }
  }, [activeDeviceId]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const deviceList = kind === 'audiooutput' && devices.length === 0 ? devicesFromMedia : devices;
  const listItems = deviceList?.map((o, index) => {
    let label = o.label;
    if (!label) {
      label = o.deviceId;
    }
    return (
      <li key={`${o.key}-${index}`} 
        onMouseDown={(event) => {
          event?.stopPropagation();
          handleOptionClick(o)
        }}>
        {label}
      </li>
    );
  });


  return (
    <div className={`${classNameAdded} ${type === "grid" ? "input-box grid-box" : "input-box"}`} ref={ref} >
      <div
        tabIndex={0}
        className={"input-box__value scroll-bar invisible"}
        onMouseDown={(event) => {
          event?.stopPropagation();
          if (listItems?.length === 0) return;
          setIsOpen(!isOpen);
        }}
      >
        <span>{selectedOption ? selectedOption : defaultOption}</span>
        <IconSvg svg={isOpen ? ArrowUpSvg : ArrowDownSvg} />
      </div>
      {isOpen && (
        <div className="input-box__options">
          <ul className="scroll-bar invisible">{listItems}</ul>
        </div>
      )}
    </div>
  );
}

export default MediaDeviceInputSelect;
