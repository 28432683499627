import React, { useEffect, useState } from "react";
import SidePanel from "./SidePanel";
import TabList, { TabItem } from "../Tabs";
import QAImg from '../../assets/brands/QandA.png';
import PollsImg from '../../assets/brands/Polls.png';
import WhiteboardImg from '../../assets/brands/Whiteboard.png';
import {ReactComponent as EmptyApp}  from '../../assets/icons/empty-chat.svg';
import {ReactComponent as EmptyAppLight}  from '../../assets/icons/empty-chat-light.svg';
import CardPoll from "./Polls/CardPoll";
import { toggleEventTool } from "../../helpers/eventApi";
import useAuthContext, { useGeneralContext } from "../../hooks/useContext";
import CardQA from "./QA/CardQA";
import { isLightBestSolidOrLinearGradient } from "../../utils/color";

export const IntegrationType = {
    POLL: 'poll',
    QA: 'Q&A',
    Whiteboard: 'White board'
}
  
export const createToolTypeMapping = (translateJSON) =>{
    const ToolTypeMapping = {
        'By Ringover': translateJSON['apps-tag-by-ringover'],
        'Answers': translateJSON['apps-tag-answers'],
        'Questions': translateJSON['apps-tag-questions'],
        'Voting': translateJSON['apps-tag-voting'],
        'Collaboration': translateJSON['apps-tag-collab'],
        'Whiteboard': translateJSON['apps-tag-whiteboard'],
        'Brainstorming': translateJSON['apps-tag-brainstorming']
    };
    return ToolTypeMapping;
}
export const IntegrationItem = ({tool, select}) => {

    const { translateJSON }= useAuthContext()
    let img = QAImg;
    if(tool.name === IntegrationType.POLL){
        img = PollsImg;
    }else if(tool.name === IntegrationType.Whiteboard){
        img = WhiteboardImg
    }
    let nameTrans = tool?.name;
    let desc = tool?.description;
    const types = (tool?.types ?? []).map(type => createToolTypeMapping(translateJSON)[type] || type);
    if(tool.name === IntegrationType.POLL){
        nameTrans = translateJSON['room-apps-polls-title'];
        desc = translateJSON['apps-polls-desc'];
    }else if(tool.name === IntegrationType.QA){
        nameTrans = translateJSON['room-apps-qa-title'];
        desc = translateJSON['apps-qa-desc'];
    }else if(tool.name === IntegrationType.Whiteboard){
        nameTrans = translateJSON['room-apps-whiteboard-title'];
        desc = translateJSON['apps-whiteboard-desc']; 
    }

    return (
        <div className="integration-item" onMouseDown={select}>
            <img src={img} alt=""/>
            <div className="integration-item__right">
                <span type='name'><strong>{nameTrans}</strong></span>
                <div className="integration-item__right__types">
                    {types?.map((item, index)=> <span type='type' key={index}>{item}</span>)}
                </div>
                <span type='description' title={desc}>{desc}</span>
            </div>
        </div>
    );
}

const IntegrationsTab = ({
    isActive = false, 
    setTypeSelected, 
	poll, 
    QA, 
    whiteboard,
})=>{

    const { translateJSON } = useAuthContext();
    const eventTools = [poll, QA];
    const ringoversApp = eventTools.filter(item => item?.is_active === isActive && item?.types.includes('By Ringover'));
    const othersApp = eventTools.filter(item => item?.is_active === isActive && !item?.types.includes('By Ringover'));

    const root = document.documentElement;
    const background_secondary_color = getComputedStyle(root).getPropertyValue('--meetroom-background-secondary-color');
	const isLightBest = isLightBestSolidOrLinearGradient(background_secondary_color);
    
    const emptyState = translateJSON['apps-empty-state'] || "You haven't added any apps yet. Select the Add more tab to add apps to your room."
    const parts = emptyState.split('. ');
  
    return (
        <>
            {isActive && (ringoversApp.length === 0 && othersApp.length === 0) 
                && <div className='group-empty-list'>
                    
                    {isLightBest ? <EmptyApp /> : <EmptyAppLight /> }
                    <span>{parts[0]}.</span>
                    <span>{parts[1]}</span>
                </div>
            }
            {ringoversApp.length !== 0 && 
                <div className="group">
                    <span className="group-name">{translateJSON['apps-tag-by-ringover'] || 'By Ringover'}</span>
                    {ringoversApp.map((item, index)=><IntegrationItem tool={item} key={index} select={()=>setTypeSelected(item.name)} />)}
                </div>
            }

            {othersApp.length !== 0 && 
                <div className="group">
                    <span className="group-name">{translateJSON['room-other-apps'] || 'Other apps'}</span>
                    {othersApp.map((item, index)=><IntegrationItem tool={item} key={index} select={()=>setTypeSelected(item.name)}/>)}
                </div>
            }
        </>
    );
} 
const IntegrationsPanel = (props)=>{
   
    const { 
        hiddenPanelState, 
        startNewQA,
        startNewPoll,
        poll, 
        setPoll, 
        QA, 
        setQA, 
        whiteboard, 
        setWhiteboard } = props;
        
    const { translateJSON } = useAuthContext();
    const [typeSelected, setTypeSelected] = useState();

    const { currentEventUUID } = useGeneralContext();
    const { userID, teamID } = useAuthContext();
    
    const integrationsTabProps = { poll, setPoll, QA, setQA, whiteboard, setWhiteboard }

    const updatePoll = async ()=>{
        const res = await toggleEventTool({ eventUUID: currentEventUUID, userID, teamID, eventToolUUID: poll?.event_tool_uuid })
        if(res){
            setPoll(prev=>{ return {...prev, is_active: !prev.is_active}});
        }
    }

    const updateQA = async ()=>{
        const res = await toggleEventTool({ eventUUID: currentEventUUID, userID, teamID, eventToolUUID: QA?.event_tool_uuid })
        if(res){
            setQA(prev=>{ return {...prev, is_active: !prev.is_active}});
        }
    }
    return (
        <>
            {!typeSelected && <SidePanel
                title={translateJSON['room-applications-title'] || 'Apps'}
                hiddenPanelState={hiddenPanelState}>
                <div className='meetroom-side-panel__body'>
                <div className="integration-panel">
                    <TabList>
                        <TabItem label={translateJSON['room-apps-added-tab'] || 'Added'}>
                            <IntegrationsTab isActive setTypeSelected={setTypeSelected} {...integrationsTabProps} />
                            
                        </TabItem>
                        <TabItem label={translateJSON['room-tab-more-apps'] || "More apps"}>
                            <IntegrationsTab setTypeSelected={setTypeSelected} {...integrationsTabProps}/>
                        </TabItem>
                    </TabList>
                </div>
            
                </div>
            </SidePanel>}
            {typeSelected && typeSelected === IntegrationType.POLL && 
                <CardPoll 
                    cardItem={poll} 
                    closeCard={()=>setTypeSelected(null)} 
                    update={updatePoll} 
                    startNewPoll={startNewPoll} 
                    isInMeetRoom={true}
                />}
            {typeSelected && typeSelected === IntegrationType.QA && 
                <CardQA 
                    cardItem={QA} 
                    closeCard={()=>setTypeSelected(null)} 
                    update={updateQA} 
                    startNewQA={startNewQA}
                    isInMeetRoom={true}
                />}
            
        </>
);
}

export default IntegrationsPanel;