import React from "react";
import useAuthContext, { useAudioVideoContext, useGeneralContext } from "../../hooks/useContext";

import CrossSvg from '../../assets/icons/cross.svg';
import BtnRegular from "../BtnRegular";
import { PermissionStatus } from "../../context/AudioVideoContextProvider";
import IconSvg from "../IconSvg";

const PermissionModal = ({from, closeModal, isInMeetRoom = false}) => {
    const { translateJSON } = useAuthContext();
    const { cameraPermission, microPermission }= useAudioVideoContext();
    let title, desc;
    if (from === 'camera') {
        switch (cameraPermission) {
            case PermissionStatus.PROMPT:
                title = 'allow-cam-title';
                desc = 'allow-cam-desc';
                break;
            case PermissionStatus.DENIED:
                title = 'blocked-cam-title';
                desc = 'blocked-cam-desc';
                break;
            case PermissionStatus.INVALID:
                title = 'cam-failed-title';
                desc = 'cam-failed-desc';
                break;
            default: break;
        }   
        
    } else if (from === 'micro') {
        switch (microPermission) {
            case PermissionStatus.PROMPT:
            case PermissionStatus.INVALID:
                title = 'allow-mic-title';
                desc = 'allow-mic-desc';
                break;
            case PermissionStatus.DENIED:
                title = 'blocked-mic-title';
                desc = 'blocked-mic-desc';
                break;
            default: break;
        }      
    }

    if (!title || !desc) return null;
    return (
        <div className={`ask-permission-modal ${isInMeetRoom ? 'is-in-meetroom' : ''}`}>
            <div className="ask-permission-modal__container">
                <div className='ask-permission-modal__container__header'>
                    <div className='ask-permission-modal__container__header__title' data-size="large">
                        <h3>{translateJSON[title] ?? title}</h3>
                    </div>
                    <IconSvg svg={CrossSvg} onMouseDown={closeModal} />
                </div>
                <div className='ask-permission-modal__container__body'>
                    <span>{translateJSON[desc] ?? desc}</span>
                </div>
                <div className='ask-permission-modal__container__footer'>
                    <BtnRegular
                        content='Close'
                        translate='btn-close'
                        event={closeModal}
                    />
                </div>
            </div>
        </div>
    );
}

export default PermissionModal;