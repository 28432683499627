import React  from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import store from './redux/store';
import AuthContextProvider from './context/AuthContextProvider';
import AudioVideoContextProvider from './context/AudioVideoContextProvider';
import GeneralContextProvider from './context/GeneralContextProvider';
import pubsub from './utils/pubsub';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import CustomRouterProvider from './routes';

// replace console.* for disable log on production
if (process.env.NODE_ENV === 'production') {
	// console.log = () => { }
	// // console.error = () => { }
	// console.debug = () => { }
	// console.warn = () => { }
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<GeneralContextProvider>
		<AuthContextProvider>
			<AudioVideoContextProvider>
				<Provider store={store}>
					<App>
						<CustomRouterProvider />
					</App>
				</Provider>
			</AudioVideoContextProvider>
		</AuthContextProvider>
	</GeneralContextProvider>
);

serviceWorkerRegistration.unregister();

reportWebVitals();
