import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {
	participantUpdated,
    selectAllActiveParticipants,
    selectLocalParticipant,
} from '../../redux/participantsSlice';

import Input from '../Input';
import SearchSvg from '../../assets/icons/search.svg';
import { ReactComponent as MicroSpeakingSvg } from '../../assets/icons/micro-blue.svg';
import { ReactComponent as MicroSvg } from '../../assets/icons/micro-in-screen.svg';
import { ReactComponent as MicroMuteSvg } from '../../assets/icons/cut-micro-in-screen.svg';
import ThreeDotsSvg from '../../assets/icons/three-dots.svg';
import PinTwoSvg from '../../assets/icons/pin-two.svg';
import { ReactComponent as RaiseHand } from '../../assets/icons/raise-hand-24.svg';
import { ReactComponent as ShareScreenActiveSvg } from '../../assets/icons/share-screen-active.svg';
import { ParticipantRole } from '../../dataModal/ParticipantModal';
import useAuthContext from '../../hooks/useContext';
import OptionMenu from './OptionMenu';
import SidePanel from './SidePanel';

import { useEnsureParticipant, useIsSpeaking, useLocalParticipant, useRemoteParticipants } from '@livekit/components-react';
import { getInitialFromString } from '../../utils/getInitialFromString';
import IconSvg from '../IconSvg';
import RequestModal from './RequestModal';
import { fetchAvatarFromCaches } from '../../helpers/auth';



/**
 * Default sort for participants, it'll order participants by:
 * 1. local participant
 * 2. share-screen participant
 * 3. dominant speaker (speaker with the loudest audio level)
 * 4. other speakers that are recently active
 * 5. participants with video on
 * 6. by joinedAt
 */
export const sortParticipants = (participants) => {
    const sortedParticipants = [...participants];
    sortedParticipants.sort((a, b) => {
        // share screen first
        if (a.isScreenShareEnabled !== b.isScreenShareEnabled) {
            return b.isScreenShareEnabled - a.isScreenShareEnabled;
        }

        if (a.isSpeaking !== b.isSpeaking) {
            return b.isSpeaking - a.isSpeaking;
        }
        
        if (a.isCameraEnabled !== b.isCameraEnabled) {
            return b.isCameraEnabled - a.isCameraEnabled;
        }
        
        // last active speaker first
        if (a?.lastSpokeAt !== b?.lastSpokeAt) {
            return (b?.lastSpokeAt?.getTime() ?? 0) - (a?.lastSpokeAt?.getTime() ?? 0)
        }


        // video on
        const aVideo = a?.videoTrackPublications?.size > 0;
        const bVideo = b?.videoTrackPublications?.size > 0;
        if (aVideo !== bVideo) {
            if (aVideo) {
                return -1;
            } else {
                return 1;
            }
        }

        // joinedAt
        return (a.joinedAt?.getTime() ?? 0) - (b.joinedAt?.getTime() ?? 0);
    });
    return sortedParticipants;
}


const ParticipantItemInPanel = ({
    participant: pFromLK,
    optionBoxIdentityInPanel,
    handleOptionBoxIdentityInPanel,
    handleMakeHost,
    handleAskMute,
    handleMuteMic,
    handleSetPinned,
    pinnedParticipantIdentity,
    setUpdatePinnedFromPanel,
    optionMenuRef,
}) => {
    
    const { teamID, userID } = useAuthContext();
    const p = useEnsureParticipant(pFromLK);
    const participant = useSelector(selectAllActiveParticipants).find(item => item.identity === pFromLK.identity);

    const { translateJSON } = useAuthContext();
    const avatarRef = useRef();
    const dispatch = useDispatch();
    const isLoggedIn = !!teamID && !!userID;
    useEffect(() => {
        if (participant?.picturePath && isLoggedIn && avatarRef.current) {
            fetchImage();
        }
    }, [participant?.picturePath, avatarRef.current]);

    const fetchImage = async () => {
        if (!isLoggedIn) return;
        
        if (participant?.picture?.startsWith('data:image')) return;
        
        const blob = await fetchAvatarFromCaches(participant?.picturePath);
        if (blob) {
            if (avatarRef.current) {
                avatarRef.current.src = blob;
            }
            dispatch(participantUpdated({ ...participant, picture: blob }));
        }
    };
    
    if (!participant) return null;
    const isHost = participant?.role === ParticipantRole.HOST;
    const showOptionMenu = !p.isLocal && p.identity === optionBoxIdentityInPanel;
    const style = showOptionMenu ? {} : { height: 60 };
    const showAvatar = isLoggedIn && !!participant?.picturePath && participant?.picture;
    const isPinned = pinnedParticipantIdentity && pinnedParticipantIdentity === pFromLK?.identity;
    return (
        <li
            style={style}
            className={`participant-list__item  ${p.isScreenShareEnabled ? '--sharing' : ''} ${!p.isScreenShareEnabled && p.isSpeaking ? '--talking' : ''}`}
            key={p.identity}
        >
            <div className='participant-list__item__no-options'>
                <div className='participant-list__item__content'>
                    <div className='participant-list__item__avatar round-pill avatar'>
                    {showAvatar && <LazyLoadImage beforeLoad={() => fetchImage()} ref={avatarRef} src={participant?.picture} alt='' />}
                    {!showAvatar &&
                        <div
                            className='macaroon'
                            style={{
                                backgroundColor: participant?.color,
                            }}>
                            {getInitialFromString(p.name)}
                        </div>}
                    
                    </div>
                    <div className='participant-list__item__label'>
                        <p className='username' title={p.name}>{p.name}</p>
                        {isHost && <p>{translateJSON['participants-host']}</p>}
                    </div>
                </div>
                <div className='participant-list__item__options' >
                    <div className='option-frame' >
                        {participant?.isRaisingHand && <RaiseHand />}
                        {p.isScreenShareEnabled && <ShareScreenActiveSvg />}
                        {p.isSpeaking ? <MicroSpeakingSvg /> : !p.isMicrophoneEnabled ? <MicroMuteSvg /> : <MicroSvg />}       
                        {participant?.isPinned && <img className='opaque' src={PinTwoSvg} alt='pin' />}
                        <IconSvg svg={ThreeDotsSvg}
                            styleAdd={{ visibility: p.isLocal ? 'hidden' : 'visible' }}
                            classAdd={`more-option ${showOptionMenu ? 'active' : ''}`}
                            onMouseDown={() => {
                                handleOptionBoxIdentityInPanel(p.identity);
                            }}
                        />
                    </div>
                </div>

            </div>
            {showOptionMenu && (
                <OptionMenu
                    ref={optionMenuRef}
                    className={'participant-list__more-options'}
                    isPinned={isPinned}
                    isHost={isHost}
                    handleSetPinned={() => { setUpdatePinnedFromPanel(prev => !prev); handleSetPinned(p.identity) }}
                    handleMakeHost={() => handleMakeHost(participant)}
                    handleAskMute={() => handleAskMute(participant)}
                    handleMuteMic={() => handleMuteMic(participant)}
                />
            )}
        </li>
    );
};


const ParticipantPanel = (props) => {
    
    const {
        hiddenPanelState,
        muteEveryone,
        requestList,
		setRequestList,
		onDenied,
		onAccept } = props;

    const localParticipant = useLocalParticipant();
    const remoteParticipants = useRemoteParticipants();
    const sort = sortParticipants([...remoteParticipants])
    const currentParticipant = useSelector(selectLocalParticipant);
    const [participantOptionTab, setParticipantOptionTab] = useState(false);
    
    const [filterParticipants, setFilterParticipants] = useState(sort ?? []);
    const [nameFilter, setNameFilter] = useState('');
    const { translateJSON } = useAuthContext();


    const isCurrentHost = currentParticipant?.role === ParticipantRole.HOST;
  
    const handleParticipantOptionTab = () => {
        setParticipantOptionTab(!participantOptionTab);
    };

    useEffect(() => {
        if (!nameFilter) {
            setFilterParticipants([localParticipant.localParticipant,...sort])
        } else {
            const newList = [localParticipant.localParticipant,...sort].filter(item =>
                item.name.toLowerCase().includes(nameFilter.toLowerCase())
            );
            setFilterParticipants([...newList]);
        }

    }, [nameFilter, sort.length]);

    return (
        <SidePanel
            title={`${translateJSON['meeting-participants']}`}
            hiddenPanelState={hiddenPanelState}>
            <div className='meetroom-side-panel__body'>
                <div className='search-frame searchframe'>
                    <Input
                        className='input-frame input-searchbar'
                        icon={SearchSvg}
                        defaultValue={nameFilter}
                        onChange={(e) => setNameFilter(e.target.value)}
                        autoFocus={false}
                        onFocus={() => { props.setInputFocus(true); }}
                        onBlur={() => { props.setInputFocus(false); }}
                        id='text_search_a_participant'
                        placeholder={translateJSON['search-participants']}
                    />
                    <button className={`btn-square ${participantOptionTab ? 'selected' : ''}`}
                        onClick={handleParticipantOptionTab}>
                        <IconSvg svg={ThreeDotsSvg} />
                    </button>
                </div>

                {participantOptionTab && (
                    <div className='participant-panel__frame'>
                        <ul>
                            <li onMouseDown={props.showShareModal}>{translateJSON['share-link-title']}</li>
                            {isCurrentHost && <li onMouseDown={() => muteEveryone()}>
                                {translateJSON['participants-mute-all']}
                            </li>}
                            
                        </ul>
                    </div>
                )}

                {requestList.length > 0 && <RequestModal requestList={requestList} onDenied={onDenied} onAccept={onAccept} isInPanel={true} />}
                <ul className='participant-list'>
                    
                    {filterParticipants.map((participant) => (
                        <ParticipantItemInPanel
                            key={participant.identity}
                            participant={participant}
                            {...props}
                        />
                    ))}
                </ul>
            </div>
        </SidePanel>
    );
};

export default ParticipantPanel;
