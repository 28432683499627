import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useMediaQuery from '../hooks/useMediaQuery';
import useAuthContext from '../hooks/useContext';

import BgPanel from '../components/BgPanel';
import Navbar from '../components/Navbar';
import PermissionModal from '../components/SharedComponents/PermissionModal';
import PreviewRightContainer from '../components/Preview/PreviewRightContainer';
import PreviewSettingsModal, { PreviewSettingOption } from '../components/Preview/PreviewSettingsModal';
import { MEDIUM_LARGE_SCREEN } from '../utils/breackpoints';
import PreviewLeftContainer from '../components/Preview/PreviewLeftContainer';

export const RequestStatus = {
	init: 'initialized',
	waiting: 'waiting',
	accepted: 'accepted',
	reject: 'reject',
};

const Preview = () => {
	
	const location = useLocation();
	const { loading } = useAuthContext();
	
	const isMediumScreen = useMediaQuery(MEDIUM_LARGE_SCREEN);
	const [showPreviewSettingModal, setShowPreviewSettingModal] = useState(false);
	const [selectedSettingsOption, setSelectedSettingsOption] = useState(PreviewSettingOption.VIDEO);
	const [showPermissionModal, setShowPermissionModal] = useState({ show: false });

	useEffect(() => {
		//not allow forward to meetroom directly
		window.history.pushState('fake-route', document.title, location.href);
	}, [location]);

	useEffect(() => {
		
		const authpath = window.localStorage.getItem('authpath');
		if (authpath) {
			window.localStorage.removeItem('authpath');
		}
	}, []);

	const showPreviewBody = !isMediumScreen || (isMediumScreen && !showPreviewSettingModal);

	if (!loading) return <></>;
	return (
		<>
			<BgPanel isInPreview={true}/>
			<div className='preview'>
				{showPreviewBody && (
					<>
						<Navbar hasControls />
						<div className='preview__body'>
							<PreviewLeftContainer {...location?.state}/>
							<PreviewRightContainer
								showPreviewSettingModal={() => setShowPreviewSettingModal(true)}
								setShowPermissionModal={setShowPermissionModal}
								selectedSettingsOption={selectedSettingsOption}
								setSelectedSettingsOption={setSelectedSettingsOption}
							/>
						</div>
					</>
				)} 
				{showPreviewSettingModal && (
					<PreviewSettingsModal
						selectedSettingsOption={selectedSettingsOption}
						setSelectedSettingsOption={setSelectedSettingsOption}
						closeModal={() => setShowPreviewSettingModal(false)}
					/>
				)}
				{showPermissionModal.show && (
					<PermissionModal
						from={showPermissionModal?.from}
						closeModal={() => setShowPermissionModal({ show: false })}
					/>
				)}
			</div>
		</>
	);
	
};

export default Preview;
