import React from 'react';

import { ReactComponent as AudioSvg } from '../../assets/icons/audio-on-mobile.svg';
import { ReactComponent as CutAudioSvg } from '../../assets/icons/sound-off.svg';
import { ReactComponent as FolderSwitchSvg } from '../../assets/icons/flip-camera.svg';
import { useAudioVideoContext } from '../../hooks/useContext';
import { PermissionStatus } from '../../context/AudioVideoContextProvider';

const VideoAudioControls = () => {

    const { cutSound, setCutSound } = useAudioVideoContext();
    
    const handleSwitchCamera = () => { console.log('switchCamera')};
    const audioIcon = cutSound ? <CutAudioSvg onMouseDown={()=>setCutSound(false)}/> : <AudioSvg onMouseDown={()=>setCutSound(true)}/>;
    const showFlip = false;
    return (
        <div className='navbar__options__controls'>
            {/*
            // TODO: Active cut sound in mobile later
             {audioIcon} */}
            {showFlip && <FolderSwitchSvg onMouseDown={handleSwitchCamera} />}
		</div>
    )
};

export default VideoAudioControls;