import React, { useState } from 'react';
import InputSelect from '../InputSelect';
import useAuthContext, { useGeneralContext } from '../../hooks/useContext';
import { getLanguageByCode, languageOptions, getFlagByCode } from '../../constants/language';
import colors from '../../constants/colors';

import CheckWiredSvg from '../../assets/icons/check-wired.svg';
import CheckCheckedSvg from '../../assets/icons/check-checked.svg';
import IconSvg from '../IconSvg';

const ProfileSettings = (props) => {

    const {
        isInMeetRoom = false,
        userlangUpdated, setUserlangUpdated,
        userNameUpdated, 
        userEmailUpdated, 
        jobTitleUpdated, setJobTitleUpdated,
        showJobTitleUpdated, setShowJobTitleUpdated,
    } = props;

    const { translateJSON, teamID, userID } = useAuthContext();
    const isLoggedIn = !!teamID && !!userID;
    const handleChangeLanguage = (value) => setUserlangUpdated(value.key.toLowerCase());

    
    return (<div className={`profile-box${isInMeetRoom ? ' is-in-meetroom':''}`}>
        <div className='input-frame'>
            <label htmlFor='language'>{translateJSON['language-expr']}</label>
            <div className="input-frame__content">
                <InputSelect
                    options={languageOptions}
                    defaultImg={getFlagByCode(userlangUpdated)}
                    defaultOption={getLanguageByCode(userlangUpdated)}
                    handleValClick={handleChangeLanguage}
                />
            </div>
        </div>
        <div className='profile-box__item'>
            <label htmlFor='display-name' >{translateJSON['audiovideo-displayname']}</label>
            <input className='input-frame__input'
                type="text"
                placeholder={translateJSON['audiovideo-displayname']}
                value={userNameUpdated}
            />
        </div>
        {isLoggedIn && <div className='profile-box__item'>
            <label htmlFor='email-gravatar'>{translateJSON['audiovideo-email']}</label>
            <input className='input-frame__input'
                type="email"
                placeholder={translateJSON['audiovideo-email']}
                value={userEmailUpdated}
            />
        </div>}
        <div className='profile-box__item'>
            <label htmlFor='role'>{translateJSON['prejoin-placeholder-role']}</label>
            <input className='input-frame__input'
                type="text"
                placeholder={translateJSON['prejoin-placeholder-role']}
                value={jobTitleUpdated}
                onChange={(e) => {
                    if(!isLoggedIn){
                        setJobTitleUpdated(e.target.value)
                    }
                }}
            />
            <div className='check-frame'>
                <div onMouseDown={() => setShowJobTitleUpdated(prev=>!prev) }>
                    <IconSvg svg={showJobTitleUpdated ? CheckCheckedSvg : CheckWiredSvg} />
                    <div className='btn-check__right' >
                        <span>{translateJSON['prejoin-show-role']}</span>
                    </div>
                </div>
               
            </div>
        </div>
    </div>);
};

export default ProfileSettings;
