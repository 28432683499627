import React, { useState, useEffect, createContext } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';
import * as Auth from '../helpers/auth';

import { getTranslationData } from '../helpers/getTranslateData';

const AuthContext = createContext();
export { AuthContext };

const AuthContextProvider = ({ children }) => {
	const [idToken, setIdToken] = useState('');
	const [accessToken, setAccessToken] = useState('');
	const [refreshToken, setRefreshToken] = useState('');
	const [userAvatarPath, setUserAvatarPath] = useState('');
	const [userAvatar, setUserAvatar] = useState();
	const [userLang, setUserLang] = useLocalStorage('lang', 'fr');
	const [isFrench, setIsFrench] = useState(false);
	
	const [userEmail, setUserEmail] = useState('');
	const [userName, setUserName] = useState('');
	const [jobTitle, setJobTitle] = useState('');
	const [userID, setUserID] = useState('');
	const [teamID, setTeamID] = useState('');
	const [userLastName, setUserLastName] = useState('');
	const [translateJSON, setTrans] = useState({});
	const [loading, setLoading] = useState(false);


	const getResponse = (response) => {
		if (!response) return;
		const {
			id_token = '',
			access_token = '',
			refresh_token = '',
			team_id = '',
			user_id = '',
			responseUser } = response;
		const lang = responseUser?.settings?.lang;
		if (lang != null) {
			setUserLang(lang);
		}
		setUserID(user_id);
		setTeamID(team_id);
		setJobTitle(responseUser.jobtitle);
		setUserName(`${responseUser.firstname} ${responseUser.lastname}`);
		setUserLastName(responseUser.lastname);
		if (responseUser.email != null) {
			setUserEmail(responseUser.email);
		}
		if (responseUser.photopath != null) {
			setUserAvatarPath(responseUser.photopath);
		}
		setIdToken(id_token);
		setAccessToken(access_token);
		setRefreshToken(refresh_token);
	};

	useEffect(() => {
		let checkaction = localStorage.getItem('refresh');
		if (checkaction) {
			if (checkaction == 2) {
				Auth.get_oauth2_tokens(localStorage.getItem('code_auth'))
					.then((res) => {
						getResponse(res);
						setLoading(true);
					})
					.catch((error) => console.error(error));
			}
		} else {
			const tokens = Auth.get_tokens();
			if (tokens && tokens.refresh_token && tokens.id_token) {
				Auth.refresh_oauth_tokens().then((res) => {
					getResponse(res);
				}).finally(() => {
					setLoading(true);
				});
			} else {
				setLoading(true);
			}
		}
	}, []);

	useEffect(() => {
		var language =
			window.navigator.userLanguage ||
			window.navigator.language ||
			window.navigator.browserLanguage;
		
		const isUserAuth = idToken && accessToken && refreshToken;
		if (!isUserAuth && !userLang) {
			setUserLang(language.substring(0, 2));
		}
	}, []);

	useEffect(() => {
		setIsFrench(userLang === 'fr');
		getTranslationData(userLang).then((data) => setTrans(data));
	}, [userLang]);

	//set jobtitle when user is not authenticated
	useEffect(() => {
		if (loading && !userID && !teamID) {
			const jobTitleFromLocalStorage = localStorage.getItem('job_title');
			if (jobTitleFromLocalStorage) {
				setJobTitle(jobTitleFromLocalStorage);
			}
		}
	}, [loading, userID, teamID]);
	
	//save to local storage
	useEffect(() => {
		if (loading && !userID && !teamID) {
			localStorage.setItem('job_title', jobTitle);
		}
	}, [loading, jobTitle ]);

	useEffect(() => {
		if (!userAvatarPath) return;
	
		Auth.fetchAvatarFromCaches(userAvatarPath)
			.then(blob => {
				if (blob) {
					setUserAvatar(blob);
				}
			});
		
	}, [userAvatarPath]);

	return (
		<AuthContext.Provider
			value={{
				idToken,
				accessToken,
				refreshToken,
				userLang,
				setUserLang,
				isFrench,
				userAvatarPath,
				userAvatar,
				setUserAvatar,
				userEmail,
				setUserEmail,
				userName,
				setUserName,
				jobTitle,
				setJobTitle,
				userID,
				setUserID,
				teamID,
				setTeamID,
				userLastName,
				translateJSON,
				loading,
				setLoading,
			}}>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthContextProvider;
