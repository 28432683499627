import React from 'react';
import {
	RouterProvider,
	createBrowserRouter,
} from 'react-router-dom';
import Home from '../pages/Home';
import MeetRoom from '../pages/MeetRoom';
import Preview from '../pages/Preview';
import ErrorRoom from '../pages/ErrorRoom';

const CustomRouterProvider = () => {

	const router = createBrowserRouter([
		{
			path: '/',
			element: <Home />,
			errorElement: <ErrorRoom />,
		},
		{
			path: '/preview',
			element: <Preview />,
			errorElement: <ErrorRoom />,
		},
		{
			path: '/preview/:eventUUID',
			element: <Preview />,
			errorElement: <ErrorRoom />,
		},
		{
			path: '/event/:eventUUID',
			element: <MeetRoom />,
			errorElement: <ErrorRoom />,
		},
	]);
	return <RouterProvider router={router} />;
};

export default CustomRouterProvider;
