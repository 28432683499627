import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import AddSmileySvg from '../../../assets/icons/add-smiley.svg';
import ArrowLeft from '../../../assets/icons/arrow-back-gray.svg';
import CloseSvg from '../../../assets/icons/cross-24.svg';
import ChatSvg from '../../../assets/icons/chat-icon.svg';
import PollsSvg from '../../../assets/icons/polls.svg';
import PollsIndiceSvg from '../../../assets/icons/polls-indice.svg';
import Polls1PNG from '../../../assets/polls/polls-1.png';
import Polls2PNG from '../../../assets/polls/polls-2.png';
import Polls3PNG from '../../../assets/polls/polls-3.png';
import Polls4PNG from '../../../assets/polls/polls-4.png';

import IconSvg from "../../IconSvg";
import useAuthContext from "../../../hooks/useContext";
import IntegrationCard from "../IntegrationCard";


const CardPoll = ({isInMeetRoom = false, cardItem, closeCard, update, startNewPoll}) =>{
    const { translateJSON } = useAuthContext();
    const images = [Polls1PNG, Polls2PNG, Polls3PNG, Polls4PNG];
    const indiceSvgs = <>
            <IconSvg svg={PollsSvg} classAdd={'polls'}/>
            <IconSvg svg={PollsIndiceSvg} classAdd={'indice'}/>
        </>;
    const startBtnTitle = translateJSON['btn-start-poll'] || 'Start poll';
    const text = translateJSON['apps-polls-start-here-desc'];
    const phrasesToHighlight = [translateJSON['apps-polls-start-here-bold'],  translateJSON['apps-polls-start-here-bold2']];
    const parts = [];
    let remainingText = text;
    
    phrasesToHighlight.forEach((phrase, index) => {
        const idx = remainingText.indexOf(phrase);
        if (idx !== -1) {
            if (idx > 0) {
                parts.push(remainingText.slice(0, idx));
            }
            parts.push(<strong key={index}>{phrase}</strong>);
            remainingText = remainingText.slice(idx + phrase.length);
        }
    });
    
    // Add any remaining text
    if (remainingText.length > 0) {
        parts.push(remainingText);
    }
    return (
        <IntegrationCard 
            isInMeetRoom={isInMeetRoom} 
            cardItem={cardItem} 
            closeCard={closeCard} 
            update={update} 
            startNew={startNewPoll} 
            images={images} 
            checkLabel={translateJSON['apps-polls-allow'] || 'Allow Polls in room'}
            startDesc={<p>{parts}</p>}
            indiceSvgs={indiceSvgs} 
            startBtnTitle={startBtnTitle} />
        
    );
}

export default CardPoll;