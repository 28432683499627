import React from "react";

import useAuthContext from "../hooks/useContext";

import CheckWiredSvg from '../assets/icons/check-wired.svg';
import CheckCheckedSvg from '../assets/icons/check-checked.svg';
import { MeetInfoOption } from "./MeetRoom/InfoModal";
import IconSvg from "./IconSvg";

const MoreConfig = (props) => {

    const { translateJSON } = useAuthContext(); 
    const {
        enableShortCuts,
        setEnableShortCuts,
        turnOffChat,
        setTurnOffChat,
        setOptionInfoModal
    } = props;

    return (
        <div className='tab-content__block --more-options'>
            <div className='--more-options__legend'>
                <span>{translateJSON["settings-more-desc"]}</span>
            </div>
            <div className='--more-options__body'>
                
                <div className='check-frame'>
                    <div className="check-frame__has-button">
                        <div onMouseDown={() => setEnableShortCuts(!enableShortCuts)}>
                        <IconSvg svg={enableShortCuts ? CheckCheckedSvg : CheckWiredSvg} />
                            <div className='btn-check__right' >
                                <span>{translateJSON['settings-enable-shortcuts']}</span>
                            </div>
                        </div>
                        <button id="open-shortcuts"  onClick={()=>setOptionInfoModal(MeetInfoOption.SHORTCUTS)}>{translateJSON['more-openshorcuts']}</button>
                    </div>

                    <div onMouseDown={() => setTurnOffChat(!turnOffChat)}>
                        <IconSvg svg={turnOffChat ? CheckCheckedSvg : CheckWiredSvg} />
                        <div className='btn-check__right' >
                            <span>{translateJSON['more-turnoffchat']}</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}
export default MoreConfig;