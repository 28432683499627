import React, { useEffect, useState } from 'react';
import useAuthContext from './hooks/useContext';
import './app.css';
import Alert from './components/PortalModal';

const App = ({ children }) => {
	const { loading, translateJSON } = useAuthContext();
	const [isJSONLoaded, setJSONLoaded]= useState(false);
	useEffect(()=>{
		if(Object.keys(translateJSON).length > 0){
			setJSONLoaded(true)
		}
	}, [!!Object.keys(translateJSON).length]);

	const showChilren = loading && isJSONLoaded;
	return (
		<div className='app'>
			{!showChilren &&
				<div className="loader">
					<div className="loader-ringover"></div>
				</div>
			}
			{showChilren && <>{children}</>}
			<Alert />
		</div>
	);
};

export default App;
