import React, { memo } from 'react';
import shortcuts from '../../constants/shortcuts';
import LinkSvg from '../../assets/icons/link-icon.svg';
import InfoSvg from '../../assets/icons/info.svg';
import KeyboardSvg from '../../assets/icons/keyboard.svg';
import FilesSvg from '../../assets/icons/files.svg';
import HubSvg from '../../assets/icons/hub.svg';
import useAuthContext from '../../hooks/useContext';
import IconSvg from '../IconSvg';
import ModalWithTabs from '../SharedComponents/ModalWithTabs';
 
export const MeetInfoOption = {
    DETAIL: 'DETAIL',
    SHORTCUTS: 'SHORTCUTS',
}

const InfoModal = ({ isHost, showShareModal, copyToClipboard, optionInfoModal, setOptionInfoModal }) => {

    const { translateJSON } = useAuthContext();

    const meetURL = `${window.location.href}`;
    
    const closeModal = () => setOptionInfoModal('');

    const isMacOS = navigator.userAgent.indexOf('Mac') != -1;
    const textareaValue = `<iframe allow ="camera; microphone; fullscreen; display-capture; autoplay" src="${meetURL}" style = "height: 100%; width: 100%; border: 0px;" ></iframe>`;

    const options = <>
        <button
            className={`btn-icon ${optionInfoModal === MeetInfoOption.DETAIL ? 'active' : ''}`}
            onClick={() => setOptionInfoModal(MeetInfoOption.DETAIL)}
            data-size={'large'}>
            <IconSvg svg={LinkSvg} />
            <span>{translateJSON['meeting-details']}</span>
        </button>
        <button
            className={`btn-icon ${optionInfoModal === MeetInfoOption.SHORTCUTS ? 'active' : ''}`}
            onClick={() => setOptionInfoModal(MeetInfoOption.SHORTCUTS)}
            data-size={'large'}>
            <IconSvg svg={KeyboardSvg} />
            <span>{translateJSON['shortcuts']}</span>
        </button>
    </>;
    const content =
        <>
            {optionInfoModal === MeetInfoOption.DETAIL && (
                <div className='--meeting-info'>
                    <div className='--meeting-info__frame'>
                        <div className='--meeting-info__container'>
                            <div className='--meeting-info__container__heading'>
                                <h4>{translateJSON['meeting-info-link']}</h4>
                            </div>
                            <div className='--meeting-info__container__content'>
                                <div className='input-frame-link'>
                                    <div className="input-label">
                                        <span>{meetURL}</span>
                                    </div>
                                                
                                    <div className='icon-frame' data-interactive={true}>
                                        <IconSvg svg={FilesSvg} onMouseDown={() => copyToClipboard(meetURL)} />
                                        <IconSvg svg={HubSvg} onMouseDown={showShareModal} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='--meeting-info__container'>
                            <div className='--meeting-info__container__heading'>
                                <h4>{translateJSON['meeting-info-embed']}</h4>
                            </div>
                            <div className='--meeting-info__container__content'>
                                <div className='input-frame-link'>
                                    <textarea defaultValue={textareaValue} readOnly rows={4} />
                                    <div className='icon-frame' data-interactive={true}>
                                        <IconSvg svg={FilesSvg} onMouseDown={() => copyToClipboard(textareaValue)} />
                                        <IconSvg svg={HubSvg} onMouseDown={showShareModal} />
                                    </div>
                                </div>
                                           
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {optionInfoModal === MeetInfoOption.SHORTCUTS && (
                <div className='--shortcuts-box'>
                    <div className='--shortcuts-box__frame'>
                        <ul className='--shortcuts-box__list'>
                            {shortcuts?.filter(item=>isHost ? item : item?.label !== "Ouvrir le panneau d'application").map((e, index) => (
                                <li className='--shortcuts-box__item' key={index}>
                                    <span>{translateJSON?.[e?.translate] ? translateJSON?.[e?.translate] : e.label}</span>
                                    <span>{isMacOS ? e.shortcodeMac : e.shortcode}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        </>;
    const footerButtons =
        <button className={'btn-regular'} onClick={closeModal}>
            <span>{translateJSON['btn-close']}</span>
        </button>;
    
    return (
        <ModalWithTabs
            isInMeetRoom={true}
            maxWidth={700}
            minHeight={300}
            headingSvg={InfoSvg}
            headingTitle={translateJSON['meeting-details']}
            closeModal={closeModal}
            options={options}
            content={content} 
            footerButtons={footerButtons} />
        
    );
};

export default memo(InfoModal);