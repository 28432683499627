import React from 'react';
import useAuthContext from '../../hooks/useContext';
import { useSelector } from 'react-redux';
import { selectLocalParticipant } from '../../redux/participantsSlice';
import { ParticipantRole } from '../../dataModal/ParticipantModal';
import { ReactComponent as ArrowSvg } from '../../assets/icons/chevron-right.svg';

const OptionMenu = ({
    className,
    isPinned,
    isHost,
    handleMakeHost,
    handleAskMute,
    handleMuteMic,
    handleSetPinned,
}) => {
    const { translateJSON } = useAuthContext();
    const localParticipant = useSelector(selectLocalParticipant);
    const isCurrentParticipantHost = localParticipant?.role === ParticipantRole.HOST;
    const makehostOrNot = isHost ? 'Demote host' : 'Promote host'; //translateJSON['participants-makehost']

    return (
        <ul className={className}>
           
            <li onMouseDown={handleSetPinned}>
                <span>
                    {translateJSON[isPinned ? 'participants-unpin' : 'participants-pin']}
                </span>
                <ArrowSvg />
            </li>

            {isCurrentParticipantHost && <>
                <li onMouseDown={handleMakeHost}>
                    <span>{makehostOrNot}</span>
                    <ArrowSvg />
                </li>
            
                <li onMouseDown={handleMuteMic}>
                    <span>{translateJSON['participants-mutemic']}</span>
                    <ArrowSvg />
                </li>
            </>}
        </ul>
    );
};

export default OptionMenu;