import { useState, useEffect } from "react";

const useModalAnimated = () => {
  
    const [isAnimated, setAnimated] = useState(false);

    useEffect(() => {
        setAnimated(true);

        return () => {
            setAnimated(false);
        };
    }, []);
    return { isAnimated };
};

export default useModalAnimated;