import React, { forwardRef, useEffect, useRef } from "react";
const AutoResizeTextarea = forwardRef(({ 
	id,
	maxLength = 255, 
	placeholder, 
	value, 
	autoComplete,
	tag : Tag = 'textarea',
	onChange, 
	onKeyDown,
	onBlur, 
	onFocus, 
	disabled,
	beforeTag, 
	afterTag,
	flexDirection = 'row'
}, ref) => {

	const tagRef = useRef();
	const adjustTextareaHeight = () => {
		const e = ref?.current || tagRef?.current;

		if (e) {
			e.style.height = 'auto';
			if (value && e.scrollHeight >= e.offsetHeight) {
				e.style.height = `${e.scrollHeight}px`;
			}
		}
	};

	useEffect(() => {
		adjustTextareaHeight()
	}, [value]);
  
	const handleChange = (e)=>{
		const value = e?.target?.value ?? '';
		//filter all emojis
		const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}]/ug;
		const newValue = value?.replaceAll(emojiRegex, '');
		onChange(newValue);
	}

	return (
		<div className="auto-resize-textarea" style={{flexDirection}}>
			{beforeTag}
			<Tag
				id={id}
				rows={1}
				autoComplete={autoComplete}
				disabled={disabled}
				maxLength={maxLength}
				ref={ref || tagRef}
				onFocus={onFocus}
				onBlur={onBlur}
				onKeyDown={onKeyDown}
				placeholder={placeholder}
				onChange={handleChange}
				value={value}
			/>
			{afterTag}
		</div>
	);
  });

  export default AutoResizeTextarea;