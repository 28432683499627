import React, { useEffect, useRef, useState } from 'react';
import LogoWithWhiteNameSVG from "../../assets/icons/logotype-ringover-white-text.svg";
import LogoWithDarkNameSVG from "../../assets/icons/logotype-ringover-black-text.svg";
import LogoWithoutNameSVG from "../../assets/icons/logotype-ringover-circle.svg";
import CrossSvg from '../../assets/icons/cross.svg';
import FilesSvg from '../../assets/icons/files.svg';
import HubSvg from '../../assets/icons/hub.svg';
import InfoSvg from '../../assets/icons/info-icon.svg';
import useAuthContext from '../../hooks/useContext';
import VideoAudioControls from '../SharedComponents/VideoAudioControls';
import { MeetInfoOption } from './InfoModal';
import IconSvg from '../IconSvg';
import useMediaQuery from '../../hooks/useMediaQuery';
import { isLightBestSolidOrLinearGradient } from '../../utils/color';
import { MORE_800 } from '../../utils/breackpoints';
import isValidHttpUrl from "../../utils/isValidHttpUrl";

const InfoBox = (props) => {
    const { logoProps, setOptionInfoModal, copyToClipboard, showShareModal, isPhoneScreen, showNotif, setShowNotif = () => { }, isInPreview = false } = props;
    const meetURL = `${window.location.href}`;
    const notifRef = useRef();
    
    const [logo, setLogo] = useState();
    const { translateJSON } = useAuthContext();

    const isLargeScreen = useMediaQuery(MORE_800);

    let timer;
    const showDuration = 3000;
    const handleClickOnCloseNotif = () => {
        setShowNotif(false);
        if (notifRef.current) {
            notifRef.current.removeEventListener('mouseleave', handleMouseLeave);
            notifRef.current.removeEventListener('mouseenter', handleMouseEnter);
            notifRef.current = null;
        }
    }
  
    
    const handleMouseLeave = () => {
        timer = setTimeout(() => {
            setShowNotif(false);
        }, showDuration);
    }
    const handleMouseEnter = () => {
        clearTimeout(timer);
    }
    
    useEffect(() => {
        
        const logoUploaded = logoProps?.logo;
        if (logoUploaded && isValidHttpUrl(logoUploaded)) {
            setLogo(logoUploaded);
            return;
        }
        
        if (!logoProps?.primaryColor || !logoProps?.backgroundPrimaryColor) return;
        const isLightBestForLogo = isLightBestSolidOrLinearGradient(logoProps?.primaryColor, logoProps?.backgroundPrimaryColor);
        let logoIcon = LogoWithoutNameSVG;
        if (isLargeScreen) {
            if (isLightBestForLogo) {
                logoIcon = LogoWithWhiteNameSVG;
            } else {
                logoIcon = LogoWithDarkNameSVG;
            }
        }
        
        setLogo(logoIcon);
        
    }, [logoProps, isLargeScreen]);

    useEffect(() => {
        if (notifRef.current) {
            notifRef.current.addEventListener('mouseleave', handleMouseLeave);
            notifRef.current.addEventListener('mouseenter', handleMouseEnter);
        } else {
            clearTimeout(timer);
        }
    }, [notifRef]);

    useEffect(() => {
        timer = setTimeout(() => {
            handleClickOnCloseNotif();
        }, showDuration);
        return () => {
            clearTimeout(timer);
        };
    }, [])

    return (
        <div className={`meetroom-info-box ${isInPreview ? 'is-in-preview' : ''}`}>
            <div className='meetroom-info-box__info'>
               
                <img src={logo} alt='' draggable={false} />
                    
                <hr />

                <div className="meetroom-info-box__info__icon">
                    <IconSvg svg={InfoSvg} title={translateJSON['meeting-info-title']}
                        onMouseDown={isPhoneScreen ? showShareModal : () => { setOptionInfoModal(MeetInfoOption.DETAIL) }} />
                    
                    {!isPhoneScreen && showNotif &&
                        <div className='meetroom-info-box__info__notif' ref={notifRef}>
                            <div className='meetroom-info-box__info__notif__header'>
                                <h4 id="text-meeting-room-link">{translateJSON['meeting-info-link']}</h4>
                                <IconSvg svg={CrossSvg} onMouseDown={handleClickOnCloseNotif} />
                            </div>
                            <div className='meetroom-info-box__info__notif__body'>
                                <div className='input-frame-link'>
                                    <div className="input-label">
                                        <span>{meetURL}</span>
                                    </div>
                                    
                                    <div className='icon-frame' data-interactive={true}>
                                        <IconSvg svg={FilesSvg} onMouseDown={() => copyToClipboard(meetURL)} />
                                        <IconSvg svg={HubSvg} onMouseDown={showShareModal} />
                                    </div>
                                </div>
                            </div>
                            <div className='meetroom-info-box__info__notif__triangle' />
                        </div>}
                </div>
            </div>
            
            {isPhoneScreen && <VideoAudioControls />}
        </div>
    )
};

export default InfoBox;