import colors from '../constants/colors';

export const generateRandom = (max) =>{
    const crypto = window.crypto || window.msCrypto;
    const randomValues = new Uint32Array(1);
    crypto.getRandomValues(randomValues);
    return Math.floor((randomValues[0] / (0xFFFFFFFF + 1)) * max); 
}

export function generateRandomColor() {
	const randomColorIndex = generateRandom(colors.length);
	return colors[randomColorIndex];
}