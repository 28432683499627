import React, {useEffect, useRef, useState, useCallback, useMemo} from 'react';
import { Track } from 'livekit-client';
import { isTrackReference, isEqualTrackRef } from '@livekit/components-core';
import {
    AudioTrack,
    ParticipantContextIfNeeded,
    TrackRefContext,
    VideoTrack,
    useEnsureParticipant,
    useEnsureTrackRef,
    useFeatureContext,
    useMaybeLayoutContext,
    useMaybeTrackRefContext,
    useParticipantTile,
} from '@livekit/components-react';
import { useDispatch, useSelector } from 'react-redux';

import { BackgroundBlur, VirtualBackground } from '@livekit/track-processors';

import { getScreenLayout } from '../../redux/meetSlice';
import { participantUpdated, selectAllActiveParticipants } from '../../redux/participantsSlice';
import useAuthContext, { useAudioVideoContext, useGeneralContext } from '../../hooks/useContext';
import { NetworkStatus, ParticipantRole } from '../../dataModal/ParticipantModal';
import { ReactComponent as SpeakingSvg } from '../../assets/icons/medium-volume.svg';
import { ReactComponent as RegularVolumeSVG } from '../../assets/icons/micro-in-screen.svg';
import { ReactComponent as MicroOffSvgOnLayout } from '../../assets/icons/cut-micro-in-screen.svg';
import { ReactComponent as RaiseHandSvg } from '../../assets/icons/raise-hand-24.svg';
import { ReactComponent as PinTwoSvg } from '../../assets/icons/pin-two.svg';
import { ReactComponent as CrownIconSvg } from '../../assets/icons/crown-icon.svg';

import ShareScreenActiveSvg from '../../assets/icons/share-screen-active.svg';
import { ReactComponent as NetworkStatusDangerSvg } from '../../assets/icons/network-status-danger.svg';
import { ReactComponent as NetworkStatusWarningSvg } from '../../assets/icons/network-status-warning.svg';
import { ReactComponent as NetworkStatusStableSvg } from '../../assets/icons/network-status-stable.svg';
import useClicHook from '../../hooks/useClick';
import CameraFailedMessage from '../SharedComponents/CameraFailedMessage';
import { PermissionStatus } from '../../context/AudioVideoContextProvider';
import { getInitialFromString } from '../../utils/getInitialFromString';
import useMediaQuery from '../../hooks/useMediaQuery';
import { SMALL_SCREEN } from '../../utils/breackpoints';
import { VisualEffectType } from '../SharedComponents/VisualEffectConfig';
import { fetchAvatarFromCaches } from '../../helpers/auth';


function TrackRefContextIfNeeded(props) {
  const hasContext = !!useMaybeTrackRefContext();
  return props.trackRef && !hasContext ? (
    <TrackRefContext.Provider value={props.trackRef}>{props.children}</TrackRefContext.Provider>
  ) : (
    <>{props.children}</>
  );
}

const capitalizeFirstLetter = (inputString)=> {
    if (!inputString) {
        return inputString;
    }
    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
}

const getDisplayName = (inputString) => {
    if (!inputString) {
        return 'Anonymous';
    }
    const spaceIndex = inputString.indexOf(' ');
    if (spaceIndex === -1) {
        return inputString;
    }
    const beforeFirstSpace = capitalizeFirstLetter(inputString.substring(0, spaceIndex));
    const afterFristSpace = inputString.substring(spaceIndex).trim();
    return beforeFirstSpace + ' ' + afterFristSpace.charAt(0).toUpperCase() + '.';
};

export function ParticipantTile({
    trackRef,
    track,
    children,
    onParticipantClick,
    disableSpeakingIndicator,
    styleAdd,
    cameraError,
    isInCarousel = false,
    ...htmlProps
}) {
    const trackReference = useEnsureTrackRef(trackRef ?? track);
    const { elementProps } = useParticipantTile({
        htmlProps,
        disableSpeakingIndicator,
        onParticipantClick,
        trackRef: trackReference,
    });
    
    const containerRef = useRef();
    const dispatch = useDispatch();
    const maybeTrackRef = useMaybeTrackRefContext();
    const screenLayoutType = useSelector(getScreenLayout);
    const p = useEnsureParticipant(trackReference.participant);
   
    const [loadVideoMetadataLoaded, setLoadVideoMetadataLoaded] = useState(!p.isLocal);
   
    const isPhoneScreen = useMediaQuery(SMALL_SCREEN);
    const layoutContext = useMaybeLayoutContext();

    const autoManageSubscription = useFeatureContext()?.autoSubscription;
    const foundParticipant = useSelector(selectAllActiveParticipants).find(item => item.identity === p.identity);
    const [isHover, setHover] = useState(false);

    const { teamID, userID, translateJSON, jobTitle } = useAuthContext();
    const { showJobTitle } = useGeneralContext();
    const { videoEnabled, cameraPermission } = useAudioVideoContext();
    const videoEnabledRef = useRef(videoEnabled);

    // const connectionQuality = useConnectionQualityIndicator(trackRef?.participant)?.quality ?? ConnectionQuality.Excellent;

    const isLoggedIn = !!teamID && !!userID;
    const ref = useRef();

    useEffect(() => {
        videoEnabledRef.current = videoEnabled;
    }, [videoEnabled]);

    useEffect(() => {
        if (!isLoggedIn) return;
        if (foundParticipant?.picturePath && !foundParticipant?.picture) {
            fetchImage();
        }
    }, [foundParticipant?.picturePath, isLoggedIn]);

    // const handleClick = useClicHook(single click, double click)
    const handleClick = useClicHook(
        () => { },
        () => {
            const isPinned = layoutContext.pin.state.find(item => isEqualTrackRef(item, trackReference));
            if (isPinned) {
            
                layoutContext.pin.dispatch?.({ msg: 'clear_pin' });
            } else {
                layoutContext.pin.dispatch({ msg: 'set_pin', trackReference: trackReference });
            }
        
        });
    
    const handleSubscribe = useCallback(
        (subscribed) => {
            if (
                trackReference.source &&
                !subscribed &&
                layoutContext &&
                layoutContext.pin.dispatch &&
                isTrackReference(trackReference, layoutContext.pin.state)
            ) {
                layoutContext.pin.dispatch({ msg: 'clear_pin' });
            }
        },
        [trackReference, layoutContext],
    );
    
    const fetchImage = async () => {
        if (!isLoggedIn) return;
        
        if (foundParticipant?.picture?.startsWith('data:image')) return;
        
        const blob = await fetchAvatarFromCaches(foundParticipant?.picturePath);
        if (blob) {
            if (ref.current) {
                ref.current.src = blob;
            }
            dispatch(participantUpdated({ ...foundParticipant, picture: blob }));
        }
    };
  	
    const root = document.documentElement;
    const nameRoleType = getComputedStyle(root).getPropertyValue('--meetroom-name-role-style');
    
    const showErrorMessage = p.isLocal && (cameraError || cameraPermission !== PermissionStatus.GRANTED) && videoEnabled;
    const showAvatar2 = !showErrorMessage && !p.isCameraEnabled && foundParticipant?.picture;
    const imgSrc = foundParticipant?.picture;
    const showInitial2 = !showErrorMessage && !p.isCameraEnabled && !foundParticipant?.picture;

    const showNetwork = foundParticipant?.networkStatus !== NetworkStatus.GOOD || isHover ;

    const isHost = foundParticipant?.role === ParticipantRole.HOST;
    const optionMicroMuted = p.isSpeaking ? <SpeakingSvg /> : !p.isMicrophoneEnabled ? <MicroOffSvgOnLayout /> : <RegularVolumeSVG />

    const displayName = getDisplayName(p.name);
    const initial = p.name && getInitialFromString(p.name);
    const connectionQualityIcon = foundParticipant?.networkStatus === NetworkStatus.GOOD ? <NetworkStatusStableSvg /> :
        foundParticipant?.networkStatus === NetworkStatus.LOW ? <NetworkStatusWarningSvg /> : <NetworkStatusDangerSvg />;
    const selectMin = Math.min(containerRef.current?.offsetHeight, containerRef.current?.offsetWidth);
    const min = isPhoneScreen ? containerRef.current?.offsetWidth : selectMin;
    const macaroonHeight = min ? 0.3 * min : '35vmin';
    const fontSize = min ? 0.15 * min : '12vmin';

    const sharedStyle = p?.isScreenShareEnabled && trackReference?.source === Track.Source.Camera ? {
        border: '2px solid #ffcf4fc4',
        background: 'linear-gradient(-120deg, #ffcf4f16, #ff9b4a16)'
    } : {};

    const height = containerRef.current?.offsetHeight;
    const newClassAdd = height < 100 ? 'less-than-100' : height > 250 ? 'more-than-250' : 'less-than-250';
    const newPadding = height < 100 ? '5px' : height > 250 ? '20px' : '10px';
    
    const featureVisualEffectData = localStorage.getItem('feature-virtual-effect');
	const featureVisualEffectRef = useRef();

	useEffect(() => {
		if (featureVisualEffectData) {
			featureVisualEffectRef.current = JSON.parse(featureVisualEffectData);
		}
		
    }, [featureVisualEffectData]);
    

    useEffect(() => {
        if (trackReference?.source === 'screen_share') return;
        if (!p.isCameraEnabled) return;
        if (!p.isLocal) return;
        if (cameraPermission !== PermissionStatus.GRANTED) return;
        if (!trackReference?.publication?.track) return;
        if (!loadVideoMetadataLoaded) return;
        loadVisualEffect()
    }, [trackReference?.source,
        p?.isLocal,
        featureVisualEffectData,
        p.isCameraEnabled,
        loadVideoMetadataLoaded,
        cameraPermission]);

    const handleLoadedMetadata = () => {
        if (p?.isLocal) {
            setLoadVideoMetadataLoaded(true);
        }
    }
    const loadVisualEffect = async () => {

        if (!trackReference?.publication?.track) return;
		const type = featureVisualEffectRef.current?.type;
        try {
            await trackReference.publication.track.stopProcessor();
			if (type === VisualEffectType.BLUR) {
				const blur = featureVisualEffectRef.current?.blur;
				const newProcessor = BackgroundBlur(blur);
				await trackReference.publication.track.setProcessor(newProcessor);
			} else if (type === VisualEffectType.IMAGE || type === VisualEffectType.UPLOAD) {
				const img = featureVisualEffectRef.current?.img;
				const newProcessor = VirtualBackground(`${img}`);
				await trackReference.publication.track.setProcessor(newProcessor);
			}
		} catch (e) {
			console.error('---e', e)
		}
    };
    
    if (!foundParticipant) return;
    
    const jobTitleElement = ()=>{
        let jobTitleDisplay;
        if(p.isLocal && jobTitle && showJobTitle){
            jobTitleDisplay = jobTitle;
        }else if(!p.isLocal && foundParticipant?.showJobTitle && foundParticipant?.jobTitle){
            jobTitleDisplay = foundParticipant?.jobTitle;
        }
        if(!jobTitleDisplay){
            return <></>;
        }
        return (<div className="name-role__bottom">
            <span className="role">{jobTitleDisplay}</span>
        </div>);
    }
    return (
        <div ref={containerRef} className='grid-item' style={{ position: 'relative', ...sharedStyle, ...styleAdd }} {...elementProps}
            data-lk-speaking={p.isSpeaking} //only when unmute but microphone permission is denied , otherwise keep true
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onMouseDown={handleClick} >
            <TrackRefContextIfNeeded trackRef={trackReference}>
                <ParticipantContextIfNeeded participant={trackReference.participant}>
                    {children ?? (
                        <>
                            {isTrackReference(trackReference) &&
                                (trackReference.publication?.kind === 'video' ||
                                    trackReference.source === Track.Source.Camera ||
                                    trackReference.source === Track.Source.ScreenShare) ? (
                                <VideoTrack
                                    onLoadedMetadata={handleLoadedMetadata}
                                    trackRef={trackReference}
                                    // onSubscriptionStatusChanged={handleSubscribe}
                                    manageSubscription={autoManageSubscription}
                                />
                            ) : (
                                isTrackReference(trackReference) && (
                                    <AudioTrack
                                        trackRef={trackReference}
                                    // onSubscriptionStatusChanged={handleSubscribe}
                                    />
                                )
                            )}
                            <div className='lk-participant-placeholder' >
                                {showErrorMessage && <CameraFailedMessage cameraError={cameraError} isSmall={isInCarousel} />}
                                {showAvatar2 && <img src={imgSrc} ref={ref} alt=' ' className={newClassAdd} style={{ height: macaroonHeight }} />}
                                {showInitial2 && <div className={`macaroon-box__frame  ${newClassAdd}`}
                                    style={{ backgroundColor: foundParticipant?.color ?? 'transparent', height: macaroonHeight }}>
                                    <span style={{ fontSize }}>{initial}</span>
                                </div>}
                            </div>
                            
                            {trackReference?.source === Track.Source.Camera ? (
                                <>
                                    <div className={`participant-metadata-overlay-right ${newClassAdd}`} style={{ '--padding': newPadding }}>
                                        {optionMicroMuted}
                                        {showNetwork && connectionQualityIcon}
                                    </div>
                                    <div className={`participant-metadata-overlay-bottom ${nameRoleType} ${newClassAdd}`} style={{ '--padding': newPadding }}>
                                        
                                        <div className="left-side" />
                                        
                                        <div className='name-role'>
                                            <div className="top">
                                                {foundParticipant?.isRaisingHand && <RaiseHandSvg />}
                                                {p.isScreenShareEnabled && <img className='opaque' src={ShareScreenActiveSvg} alt='share screen' />}
                                            </div>

                                            <div className="right">
                                                {foundParticipant?.isPinned && <PinTwoSvg/>}
                                            </div>
                                            <div className="name-role__top">
                                                {isHost && <CrownIconSvg />}
                                                <span title={p.name} className="participant-display-name">{p.name}</span>
                                            </div>
                                            {jobTitleElement()}
                                        </div>
                                        
                                    </div>
                                </>
                            ) : (
                                
                                <div className={`participant-metadata-overlay-bottom`} style={{'--padding':newPadding}}>
                                        <div className={`participant-display-name ${newClassAdd}`} >
                                            <span title={`${p.name} ${translateJSON['is-sharing-screen']}`}>{p.name} {translateJSON['is-sharing-screen']}</span>
                                        </div>
                                    </div>
                            )}
                        </>
                    )}
                </ParticipantContextIfNeeded>
            </TrackRefContextIfNeeded>
        </div>
    );
};