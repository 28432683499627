import { PermissionStatus } from "../context/AudioVideoContextProvider";

const handleCameraError = (error, setCameraPermission, callback = ()=>{}) => {
    if(error.message === 'Permission dismissed'){
        setCameraPermission(PermissionStatus.PROMPT);
        return;
    }
    switch (error.name) {
      case 'NotAllowedError':
      case 'PermissionDeniedError':
        console.error('Camera access not allowed.');
        setCameraPermission(PermissionStatus.DENIED);
        break;
      case 'NotFoundError':
        console.error('No camera found.');
        setCameraPermission(PermissionStatus.INVALID);
        break;
      case 'NotReadableError':
      case 'AbortError':
        console.error('Unable to access camera.');
        callback('NotReadableError')
        break;
      case 'OverconstrainedError':
        console.error('Constraints cannot be satisfied.');
        setCameraPermission(PermissionStatus.INVALID);
        break;
      default:
        console.error('An unknown error occurred.');
        setCameraPermission(PermissionStatus.INVALID);
        break;
    }
  };


const handleMicroError = (error, setMicroPermission) => {
    if(error.message === 'Permission dismissed'){
        setMicroPermission(PermissionStatus.PROMPT);
        return;
    }
    switch (error.name) {
      case 'NotAllowedError':
      case 'PermissionDeniedError':
        setMicroPermission(PermissionStatus.DENIED);
        break;
      case 'NotFoundError':
        setMicroPermission(PermissionStatus.INVALID);
        break;
      case 'NotReadableError':
      case 'AbortError':
        // setMicroPermission(PermissionStatus.PROMPT);
        break;
      case 'OverconstrainedError':
        setMicroPermission(PermissionStatus.INVALID);
        break;
      default:
        setMicroPermission(PermissionStatus.INVALID);
        break;
    }
  };

  export { handleCameraError, handleMicroError};