import React, { useEffect, useRef, useState } from "react";

import { ReactComponent as FilesSvg } from '../../assets/icons/file.svg';
import { ReactComponent as ArrowUpSvg } from '../../assets/icons/arrow-up.svg';
import { ReactComponent as ArrowDownSvg } from '../../assets/icons/arrow-down.svg';
import { ReactComponent as DoorSvg } from '../../assets/icons/door.svg';
import { ReactComponent as RadioCheckSvg } from '../../assets/icons/radio-check.svg';
import { ReactComponent as RadioSvg } from '../../assets/icons/radio.svg';
import { ReactComponent as CustomThemeSvg } from '../../assets/icons/paint.svg';
import { ReactComponent as ApplicationSvg } from '../../assets/icons/integration.svg';

import { ReactComponent as CheckBadgeSvg } from '../../assets/icons/check-badge.svg';

import { ReactComponent as EmptyChat }  from '../../assets/icons/empty-chat.svg';
import { ReactComponent as PlusSvg } from '../../assets/icons/plus.svg';
import { ReactComponent as MinSvg } from '../../assets/icons/min.svg';
import { ReactComponent as ArrowBackSvg } from '../../assets/icons/arrow-back.svg';
import { ReactComponent as ArrowNextSvg } from '../../assets/icons/arrow-next.svg';
import { ReactComponent as UploadSvg }  from '../../assets/icons/plus-icon.svg';
import { ReactComponent as ResetSvg } from '../../assets/icons/reset.svg';
import { ReactComponent as WarningSvg }  from '../../assets/icons/warning.svg';
import { ReactComponent as TrashSvg }  from '../../assets/icons/trash-with-stroke-24.svg';
import TemplateDarkPurplePng from '../../assets/pictures/template-dark-purple.png';
import TemplateLightPurplePng from '../../assets/pictures/template-light-purple.png';
import TemplateClassicMixedPng from '../../assets/pictures/template-classic-mixed.png';
import TemplateClassicDarkPng from '../../assets/pictures/template-classic-dark.png';
import TemplateClassicLightPng from '../../assets/pictures/template-classic-light.png';
import TemplateCustomPng from '../../assets/pictures/templates-customize.png';
import { getSolidOrLinearGradientWithOpacity, isLightBestSolidOrLinearGradient, linearGradientToHex } from "../../utils/color";
import { ThemeTemplateType, btn, dark, darkViolet, lightLinearViolet, lightWhite, linearViolet, semiTransparentDarkGray, superExtraSemiTransparentDarkGray, superLightWhite, white } from "../../constants/colors";

import LogoWithWhiteNameSVG from "../../assets/icons/logotype-ringover-white-text.svg";
import LogoWithDarkNameSVG from "../../assets/icons/logotype-ringover-black-text.svg";

import PollsPng from '../../assets/brands/brand=polls.png';
import QAPng from '../../assets/brands/QandA.png';
import WhiteboardPng from '../../assets/brands/Whiteboard.png';
import useAuthContext from "../../hooks/useContext";
import PreviewInEditPanel from "./PreviewInEditPanel";
import BtnRegular from "../BtnRegular";
import useUndoRedo from "../../hooks/useUndoRedo";
import CustomThemeEditPanel from "./CustomThemeEditPanel";
import { getThemesList, updateEventData } from "../../helpers/eventApi";
import isValidHttpUrl from "../../utils/isValidHttpUrl";
import { createToolTypeMapping, IntegrationItem, IntegrationType } from "../MeetRoom/IntegrationsPanel";
import CardPoll from "../MeetRoom/Polls/CardPoll";
import CardQA from "../MeetRoom/QA/CardQA";


const ThemeEditPanel = ({ themeEdit, setThemeEdit, setNeedUpdateInstantMeeting, setShowToast, setToast}) => {

    const [themeEditUpdate, setThemeEditUpdate] = useState({ ...themeEdit });
    const { translateJSON, teamID, userID } = useAuthContext();

    const [isExpendNameDesc, setExpendNameDesc] = useState(true);
    const [isExpendAccessPermissions, setExpendAccessPermissions] = useState(false);
    const [isExpendOtherPermissions, setExpendOtherPermissions] = useState(false);
    const [isExpendThemeLogo, setExpendThemeLogo] = useState(false);
    const [isExpendApplications, setExpendApplications] = useState(false);
    const [showCustomizationPanel, setShowCustomizationPanel] = useState(false);
    const [clickCustomization, setCustomization] = useState();
    const [themeList, setThemeList] = useState([]);
    const [eventTools, setEventTools] = useState(themeEdit.event_tools);
    const [toolSelected, setToolSelected]= useState();
    const initState = {
        theme: themeEditUpdate?.configuration?.appearance?.theme || ThemeTemplateType.DARK_PURPLE,
        shadow: themeEditUpdate?.configuration?.appearance?.shadow,
        primary_color: themeEditUpdate?.configuration?.appearance?.primary_color,
        background_primary_color: themeEditUpdate?.configuration?.appearance?.background_primary_color,
        secondary_color: themeEditUpdate?.configuration?.appearance?.secondary_color,
        background_secondary_color: themeEditUpdate?.configuration?.appearance?.background_secondary_color,
        background_image: themeEditUpdate?.configuration?.appearance?.background_image,
        logo: themeEditUpdate?.configuration?.appearance?.logo,
        use_background_image: themeEditUpdate?.configuration?.appearance?.use_background_image,
        name_role_background: themeEditUpdate?.configuration?.appearance?.name_role_background ?? 'secondary',
    };

    const {
        state: themeState,
        setState: setThemeState,
        undo,
        redo,
        reset,
        isUndoPossible,
        isRedoPossible
    } = useUndoRedo({ ...initState });

    const [zoom, setZoom] = useState(0.5);
    const zoomInitRef = useRef();
    const [previewLeft, setPreviewLeft] = useState(0);
    const previewExpendRef = useRef();

    let observerExpend;
    
    const [primaryColor, setPrimaryColor] = useState();
    const [secondaryColor, setSecondaryColor] = useState();
    const [backgroundPrimaryColor, setBackgroundPrimaryColor] = useState();
    const [backgroundSecondaryColor, setBackgroundSecondaryColor] = useState();
  
    const [shadow, setShadow] = useState('no');
    const [logoUploaded, setLogoUploaded] = useState();
    const [showLogoUploadedWarning, setShowLogoUploadWarning] = useState(false);
    const [backgroundImageUpload, setBackgroundImageUpload] = useState();
    const root = document.querySelector(':root');

    const updateContrastSecondaryColorInBackgroundSecondaryColor = () => {

        if (!secondaryColor || !backgroundSecondaryColor) return;
        const secondaryColor25 = getSolidOrLinearGradientWithOpacity(secondaryColor, 0.25);
        const isLightBest = isLightBestSolidOrLinearGradient(secondaryColor, backgroundSecondaryColor);
        const contrastSecondaryInBackground = isLightBest ? white : dark;
        root.style.setProperty('--preview-contrast-secondary-color-in-background-secondary-color', contrastSecondaryInBackground);

        const isLightBest25Secondary = isLightBestSolidOrLinearGradient(secondaryColor25, backgroundSecondaryColor);
        const contrastSecondary25InBackgroundSecondary = isLightBest25Secondary ? white : dark;
        root.style.setProperty('--preview-contrast-secondary-color-25-in-background-secondary-color', contrastSecondary25InBackgroundSecondary);

        const isLightBest25Primary = isLightBestSolidOrLinearGradient(secondaryColor25, backgroundPrimaryColor);
        const contrastSecondary25InPrimary = isLightBest25Primary ? white : dark;
        root.style.setProperty('--preview-contrast-secondary-color-25-in-background-primary-color', contrastSecondary25InPrimary);
    }

    const updateContrastSecondaryColorInBackgroundPrimaryColor = () => {

        if (!secondaryColor || !backgroundPrimaryColor) return;
        const secondaryColor25 = getSolidOrLinearGradientWithOpacity(secondaryColor, 0.25);
         
        const isLightBest25Primary = isLightBestSolidOrLinearGradient(secondaryColor25, backgroundPrimaryColor);
        const contrastSecondary25InPrimary = isLightBest25Primary ? white : dark;
        root.style.setProperty('--preview-contrast-secondary-color-25-in-background-primary-color', contrastSecondary25InPrimary);
     }
    
    const updateContrastPrimaryInBackgroundPrimary = () => {

        if (!primaryColor || !backgroundPrimaryColor) return;
        const isLightBest = isLightBestSolidOrLinearGradient(primaryColor, backgroundPrimaryColor);
        const contrastPrimaryInBackgroundPrimary = isLightBest ? white : dark;
        root.style.setProperty('--preview-contrast-primary-in-background-primary-color', contrastPrimaryInBackgroundPrimary);
    }

    const updateContrastPrimaryInBackgroundSecondary = () => {

        if (!primaryColor || !backgroundSecondaryColor) return;
        const isLightBest = isLightBestSolidOrLinearGradient(primaryColor, backgroundSecondaryColor);
        const contrastPrimaryInBackgroundSecondary = isLightBest ? lightWhite : semiTransparentDarkGray;
        root.style.setProperty('--preview-contrast-primary-in-background-secondary-color-50', contrastPrimaryInBackgroundSecondary);
    }

    const updateContrastBackgroundSecondaryColorInBackgroundPrimaryColor = () => {
        if (!backgroundSecondaryColor || !backgroundPrimaryColor) return;
        const isLightBestSecondary = isLightBestSolidOrLinearGradient(backgroundSecondaryColor, backgroundPrimaryColor);
        const contrastBackgroundSecondaryColorInPrimary = isLightBestSecondary ? superLightWhite : superExtraSemiTransparentDarkGray;
        root.style.setProperty('--preview-contrast-background-secondary-color-in-background-primary-color', contrastBackgroundSecondaryColorInPrimary);
    }
    
    useEffect(() => {
        let shadowsCss = '0px 4px 24px -1px rgba(0, 0, 0, 0.05)';
        if (shadow === 'no') {
            shadowsCss = 'unset';
        } else if (shadow === 'dark') {
            shadowsCss = '0px 4px 24px -1px rgba(0, 0, 0, 0.25)';
        }
        root.style.setProperty('--preview-box-shadow', shadowsCss);
     }, [shadow]);
    
    useEffect(() => {
        const firstIndex = themeState?.shadow?.indexOf(' ') ?? 0;
        const subString = themeState?.shadow?.substring(0, firstIndex) ?? '';
        const lowercaseString = subString.toLowerCase() || themeState?.shadow || 'no';
        setShadow(lowercaseString);
    }, [themeState?.shadow]);
  
    useEffect(() => {
        setPrimaryColor(themeState?.primary_color || lightLinearViolet);
    }, [themeState?.primary_color]);
   
    useEffect(() => {
        if (!primaryColor) return;
        root.style.setProperty('--preview-primary-color', primaryColor);

        if (!backgroundPrimaryColor) return;
        updateContrastPrimaryInBackgroundSecondary();
        updateContrastPrimaryInBackgroundPrimary();
    }, [primaryColor]);


    useEffect(() => {
        setBackgroundPrimaryColor(themeState?.background_primary_color || linearViolet);
    }, [themeState?.background_primary_color]);

      
    useEffect(() => {
        if (!backgroundPrimaryColor) return;
        root.style.setProperty('--preview-background-primary-color', backgroundPrimaryColor);
        const isLightBest = isLightBestSolidOrLinearGradient(backgroundPrimaryColor);
        const contrastBackgroundPrimaryColor5 = isLightBest ? superLightWhite : superExtraSemiTransparentDarkGray;
        root.style.setProperty('--preview-contrast-background-primary-color-5', contrastBackgroundPrimaryColor5);

        updateContrastPrimaryInBackgroundPrimary();
        updateContrastBackgroundSecondaryColorInBackgroundPrimaryColor();
        updateContrastSecondaryColorInBackgroundPrimaryColor();
    }, [backgroundPrimaryColor]);


    useEffect(() => {
        setSecondaryColor(themeState?.secondary_color || darkViolet);
    }, [themeState?.secondary_color]);

     useEffect(() => {
        if (!secondaryColor) return;
        root.style.setProperty('--preview-secondary-color', secondaryColor);

        const secondaryColor5 = getSolidOrLinearGradientWithOpacity(secondaryColor, 0.05);
        root.style.setProperty('--preview-secondary-color-5', secondaryColor5);

        const secondaryColor25 = getSolidOrLinearGradientWithOpacity(secondaryColor, 0.25);
        root.style.setProperty('--preview-secondary-color-25', secondaryColor25);

        const secondaryColor50 = getSolidOrLinearGradientWithOpacity(secondaryColor, 0.5);
        root.style.setProperty('--preview-secondary-color-50', secondaryColor50);

        updateContrastSecondaryColorInBackgroundSecondaryColor();
        updateContrastSecondaryColorInBackgroundPrimaryColor();

    }, [secondaryColor]);


    useEffect(() => {
        setBackgroundSecondaryColor(themeState?.background_secondary_color || btn);
    }, [themeState?.background_secondary_color]);


    useEffect(() => {

        if (!backgroundSecondaryColor) return;
        root.style.setProperty('--preview-background-secondary-color', backgroundSecondaryColor);
        const isLightBest = isLightBestSolidOrLinearGradient(backgroundSecondaryColor);
        const contrastBackgroundSecondaryColor = isLightBest ? white : dark;
        root.style.setProperty('--preview-contrast-background-secondary-color', contrastBackgroundSecondaryColor);
        const contrastBackgroundSecondaryColor5 = isLightBest ? superLightWhite : superExtraSemiTransparentDarkGray;
        root.style.setProperty('--preview-contrast-background-secondary-color-5', contrastBackgroundSecondaryColor5);
        const contrastBackgroundSecondaryColor50 = isLightBest ? lightWhite : semiTransparentDarkGray;
        root.style.setProperty('--preview-contrast-background-secondary-color-50', contrastBackgroundSecondaryColor50);

        updateContrastBackgroundSecondaryColorInBackgroundPrimaryColor();
        updateContrastSecondaryColorInBackgroundSecondaryColor();

        updateContrastPrimaryInBackgroundSecondary();
    }, [backgroundSecondaryColor]);

    const lastThemeSelected = useRef(themeState?.theme);
    
    useEffect(() => {
        if (clickCustomization === undefined) return;
        setShowCustomizationPanel(true);
    }, [clickCustomization]);

    useEffect(() => {
        const width = previewExpendRef?.current?.getBoundingClientRect()?.width;

        if (!width) return;
        const isPreviewLarger = zoom * 1800 >= width;
        setPreviewLeft(isPreviewLarger ? '0' : 'unset');

    }, [previewExpendRef?.current?.getBoundingClientRect()?.width, zoom]);



    const handleKeyDown = (e) => {
        if (e.keyCode == '27' && zoomInitRef.current) { // esc
            setZoom(zoomInitRef.current)
		}
    }
    
    useEffect(()=>{
        
        setEventTools(prev=> prev.map(item=>{
            const typesTrans = (item?.types ?? []).map(type => createToolTypeMapping(translateJSON)[type] || type);
            if(item.name === IntegrationType.POLL){
                return {...item, 
                    typesTrans,
                    nameTrans: translateJSON['room-apps-polls-title'], 
                    descriptionTrans: translateJSON['apps-polls-desc'], 
                    longDescriptionTrans: translateJSON['apps-polls-desc-long']}
            }else if(item.name === IntegrationType.QA){
                return {...item, 
                    typesTrans,
                    nameTrans: translateJSON['room-apps-qa-title'], 
                    descriptionTrans: translateJSON['apps-qa-desc'], 
                    longDescriptionTrans: translateJSON['apps-qa-desc-long']}
            }else if(item.name === IntegrationType.Whiteboard){
                return {...item, 
                    typesTrans,
                    nameTrans: translateJSON['room-apps-whiteboard-title'], 
                    descriptionTrans: translateJSON['apps-whiteboard-desc'], 
                    longDescriptionTrans: translateJSON['apps-whiteboard-desc-long']}
            }
            return item;
        }))
    },[!!eventTools]);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);

            root.style.setProperty('--preview-background-primary-color', '');
            root.style.setProperty('--preview-background-secondary-color', '');
            root.style.setProperty('--preview-background-secondary-color-50', '');
            root.style.setProperty('--preview-background-secondary-color-25', '');
            root.style.setProperty('--preview-contrast-background-primary-color-5', '');
            root.style.setProperty('--preview-contrast-background-secondary-color', '');
            root.style.setProperty('--preview-contrast-background-secondary-color-50', '');
            root.style.setProperty('--preview-contrast-background-secondary-color-25', '');
            root.style.setProperty('--preview-contrast-background-secondary-color-15', '');
            root.style.setProperty('--preview-contrast-background-secondary-color-5', '');
            root.style.setProperty('--preview-contrast-background-secondary-color-in-background-primary-color', '');
            root.style.setProperty('--preview-primary-color', '');
            root.style.setProperty('--preview-primary-color-50', '');
            root.style.setProperty('--preview-primary-color-25', '');
            root.style.setProperty('--preview-contrast-primary-color', '');
            root.style.setProperty('--preview-contrast-primary-color-50', '');
            root.style.setProperty('--preview-contrast-primary-color-25', '');
            root.style.setProperty('--preview-contrast-primary-color-15', '');
            root.style.setProperty('--preview-contrast-primary-color-5', '');
            root.style.setProperty('--preview-secondary-color', '');
            root.style.setProperty('--preview-secondary-color-5', '');
            root.style.setProperty('--preview-secondary-color-50', '');
            root.style.setProperty('--preview-secondary-color-25', '');
            root.style.setProperty('--preview-contrast-secondary-color', '');
            root.style.setProperty('--preview-contrast-secondary-color-in-background-secondary-color', '');
            root.style.setProperty('--preview-contrast-secondary-color-25', '');
            root.style.setProperty('--preview-contrast-secondary-color-25-in-background-primary-color', '');
            root.style.setProperty('--preview-contrast-secondary-color-25-in-background-secondary-color', '');
            root.style.setProperty('--preview-contrast-secondary-color-15', '');
            root.style.setProperty('--preview-contrast-secondary-color25', '');
            root.style.setProperty('--preview-contrast-secondary-color25-5', '');
            root.style.setProperty('--preview-contrast-secondary-color-in-primary', '');
            root.style.setProperty('--preview-contrast-secondary-color25-in-primary', '');
            root.style.setProperty('--preview-box-shadow', '');
            root.style.setProperty('--preview-contrast-primary-in-background-primary-color', '');
            root.style.setProperty('--preview-contrast-primary-in-background-secondary-color-50', '');
        }
    }, [])
    
    useEffect(() => {
        if (previewExpendRef?.current && !observerExpend) {
            observerExpend = new ResizeObserver(entries => {

                const width = entries[0].contentRect.width;
                const height = entries[0].contentRect.height;
                const scaleWidth = width / 1800;
                const scaleHeight = (window.innerHeight - 90 - 20 - 76) / 925;
                const scale = Math.min(scaleWidth, scaleHeight, 1);
                zoomInitRef.current = scale;
                setZoom(scale);
            })
            observerExpend.observe(previewExpendRef.current);
        }
        return () => {
            observerExpend?.disconnect();
        }
        
    }, [previewExpendRef?.current, showCustomizationPanel]);
	
    const fetchThemesList = async () => {
        const list = await getThemesList({ teamID, userID }) ?? [];
        setThemeList(list);
    };

    useEffect(()=>{
        fetchThemesList();
    },[])

    const saveEdit = () => {
        const foundDefaultAppearance = themeList?.filter(item=>item.title === themeState.theme)?.[0]?.configuration?.appearance;
        const isCUSTOMIZE = themeState.theme === ThemeTemplateType.CUSTOMIZE;
        let appearance = isCUSTOMIZE? themeState : {logo: themeState.logo, ...foundDefaultAppearance}
        if(isCUSTOMIZE){
            //need convert to hexa
            const primary_color = linearGradientToHex(themeState?.primary_color?.toLowerCase());
            const background_primary_color = linearGradientToHex(themeState?.background_primary_color?.toLowerCase());
            const secondary_color = linearGradientToHex(themeState?.secondary_color?.toLowerCase());
            const background_secondary_color = linearGradientToHex(themeState?.background_secondary_color?.toLowerCase());
            appearance = {...appearance, primary_color, background_primary_color, secondary_color, background_secondary_color}
        }
        const themeToSave = {
            ...themeEditUpdate,
            configuration: {
                ...themeEditUpdate.configuration,
                appearance: {
                    ...themeEditUpdate.configuration.appearance,
                    ...appearance
                }
            }
        }
        
        const findDifferentIsActive = (arr1, arr2) => {
            return arr1.filter(item1 => 
                arr2.some(item2 => 
                    item1.name === item2.name && item1.is_active !== item2.is_active
                )
            );
        };

        
        const result1 = findDifferentIsActive(eventTools, themeEditUpdate.event_tools);
        const result2 = findDifferentIsActive(themeEditUpdate.event_tools, eventTools);

        const combinedResult = [...result1, ...result2.filter(item2 => !result1.some(item1 => item1.name === item2.name))];
    
        updateEventData({ eventUUID: themeToSave?.event_uuid, teamID, userID, themeToUpdate: themeToSave, logo: logoUploaded?.file, backgroundImage: backgroundImageUpload?.file, eventToolsToUpdate:combinedResult.map(item=>item?.event_tool_uuid) })
            .then(res => {
                setNeedUpdateInstantMeeting(prev => !prev);
                setShowToast(true);
                
                if(res === 200){
                    setToast(translateJSON['room-changes-saved']);
                }else if(res === 304){
                    setToast(translateJSON['room-no-changes']);
                }
                setThemeEdit(null);
            }).catch(err => {
                console.error('patch theme failed: ' + err);
                let msg = err?.message
                if(err?.message === 400){
                    msg = translateJSON['error-room-save-failed']
                }else if(err?.message === 403){
                    msg = translateJSON['error-room-not-admin']
                }
                window.bus.publish("alert", msg);
            });
        
    }
    
    const fileUploadLimit = 1 * 1024 * 1024; // 1MB in bytes. Formula: 1MB = 1 * 1024 * 1024.
    const handleUploadIcon = (e) => {
        const file = e.target.files[0];
        if (file && file.size <= fileUploadLimit) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result.toString();
                setLogoUploaded({ file, base64: base64String });
                if(showLogoUploadedWarning){
                    setShowLogoUploadWarning(false)
                }
            }
            reader.readAsDataURL(file);
        }else{
            setShowLogoUploadWarning(true);
        }
    }

    const enterMode = themeEditUpdate?.configuration?.settings?.mode;

    const enterModeElement = (mode, title, desc) =>
        <div className='option-frame' key={mode} onMouseDown={() =>
            setThemeEditUpdate((prev) => 
                ({
                    ...prev,
                    configuration: {
                        ...prev.configuration,
                        settings: {
                            ...prev.configuration?.settings,
                            mode
                        }
                    }
                })
            )}>
            {enterMode === mode ? <RadioCheckSvg /> : <RadioSvg />}
            <div className='option-frame__text'>
                <span>{translateJSON[title]}</span>
                <span>{translateJSON[desc]}</span>
            </div>
        </div>;
    
    const permissionElement = (key, title, desc) => {

        const checked = !themeEditUpdate?.configuration?.['live_settings']?.[key]; 

        const update = () =>
            setThemeEditUpdate(prev => {
                return {
                    ...prev,
                    configuration: {
                        ...prev.configuration,
                        ['live_settings']: {
                            ...prev.configuration?.['live_settings'],
                            [key]: checked
                        }
                    }
                };
            });
        return (
            <div className='check-frame other-permissions' key={key}>
                <input type="checkbox" id={`switch-${key}`} checked={checked} onChange={update} />
                <label htmlFor={`switch-${key}`}>Toggle</label>
                <div onMouseDown={update}>
                    <span>{title}</span>
                    <span>{desc}</span>
                </div>
            </div>
        );
    }
    
    const themeOptionElement = (option) => {
        const title = option?.title;
        let img = TemplateCustomPng;
        let titleString = translateJSON['themes-customize'];
        switch (title) {
            case ThemeTemplateType.DARK_PURPLE: 
                img = TemplateDarkPurplePng;
                titleString = translateJSON['themes-dark-purple'];
                break;
            case ThemeTemplateType.LIGHT_PURPLE: 
                img = TemplateLightPurplePng;
                titleString = translateJSON['themes-light-purple'];
                break;
            case ThemeTemplateType.CLASSIC_MIXED: 
                img = TemplateClassicMixedPng;
                titleString = translateJSON['themes-classic-mixed'];
                break;
            case ThemeTemplateType.CLASSIC_DARK: 
                img = TemplateClassicDarkPng;
                titleString = translateJSON['themes-classic-dark'];
                break;
            case ThemeTemplateType.CLASSIC_LIGHT: 
                img = TemplateClassicLightPng;
                titleString = translateJSON['themes-classic-light'];
                break;
        }
        return (
            <div key={title} className='template-item' onMouseDown={() => {
                if (title === ThemeTemplateType.CUSTOMIZE) {
                    setCustomization(prev => !prev);
                    const newState = { ...themeState, theme: title };
                    setThemeState(newState);
                }else{
                    const newValues = {
                        primary_color: primaryColor,
                        secondary_color: secondaryColor,
                        background_primary_color: backgroundPrimaryColor,
                        background_secondary_color: backgroundSecondaryColor
                    };

                    const appearance = option?.configuration?.appearance;
                    delete appearance?.appearance_uuid;
                    delete appearance?.logo;
                    delete appearance?.music; // todo
                    
                    const firstIndex = appearance?.shadow?.indexOf(' ') ?? 0;
                    const subString = appearance?.shadow?.substring(0, firstIndex) ?? '';
                    const shadow = subString.toLowerCase() || themeState.shadow || 'no';
                    const name_role_background = appearance?.name_role_background || themeState.name_role_background || 'secondary';
                    const newState = { ...themeState, theme: title, ...appearance, ...newValues, shadow, name_role_background };
                    
                    setThemeState(newState);
                }
                
            }}>
                
                <div className={`template-item__outside ${themeState?.theme === title ? 'is-selected' : ''}`}>
                    <div className={`template-item__inside ${themeState?.theme === title ? 'is-selected' : ''}`}>
                        <img src={img} alt='' />
                    </div>
                </div>
                <span>{titleString}</span>
            </div>
        );
    }
    
    const update = () =>
        setToolSelected(prev=>{
            setEventTools(ele=> ele.map(item=>item.name === prev.name ? {...item, is_active: !item.is_active}: item));
            return {...prev, is_active: !prev.is_active}}
        );

    const customPanelProps = {
        themeState,
        primaryColor,
        secondaryColor,
        backgroundPrimaryColor,
        backgroundSecondaryColor,
        shadow,
        backgroundImageUpload,
        setBackgroundImageUpload,
        setThemeState,
        cancelEditCustomPanel: () => {
            setThemeState({ ...themeState, theme: lastThemeSelected.current || ThemeTemplateType.DARK_PURPLE });
            setShowCustomizationPanel(false);
        },
        saveCustomTheme: () => {
            lastThemeSelected.current = ThemeTemplateType.CUSTOMIZE;
            setShowCustomizationPanel(false);
        }
    }

    const showGeneralConfig = !showCustomizationPanel && !!themeEdit;
    const showThemeCustomization = showCustomizationPanel && !!themeEdit;
    let logoImage, logoPreview;
    if (logoUploaded) {
        logoImage = `url(${logoUploaded?.base64})`;
        logoPreview = `url(${logoUploaded?.base64})`;
    } else {
        if (themeState?.logo && isValidHttpUrl(themeState?.logo)) {
            logoImage = `url(${themeState?.logo})`;
            logoPreview = `url(${themeState?.logo})`;
        } else {
            const isLightBestForLogo = isLightBestSolidOrLinearGradient(primaryColor, backgroundPrimaryColor);
            let logoIcon = LogoWithWhiteNameSVG;
            if (!isLightBestForLogo) {
                logoIcon = LogoWithDarkNameSVG;
            }
            logoPreview = `url(${logoIcon})`;
        }
    }

    let backgroundImagePreview;
    if (backgroundImageUpload) {
        backgroundImagePreview = `url(${backgroundImageUpload?.base64})`;
    } else {
        if (themeState?.background_image) {
            backgroundImagePreview = `url(${themeState?.background_image})`;
        }
    }
    const hasValidLogo = themeState.logo && isValidHttpUrl(themeState.logo);
    const needTrashIcon = hasValidLogo || logoUploaded;
    const activeApp = eventTools.filter(item => item.is_active);
    const ringoversApp = eventTools.filter(item => !item.is_active && item?.types.includes('By Ringover'));
    
    const emptyState = translateJSON['apps-empty-state'] || "You haven't added any apps yet. Select the Add more tab to add apps to your room."
    const parts = emptyState.split('. ');
  
    return (
        <div className={`homepage__theme-edit ${!!themeEdit ? 'show' : 'hidden'}`}>
            {showGeneralConfig &&
                <div className={`homepage__theme-edit__panel ${!!themeEdit ? 'show' : 'hidden'} ${showCustomizationPanel ? 'is-custom-panel' : ''}`}>
                    <div className='homepage__theme-edit__panel__body'>
                        <div className='homepage__theme-edit__panel__item' key='name-desc'>
                            <div className='homepage__theme-edit__panel__item__header'
                                onMouseDown={() => setExpendNameDesc(prev => !prev)}>
                                <div>
                                    <FilesSvg />
                                    <span>{translateJSON['room-name-desc-title']|| 'Room name and description'}</span>
                                </div>
						
                                {isExpendNameDesc ? <ArrowUpSvg /> : <ArrowDownSvg />}
						
                            </div>
                            {isExpendNameDesc &&
                                <div className='homepage__theme-edit__panel__item__body'>
                                    <input placeholder={translateJSON['placeholder-your-room'] || 'Room name'} 
                                        disabled
                                        readOnly
                                        value={themeEditUpdate?.title}
                                        // onChange={(event) => setThemeEditUpdate((prev) => ({ ...prev, title: event.target.value }))} 
                                        />
                                    <textarea placeholder={translateJSON['placeholder-room-desc'] || 'Room description'}
                                        rows={4}
                                        defaultValue={themeEditUpdate?.description}
                                        onChange={(event) =>
                                            setThemeEditUpdate((prev) => ({ ...prev, description: event.target.value }))} />
                                </div>}
                        </div>

                        <div className='homepage__theme-edit__panel__item' key='access-permissions'>
                            <div className='homepage__theme-edit__panel__item__header'
                                onMouseDown={() => setExpendAccessPermissions(prev => !prev)}>
                                <div>
                                    <DoorSvg />
                                    <span>{translateJSON['access-permissions-title'] || 'Access permissions'}</span>
                                </div>
                                {isExpendAccessPermissions ? <ArrowUpSvg /> : <ArrowDownSvg />}
                            </div>
                            {isExpendAccessPermissions &&
                                <div className='homepage__theme-edit__panel__item__body'>
                                    {enterModeElement('anyone with the link can enter', 'settings-host-withlink', 'host-withlink-desc')}
                                    {enterModeElement('knock to enter', 'settings-host-knock', 'host-knock-desc')}
                                </div>}
                        </div>

                        <div className='homepage__theme-edit__panel__item' key='other-permissions'>
                            <div className='homepage__theme-edit__panel__item__header'
                                onMouseDown={() => {
                                    setExpendOtherPermissions(prev => !prev)
                                }}>
                                <div>
                                    <CheckBadgeSvg />
                                    <span>{translateJSON['meeting-permissions-title'] || 'Meeting permissions'}</span>
                                </div>
                                {isExpendOtherPermissions ? <ArrowUpSvg /> : <ArrowDownSvg />}
                            </div>
                            {isExpendOtherPermissions &&
                                <div className='homepage__theme-edit__panel__item__body'>
                                    {permissionElement('open_chat', translateJSON['openchat-tooltip'], translateJSON['permissions-chat-desc'])}
                                    {permissionElement('reactions', translateJSON['permissions-reactions-title'], translateJSON['permissions-reactions-desc'])}
                                    {permissionElement('screen_share', translateJSON['permissions-screen-sharing-title'], translateJSON['permissions-screen-sharing-desc'])}
                                </div>}
                        </div>

                        <div className='homepage__theme-edit__panel__item' key='custom-theme-logo'>
                            <div className='homepage__theme-edit__panel__item__header'
                                onMouseDown={(e) => setExpendThemeLogo(prev => !prev)}>
                                <div>
                                    <CustomThemeSvg />
                                    <span>{translateJSON['custom-theme-logo-title'] || 'Custom theme and logo'}</span>
                                </div>
                                {isExpendThemeLogo ? <ArrowUpSvg /> : <ArrowDownSvg />}
						
                            </div>
                            {isExpendThemeLogo &&
                                <div className='homepage__theme-edit__panel__item__body'>
                                    <div className='theme-configuration'>
                                        <div className='card'>
                                            <span className="title">{translateJSON['themes-title'] || 'Themes'}</span>
                                            <div className='template-list'>
                                                {themeList.map(item =>themeOptionElement(item))}
                                                {themeOptionElement({title:ThemeTemplateType.CUSTOMIZE})}
                                            </div>
                                        </div>
                                        <div className='card'>
                                            <span className="title">{translateJSON['logo-title'] || 'Logo'}</span>
                                            <div className='card__color-item__upload-btn'>
                                                <div className="card__color-item__upload-btn__bg">
                                                    <button style={{ backgroundImage: logoImage }} >
                                                        <div>
                                                            <UploadSvg />
                                                            <input type='file' onClick={(event) => event.target.value = ''} name="upload-icon" onChange={handleUploadIcon} accept=".png, .jpg, .jpeg, .svg" />
                                                        </div>
                                                    </button>
                                                    {needTrashIcon && <TrashSvg className='trash-svg' onMouseDown={() => {
                                                        setLogoUploaded(null);
                                                        setThemeState({ ...themeState, logo:''});
                                                    }} />}
                                                    
                                                </div>
                                                <div className="logo-warning-container">
                                                    {showLogoUploadedWarning && <WarningSvg />}
                                                    <span type={`${showLogoUploadedWarning ? 'logo-warning':'logo'}`}>{translateJSON['logo-file-formats'] || 'Accepted file format: JPEG, PNG (max. size 1 MB)'}</span>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className='homepage__theme-edit__panel__item' key='applications'>
                            <div className='homepage__theme-edit__panel__item__header'
                                onMouseDown={() => setExpendApplications(prev => !prev)}>
                                <div>
                                    <ApplicationSvg />
                                    <span>{translateJSON['room-applications-title'] || 'Applications'}</span>
                                </div>
                                {isExpendApplications ? <ArrowUpSvg /> : <ArrowDownSvg />}
						
                            </div>
                            {isExpendApplications &&
                                <div className='homepage__theme-edit__panel__item__body application'>
                                    {!toolSelected && <>
                                        <div className='left'>
                                            <span type='added-apps'>{translateJSON['room-added-apps'] || 'Added apps'}</span>
                                            <div className="left-list">
                                                {activeApp.length !== 0 && 
                                                
                                                    activeApp.map((item,index)=> {
                                                        let imgSrc = QAPng;

                                                        if(item.name === 'poll'){
                                                            imgSrc = PollsPng;
                                                        }
                                                        if(item.name === 'White board'){
                                                            imgSrc = WhiteboardPng;
                                                        }
                                                        return <div className="active-app" key={index} onMouseDown={()=>setToolSelected(item)}>
                                                            <img src={imgSrc} alt={item.name} />
                                                            <span type='app-name'>{item.name}</span>
                                                        </div>
                                                    })
                                                    
                                                }
                                            </div>
                                            {activeApp.length === 0 && 
                                                <>
                                                    <EmptyChat />
                                                    <div>
                                                        <span type='empty-added' key='part1'>{parts[0]}. </span>
                                                        <span type='empty-added' key='part2'>{parts[1]}</span>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        <div className="divide"/>
                                        <div className='right'>
                                            <span type='other-apps'>{translateJSON['room-other-apps'] || 'Other apps'}</span>
                                            {ringoversApp.length !== 0 && 
                                                <div className="group">
                                                    <span className="group-name">{translateJSON['apps-tag-by-ringover'] || 'By Ringover'}</span>
                                                    {ringoversApp.map((item, index)=><IntegrationItem tool={item} key={index} select={()=>setToolSelected(item)} />)}
                                                </div>
                                            }

                                            {/* {othersApp.length !== 0 && 
                                                <div className="group">
                                                    <span className="group-name">Other apps</span>
                                                    {othersApp.map((item, index)=><IntegrationItem tool={item} key={index} setTypeSelected={setToolSelected}/>)}
                                                </div>
                                            } */}
                                        </div>
                                    </>}
                                    {toolSelected && toolSelected?.name === IntegrationType.POLL && 
                                        <CardPoll
                                            cardItem={toolSelected}
                                            closeCard={()=>setToolSelected(null)}
                                            update={update}
                                        />}
                                    {toolSelected && toolSelected?.name === IntegrationType.QA && 
                                        <CardQA
                                            cardItem={toolSelected}
                                            closeCard={()=>setToolSelected(null)}
                                            update={update}
                                        />}
                                </div>
                            }
                        </div>
                    </div>

                    <div className='homepage__theme-edit__panel__footer'>
                        <BtnRegular
                            content='Cancel'
                            translate='btn-cancel'
                            id='cancel_settings'
                            event={() => setThemeEdit(null)}
                        />
                        <BtnRegular
                            content='Save'
                            id='save_settings'
                            translate='btn-save'
                            event={saveEdit}
                        />
                    </div>
                </div>
            }
            {showThemeCustomization &&
                <>
                    <CustomThemeEditPanel {...customPanelProps} />
                    <div className='homepage__theme-edit__preview-expend' style={{ width: 'calc(100% - 400px)' }}>
                        <div className='homepage__theme-edit__preview-expend__header'>
                            <div className='homepage__theme-edit__preview-expend__titles'>
                                <h4>{translateJSON['room-preview-title'] || 'Room Preview'}</h4>
                                <span>{translateJSON['room-preview-minimize'] || 'Press echap to minimize'}</span>
                            </div>

                            <div className='homepage__theme-edit__preview-expend__controls'>
                                <button title={translateJSON['room-preview-reset'] || 'reset'} onClick={reset}><ResetSvg /></button>
                                <button title={translateJSON['room-preview-undo'] || 'undo'} disabled={!isUndoPossible} onClick={undo}><ArrowBackSvg /></button>
                                <button title={translateJSON['room-preview-redo'] || 'redo'} disabled={!isRedoPossible} onClick={redo}><ArrowNextSvg /></button>
                            </div>
                        </div>
                        <div className='homepage__theme-edit__preview-expend__body'>
                            <div className='homepage__theme-edit__preview-expend__body__zoom-controls' >
                                <div className='zooms'>
                                    <PlusSvg title="zoom in" onMouseDown={() => setZoom(prev => prev + 0.05)} />
                                    <hr />
                                    <MinSvg title="zoom out" onMouseDown={() => setZoom(prev => prev - 0.05 < 0 ? prev : prev - 0.05)} />
                                </div>
                            </div>
                            <div className='homepage__theme-edit__preview-expend__body__preview' ref={previewExpendRef} >
                                <div className="preview-expend-wrapper"
                                    style={{ width: 1800 * (zoom ?? 0), height: 925 * (zoom ?? 0) }}>
                                    <PreviewInEditPanel themeEdit={themeState} scaleZoom={zoom} scaleZoomInit={zoomInitRef.current} setZoom={setZoom} left={previewLeft} logo={logoPreview} backgroundImage={backgroundImagePreview} />
                                </div>
                            </div>
                        </div>
                    </div>
                </>}
        </div>
    );
}

export default ThemeEditPanel;