import React from "react";

import useAuthContext from "../../hooks/useContext";

import CrossSvg from '../../assets/icons/cross-gray.svg';
import RecordSvg from '../../assets/icons/record-icon.svg';
import BtnRegular from "../BtnRegular";
import useModalAnimated from "../../hooks/useModalAnimated";
import { recordEvent } from '../../helpers/eventApi';
import IconSvg from "../IconSvg";

const StopRecordModal = ({
    setStopRecordModal,
    setReadyRecordModal,
    setRecordingStart,
    eventUUID,
    participantUUID
}) => {

    const { translateJSON, teamID, userID } = useAuthContext();
    const { isAnimated } = useModalAnimated();
    const stopRecord = () => {

        setRecordingStart(false);
        setStopRecordModal(false);
        recordEvent({ isStart: false, participantUUID, eventUUID, teamID, userID })
            .then((res) => {
                console.log('stop record succeful', res)
            }).catch((err) => {
                console.error('stop record failed', err)
            });
    }

    return (
        <>
            <div className='record-modal-overlay' onMouseDown={() => setStopRecordModal(false)} />
            <div className={`record-modal --end ${isAnimated ? 'active' : ''}`}>
                <div className='record-modal__header'>
                    <div className='heading'>
                         <IconSvg svg={RecordSvg} />
                        <h2>{translateJSON['record-stop-title']}</h2>
                    </div>
                    <IconSvg svg={CrossSvg} onMouseDown={() => setStopRecordModal(false)} />
                </div>
                <div className='record-modal__body'>
                    <h5>{translateJSON['record-stop-msgtitle']}</h5>
                    <p>{translateJSON['record-stop-msg']}</p>
                </div>
                <div className='record-modal__footer'>
                    <button className={'btn-regular'} onClick={() => setReadyRecordModal(false)}>
                        <span>{translateJSON['btn-cancel']}</span>
                    </button>
                    <button className={'btn-regular'} onClick={stopRecord}>
                        <span>{translateJSON['record-btn-stop']}</span>
                    </button>
                </div>
            </div>
        </>
    )
};

export default StopRecordModal;