import React, { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import reactionsList from '../../constants/reactionsList';
import { generateRandom } from '../../utils/generateRandom';

const EmojiAnimation = (props) => {
    const { item = {}, needRemoved = ()=>{} } = props;
    const [isVisible, setIsVisible] = useState(true);

    const [position] = useState(()=>{
        const randomLeft = generateRandom(window.innerWidth * 0.3);
        const randomBottom = -generateRandom(window.innerHeight * 0.3) - window.innerHeight * 0.2;

        return {
            left: randomLeft,
            bottom: randomBottom,
        };
    })
    
    const emojiAnimation = useSpring({
        from: {
            opacity: 1,
            transform: `scale(1.5, 1.5) translate(${position.left}px, ${0}px)`,
        },
        to: async (next) => {
            await next({ transform: `scale(1.5, 1.5) translate(${position.left}px, ${position.bottom}px)` });
            await next({ opacity: 0 });
        },
        config: {
            tension: 150,
            friction: 50,
        },
        onRest: () => needRemoved(item)
    });

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setIsVisible(false);
        }, 6000);

        return () => clearTimeout(timeoutId);
    }, []);

    const emojiSvg = reactionsList.find(ele => ele.key === item.emoji)?.src;
    return (
        <div>
            {isVisible && emojiSvg &&
                <div>
                    <animated.div style={emojiAnimation}>
                        <img src={emojiSvg} alt={item?.name ?? ''} />
                        <span>{item?.name ?? ''}</span>
                    </animated.div>
                </div>}
        </div>
    );
};

export default EmojiAnimation;
