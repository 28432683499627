import React from "react";
import useAuthContext from "../hooks/useContext";
import BtnIcon from "../components/BtnIcon";
import ArrowBackSvg from '../assets/icons/arrow-back.svg';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { participantDeleteAll } from "../redux/participantsSlice";
import Navbar from "../components/Navbar";

const ErrorRoom = ({ roomNotFound = true }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { translateJSON, setUserName } = useAuthContext();
    const message = translateJSON[roomNotFound ? 'room-not-found' : 'room-not-created'];
    let messageArr = message?.split('. ');
    const handleBackToHome = () => {
        const hasTokensInLocalStorage = localStorage.getItem('id_token') && localStorage.getItem('access_token');
        if (!hasTokensInLocalStorage) {
            setUserName('');
            dispatch(participantDeleteAll())
        }
        navigate('/');
    }
    return (
        <div className='meetroom-room-not-found'>
            <Navbar />
            <div className='meetroom-room-not-found__body'>
                <div>
                    {messageArr?.map((item, index) => {
                        let itemUpdate = item;
                        if (!item.endsWith('.')) {
                            itemUpdate = item.concat('.');
                        }
                        return <p key={index} >{itemUpdate}</p>
                    })}
                </div>
                
                <BtnIcon
                    content='Back to Home'
                    icon={ArrowBackSvg}
                    translate='button-prejoin-back-to-home'
                    event={handleBackToHome} />
            </div>
        </div>
    )
};

export default ErrorRoom;