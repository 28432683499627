import React, { useEffect, useState,useRef } from "react";
import BtnRegular from '../BtnRegular';
import PreviewWithUserNameInput, { PreviewWithUserJobTitleInput } from './withUserNameInput';
import useAuthContext, { useGeneralContext } from "../../hooks/useContext";
import LogotypeSvg from '../../assets/icons/logotype-prejoin.svg';

import { ReactComponent as CheckWiredSvg } from '../../assets/icons/check-wired.svg';
import { ReactComponent as CheckCheckedSvg } from '../../assets/icons/check-checked.svg';
import * as Auth from '../../helpers/auth';
import { useNavigate } from "react-router-dom";
import { getEventData, postEventToken } from "../../helpers/eventApi";
import { MeetEnterMode } from "../MeetRoom/Settings/AdminConfig";
import { RequestWaitingTimeout } from "../../pages/MeetRoom";

const RequestStatus = {
	init: 'initialized',
	waiting: 'waiting',
	accepted: 'accepted',
	reject: 'reject',
};

const PreviewKnockToEnter = ({ onJoinMeet: onJoinMeetFromPreview, onJoinMeetWithToken, isAlreadySendRequest = false }) => {
    
    const navigate = useNavigate();
    const { userName, translateJSON, teamID, userID } = useAuthContext();
    const { lastWebSocketMessage, currentParticipantUUID, isReconnectAttemptFinished, currentEventUUID } = useGeneralContext();
    
    const receivedTokenRef = useRef();

    const [requestStatus, setRequestStatus] = useState(isAlreadySendRequest ? RequestStatus.waiting : RequestStatus.init);
    const requestStatusRef = useRef(requestStatus);
    const [message, setMessage] = useState('');
    const [notification, setNotification] = useState('');
    const [nextRequestInterval, setNextRequestInterval] = useState(-1);
    const [sendRequestTimesReste, setSendRequestTimesReste] = useState(3);

    const [joinAutomated, setJoinAutomated] = useState(true);
    const joinAutomatedRef = useRef(joinAutomated);
    const interval = useRef(nextRequestInterval);
    const timer = useRef(null);

    const [time, setTime] = useState('');
    const waitingTimeout = useRef();
    const isLoggedIn = !!teamID && !!userID;

    const showWebsocketFailureMessage = ()=>{
        const message = translateJSON['websocket-failed'];
        window.bus.publish("alert",  { message });
    }
    const handleSendRequest = (e) => {
        e.stopPropagation();
        e.preventDefault();
        getEventData({ eventUUID:currentEventUUID, teamID, userID })
            .then( async (res) => {
                const mode = res?.configuration?.settings?.mode ?? MeetEnterMode.NONE;
                if (mode !== MeetEnterMode.KNOCK) {
                    await onJoinMeetFromPreview();
                    return;
                }
                if(isReconnectAttemptFinished){
                    showWebsocketFailureMessage();
                    return;
                }
                setRequestStatus(RequestStatus.waiting);
                setSendRequestTimesReste(prev => prev - 1);
                postEventToken({ userName, participantUUID: currentParticipantUUID, eventUUID:currentEventUUID, teamID, userID })
                .then(res1 =>{
                    const token = res1?.token;
                    if (token) {
                        navigate(`/event/${currentEventUUID}`, {
                            state: {
                                token: token,
                                enterMode: mode,
                                eventUUID: currentEventUUID,
                                isHost: res1.is_admin ?? false,
                                isFromPreview: true
                            }
                        });
                    }
                })
                .catch(error=>{
                    console.error(error.message);
                    setRequestStatus(RequestStatus.init);
                    setSendRequestTimesReste(prev => prev + 1);
                    window.bus.publish("alert", error?.message);
                })
                
            })
        
    }
    
    const onJoinMeet = (e) => {
        e.stopPropagation();
        e.preventDefault();
        onJoinMeetWithToken({token: receivedTokenRef.current})
    }
    useEffect(() => {
        requestStatusRef.current = requestStatus;
        if (requestStatusRef.current === RequestStatus.reject && sendRequestTimesReste === 0) {
            navigate('/')
        }
        if(requestStatus === RequestStatus.accepted){
            if(waitingTimeout.current){
                clearTimeout(waitingTimeout.current);
            }
        }
        if(requestStatus === RequestStatus.waiting){
            if(waitingTimeout.current){
                clearTimeout(waitingTimeout.current);
            }
            waitingTimeout.current = setTimeout(() => {
                setRequestStatus(RequestStatus.reject);
            }, RequestWaitingTimeout);
        }
    }, [requestStatus]);

    useEffect(() => {
        let notificationUpdate;
        switch (requestStatus) {
            case RequestStatus.init:
                if (isLoggedIn) {
                    notificationUpdate = translateJSON['prejoin-signedin-knock-desc']; // Knock to enter and wait until the host lets you in
                } else {
                    notificationUpdate = translateJSON['description-prejoin-send-request']; //Enter your name or sign in to knock to enter
                }
                break;
            case RequestStatus.waiting:
                setMessage(translateJSON['prejoin-subheading-pleasewait']);//Please wait until the host lets you in
                notificationUpdate = translateJSON['prejoin-request-sent-desc'];//Your request has been sent. We'll let you know when the host has accepted your request.
                break;
            case RequestStatus.accepted:
                setMessage(translateJSON['prejoin-request-accepted']);//Your request has been accepted!
                notificationUpdate = translateJSON['prejoin-accepted-desc'];//Select the button below to join the meeting
                break;
            case RequestStatus.reject:


                setMessage(translateJSON['prejoin-request-denied']);//Your request to join the meeting has been denied

                if (interval.current <= 0 && sendRequestTimesReste < 4) {
                    //request remaining  //requests remaining
                    let notif = translateJSON['prejoin-request-remaining-multiple'];
                    if (sendRequestTimesReste === 1) {
                        notif = translateJSON['prejoin-request-remaining-single'];
                    }
                    //You have
                    notificationUpdate = `${translateJSON['prejoin-you-have-expr']} ${sendRequestTimesReste} ${notif}.`
                } else {
                    //You can send another request in
                    notificationUpdate = `${translateJSON['request-denied-tryagain']} ${time}.`
                }
                
        }

        const signText = translateJSON['btn-sign-in'];
        const signInReg = new RegExp(`${signText}`, 'g');
        const match = notificationUpdate ? [...notificationUpdate.matchAll(signInReg)] : [];
        let signInKey = '', signInIndex;
        if (match.length === 1) {
            signInKey = match[0][0];
            signInIndex = match[0].index;
        }
        const firstPart = notificationUpdate?.slice(0, signInIndex);
        const lastPart = notificationUpdate?.slice(signInIndex + signInKey.length);
        const legend = signInKey ? (
            <>
                <span
                    className='legend'
                    key={'first'}>
                    {firstPart}
                </span>
                <span
                    className='sign-in'
                    key={'sign-in'}
                    onMouseDown={() => Auth.oauth_auth()}>
                    {signInKey}
                </span>
                <span
                    className='legend'
                    key={'last'}>
                    {lastPart}
                </span>
            </>
        ) : <span className='legend'>{notificationUpdate}</span>;
        setNotification(legend);
    }, [requestStatus, time, translateJSON]);


    useEffect(()=>{
        if(requestStatus !== RequestStatus.init && isReconnectAttemptFinished){
            showWebsocketFailureMessage();
        }
    },[isReconnectAttemptFinished]);

    useEffect(() => {
        interval.current = nextRequestInterval;
        dealData();
    }, [nextRequestInterval]);

    useEffect(() => {
        //2 min, 5 min, 10min
        if (requestStatus === RequestStatus.reject) {
            if (sendRequestTimesReste === 2) {
                setNextRequestInterval(2 * 60);
            } else if (sendRequestTimesReste === 1) {
                setNextRequestInterval(5 * 60);
            } else if (sendRequestTimesReste === 0) {
                setNextRequestInterval(10 * 60);
            }
        }
        
    }, [requestStatus]);


    const dealData = () => {
        interval.current--;
        if (requestStatusRef.current === RequestStatus.accepted) {
            setTime('');
            return timer.current && clearTimeout(timer.current);
        }
        if (interval.current <= -1) {
            setTime('');
            if (sendRequestTimesReste === 0) {
                navigate('/')
            }
            return timer.current && clearTimeout(timer.current);
        }
        const m = parseInt(((interval.current / 60) % 60) + '');
        const s = parseInt((interval.current % 60) + '');
        
        const seconds = s > 1 ? translateJSON['back-home-secs'] : translateJSON['back-home-onesec'];
        if (m === 0) {
            setTime(`${s} ${seconds}`);
        } else {
            const minutes = m > 1 ? translateJSON['counter-minutes'] : translateJSON['counter-minute'];
            setTime(`${m} ${minutes} ${s} ${seconds}`); // has no minute translation
        }
        timer.current = setTimeout(() => {
            dealData();
        }, 1000);
    };

    useEffect(() => {
        joinAutomatedRef.current = joinAutomated;
    },[joinAutomated])

	useEffect(() => {
		
        const action = lastWebSocketMessage?.Action;
        if (action === 'DeniedKnockToEnter'){
            setRequestStatus(RequestStatus.reject);
        }
        if (action === 'AllowKnockToEnter') {
            const token = lastWebSocketMessage?.Payload?.token;
            receivedTokenRef.current = token;
            if (joinAutomatedRef.current) {
                onJoinMeetWithToken({ token: receivedTokenRef.current })
            } else {
                setRequestStatus(RequestStatus.accepted);
            }
        }
        
    }, [lastWebSocketMessage]);
    
    useEffect(() => {
        dealData();
        return () => {
            timer.current && clearTimeout(timer.current);
        }
    }, []);
    
    const showNameInput = requestStatusRef.current === RequestStatus.init && !isLoggedIn;
    const showLogo = requestStatusRef.current === RequestStatus.waiting || requestStatusRef.current === RequestStatus.reject;
    const hasLogoAnimation = requestStatusRef.current === RequestStatus.waiting;
    const showJoinButton = requestStatusRef.current === RequestStatus.waiting || requestStatusRef.current === RequestStatus.accepted;
    const joinButtonClass = requestStatusRef.current === RequestStatus.waiting ? 'not-ready' : '';
    const showRequestButton = requestStatusRef.current === RequestStatus.init || requestStatusRef.current === RequestStatus.reject;
    const requestButtonClass = (!userName || requestStatusRef.current === RequestStatus.waiting || (requestStatusRef.current === RequestStatus.reject && interval.current !== -1)) ? 'not-ready' : '';
    return (
        <>
           
            {showLogo && <div className={`logo-box ${hasLogoAnimation ? '' : 'stop-animation'}`}>
                <img src={LogotypeSvg} alt="logotype preview" />
            </div>}
            <div className='notif-box'>
                <div className='notif-box__header'>
                    <p>{message}</p>
                    <p>{notification}</p>
                </div>

                <div className='notif-box__body'>
                    <form className='notif-box__body__form'>
                            
                        {requestStatusRef.current === RequestStatus.waiting &&
                            <div className='notif-box__body__form__header'>
                                <div className='check-frame'>
                                    <div onMouseDown={()=>setJoinAutomated(prev=>!prev)}>
                                        {joinAutomated ? <CheckCheckedSvg /> : <CheckWiredSvg />}
                                        <span>{translateJSON['prejoin-checkbox-joinautomatically']}</span>
                                    </div>
                                </div>
                            </div>}
                        <div className='notif-box__body__form__body'>
                            {showNameInput && <PreviewWithUserNameInput />}
                            <PreviewWithUserJobTitleInput />
                        </div>
                        <div className='notif-box__body__form__footer'>
                            {showRequestButton && <BtnRegular className={`btn-regular ${requestButtonClass}`} content='Knock to enter' translate='settings-host-knock' event={handleSendRequest} />}
                            {showJoinButton && <BtnRegular className={`btn-regular ${joinButtonClass}`} content='Join now' translate='prejoin-button-join-now' event={onJoinMeet} />}
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
};
export default PreviewKnockToEnter;