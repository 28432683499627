import React from 'react';
import emojiSymbols from '../constants/emoji';

const EmojiContainer = ({ onClickEmoji }) =>
    <div className='emoji-library'>
        {emojiSymbols &&
            emojiSymbols.map((emojiSymbol, index) =>
                <span key={index} onMouseDown={()=>onClickEmoji(emojiSymbol)}>
                    {emojiSymbol}
                </span>
            )
        }
    </div>
    

export default EmojiContainer;