import React, {useEffect} from 'react';
import StaggeredAnimation from './StaggeredAnimation';

import HelloSvg from '../assets/icons/hello.svg';
import {ReactComponent as CrossSvg} from '../assets/icons/cross.svg';

import useMediaQuery from '../hooks/useMediaQuery';
import { MEDIUM_SCREEN } from '../utils/breackpoints';
import useAuthContext from '../hooks/useContext';

const LoginNotification = (props) => {

    const { translateJSON } = useAuthContext();
	const isMediumScreen = useMediaQuery(MEDIUM_SCREEN);

    useEffect(() => {
		const anim = new StaggeredAnimation(
			'.login-notification',
			isMediumScreen ? 350 : -350,
			'posY',
			1,
			'ease',
			250
		);
        anim.animate();
    }, []);
    
    return (

        <div className='login-notification' data-size="small">
            <div className='login-notification__triangle'>
                <div></div>
            </div>
            <div className='login-notification__body'>
                <img src={HelloSvg} alt="Hello" />
                <div className='login-notification__info'>
                    <span onMouseDown={() => props.userLoggedIn()}>{translateJSON['home-popup-signin']}</span>
                </div>
                <CrossSvg onMouseDown={() => props.closeModal() }/>
            </div>
        </div>
    );
}
export default LoginNotification

