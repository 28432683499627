import React, {useEffect, useRef, useState} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useAuthContext from '../hooks/useContext';
import BtnRegular from '../components/BtnRegular';
import { ReactComponent as LOGOSVG } from '../assets/icons/logo.svg';
import { useDispatch } from 'react-redux';
import { setConfig } from '../redux/meetSlice';

const EndingPage = ({ rejoinEvent }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const {translateJSON, setUserName } = useAuthContext();

	const interval = useRef();
    const timer = useRef(null);
	const [time, setTime] = useState(0);
	
	const handleBackToHome = () => {
		const hasTokensInLocalStorage = localStorage.getItem('id_token') && localStorage.getItem('access_token');
		if (!hasTokensInLocalStorage) {
            setUserName('');
			dispatch(setConfig({ duration: 0 }))
		}
		//clear state, avoid reconnect to meetroom when onpopstate
		navigate(location.pathname, { replace: true });
		navigate('/');
		
	};

	const dealData = () => {
        if (interval.current <= 0) {
			setTime('');
			handleBackToHome();
            return timer.current && clearTimeout(timer.current);
        }
		setTime(interval.current);
		
        interval.current--;
        timer.current = setTimeout(() => {
            dealData();
        }, 1000);
    };

	useEffect(() => {
		interval.current = 60;
        dealData();
        return () => timer.current && clearTimeout(timer.current);
	}, []);
	
	const counterText = `${time} ${time > 1 ? translateJSON['back-home-secs'] : translateJSON['back-home-onesec']}`;
	
	return (
		<div className='endingpage'>
			<div className='endingpage__body'>
				<div className='endingpage__body__title'>
					<h1>{translateJSON['meeting-end-title']}</h1>
				</div>
				<div className='endingpage__body__buttons'>
					<BtnRegular
						translate={'meeting-end-rejoin'}
						event={rejoinEvent}
					/>
					<BtnRegular
						translate={'button-prejoin-back-to-home'}
						event={handleBackToHome}
					/>
				</div>
				<div className='endingpage__body__footer'>
					<LOGOSVG className='logo-box'/>
					<div>
						<span>{translateJSON['back-home-count']}</span>
						<br />
						<span>{counterText}</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EndingPage;
