import React, { useState, useEffect, createContext, useCallback } from 'react';

import useSessionStorage from '../hooks/useSessionStorage';
import * as utils from '../utils';
import { NetworkStatus } from '../dataModal/ParticipantModal';
import isVisuelEffectSupported from '../utils/checkPipelineSupport';
import useWebSocket from 'react-use-websocket';
import { generateRandomColor } from '../utils/generateRandom';
const connection =
	navigator.connection || navigator.mozConnection || navigator.webkitConnection;

const GeneralContext = createContext();
export { GeneralContext };


const GeneralContextProvider = ({ children }) => {
	const isPipelineSupported = isVisuelEffectSupported();
	const [featureVisualEffect, setFeatureVisualEffect] = useState(''); //'feature-virtual-effect', `{ type: 'none' }`
	const [lostConnection, setLostConnection] = useState(!navigator.onLine);
	const [networkStatus, setNetworkStatus] = useState(NetworkStatus.GOOD);
	const [currentParticipantColor, setCurrentParticipantColor] = useState(generateRandomColor());
	const [isRaisingHand, setRaisingHand] = useState(false);
	const [isShareScreen, setShareScreen] = useState(false);
	const [lastTimestampShareScreen, setLastTimestampShareScreen] = useState();
	const [localParticipantIdentifier, setLocalParticipantIdentifier] = useState();
	const [showJobTitle, setShowJobTitle] = useState(true);
	const [currentEventUUID, setCurrentEventUUID] = useState();
	const [currentParticipantUUID, setCurrentParticipantUUID] = useState();
	const [isReconnectAttemptFinished, setReconnectAttemptFinished] = useState(false);

    const getSocketUrl = useCallback(() => {
        return `${process.env.REACT_APP_WS_PATH}/meet/v2/anonymous/websocket/${currentParticipantUUID}/init`;
    }, [currentParticipantUUID]);

	const isConnectToWS = !!currentParticipantUUID && !!currentEventUUID;
	const { lastJsonMessage: lastWebSocketMessage, readyState } = useWebSocket(getSocketUrl, 
		{
		reconnectAttempts: 2,
		reconnectInterval: 1000,
		//Will attempt to reconnect on all close events, such as server shutting down
		shouldReconnect: () => true,
		onReconnectStop:()=>{ setReconnectAttemptFinished(true) },
	}, isConnectToWS);

	useEffect(() => {
		const featureVisualEffectData = localStorage.getItem('feature-virtual-effect');
		let featureVisualEffectParsed;
		if (featureVisualEffectData && isPipelineSupported) {
			featureVisualEffectParsed = JSON.parse(featureVisualEffectData);
		} else {
			featureVisualEffectParsed = { type: 'none' };
		}
		setFeatureVisualEffect(featureVisualEffectParsed);

		window.addEventListener('offline', handleOffline);
		window.addEventListener('online', handleOnline);
		connection?.addEventListener('change', updateConnectionStatus);

		localStorage.removeItem('theme');

		//remove old creation_date
		window.localStorage.removeItem('creation_date');

		return () => {
			window.removeEventListener('offline', handleOffline);
			window.removeEventListener('online', handleOnline);
			connection?.removeEventListener('change', updateConnectionStatus);
		};
	}, []);
	
	
	const updateConnectionStatus = () => {
		configNetworkStatus();
	};

	const handleOffline = () => setLostConnection(true);
	const handleOnline = () => setLostConnection(false);

	useEffect(() => {
		localStorage.setItem(
			'feature-virtual-effect',
			JSON.stringify(featureVisualEffect)
		);
	}, [featureVisualEffect]);

	const configNetworkStatus = () => {
		if (connection?.downlink < 0.5) {
			setNetworkStatus(NetworkStatus.BAD);
		} else if (connection?.downlink > 0.5 && connection.downlink < 1) {
			setNetworkStatus(NetworkStatus.LOW);
		} else {
			setNetworkStatus(NetworkStatus.GOOD);
		}
	};

	useEffect(() => {
		configNetworkStatus();
	}, []);

	return (
		<GeneralContext.Provider
			value={{
				featureVisualEffect,
				setFeatureVisualEffect,
				lostConnection,
				networkStatus,
				currentParticipantColor,
				setCurrentParticipantColor,
				isRaisingHand,
				setRaisingHand,
				isShareScreen, 
				setShareScreen,
				lastTimestampShareScreen,
				setLastTimestampShareScreen,
				localParticipantIdentifier,
				setLocalParticipantIdentifier,
				showJobTitle, setShowJobTitle,
				currentEventUUID,
				setCurrentEventUUID,
				currentParticipantUUID,
				setCurrentParticipantUUID,
				lastWebSocketMessage,
				readyState,
				isReconnectAttemptFinished
			}}>
			{children}
		</GeneralContext.Provider>
	);
};

export default GeneralContextProvider;
