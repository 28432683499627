import React, { useEffect, useRef, useState, createRef } from 'react';
import dayjs from 'dayjs';
import '@livekit/components-styles';
import { useSelector, useDispatch } from 'react-redux';
import { Room, RoomEvent } from 'livekit-client';
import { LiveKitRoom } from '@livekit/components-react';

import ChatPanel from './ChatPanel';
import SidePanel from './SidePanel';
import ParticipantPanel from './ParticipantsPanel';
import SettingModalMobile from './SettingModalMobile';
import ScreenLayoutConfig from './Settings/ScreenLayoutConfig';
import MyVideoConference, { decoder, encoder } from './MyVideoConference';
import VisualEffectConfig from '../SharedComponents/VisualEffectConfig';
import {
	participantUpdated,
	selectAllSortedParticipants,
	selectAllHostParticipants,
	participantAdded,
} from '../../redux/participantsSlice';

import {
	getScreenLayout,
	getScreenTiles,
	setScreenLayout,
	setScreenTiles
} from '../../redux/meetSlice';

import ParticipantModal, { ParticipantRole } from '../../dataModal/ParticipantModal';

import useMediaQuery from '../../hooks/useMediaQuery';
import useAuthContext, { useAudioVideoContext, useGeneralContext } from '../../hooks/useContext';

import isVisuelEffectSupported from '../../utils/checkPipelineSupport';
import { SMALL_SCREEN } from '../../utils/breackpoints';
import { handleCameraError, handleMicroError } from '../../utils/errorHandler';
import { PermissionStatus } from '../../context/AudioVideoContextProvider';
import { ChatIconType, SidePanelType } from '../../pages/MeetRoom';
import IntegrationsPanel from './IntegrationsPanel';

const MyLivekitRoom = ({
    room = new Room(),
    isHost,
    lkToken,
    setMeetDuration,
    setLastMessage,
	onSendEmojiChange,
    handleShowShareModal,
    handleReceivedEmoji,
    liveSettings, 
	setLiveSettings,
    sidePanelType,
    setSidePanelType,
    messagesList,
    setMessagesList,
	layoutContext,
	requestPopupProps,
	hasInputFocus, 
	setInputFocus,
	optionBoxIdentityInPanel, 
	setOptionBoxIdentityInPanel,
	
	iconSelectInChatSidePanel, 
	setIconSelectInChatSidePanel,
	
	poll, setPoll,
	pollList, setPollList,
	pollToCreated, setPollToCreated,
	pollDeleted, setPollDeleted,
	pollAction, setPollAction,
	updatePollWithOptionUUID,
	showPollConfirmation, setShowPollConfirmartion,
	
	QA, setQA,
	questionList, setQuestionList,
	questionDeleted, setQuestionDeleted,
	questionToAnswer, setQuestionToAnswer,
	questionAction, setQuestionAction,

	whiteboard, setWhiteboard
}) => {
	const dispatch = useDispatch();
	const isPipelineSupported = isVisuelEffectSupported();
	const isPhoneScreen = useMediaQuery(SMALL_SCREEN);

	const questionToAnswerRef = useRef(questionToAnswer);
	const { userID, userLastName, userEmail, translateJSON, userAvatarPath, userAvatar, jobTitle } = useAuthContext();

	const {
		audioEnabled,
		setAudioEnabled,
		videoEnabled,
		outputAudioSelected,
		audioDeviceId,
		videoDeviceId,
		setMicroPermission,
		setCameraPermission,
		setForceRequestPermission,
		isActiveNoiseCancellation,
		isActiveEchoCancellation
	} = useAudioVideoContext();
	
	const {
		showJobTitle,
		featureVisualEffect,
		setFeatureVisualEffect,
		networkStatus,
		currentParticipantColor,
		isRaisingHand,
		isShareScreen: isShareScreenContext,
		setShareScreen,
		lastTimestampShareScreen: lastTimestampShareScreenContext,
		setLastTimestampShareScreen,
		setLocalParticipantIdentifier,
	} = useGeneralContext();


	const screenLayoutType = useSelector(getScreenLayout);
	const screenLayoutTiles = useSelector(getScreenTiles);
	const participantsFromRedux = useSelector(selectAllSortedParticipants);
	const participantsFromReduxRef = useRef([...participantsFromRedux]);
	const totalHostParticipants = useSelector(selectAllHostParticipants);

	const [msgE, setMsgE] = useState('');
	const [cameraError, setCameraError]= useState();
	const [isRoomConnected, setRoomConnected] = useState(false);
	const [pinnedParticipantIdentity, setPinnedParticipantIdentity] = useState(null);
	const [updatePinnedFromPanel, setUpdatePinnedFromPanel] = useState(false);
	const [receiveRemoteParticipantCount, setReceiveRemoteParticipantCount] = useState();
	const [forceUpdate, setForceUpdate] = useState(false);
	
	const audioEnabledRef = useRef(audioEnabled);
	const videoEnabledRef = useRef(videoEnabled);
	const elRef = useRef();
	const messagesListRef = useRef();
	const isRaisingHandRef = useRef(isRaisingHand);
	const receiveRemoteCountTimerRef = useRef();
	const lastTimestampShareScreenContextRef = useRef(lastTimestampShareScreenContext);
	
	const sendChatMessageRef = useRef();
	const onSendChatMessageChange = (method) => {
		sendChatMessageRef.current = method;
	};
    
	const handleRoomDataReceived = (data) => {
		const decodeData = JSON.parse(decoder.decode(data) ?? '');
		const type = decodeData.type;
		const identity = decodeData?.payload?.identity;
		let foundParticipant = participantsFromReduxRef?.current.find(item => item.identity === identity);
		
		if (type === 'raise-hand') {
			if (foundParticipant) {
				dispatch(participantUpdated({ ...foundParticipant, isRaisingHand: decodeData.payload.isRaisingHand }));
			}
			
		} else if (type === 'share-screen') {
			const lastTimestampShareScreen = decodeData.payload.lastTimestampShareScreen;
			if (foundParticipant) {
				dispatch(participantUpdated({
					...foundParticipant,
					lastTimestampShareScreen
				}));
			}
			
		} else if (type === 'update-local-participant-data') {
			const payloadData = decodeData.payload.data;
			dispatch(participantUpdated({ ...foundParticipant, ...payloadData }));
			
		} else if (type === 'update-again-local-participant-data') {
			const payloadData = decodeData.payload.data;
			setReceiveRemoteParticipantCount(prev => {
				const newValue = prev - 1;
				if (newValue < 0) {
					return 0
				}
				return newValue;
			});
			dispatch(participantUpdated({ ...payloadData }));
		}
		else if (type === 'need-request-remote') {
			const sender = decodeData.payload.sender;
			
			const sharedLocalParticipantProps = {
				identity: room.localParticipant.identity,
				id: userID,
				userName: room.localParticipant.name,
				familyName: userLastName,
				color: currentParticipantColor,
				email: userEmail,
				jobTitle,
				picturePath: userAvatarPath,
				role: isHost ? ParticipantRole.HOST : ParticipantRole.GUEST,
				networkStatus,
				isRaisingHand: isRaisingHandRef.current,
				lastTimestampShareScreen: lastTimestampShareScreenContextRef.current,
				showJobTitle
			};
			const type = 'update-again-local-participant-data';
			const data = {
				type,
				payload: {
					identity: room.localParticipant.identity,
					data:
					{
						...sharedLocalParticipantProps,
						updateFromRemote: true,
					}
				},
			};
			const encodedData = encoder.encode(JSON.stringify(data));
		
			// publish reliable data to a set of participants
			room.localParticipant.publishData(encodedData, { reliable: true, destinationIdentities: [sender.identity] });
			

		} else if (type === 'send-emoji') {
			handleReceivedEmoji({ ...decodeData?.payload });
		} else if (type === 'network-status-update') {
			if (foundParticipant) {
				dispatch(participantUpdated({ ...foundParticipant, networkStatus: decodeData.payload.networkStatus }));
			}
		} else if (type === 'jobTitle-update') {
			if (foundParticipant) {
				dispatch(participantUpdated({ ...foundParticipant, jobTitle: decodeData.payload.jobTitle }));
			}
		} else if (type === 'show-jobTitle-update') {
			if (foundParticipant) {
				dispatch(participantUpdated({ ...foundParticipant, showJobTitle: decodeData.payload.showJobTitle }));
			}
		} else if (type === 'mute-everyone-from-host') {
			setAudioEnabled(false);
		} else if (type === 'mute-someone-from-host') {
			if (identity === room.localParticipant.identity) {
				setAudioEnabled(false);
			}
		}
		else if (type === 'promote-demote-from-host') {
			const newRole = decodeData.payload.newRole;
			dispatch(participantUpdated({ ...foundParticipant, role: newRole }));
		}
		else if(type === 'poll-delete'){
			const newPollDeleted = decodeData.payload.pollUUID;
			setPollList(prev=>prev.filter(item=>item?.poll?.poll_uuid !== newPollDeleted));
		}
		else if(type === 'question-delete'){
			const newQuestionDeleted = decodeData.payload.questionUUID;
			setQuestionList(prev=>prev.filter(item=>item?.question?.qna_uuid !== newQuestionDeleted));
			if(questionToAnswerRef.current?.questionUUID === newQuestionDeleted){
				setQuestionToAnswer(null);
			}
		}
		else if(type === 'question-active'){
			const newQA = decodeData.payload.QA;
			setQA(newQA);
		}
		else if(type === 'poll-active'){
			const newPoll = decodeData.payload.poll;
			setPoll(newPoll);
		}
		else if(type === 'live-settings-update'){
			const newLiveSettings = decodeData.payload.liveSettings;
			setLiveSettings(newLiveSettings);
		}
	};

	const handleParticipantDisconnected = (participant) => {
		const foundParticipant = participantsFromReduxRef?.current.find(item => item.identity === participant.identity);
        dispatch(participantUpdated({...foundParticipant, isLeft: true}));
	}

	const handleOnLocalParticipantConnected = () => {
		const sharedLocalParticipantProps = {
			identity: room.localParticipant.identity,
			id: userID,
			userName: room.localParticipant.name,
			jobTitle,
			showJobTitle,
			familyName: userLastName,
			color: currentParticipantColor,
			email: userEmail,
			picturePath: userAvatarPath,
			role: isHost ? ParticipantRole.HOST : ParticipantRole.GUEST,
			networkStatus,
			isRaisingHand: isRaisingHandRef.current,
			lastTimestampShareScreen: lastTimestampShareScreenContextRef.current,
		};
		const currentParticipantModal = new ParticipantModal({
			...sharedLocalParticipantProps,
			picture: userAvatar,
			isLocal: true,
		});
		dispatch(participantAdded(currentParticipantModal));
		
		setLocalParticipantIdentifier(room.localParticipant.identity);
		
		const type = 'update-local-participant-data';
		const data = {
			type,
			payload: {
				identity: room.localParticipant.identity,
				data:
				{
					...sharedLocalParticipantProps,
					updateFromRemote: true,
				}
			},
		};
		const encodedData = encoder.encode(JSON.stringify(data));
		room.localParticipant.publishData(encodedData, { reliable: true });
			
		const keys = Array.from(room.remoteParticipants.values()).map(item => item.identity);
		const keysHasNotExistInRedux = keys.filter(item => !participantsFromReduxRef.current.find(ele => ele.identity === item));
		if (keysHasNotExistInRedux.length > 0) {
			const type = 'need-request-remote';
			const data = {
				type,
				payload: { sender: room.localParticipant },
			};
				
			const encodedData = encoder.encode(JSON.stringify(data));
			room.localParticipant.publishData(encodedData, { reliable: true, destinationIdentities: Array.from(room.remoteParticipants.keys()) })
				.then(() => {
					setReceiveRemoteParticipantCount(room.remoteParticipants.size);
				})
		}
	};

	const handleOnParticipantConnected = (participant) => {
		// add new participant
		const newParticipant = new ParticipantModal({
			identity: participant.identity,
			userName: participant.name
		})
		
		dispatch(participantAdded(newParticipant));
	};
	
	const hiddenPanelState = () => setSidePanelType(null)
	
	const handleMakeHost = (participant) => {
		const isLocal = room.localParticipant.identity === participant.identity;
		let newRole;
		if (isLocal) {
			if (totalHostParticipants.length !== 1) {
				newRole = ParticipantRole.GUEST
			}
		} else {
			newRole = participant.role === ParticipantRole.HOST ? ParticipantRole.GUEST : ParticipantRole.HOST;
		}

		if (newRole) {
			dispatch(participantUpdated({ ...participant, role: newRole }));

			//send to all participants
			const data = {
				type: 'promote-demote-from-host',
				payload: {
					newRole,
					identity: participant.identity
				},
			};
			const encodedData = encoder.encode(JSON.stringify(data));
			room.localParticipant.publishData(encodedData, { reliable: true });
		}
	};

	const handleAskMute = (participant) => {
		// todo
	};

	const handleMuteMic = (participant) => {
		dispatch(
			participantUpdated({ ...participant, audioEnabled: !participant.audioEnabled })
		);

		const data = {
			type: 'mute-someone-from-host',
			payload: {
				identity: participant.identity
			},
		};
		const encodedData = encoder.encode(JSON.stringify(data));
		room.localParticipant.publishData(encodedData, { reliable: true });
	};

	const handleSetPinned = (identity) => {
		setPinnedParticipantIdentity(prev => {
			if (prev && identity === prev) return null;
			return identity
		})
	};

	const handleOptionBoxIdentityInPanel = (identity) => {
		setOptionBoxIdentityInPanel((prev) => {
			if (prev && prev === identity) return null;
			return identity;
		});
	};

	const muteEveryone = () => {
		const data = {
			type: 'mute-everyone-from-host',
			payload: {
				identity: room.localParticipant.identity
			},
		};
		const encodedData = encoder.encode(JSON.stringify(data));
		room.localParticipant.publishData(encodedData, { reliable: true });
		
		setAudioEnabled(false);
	};

	const startNewPoll = ()=>{
		setIconSelectInChatSidePanel(ChatIconType.POLL);
		setSidePanelType(SidePanelType.CHAT);
	};

	const startNewQA = ()=>{
		setIconSelectInChatSidePanel(ChatIconType.QA);
		setSidePanelType(SidePanelType.CHAT);
	};


    useEffect(() => {
		messagesListRef.current = [...messagesList];
	}, [JSON.stringify(messagesList)]);

	useEffect(()=>{
		const identity = room.localParticipant.identity;
		if(isRoomConnected){
			handleOnLocalParticipantConnected();
		}else{
			// dispatch(participantDelete(identity));

			const foundParticipant = participantsFromReduxRef?.current.find(item => item.identity === identity);
			if(foundParticipant){
				dispatch(participantUpdated({...foundParticipant, isLeft: true}));
			}
			
		}
	},[isRoomConnected])

	useEffect(() => {
		audioEnabledRef.current = audioEnabled;
	}, [audioEnabled]);

	useEffect(() => {
		videoEnabledRef.current = videoEnabled;
	}, [videoEnabled]);

	useEffect(() => {
		const creationTime = room?.roomInfo?.creationTime;
		const currentTimestamp = Math.floor(dayjs().valueOf()/1000);
		if (!creationTime) return;
		if (typeof creationTime !== 'bigint') return;
		const bigintString = creationTime?.toString();
		const creationTimeNumber = +bigintString;
		const difference = currentTimestamp - creationTimeNumber < 0 ? 0 : currentTimestamp - creationTimeNumber;
		setMeetDuration(difference);
	}, [room?.roomInfo?.creationTime]);

	useEffect(() => {
		participantsFromReduxRef.current = [...participantsFromRedux].map(
			(item) => new ParticipantModal({ ...item })
		);
	}, [JSON.stringify(participantsFromRedux)])
	
	useEffect(()=>{
		room?.on(RoomEvent.DataReceived, handleRoomDataReceived);
		room?.on(RoomEvent.ParticipantConnected, handleOnParticipantConnected);
		room?.on(RoomEvent.ParticipantDisconnected, handleParticipantDisconnected);
		room?.on('send-emoji-from-myself', (data) => {
			handleReceivedEmoji({ ...data });
		});
		return()=>{
			room.removeAllListeners();
		}
	},[room]);

	useEffect(() => {
		const state = room?.state;
		if (state === 'connected') {
			setRoomConnected(true);
		}
		else{
			setRoomConnected(false);
			
		}
	}, [room?.state]);

	useEffect(() => {
		if (receiveRemoteCountTimerRef.current) {
			clearTimeout(receiveRemoteCountTimerRef.current);
		}
		
		if (receiveRemoteParticipantCount) {
			receiveRemoteCountTimerRef.current = setTimeout(() => {
				const allRemoteParticipants = Array.from(room.remoteParticipants.values());
				const keys = allRemoteParticipants.map(item => item.identity);
				const keysHasNotExistInRedux = keys.filter(item => !participantsFromReduxRef.current.find(ele => ele.identity === item))
				const notReceiveRemoteParticipants = allRemoteParticipants.filter(item => keysHasNotExistInRedux.includes(item.identity));
				const type = 'need-request-remote';
				const data = {
					type,
					payload: { sender: room.localParticipant },
				};
				
				const encodedData = encoder.encode(JSON.stringify(data));
				room.localParticipant.publishData(encodedData, { reliable: true, destinationIdentities: notReceiveRemoteParticipants.map(item => item.identity) })
					.then(() => {
						setForceUpdate(prev => !prev);
						setReceiveRemoteParticipantCount(notReceiveRemoteParticipants.length);
					})
				
			}, 3000);
		}
	}, [receiveRemoteParticipantCount, forceUpdate]);

	useEffect(() => {
		if (!('setSinkId' in HTMLAudioElement.prototype)) return;
		if (outputAudioSelected) {
			room?.switchActiveDevice('audiooutput', outputAudioSelected, true);
		}
		
	}, [room, outputAudioSelected]);
	
	useEffect(() => {
		if (audioDeviceId) {
			room?.switchActiveDevice('audioinput', audioDeviceId, true);
		}
	}, [room, audioDeviceId]);
	
	useEffect(() => {
		if (videoDeviceId) {
			room?.switchActiveDevice('videoinput', videoDeviceId, true);
		}
	}, [room, videoDeviceId]);
	//only when has enviroment camera, show the flip switch button
	// useEffect(() => {
	// 	if (!isFirstloading) return;
	// 	if (!useEnvironmentCamera) return;
	// 	const cameras = devices?.cameras ?? [];
	// 	if (cameras.length > 1) {
	// 		const nextCamera = cameras.find(item => item.deviceId !== videoDeviceId);
	// 		if (nextCamera && nextCamera?.deviceId) {
	// 			setVideoDeviceId(nextCamera.deviceId)
	// 		}
	// 	}
	// }, [useEnvironmentCamera]);

	useEffect(() => {
		if (isRoomConnected) {
			const data = {
				type: 'network-status-update',
				payload: {
					networkStatus,
					identity: room.localParticipant.identity
				},
			};
			const encodedData = encoder.encode(JSON.stringify(data));
			room.localParticipant.publishData(encodedData, {reliable: true});
		}
		
	}, [isRoomConnected, networkStatus]);


	useEffect(() => {
		if(!isRoomConnected) return;
		const localParticipant = participantsFromReduxRef.current.find(participant => participant.identity === room.localParticipant.identity);
		if (localParticipant) {
			dispatch(participantUpdated({ ...localParticipant, jobTitle }))
		};

		const data = {
			type: 'jobTitle-update',
			payload: {
				jobTitle,
				identity: room.localParticipant.identity
			},
		};
		const encodedData = encoder.encode(JSON.stringify(data));
		room.localParticipant.publishData(encodedData, { reliable: true });
		
	}, [isRoomConnected,
		jobTitle,
		room.localParticipant.identity,
		participantsFromReduxRef.current?.find(participant => participant.identity === room.localParticipant.identity)
	]);
	
	useEffect(() => {
		if(!isRoomConnected) return;
		const localParticipant = participantsFromReduxRef.current.find(participant => participant.identity === room.localParticipant.identity);
		if (localParticipant) {
			dispatch(participantUpdated({ ...localParticipant, showJobTitle }))
		};

		const data = {
			type: 'show-jobTitle-update',
			payload: {
				showJobTitle,
				identity: room.localParticipant.identity
			},
		};
		const encodedData = encoder.encode(JSON.stringify(data));
		room.localParticipant.publishData(encodedData, { reliable: true });
		
	}, [isRoomConnected,
		showJobTitle,
		room.localParticipant.identity,
		participantsFromReduxRef.current?.find(participant => participant.identity === room.localParticipant.identity)
	]);
	

	useEffect(() => {
		if (!isRoomConnected) return;

        if(room?.localParticipant?.isCameraEnabled === videoEnabledRef.current) return;
        
		room?.localParticipant.setCameraEnabled(videoEnabledRef.current)
		.then((res)=>{
            
			if(res?.kind === 'video' && res?.source=== 'camera'){
				setCameraPermission(PermissionStatus.GRANTED);
			}
            if(!videoEnabledRef.current){
                res?.track?.stop();
            }
		}).catch(error => {
            handleCameraError(error, setCameraPermission, (hasError) => setCameraError(hasError))
        })
	}, [isRoomConnected, videoEnabled, room?.localParticipant?.isCameraEnabled]);
    
	useEffect(() => {
		if (!isRoomConnected) return;
		room?.localParticipant.setMicrophoneEnabled(
			audioEnabledRef.current, 
			{ 
				echoCancellation: isActiveEchoCancellation, 
				noiseSuppression: isActiveNoiseCancellation
			}
		)
		.then((res)=>{
			if(res?.kind === 'audio' && res?.source=== 'microphone'){
				setMicroPermission(PermissionStatus.GRANTED)
			}
			if(!audioEnabledRef.current){
				res?.track?.stop();
			}
		}).catch(error => {
            handleMicroError(error, setMicroPermission)
        });
	
	}, [isRoomConnected, audioEnabled, isActiveEchoCancellation, isActiveNoiseCancellation]);

	useEffect(() => {
		if (!isRoomConnected) return;
		const isScreenShareEnabled = room?.localParticipant?.isScreenShareEnabled;
		setShareScreen(isScreenShareEnabled);

		const lastTimestampShareScreen = room?.localParticipant?.isScreenShareEnabled ? +new Date() : null;
		lastTimestampShareScreenContextRef.current = lastTimestampShareScreen;
		setLastTimestampShareScreen(lastTimestampShareScreen);

		const data = {
			type: 'share-screen',
			payload: {
				lastTimestampShareScreen,
				identity: room.localParticipant.identity
			},
		};
		const encodedData = encoder.encode(JSON.stringify(data));
		room.localParticipant.publishData(encodedData, { reliable: true });
	}, [room?.localParticipant?.isScreenShareEnabled]);

	useEffect(() => {
		if (!isRoomConnected) return;
		const localParticipant = participantsFromReduxRef.current.find(participant => participant.identity === room.localParticipant.identity);
		if (localParticipant) {
			dispatch(participantUpdated({ ...localParticipant, lastTimestampShareScreen: lastTimestampShareScreenContext }))
		}
		
	}, [isRoomConnected, lastTimestampShareScreenContext]);

	useEffect(() => {
		if (!isRoomConnected) return;
		const previousIsScreenShareEnabled = room.localParticipant.isScreenShareEnabled;

		if (previousIsScreenShareEnabled !== isShareScreenContext) {
			room.localParticipant.setScreenShareEnabled(isShareScreenContext, { audio: true })
			.then(()=>{})
			.catch(_=>{
				setShareScreen(false)
			})
		}
	}, [isRoomConnected, isShareScreenContext]);

	useEffect(() => {
		if (!isRoomConnected) return;

		isRaisingHandRef.current = isRaisingHand;
		const data = {
			type: 'raise-hand',
			payload: {
				isRaisingHand,
				identity: room.localParticipant.identity
			},
		};
		const encodedData = encoder.encode(JSON.stringify(data));
		room.localParticipant.publishData(encodedData, {reliable: true});
		
		const localParticipant = participantsFromReduxRef.current.find(participant => participant.identity === room.localParticipant.identity);
		if (localParticipant) {
			dispatch(participantUpdated({ ...localParticipant, isRaisingHand }))
		}

	}, [isRoomConnected, isRaisingHand]);

	useEffect(() => {
		if (!isRoomConnected || !pollDeleted) return;
		const data = {
			type: 'poll-delete',
			payload: {
				pollUUID: pollDeleted
			},
		};
		const encodedData = encoder.encode(JSON.stringify(data));
		room.localParticipant.publishData(encodedData, {reliable: true})
		.then(()=>{
			setPollList(prev=>prev.filter(item=>item?.poll?.poll_uuid !== pollDeleted));
			setPollDeleted(null);
		});
		
	}, [isRoomConnected, pollDeleted]);

	useEffect(()=>{
		if (!isRoomConnected || !QA || !isHost) return;
		const data = {
			type: 'question-active',
			payload: {
				QA
			},
		};
		const encodedData = encoder.encode(JSON.stringify(data));
		room.localParticipant.publishData(encodedData, {reliable: true})


	},[isRoomConnected, QA]);

	useEffect(()=>{
		if (!isRoomConnected || !poll || !isHost) return;
		const data = {
			type: 'poll-active',
			payload: {
				poll
			},
		};
		const encodedData = encoder.encode(JSON.stringify(data));
		room.localParticipant.publishData(encodedData, {reliable: true})


	},[isRoomConnected, poll]);

	useEffect(() => {
		if (!isRoomConnected || !questionDeleted) return;
		const data = {
			type: 'question-delete',
			payload: {
				questionUUID: questionDeleted
			},
		};
		const encodedData = encoder.encode(JSON.stringify(data));
		room.localParticipant.publishData(encodedData, {reliable: true})
		.then(()=>{
			setQuestionList(prev=>prev.filter(item=>item?.question?.qna_uuid !== questionDeleted));
			
			if(questionToAnswerRef.current?.questionUUID === questionDeleted){
				setQuestionToAnswer(null);
			}
			setQuestionDeleted(null);
		});
		
	}, [isRoomConnected, questionDeleted]);

	useEffect(()=>{
		if (!isRoomConnected || !liveSettings || !isHost) return;
		const data = {
			type: 'live-settings-update',
			payload: {
				liveSettings
			},
		};
		const encodedData = encoder.encode(JSON.stringify(data));
		room.localParticipant.publishData(encodedData, {reliable: true})

	},[isRoomConnected, liveSettings])

	useEffect(()=>{
		if(!!questionToAnswer && sidePanelType !== SidePanelType.CHAT){
			setSidePanelType(SidePanelType.CHAT);
			setIconSelectInChatSidePanel(ChatIconType.QA);
		}
	},[questionToAnswer])


	useEffect(()=>{
		questionToAnswerRef.current = questionToAnswer;
	},[questionToAnswer])

	useEffect(() => {		
		setForceRequestPermission(true);
		return () => {
			room?.removeAllListeners();
			room?.disconnect();
		}
	}, []);

	const integrationProps = {
		poll, setPoll,
		pollList, setPollList,
		pollToCreated, setPollToCreated,
		pollDeleted, setPollDeleted,
		pollAction, setPollAction,
		updatePollWithOptionUUID,
		showPollConfirmation, setShowPollConfirmartion,
		
		QA, setQA,
		questionList, setQuestionList,
		questionDeleted, setQuestionDeleted,
		questionToAnswer, setQuestionToAnswer,
		questionAction, setQuestionAction,

		whiteboard, setWhiteboard
	}
	const commonSidePanelProps = {
		hasInputFocus,
		setInputFocus
	}
	const optionMenuProps = {
		handleMakeHost,
		handleAskMute,
		handleMuteMic,
		handleSetPinned,
		pinnedParticipantIdentity,
		setUpdatePinnedFromPanel
	};

	const sidePanelTypeOnlyInPhoneScreen =
		sidePanelType === SidePanelType.SETTINGS ||
		sidePanelType === SidePanelType.VISUALEFFECTS ||
		sidePanelType === SidePanelType.SCREENLAYOUT;
	const showSidePanel =
		sidePanelType &&
		(isPhoneScreen || (!isPhoneScreen && !sidePanelTypeOnlyInPhoneScreen));

	const showVisualEffectPanel =
		isPhoneScreen && sidePanelType === SidePanelType.VISUALEFFECTS && isPipelineSupported;
	const showScreenLayoutPanel =
		isPhoneScreen && sidePanelType === SidePanelType.SCREENLAYOUT;
	const showSettingsMobile =
		isPhoneScreen && sidePanelType === SidePanelType.SETTINGS;
	const mainClassName = `meetroom__body__main ${sidePanelType ? 'show-side-panel' : ''
		}`;

	const mainStyle = {
		flex: 1,
		overflow: 'hidden',
		transition: 'flex 0.3s ease-in-out',
		display: 'flex',
	};

	const width = !sidePanelType ? 0 : isPhoneScreen ? '100%' : '410px';
	const padding = !sidePanelType ? 0 : isPhoneScreen ? 0 : '0 0 0 10px';
	const transition = isPhoneScreen ? 'unset' : 'width 0.3s ease-in-out'
	const sidePanelStyle = {
		width: width,
		padding: padding,
		overflow: 'hidden',
		transition: transition,
	};

	if(!lkToken) return <></>;
	return (
        <div
            ref={elRef}
            style={mainStyle}
            className={mainClassName}>
            <main
                data-lk-theme='default'
                style={{ width: '100%', height: '100%' }}>
                <LiveKitRoom
                    room={room}
                    serverUrl={process.env.REACT_APP_URL_LIVEKIT}
                    token={lkToken}
                    connect={true}
					connectOptions={{maxRetries:2}}
					
                >
                    {/* <VideoConference */}
                    <MyVideoConference
                        hasSidePanel={!!sidePanelType}
                        context={layoutContext}
                        updatePinnedFromPanel={updatePinnedFromPanel}
                        pinnedParticipantIdentity={pinnedParticipantIdentity}
                        setPinnedParticipantIdentity={setPinnedParticipantIdentity}
                        showChat={sidePanelType === SidePanelType.CHAT}
                        onSendChatMessageChange={onSendChatMessageChange}
                        onSendEmojiChange={onSendEmojiChange}
                        setLastMessage={setLastMessage}
                        messagesList={messagesList}
                        setMessagesList={setMessagesList}
                        isHost={isHost}
                        cameraError={cameraError}
                    />
        
                    <div className={`meetroom__body__side-panel`} style={sidePanelStyle}>
                        {sidePanelType === SidePanelType.CHAT && 
                            <ChatPanel
								isHost={isHost}
                                sendChatMessage={sendChatMessageRef.current}
                                hiddenPanelState={hiddenPanelState}
                                msgE={msgE}
                                setMsgE={setMsgE}
                                messagesList={messagesList}
                                setMessagesList={setMessagesList}
								
								setInputFocus={setInputFocus}
								iconSelectInChatSidePanel={iconSelectInChatSidePanel}
								setIconSelectInChatSidePanel={setIconSelectInChatSidePanel}
								liveSettings={liveSettings}
                                {...showSidePanel}
								{...integrationProps}
                                {...commonSidePanelProps}
                            />
                        }
                        {sidePanelType === SidePanelType.PARTICIPANT && 
                            <ParticipantPanel
                                room={room}
                                hiddenPanelState={hiddenPanelState}
                                optionBoxIdentityInPanel={optionBoxIdentityInPanel}
                                handleOptionBoxIdentityInPanel={handleOptionBoxIdentityInPanel}
                                muteEveryone={muteEveryone}
                                showShareModal={() => handleShowShareModal()}
                                {...requestPopupProps}
                                {...showSidePanel}
                                {...optionMenuProps}
                                {...commonSidePanelProps}
                            />
                        }
						{sidePanelType === SidePanelType.INTEGRATION && isHost &&
							<IntegrationsPanel	
								hiddenPanelState={hiddenPanelState}
								poll={poll}
								setPoll={setPoll}
								QA={QA}
								setQA={setQA}
								whiteboard={whiteboard}
								setWhiteboard={setWhiteboard}
								startNewPoll={startNewPoll}
								startNewQA={startNewQA}
							/>
						}
                        {showVisualEffectPanel &&
                            <SidePanel
                                title={translateJSON['btn-visual-effects']}
                                hiddenPanelState={hiddenPanelState}>
                                <VisualEffectConfig
                                    isInMeetRoom={true}
                                    visualEffect={featureVisualEffect}
                                    setVisualEffect={setFeatureVisualEffect}
                                />
                            </SidePanel>
                        }
                        {showScreenLayoutPanel &&
                            <SidePanel
                                title={translateJSON['settings-layout-title']}
                                hiddenPanelState={hiddenPanelState}>
                                <ScreenLayoutConfig
                                    screenLayoutType={screenLayoutType}
                                    setScreenLayoutType={(value) =>
                                        dispatch(setScreenLayout(value))
                                    }
                                    screenLayoutTiles={screenLayoutTiles}
                                    setScreenLayoutTiles={(value) =>
                                        dispatch(setScreenTiles(value))
                                    }
                                />
                            </SidePanel>}
					
                        {showSettingsMobile && <SettingModalMobile hiddenPanelState={hiddenPanelState} />}
                    </div>
                </LiveKitRoom>
            </main>
        </div>
	);
};

export default MyLivekitRoom;
