import React, { useState, useEffect, useRef } from 'react';
import ArrowDownSvg from '../assets/icons/arrow-down.svg';
import ArrowUpSvg from '../assets/icons/arrow-up.svg';
import useAuthContext from '../hooks/useContext';
import IconSvg from './IconSvg';

const InputSelect = ({ options, handleValClick, type, defaultOption, disabled, defaultImg }) => {

  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  const { translateJSON } = useAuthContext();

  const [selectedOption, setSelectedOption] = useState(null)

  const handleOptionClick = (option) => {
    setSelectedOption(option.translate ? option.translate : option.label)
    setIsOpen(false);
    if (handleValClick) {
      handleValClick(option);
    }
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const listItems = options.map((o, index) => {
    let label = o.translate ? translateJSON[o.translate] : o.label ;
    if (!label) {
      label = o.deviceId;
    }
    return <li key={`${o.key}-${index}`} 
      onMouseDown={(event) => {
        event?.stopPropagation();
        handleOptionClick(o)}
      }>
        {o?.img && <img src={o?.img} alt='${label}' />}
        {label}
    </li>
  
  });
  
  return (
    <div className={type === 'grid' ? 'input-box grid-box' : 'input-box'} ref={ref}>
      <div tabIndex={0} className={`input-box__value scroll-bar invisible ${defaultImg ? 'has-img':''}`} 
      onMouseDown={(event) => {
        event?.stopPropagation();
        if (disabled) return;
        if (listItems.length === 0) return;
        setIsOpen(!isOpen);
      }}>
        {defaultImg && <img src={defaultImg} alt='${label}' />}
        <span>
          {selectedOption ? (
            translateJSON[selectedOption] || selectedOption
          ) : (
            defaultOption
          )}
        </span>
        <IconSvg svg={isOpen ? ArrowUpSvg : ArrowDownSvg} />
      </div>
      {isOpen && (
        <div className="input-box__options">
          <ul className="scroll-bar invisible">{listItems}</ul>
        </div>
      )}
    </div>
  )
};

export default InputSelect;
