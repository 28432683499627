import React from "react";
import IconSvg from "../../IconSvg";
import CrossSvg from '../../../assets/icons/cross-gray.svg';
import ErrorInfoSVG from '../../../assets/icons/error-info.svg';
import useAuthContext from "../../../hooks/useContext";

const QuestionActionModal = ({ questionUUID, closeModal, deleteQuestion })=>{

    const { translateJSON } = useAuthContext();
    const title = translateJSON['qa-delete-question'];

    const part = translateJSON['qa-delete-question-desc'];
    console.log('part', part)
    const array = part.split('? ');
    console.log('array',array)
    let part1 = part, part2 = '';
    if(array?.length){
        part1 = `${array[0]}? `;
        part2 = array[1];
    }
    const btnTitle = translateJSON['btn-delete'];
    const color = '#C74747';
    return(
        <div className='qa-action-modal'>
            <div className='qa-action-modal-mask' />

            <div className='qa-action-modal-container'>

                <div className='qa-action-modal-container__body'>
                    <div className='qa-action-modal-container__body__header'>
                        <div className='qa-action-modal-container__body__header__close'  onMouseDown={closeModal}>
                            <IconSvg svg={CrossSvg} />
                        </div>

                        <div className='qa-action-modal-container__body__header__warning'>
                            <IconSvg svg={ErrorInfoSVG} backgroundAdd={color} />
                        </div>
                    </div>
                    <span type='title'>{title}</span>
                    <div className="info">
                        <span type='part'>{part1}</span>
                        <span type='part'>{part2}</span>
                    </div>

                    </div>
                <div className='qa-action-modal-container__footer'>
                    <button style={{border: `1px solid ${color}`, background: 'transparent' }} onClick={closeModal}>
                        <span style={{ color }}>{translateJSON['btn-cancel'] || 'Cancel'}</span>
                    </button>
                    <button style={{background: color, border: 'unset'}} 
                        onClick={()=>{ 
                            deleteQuestion(questionUUID);
                            closeModal();
                        }}>
                        <span style={{color: "white"}}>{btnTitle}</span>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default QuestionActionModal;