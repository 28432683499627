
import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import duration from 'dayjs/plugin/duration';


import useAuthContext from "../../../hooks/useContext";
dayjs.extend(duration);

const CountdownTimer = ({ isClosed, endDate, handleClosePoll }) => {
    const { translateJSON } = useAuthContext();
    const [timeLeft, setTimeLeft] = useState({});
    const intervalIdRef = useRef();

    useEffect(() => {
        const calculateTimeLeft = () => {
            const now = dayjs();
            const end = dayjs(endDate);
            const difference = end.diff(now);
            if (difference > 0) {
                const duration = dayjs.duration(difference);
                return {
                    days: duration.days(),
                    hours: duration.hours(),
                    minutes: duration.minutes(),
                    seconds: duration.seconds()
                };
            }
            clearInterval(intervalIdRef.current);
            handleClosePoll();
            return {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0
            };
            
        };

        setTimeLeft(calculateTimeLeft());

        intervalIdRef.current = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(intervalIdRef.current);
    }, [endDate]);

    let res = ''
    if(timeLeft.days > 0){
        res += `${timeLeft.days} ${translateJSON['post-poll-day']}`;
    }
    if(timeLeft.hours > 0) {
        if(res){
            res += ' '; 
        }
        res += `${timeLeft.hours} ${timeLeft.hours > 1 ? translateJSON['global-hours'] : translateJSON['global-one-hour']}`;
    }
    if(timeLeft.minutes > 0){
        if(res){
            res += ' '; 
        }
        res += `${timeLeft.minutes} ${timeLeft.minutes > 1 ? translateJSON['counter-minutes'] : translateJSON['counter-minute']}`;
    }
    if(timeLeft.seconds > 0){
        if(res){
            res += ' '; 
        }
        res += `${timeLeft.seconds} ${timeLeft.seconds > 1 ? translateJSON['back-home-secs'] : translateJSON['back-home-onesec']}`;
    }
    if(!res) return <></>;
    if(isClosed){
        res = '0 min 0 sec';
    }

    return (
        <span type='poll-duration'>
            {res}
        </span>
    );
};

export default CountdownTimer;