import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import AddSmileySvg from '../../assets/icons/add-smiley.svg';
import ArrowLeft from '../../assets/icons/arrow-back-gray.svg';
import CloseSvg from '../../assets/icons/cross-24.svg';
import ChatSvg from '../../assets/icons/chat-icon.svg';
import PollsSvg from '../../assets/icons/polls.svg';

import IconSvg from "../IconSvg";
import useAuthContext from "../../hooks/useContext";
import AutoResizeTextarea from "./AutoResizeTextarea";
import { createToolTypeMapping, IntegrationType } from "./IntegrationsPanel";


const IntegrationCard = ({isInMeetRoom = false, cardItem, closeCard, update, startNew, images, checkLabel,startDesc, indiceSvgs, startBtnTitle}) =>{
   
    const { translateJSON }= useAuthContext();

    const navAppsTitle = translateJSON['room-applications-title'] || 'Apps';
    const navBackTitle = translateJSON['global-back'] || 'Back';

    let nameTrans, longDescriptionTrans;
    const types = (cardItem?.types ?? []).map(type => createToolTypeMapping(translateJSON)[type] || type);
    if(cardItem.name === IntegrationType.POLL){
        nameTrans = translateJSON['room-apps-polls-title'];
        longDescriptionTrans = translateJSON['apps-polls-desc-long'];
    }else if(cardItem.name === IntegrationType.QA){
        nameTrans = translateJSON['room-apps-qa-title'];
        longDescriptionTrans = translateJSON['apps-qa-desc-long'];
    }else if(cardItem.name === IntegrationType.Whiteboard){
        nameTrans = translateJSON['room-apps-whiteboard-title'];
        longDescriptionTrans = translateJSON['apps-whiteboard-desc-long'];
    }

    return (
        <div className={`integration-card ${isInMeetRoom ? 'is-in-meetroom':''}`}>
            <div className={`integration-card__body`} style={{height: (isInMeetRoom && cardItem?.is_active) ? 'calc(100% - 64px)' : '100%'}}>
                <div className="integration-card__top">
                    <div className="integration-card__nav">
                        <div className="integration-card__nav__left" onMouseDown={closeCard} >
                            <IconSvg svg={ArrowLeft} />
                            <span role="nav-title">{!!isInMeetRoom ? navAppsTitle : navBackTitle}</span>
                        </div> 
                        {!!isInMeetRoom && <IconSvg svg={CloseSvg} onMouseDown={closeCard} />}
                    </div>

                    <div className="integration-card__title_checkframe">
                        <div className="integration-card__title">
                            <h4>{nameTrans}</h4>
                            <div className="integration-card__title__types">
                                {types.map((item, index)=> <span type='type' key={index}>{item}</span>)}
                            </div>
                        </div>

                        <div className={`integration-card__check-frame ${isInMeetRoom ? 'is-in-meetroom':''}`}>
                            <input type="checkbox" id={`switch`} checked={cardItem?.is_active} onChange={update} />
                            <label htmlFor={`switch`}>Toggle</label>
                            <div className="check-frame-wrapper" onMouseDown={update}>
                                <span role="check-frame-label" className={!isInMeetRoom ? 'span-is-in-meetroom':''}>{checkLabel}</span>
                            </div>
                            
                        </div>
                    </div>
                </div>
               
                <div className='integration-card__description'>
                    <Swiper
                        effect={'coverflow'}
                        grabCursor={true}
                        centeredSlides={true}
                        slidesPerView={'auto'}
                        coverflowEffect={{
                            rotate: 50,
                            stretch: 0,
                            depth: 100,
                            modifier: 1,
                            slideShadows: true,
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={true}
                        modules={[EffectCoverflow, Pagination, Navigation]}
                        className="mySwiper"
                    >
                        {images.map((item, index)=>
                            <SwiperSlide data-hash="slide1" key={index}>
                                <img src={item} />
                            </SwiperSlide>
                        )}
                    </Swiper>
                    <span>{longDescriptionTrans}</span>
                </div>

                {isInMeetRoom && 
                    <div className='integration-card__indice'>
                        {startDesc}
                        <div className='integration-card__indice__footer'>
                            <div className='integration-card__indice__footer__top'>
                                <IconSvg svg={ChatSvg} classAdd={'chat'}/>
                                {indiceSvgs}
                            </div>
                            <AutoResizeTextarea
                                placeholder={translateJSON['chat-message-placeholder']}
                                disabled={true}
                                afterTag={<IconSvg svg={AddSmileySvg} />}
                            />
                        </div>
                    </div>
                }
                
            </div>

            {isInMeetRoom && cardItem?.is_active &&
                <div className='integration-card__start-poll-btn'>
                    <button
                        className={'btn-icon'}
                        title={startBtnTitle}
                        onClick={startNew}
                        data-size={'large'}>
                        <IconSvg svg={PollsSvg} />
                        <span>{startBtnTitle}</span>
                    </button>
                </div>
            }
        </div>
    );
}

export default IntegrationCard;