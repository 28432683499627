import React from "react";
import formatTime from "../../utils/formatTime"

const MeetDuration = ({ duration = 0 }) =>
    <div className="time-elapsed">
        <span>{formatTime(duration, false)}</span>
    </div>

export default MeetDuration

