import React from "react";
import InputSelect from "../InputSelect";
import useAuthContext from "../../hooks/useContext";
import { languageOptions, getLanguageByCode, getFlagByCode } from "../../constants/language";
import SidePanel from "./SidePanel";

const SettingModalMobile = (props) => {    
    const { hiddenPanelState } = props;
    const { translateJSON, userLang, setUserLang } = useAuthContext();

    const handleChangeLanguage = (value) => setUserLang(value.key.toLowerCase());


    return (
        <SidePanel title={'Settings (no translate)'} hiddenPanelState={hiddenPanelState}>

            <div className='setting-modal-mobile'>
                <div className='input-frame'>
                    <label htmlFor='language'>{translateJSON['language-expr']}</label>
                    <InputSelect
                        options={languageOptions}
                        defaultImg={getFlagByCode(userLang)}
                        defaultOption={getLanguageByCode(userLang)}
                        handleValClick={handleChangeLanguage}
                    />
                </div>
            </div>
        </SidePanel>
    )
}

export default SettingModalMobile;