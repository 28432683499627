import React, {useEffect} from 'react';
import useAuthContext from '../hooks/useContext';
import BtnRegular from './BtnRegular';
import StaggeredAnimation from './StaggeredAnimation';
import useMediaQuery from '../hooks/useMediaQuery';
import { MEDIUM_SCREEN } from '../utils/breackpoints';

const UserModal = (props) => {
    const { src, closeModal, disconnect } = props;
    const { userEmail, userName, jobTitle, translateJSON } = useAuthContext();
    
	const isMediumScreen = useMediaQuery(MEDIUM_SCREEN);
    useEffect(() => {
		const anim = new StaggeredAnimation(
			'.user-modal',
			isMediumScreen ? 350 : -350,
			'posY',
			1,
			'ease',
			250
		);
        anim.animate();
    }, []);
    
    return (
        <>
            <div className="overlay-user-modal" onMouseDown={closeModal} />
            
            <div className='user-modal'>
                <div className="user-modal__avatar" >
                    <img src={src} alt="portrait" />
                </div>
                <div className='user-modal__info'>
                    <h4>{userName}</h4>
                    <p>{jobTitle}</p>
                    <span>{translateJSON['home-signedin']} {userEmail}</span>
                </div>
                <BtnRegular content='Disconnect' event={disconnect} translate='btn-sign-out' />
                <div className='user-modal__contact'>
                    <span>{translateJSON['home-signedin-help']}</span> 
                    <span>+44 20 3808 5555</span>
                </div>
                
            </div>
        </>
    );
}
export default UserModal

