import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";

import PollsSvg from '../../../assets/icons/polls.svg';
import DotsSvg from '../../../assets/icons/three-dots.svg';
import CrossSvg from '../../../assets/icons/cross-24.svg';
import DeleteSvg from '../../../assets/icons/minus-icon.svg';
import CheckSvg from '../../../assets/icons/check.svg';
import CloseSvg from '../../../assets/icons/remove.svg';
import useAuthContext, { useGeneralContext } from "../../../hooks/useContext";
import ParticipantModal from "../../../dataModal/ParticipantModal";
import { fetchAvatarFromCaches } from "../../../helpers/auth";
import { participantUpdated, selectLocalParticipant } from "../../../redux/participantsSlice";
import { closePoll } from "../../../helpers/eventApi";
import { getInitialFromString } from "../../../utils/getInitialFromString";
import IconSvg from "../../IconSvg";
import { PopupShowDuration } from "../../../pages/MeetRoom";
import CountdownTimer from "./CountdownTimer";
import PollOptionItem from "./PollOptionItem";


const PollItem = (props)=>{
    const {
        isInPopUp = false,
        isHost,
        poll : {
            poll_uuid: pollUUID,
            event_uuid: eventUUID,
            question,
            options,
            show_results : showResults,
            is_closed: isClosed,
            end_date: endDate,
            poll_response_summary: responseSummary, 
            selected_poll_option_uuid
        },
        sender: senderFromProps,
        isSameWithPrevious,
        isSameWithNext,
        setPollAction = ()=>{},
        updateShow = ()=>{},
        updatePollWithOptionUUID = ()=>{}
    } = props;

    const dispatch = useDispatch();
    
    const currentParticipant = useSelector(selectLocalParticipant);
    const { teamID, userID, translateJSON } = useAuthContext();
    const { currentParticipantUUID } = useGeneralContext();
    const sender = new ParticipantModal({ ...senderFromProps });
    const avatarRef = useRef();
    const [showMoreOption, setShowMoreOption] = useState(false);
    const [showSummary, setShowSummary] = useState(false);
    const isCreator = sender?.id === currentParticipant?.id;
    const isRightSide = isInPopUp ? '' : isCreator ? 'is-right-side' : 'is-left-side';
    const modalRef = useRef();
    let timer;
    const isLoggedIn = !!teamID && !!userID;
    const fetchImage = async () => {
        if (!isLoggedIn) return;

        if (sender?.picture?.startsWith('data:image')) return;
        const blob = await fetchAvatarFromCaches(sender?.picturePath);
        if (blob) {
            if (avatarRef.current) {
                avatarRef.current.src = blob;
            }
            dispatch(participantUpdated({ ...sender, picture: blob }));
        }
    };

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setShowMoreOption(false);
        }
      };

    const handleClosePoll = () => closePoll({ eventUUID, pollUUID, teamID, userID });

    useEffect(()=>{
        if(!isInPopUp) return;
        if(timer){
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            updateShow();
        }, PopupShowDuration);
    },[isInPopUp]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            if(timer){
                clearTimeout(timer);
            }
        };
    }, []);

    const initial = getInitialFromString(sender.userName);
    
    const className = `meetroom-poll-item ${isRightSide} ${!isSameWithPrevious ? 'not-same-with-previous' : ''} ${!isSameWithNext ? 'not-same-with-next' : ''}`;
    const hasResponse = responseSummary?.find(item => item.answered_by && item.answered_by.includes(currentParticipantUUID))?.length;
    const isVoted = !!hasResponse|| !!selected_poll_option_uuid;
    const showCreatorSeeResults = isCreator && !showSummary;
    const showCreatorBackToVote = isCreator && showSummary;
    const showGuestVoteToSeeResults = !isCreator && showResults && !isVoted;
    const showGuestResult = !isCreator && isVoted;
    const showGuestVoted = !isCreator && !showResults && isVoted; 
    const showLeftEmpty = !isCreator && !showGuestVoteToSeeResults && !showGuestVoted;
    const showMore = showCreatorSeeResults || showCreatorBackToVote || showGuestVoteToSeeResults || showGuestVoted || !!endDate;
    const optionList = ((showCreatorBackToVote || showGuestResult) && !!responseSummary) ? responseSummary.sort((a, b) => (b?.num_response ?? 0) - (a?.num_response ?? 0)) : options;

    return (
        <div className={className}>

            <div className={`meetroom-poll-item__container ${isInPopUp ? 'is-in-popup':''}`}>
                <div className='meetroom-poll-item__container__header'>
                    <div className='meetroom-poll-item__container__header__title' data-size="large">
                        <IconSvg svg={PollsSvg} />
                        <span type={isInPopUp ? 'title-in-popup' : 'title-in-item'}>{!!isClosed ? translateJSON['poll-closed-title']: translateJSON['poll-started-title']}</span>
                    </div>
                    {isInPopUp && <IconSvg classAdd={'close'} svg={CrossSvg} 
                        onMouseDown={(event)=>{
                            event?.stopPropagation();
                            updateShow();
                        }}/>}
                    {!isInPopUp && isHost && <IconSvg classAdd={'more'} svg={DotsSvg} 
                        onMouseDown={(event)=>{
                            event?.stopPropagation(); 
                            setShowMoreOption(true);
                        }} />}
                    {showMoreOption && <div className="more-options" ref={modalRef}>
                        <div className="item" onMouseDown={(event)=>{
                            event?.stopPropagation();
                            setPollAction({action: 'delete', pollUUID });
                            setShowMoreOption(false);
                        }}>
                            <IconSvg svg={DeleteSvg} />
                            <span>{translateJSON['poll-delete'] || 'Delete poll'}</span>
                        </div>

                        {!isClosed && <div className="item" onMouseDown={(event)=>{
                            event?.stopPropagation();
                            setPollAction({action: 'close', pollUUID });
                            setShowMoreOption(false);
                        }}>
                            <IconSvg svg={CloseSvg} />
                            <span>{translateJSON['poll-close']}</span>
                        </div>}
            </div>}
                </div>
                <div className='meetroom-poll-item__container__body'>
                    <div className="top">
                        <div className="creator">
                            {(isLoggedIn && sender?.picture)
                                ? <LazyLoadImage beforeLoad={() => fetchImage()} src={sender.picture} ref={avatarRef}  alt='' />
                                : <div className='macaroon' style={{ backgroundColor: sender.color }}><span>{initial}</span></div>
                            }
                            <span type='username'>{isCreator ? translateJSON['polls-you'] : sender.userName}</span>
                        </div>
                        <p type='question'>{question}</p>
                    </div>
                    
                    <div className="options">
                        
                        {optionList?.map((item,index)=>{
                            const response = (responseSummary ?? [])?.find(ele=>ele?.poll_option_uuid === item?.poll_option_uuid);
                            return <PollOptionItem key={`${index}-${item?.poll_option_uuid}-${response?.num_response}`} 
                                isVoted={isVoted}
                                isClosed={isClosed}
                                isCreator={isCreator}
                                showSummary={showSummary}
                                showResults={showResults}
                                pollUUID={pollUUID}
                                eventUUID={eventUUID}
                                pollOption={item?.poll_option}
                                pollOptionUUID={item?.poll_option_uuid}
                                answeredBy={response?.answered_by}
                                numResponse={response?.num_response}
                                percentage={response?.percentage}
                                selectedPollOptionUUID={selected_poll_option_uuid}
                                updatePollWithOptionUUID={updatePollWithOptionUUID}
                        /> })
                        }
                    </div>
                    
                    {showMore && <div className='more-info'>
                        {showCreatorSeeResults && <span type='see-results' onMouseDown={()=>setShowSummary(true)}>{translateJSON['poll-view-results'] || 'View results'}</span>}
                        {showCreatorBackToVote && <span type='back-to-vote' onMouseDown={()=>setShowSummary(false)}>{translateJSON['poll-back-to-vote'] || 'Back to vote'}</span>}
                        {showLeftEmpty && <span>{ }</span>}
                        {showGuestVoteToSeeResults && <span type='vote-to-see-results'>{translateJSON['poll-vote-to-view'] || 'Vote to view results'}</span>}
                        {showGuestVoted && <div className="you-voted">
                            <IconSvg svg={CheckSvg} />
                            <span type='you-voted'>{translateJSON['poll-vote-confirm'] || 'You voted'}</span>
                        </div>}
                        {!!endDate && <CountdownTimer isClosed={isClosed} endDate={endDate} handleClosePoll={handleClosePoll} />}
                    </div>}
                </div>
                
            </div>
        </div>);
}

export default PollItem;