import React from "react";

import useAuthContext from "../../hooks/useContext";

import CrossSvg from '../../assets/icons/cross-gray.svg';
import RecordSvg from '../../assets/icons/record-icon.svg';
import BtnRegular from "../BtnRegular";
import useModalAnimated from "../../hooks/useModalAnimated";
import { recordEvent } from '../../helpers/eventApi';
import IconSvg from "../IconSvg";

const ReadyRecordModal = ({
    setReadyRecordModal,
    setRecordingStart,
    eventUUID,
    participantUUID
}) => {
    const { translateJSON, teamID, userID } = useAuthContext();
    const { isAnimated } = useModalAnimated();

    const handleClickStartButton = () => {
        setReadyRecordModal(false);
        setRecordingStart(true);

        recordEvent({ isStart: true, participantUUID, eventUUID, teamID, userID })
            .then(() => {
                console.log('start record succeful')
            }).catch((err) => {
                console.error('start record failed', err)
            });
    }

    return (
        <>
            <div className='record-modal-overlay' onMouseDown={() => setReadyRecordModal(false)} />
            <div className={`record-modal --start ${isAnimated ? 'active': ''}`}>
                <div className='record-modal__header' >
                    <div className='heading' data-size="x-large">
                        <IconSvg svg={RecordSvg} />
                        <h2>{translateJSON['record-title']}</h2>
                    </div>
                    <IconSvg svg={CrossSvg} onMouseDown={() => setReadyRecordModal(false)} />
                </div>
                <div className='record-modal__body'>
                    <h5>{translateJSON['record-msg-title']}</h5>
                    <p>{translateJSON['record-msg']}</p>
                </div>
                <div className='record-modal__footer'>
                    <button className={'btn-regular'} onClick={() => setReadyRecordModal(false)}>
                        <span>{translateJSON['btn-cancel']}</span>
                    </button>
                    <button className={'btn-regular'} onClick={handleClickStartButton}>
                        <span>{translateJSON['record-btn-start']}</span>
                    </button>
                </div>
            </div>
        </>
    )
};

export default ReadyRecordModal;