
import React, { memo, useEffect, useState } from 'react';
import WheelSvg from '../../assets/icons/wheel-params.svg';
import CheckSvg from '../../assets/icons/check.svg';
import ThreeStarsSvg from '../../assets/icons/three-stars.svg';
import UserProfileSvg from '../../assets/icons/user-profile.svg';

import IconSvg from '../IconSvg';
import VisualEffectConfig from '../SharedComponents/VisualEffectConfig';
import AudioVideoConfig from '../SharedComponents/AudioVideoConfig';
import ProfileSettings from '../SharedComponents/ProfileSettings';
import ModalWithTabs from '../SharedComponents/ModalWithTabs';

import isVisuelEffectSupported from '../../utils/checkPipelineSupport';
import { MEDIUM_SCREEN, SMALL_SCREEN } from '../../utils/breackpoints';
import useMediaQuery from '../../hooks/useMediaQuery';
import useAuthContext, { useGeneralContext } from '../../hooks/useContext';
import useSharedPropsForSettingsModal from '../../hooks/useSharedPropsForSettingsModal';

export const PreviewSettingOption = {
    VIDEO: 'audio',
    VISUALEFFECT: 'visualEffect',
    PROFILE: 'profile',
}
const PreviewSettingOptionsList = [
    {label: PreviewSettingOption.VIDEO, title: 'Audio and video', icon: WheelSvg, translate: 'prejoin-button-audio-video' },
    {label: PreviewSettingOption.VISUALEFFECT, title: 'Visual effects', icon: ThreeStarsSvg, translate: 'btn-visual-effects' },
    {label: PreviewSettingOption.PROFILE, title: 'Profile', icon: UserProfileSvg, translate:'settings-profile-title' }
];
    
const PreviewSettingsModal = ({ closeModal, selectedSettingsOption, setSelectedSettingsOption }) => {
    
    const isPipelineSupported = isVisuelEffectSupported();
    const isPhoneScreen = useMediaQuery(SMALL_SCREEN);
    const isMediumScreen = useMediaQuery(MEDIUM_SCREEN);

    const { translateJSON } = useAuthContext();
    const { audioVideoConfigProps, profilConfigProps, visualEffectConfigProps, saveUpdated } = useSharedPropsForSettingsModal();
    
    const handleClickSave = () => {
        saveUpdated();
        closeModal();
    }
    
    const renderOptionContent = () => {
        let content;
        switch (selectedSettingsOption) {
            case PreviewSettingOption.VIDEO:
                content = <AudioVideoConfig {...audioVideoConfigProps} />
                break;
            case PreviewSettingOption.VISUALEFFECT:
                content = <VisualEffectConfig {...visualEffectConfigProps} />
                break;
            case PreviewSettingOption.PROFILE:
                content = <ProfileSettings {...profilConfigProps} />
                break
            default:
                break;
        }
        return content;
    }
    
    if (isPhoneScreen && selectedSettingsOption === PreviewSettingOption.VIDEO) {
        closeModal();
    }

    const options = PreviewSettingOptionsList.filter((item) => {
        if (!isPipelineSupported) {
            return item.label !== PreviewSettingOption.VISUALEFFECT;
        }
        if (isMediumScreen) {
            return item;
        }
        return item.label !== PreviewSettingOption.VIDEO;
                            
    }).map((option, index) =>
        <button
            key={index}
            className={`btn-icon ${selectedSettingsOption === option?.label ? 'active' : ''}`}
            onClick={() => setSelectedSettingsOption(option?.label)}
            data-size={'large'}>
            <IconSvg svg={option.icon} />
            <span>{translateJSON[option.translate]}</span>
        </button>)
        
    const footerButtons = <>
        <button className={'btn-regular'} onClick={closeModal}>
            <span>{translateJSON['btn-cancel']}</span>
        </button>
        <button className={'btn-regular'} onClick={handleClickSave}>
            <span>{translateJSON['btn-save']}</span>
        </button>
    </>;
    return (
        <ModalWithTabs
            maxWidth={800}
            minHeight={435}
            headingSvg={CheckSvg}
            headingTitle={translateJSON['audiovideo-title']}
            closeModal={closeModal}
            options={options}
            content={renderOptionContent()}
            footerButtons={footerButtons} />
    )
};

export default memo(PreviewSettingsModal);