import React, { useEffect, useState } from 'react';
import BGSVG from '../assets/icons/bg.svg';
import IconSvg from './IconSvg';

const BgPanel = ({ isInMeetRoom = false, isInPreview = false, appearance }) => {

    const [showBackgroundImage, setShowBackgroundImage] = useState(false);
    useEffect(()=>{
        if(appearance){
            const use_background_image = appearance?.use_background_image ?? false;
            const backgroundImage = appearance?.background_image;
            if(use_background_image && backgroundImage){
                setShowBackgroundImage(true);
            }
        }
    },[appearance]);

    const showRight = !isInMeetRoom && !isInPreview;
    return (
        <>
            {showBackgroundImage && <div className={'bg-panel is-in-meetroom'} style={{  backgroundImage: `url(${appearance?.background_image})` }} />}
            {!showBackgroundImage && <div className={`bg-panel ${isInMeetRoom ? 'is-in-meetroom' : ''}`}>
                <div className='bg-panel__left'>
                    <div className='ornament-frame'>
                        <IconSvg svg={BGSVG} />
                    </div>
                </div>
                {showRight && <div className='bg-panel__right'>
                    <div className='ornament-frame'>
                        
                        <IconSvg svg={BGSVG} />
                        <IconSvg svg={BGSVG} />
                    </div>
                </div>}
            </div>}
        </>
    );
}
    
export default BgPanel;