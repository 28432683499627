import React, { forwardRef } from 'react';
import useAuthContext from '../hooks/useContext';


const BtnIcon = forwardRef((props, ref) => {

	const { translateJSON } = useAuthContext();

	const newClassName = props.customClassName ? `btn-icon__${props.customClassName}` : ''
	const spanText = (props.translate && translateJSON[props.translate]) ? `${translateJSON[props.translate]}` : props.content;
	const buttonStyle = props.style ? props.style : {};
	return (
		<button
			ref={ref}
			style={buttonStyle}
			className={`btn-icon ${newClassName} ${props.classAdded ?? ''}`}
			data-size={props.size}
			onClick={props.event}
			disabled={props.disabled}	
			data-interactive={props.interactive}>
			{props.icon && (
				<img
					src={props.icon}
					alt={props.alt}
				/>
			)}
			<span id={props.id} >{spanText}</span>
		</button>
	);
});

export default BtnIcon;