import { ReactComponent as NetworkStatusDangerSvg } from '../assets/icons/network-status-danger.svg';
import { ReactComponent as NetworkStatusWarningSvg } from '../assets/icons/network-status-warning.svg';
import { ReactComponent as NetworkStatusStableSvg } from '../assets/icons/network-status-stable.svg';

export const ParticipantRole = {
	HOST: 'host',
	GUEST: 'guest',
};

// const ParticipantType = {
// 	INTERNAL: 'internal',
// 	EXTERNAL: 'external',
// };

export const NetworkStatus = {
	GOOD: 'good connection',
	LOW: 'low connection',
	BAD: 'bad connection',
};

class ParticipantModal {
	constructor({
		identity = '',
		id = '',
		userName = '',
		familyName = '',
		jobTitle= '',
		color,
		email = '',
		picture = '',
		picturePath = '',
		role = ParticipantRole.GUEST,
		networkStatus = NetworkStatus.GOOD,
		isRaisingHand = false,
		lastTimestampShareScreen = null,
		durationTalk = 0,
		isLocal = false,
		updateFromRemote = false,
		showJobTitle = true,
		isLeft = false,
	}) {
		this.id = id;
		this.identity = identity;
		this.userName = userName;
		this.familyName = familyName;
		this.jobTitle = jobTitle;
		this.email = email;
		this.picture = picture;
		this.picturePath = picturePath;
		this.role = role;
		// this.type = type;
		this.color = color;
		this.networkStatus = networkStatus;
		this.isRaisingHand = isRaisingHand;
		this.lastTimestampShareScreen = lastTimestampShareScreen;
		this.durationTalk = durationTalk;
		this.isLocal = isLocal;
		this.updateFromRemote = updateFromRemote;
		this.showJobTitle = showJobTitle;
		this.isLeft = isLeft;
	}


	get displayName() {
		if (this.userName) return this.userName;
		if (this.email) return this.email;
		if (this._initial) return this._initial;
		return '';
	}

	get networkStatusIcon() {
		if (this.networkStatus === NetworkStatus.BAD)
			return <NetworkStatusDangerSvg />;
		if (this.networkStatus === NetworkStatus.LOW)
			return <NetworkStatusWarningSvg />;
		return <NetworkStatusStableSvg />;
	}
	isEqualTo(other) {
		const res =
			other &&
			other.identity === this.identity &&
			other.id === this.id &&
			other.userName === this.userName &&
			other.email === this.email;
		return res;
	}
}

export default ParticipantModal;