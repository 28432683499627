import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
	useChat,
	useRoomContext,
    useTracks,
    usePinnedTracks,
    LayoutContextProvider,
    FocusLayoutContainer,
    CarouselLayout,
    RoomAudioRenderer,
    TrackLoop,
    TrackRefContext,
} from '@livekit/components-react';

import { isEqualTrackRef, isTrackReference, isWeb } from '@livekit/components-core';
import { RoomEvent, Track } from 'livekit-client';
import { useDispatch, useSelector } from 'react-redux';
import {
	selectAllParticipants,
    selectLastShareScreenParticipantIdentity,
} from '../../redux/participantsSlice';
import { ScreenLayoutType, getScreenLayout, getScreenTiles, setConfig } from '../../redux/meetSlice';
import { ParticipantTile } from './ParticipantTile';
import reactionsList from '../../constants/reactionsList';
import useMediaQuery from '../../hooks/useMediaQuery';
import { SMALL_SCREEN } from '../../utils/breackpoints';
import getParticipantTileStyle from '../../utils/getDynamicStyle';
import { ChatMessageType } from './ChatPanel';

export const encoder = new TextEncoder();
export const decoder = new TextDecoder();

const MyVideoConference = ({
    context: layoutContext,
    showChat,
    onSendChatMessageChange,
    onSendEmojiChange,
    setMessagesList,
    updatePinnedFromPanel,
    pinnedParticipantIdentity,
    setPinnedParticipantIdentity,
    cameraError
}) => {
    const dispatch = useDispatch();
    
    const roomContext = useRoomContext();
    const screenLayout = useSelector(getScreenLayout);
    const participantsFromRedux = useSelector(selectAllParticipants);
    const screenLayoutTiles = useSelector(getScreenTiles);
    const participantsRef = useRef();
    
    const [gridLayout, setGridLayout] = useState();
    const [gridLayoutWidth, setGridLayoutWidth] = useState(0);
    const [gridLayoutHeight, setGridLayoutHeight] = useState(0);
    const lastShareScreenParticipantIdentity = useSelector(selectLastShareScreenParticipantIdentity);
    const { send, chatMessages } = useChat();
    const lastReadMsgAt = useRef(0);
    const isPhoneScreen = useMediaQuery(SMALL_SCREEN);

    const observer = useMemo(() =>
        new ResizeObserver((entries) => {
            const target = entries[0].target;
            const width = target?.getBoundingClientRect()?.width;
            setGridLayoutWidth(width);
            const height = target?.getBoundingClientRect()?.height;
            setGridLayoutHeight(height);
        }), []);

    const gridLayoutRef = useRef(null);
    const gridLayoutSizeRef = useCallback((node) => {
        if (gridLayoutRef !== null) {
            gridLayoutRef.current = node;
            if (!node) return;
            observer.observe(node);
        } else {
            observer.unobserve(gridLayoutRef.current);
            gridLayoutRef.current = null;
        }
    }, [observer]);

    const tracks = useTracks(
        [
            { source: Track.Source.Camera, withPlaceholder: true },
            { source: Track.Source.ScreenShare, withPlaceholder: false },
        ],
        { updateOnlyOn: [RoomEvent.ActiveSpeakersChanged], onlySubscribed: false },
    );

    const screenShareTracks = tracks
        .filter(isTrackReference)
        .filter((track) => track.publication.source === Track.Source.ScreenShare);

    const focusTrack = usePinnedTracks(layoutContext)?.[0];
    const carouselTracks = tracks.filter((track) => !isEqualTrackRef(track, focusTrack));
    useEffect(() => {
        if (!layoutContext || chatMessages.length === 0) return;

        if (
            showChat &&
            chatMessages.length > 0 &&
            lastReadMsgAt.current !== chatMessages[chatMessages.length - 1]?.timestamp
        ) {
            lastReadMsgAt.current = chatMessages[chatMessages.length - 1]?.timestamp;
            return;
        }

        const unreadMessageCount = chatMessages.filter(
            (msg) => !lastReadMsgAt.current || msg.timestamp > lastReadMsgAt.current,
        ).length;

        const { widget } = layoutContext;
        if (unreadMessageCount > 0 && widget.state?.unreadMessages !== unreadMessageCount) {
            widget.dispatch?.({ msg: 'unread_msg', count: unreadMessageCount });
        }
    }, [chatMessages, layoutContext?.widget]);
    
    //need set send to ref, otherwise send is undefined
    const sendRef = useRef();
    useEffect(() => {
        sendRef.current = send;
    }, [send])
    
    //chat message type : 'chat' / 'reaction'
    const sendChatMessage = (msg, type = ChatMessageType.CHAT) => {
        const message = JSON.stringify({ message: msg, type });
        sendRef.current(message);
    };

    const sendEmoji = (emoji) => {
        const dateTimstamp = +new Date
        const data = {
            type: 'send-emoji',
            payload: {
                identity: roomContext.localParticipant.identity,
                name: roomContext.localParticipant.name,
                emoji,
                timestamp: dateTimstamp
            }
        };
        
        const encodedData = encoder.encode(JSON.stringify(data));
        roomContext.localParticipant.publishData(encodedData, { reliable: true} );
        roomContext.emit('send-emoji-from-myself', {
            identity: roomContext.localParticipant.identity,
            name: roomContext.localParticipant.name,
            emoji,
            timestamp: dateTimstamp
        }, Array.from(roomContext.remoteParticipants.keys()));
        const findEmoji = reactionsList.find(item => item.key === emoji)?.emoji;
        if (findEmoji) {
            sendChatMessage(findEmoji, ChatMessageType.REACTION);
        }
        
    }

    useEffect(() => {
        onSendChatMessageChange(sendChatMessage);
        onSendEmojiChange(sendEmoji);
    }, []);
    
    
    useEffect(() => {
        participantsRef.current = [...participantsFromRedux];
    }, [JSON.stringify(participantsFromRedux)]);


    useEffect(() => {
        setMessagesList(prev => {
            const newMessages = chatMessages.map(chat => {
                const jsonMessage = JSON.parse(chat?.message);
                const type = jsonMessage.type;
                
                let foundParticipant = participantsRef.current.find(
                    (item) => item.identity && item.identity === chat.from.identity
                );
                if (!foundParticipant) {
                    foundParticipant = prev.find(ele => ele?.sender?.identity === chat?.from?.identity)?.sender
                }
                if (foundParticipant) {
                    return { message: jsonMessage.message, timestamp: chat?.timestamp, sender: { ...foundParticipant }, type }
                }
                
            })
            return [...newMessages];
        });
    }, [chatMessages.length]);

    useEffect(() => {
        const pinned = layoutContext?.pin?.state?.[0];
        if (screenLayout === ScreenLayoutType.MOSAIC && pinned) {
            layoutContext.pin.dispatch?.({ msg: 'clear_pin' });
        } else if (screenLayout === ScreenLayoutType.FOCUS && !pinned) {
            let newPinned = screenShareTracks?.find(item => item?.participant?.identity === lastShareScreenParticipantIdentity);
            if (!newPinned) {
                newPinned = tracks?.find(track => track?.participant?.identity === roomContext?.localParticipant?.identity);
            }
            if (newPinned) {
                layoutContext.pin.dispatch?.({ msg: 'set_pin', trackReference: newPinned });
            } 
        }
    }, [screenLayout]);

    useEffect(() => {
        
        const pinned = layoutContext?.pin?.state?.[0];
        if (lastShareScreenParticipantIdentity) {
            if (pinned) {
                const shareTrack = screenShareTracks.find(item => item?.participant?.identity === lastShareScreenParticipantIdentity);
                if (shareTrack) {
                    layoutContext?.pin?.dispatch?.({ msg: 'set_pin', trackReference: shareTrack });
                } 
            }if(screenShareTracks.length === 1){
                layoutContext?.pin?.dispatch?.({ msg: 'set_pin', trackReference: screenShareTracks[0] });
            }
        } 

        if (!screenShareTracks.length && screenLayout === ScreenLayoutType.FOCUS) {
            layoutContext.pin.dispatch?.({ msg: 'clear_pin' });
        }
    }, [lastShareScreenParticipantIdentity, screenShareTracks.length]);

    useEffect(() => {
        const pinned = layoutContext?.pin?.state?.[0];
        const identity = pinned?.participant?.identity;
        if (pinned && screenLayout === ScreenLayoutType.MOSAIC) {
            dispatch(setConfig({ screenLayout: ScreenLayoutType.FOCUS }));
        }
        else if (!pinned && screenLayout === ScreenLayoutType.FOCUS) {
            dispatch(setConfig({ screenLayout: ScreenLayoutType.MOSAIC }));
        }
        setPinnedParticipantIdentity(identity);
    }, [layoutContext?.pin?.state?.[0]?.participant?.identity]);

    //listen updatePinnedFromPanel au lieu de pinnedParticipantIdentity pour eviter les boucles infinites
    useEffect(() => {
        if (pinnedParticipantIdentity) {
            let foundTrack = screenShareTracks?.find(item => item?.participant?.identity === pinnedParticipantIdentity);
            if (!foundTrack) {
                foundTrack = tracks?.find(item => item?.participant?.identity === pinnedParticipantIdentity);
            }
            if (foundTrack) {
                layoutContext.pin.dispatch?.({ msg: 'set_pin', trackReference: foundTrack });
            }
        } else {
            layoutContext.pin.dispatch?.({ msg: 'clear_pin' });
        }
    }, [updatePinnedFromPanel]);

    useEffect(() => {
        const bestStyle = getParticipantTileStyle({
            width: gridLayoutWidth,
            height: gridLayoutHeight,
            screenLayoutTiles,
            lengthTracks: tracks.length,
            isPhoneScreen
        })
        if (!bestStyle) return;
        setGridLayout({ row: bestStyle?.row, height: bestStyle?.height, maxWidth: bestStyle?.width, column: bestStyle?.column, totalWidth: bestStyle?.totalWidth, totalHeight: bestStyle?.totalHeight });
    }, [
        gridLayoutWidth,
        gridLayoutHeight,
        tracks.length,
        screenLayoutTiles
    ]);
    

    const outerStyle = !!focusTrack ? {} : { flexDirection: 'column', justifyContent: 'center', alignItems: 'center' };
    const innerStyle = !!focusTrack ? {} : { maxWidth: gridLayout?.totalWidth, maxHeight: gridLayout?.totalHeight };
   
    return (
        <div className="lk-video-conference" ref={gridLayoutSizeRef}>
            {isWeb() && (
                <LayoutContextProvider
                    value={layoutContext}
                // onPinChange={handleFocusStateChange}
                // onWidgetChange={handleWidgetStateChange}
                >
                    <div style={{display: 'flex', width: '100%', height: '100%', ...outerStyle}} >
                        <div className="lk-video-conference-inner" style={{ flex: 1,...innerStyle  }}>
                            {!!focusTrack ? (
                                <div className="lk-focus-layout-wrapper">
                                    <FocusLayoutContainer>
                                        <CarouselLayout tracks={carouselTracks}>
                                            <ParticipantTile cameraError={cameraError} isInCarousel/>
                                        </CarouselLayout>
                                        {focusTrack && <ParticipantTile trackRef={focusTrack} cameraError={cameraError}/> }
                                    </FocusLayoutContainer>
                                </div>
                            ) : (
                            
                                <div className="grid-layout-wrapper" style={{ '--grid-col-count': gridLayout?.column ?? 1, '--grid-row-count': gridLayout?.row ?? 1 }}>
                                    <TrackLoop tracks={tracks} >
                                        <TrackRefContext.Consumer>
                                            {(trackRef) => trackRef &&
                                                <ParticipantTile styleAdd={gridLayout} trackRef={trackRef} cameraError={cameraError}/>}
                                        </TrackRefContext.Consumer>
                                    </TrackLoop>
                                </div>
                            )}
                        </div>
                    </div>
                </LayoutContextProvider>
            )}
            <RoomAudioRenderer />
        </div>
    );
};

export default MyVideoConference;
