
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useAuthContext, { useGeneralContext } from "../../../hooks/useContext";
import { votePoll } from "../../../helpers/eventApi";
import { selectAllParticipants } from "../../../redux/participantsSlice";
import { getInitialFromString } from "../../../utils/getInitialFromString";

const PollOptionItem = (props)=>{
    const { 
        eventUUID, 
        pollUUID, 
        pollOption,
        pollOptionUUID,
        answeredBy: answersList = [],
        numResponse = 0,
        percentage = 0,
        showResults = false,
        isCreator,
        showSummary = false,
        isVoted = false,
        isClosed = true,
        selectedPollOptionUUID,
        updatePollWithOptionUUID = ()=>{}
    } = props;

    const allParticipants = useSelector(selectAllParticipants);
    const { teamID, userID, translateJSON } = useAuthContext();
    const { currentParticipantUUID } = useGeneralContext();
    const [showAllAvatars, setShowAllAvatars] = useState(false);
    const isVotedCurrent = answersList?.includes(currentParticipantUUID) || selectedPollOptionUUID === pollOptionUUID;

    const allVoted = (answersList ?? [])?.reduce((acc, item) => {
        const participant = allParticipants?.find(participant => participant.identity === item);
        if (participant) {
          acc.push({
            picture: participant.picture,
            userName: participant.userName,
            color: participant.color,
          });
        }
        return acc;
      }, []);
      
    const showResult = (isCreator && showSummary) ||(!isCreator && showResults && isVoted);
    const percentageString =  parseFloat(parseFloat(percentage).toFixed(1)).toString() + '%';
    
    const voteCount = numResponse > 1 ? `${numResponse} ${translateJSON['poll-votes-count-multi']}` : `${numResponse} ${translateJSON['poll-votes-count-one']}`;
    return (
        <div className={`poll-option-item`} 
            onMouseDown={()=>{ 
                if(isVoted) return;
                if(showSummary) return;
                if(isClosed) return;
                votePoll({eventUUID, pollUUID, participantUUID: currentParticipantUUID, pollOption, pollOptionUUID, teamID, userID})
                .then(()=> updatePollWithOptionUUID({pollUUID, pollOptionUUID}))
            }}>
            
            {!showResult && 
                <div className={`poll-option ${isVotedCurrent ? 'is-voted':''} ${(isVoted || isClosed) ? 'has-voted' : 'not-yet-voted'}`}>
                    <p type='poll-option'>{pollOption}</p>
                </div>}
            {showResult && 
                <div className="poll-option-result">
                    <p type='poll-option-result__title'>{pollOption}</p>
                    <div className="poll-option-result__detail">
                        <div className="background-percentage" style={{width:`${percentage}%`, borderRadius: percentage === 100 ? '10px':'10px 0 0 10px'}}></div>
                        <div className="count-detail">
                            <div className="avatar-count" 
                                onMouseEnter={() => setShowAllAvatars(true)}
                                onMouseLeave={() => setShowAllAvatars(false)} >
                                {isCreator && !!allVoted?.length && 
                                    <div className="avatars" 
                                        >
                                        {allVoted?.slice(0, 4)?.map((item, index) => (
                                            <div className="avatar-item" style={{ background: item?.color }} key={index}>
                                                {item?.picture ? 
                                                    <img src={item?.picture} alt={item?.userName} /> : 
                                                    <span type='initial'>{getInitialFromString(item?.userName)}</span>}
                                            </div>
                                        ))}
                                        {allVoted?.length > 4 && 
                                            <div className="avatar-item-left-count" key={4}>
                                                <span type='initial'>{`+${allVoted?.length - 4}`}</span>
                                            </div>}
                                    </div>
                                }
                                <span type='count'>{voteCount} </span>
                                
                            </div>
                            
                            <span type='percentage'>{percentageString}</span>
                        </div>

                        {isCreator && showAllAvatars && !!allVoted?.length && <div className="avatar-list"  
                            onMouseEnter={() => setShowAllAvatars(true)}
                            onMouseLeave={() => setShowAllAvatars(false)}>
                            {allVoted.map((item, index) => (
                                <div className="avatar-item" >
                                    <div className="avatar" style={{ background: item?.color }} key={index}>
                                        {item?.picture ? 
                                            <img src={item?.picture} alt={item?.userName} /> : 
                                            <span type='initial'>{getInitialFromString(item?.userName)}</span>}
                                        
                                    </div>
                                    <span type='username' title={item?.userName}>{item?.userName}</span>
                                </div>))}
                        </div>}
                    </div>
                </div>}   
        </div>
    );
}

export default PollOptionItem;