import React, { useState, useEffect, createContext, useRef } from 'react';
import { usePersistentUserChoices } from '@livekit/components-react';
import { handleCameraError, handleMicroError } from '../utils/errorHandler';
export const AudioVideoContext = createContext();

export const PermissionStatus = {
	GRANTED: 'granted',
	DENIED: 'denied',
	PROMPT: 'prompt',
	INVALID: 'invalid', //ONLY FOR FIREFOX
};


const DEFAULT_USER_CHOICES = {
  videoEnabled: true,
  audioEnabled: true,
  videoDeviceId: '',
  audioDeviceId: '',
};

const AudioVideoContextProvider = ({ children }) => {

    const {
      userChoices: initialUserChoices,
      saveAudioInputDeviceId,
      saveAudioInputEnabled,
      saveVideoInputDeviceId,
      saveVideoInputEnabled,
    } = usePersistentUserChoices({
      defaults: DEFAULT_USER_CHOICES
    });
  
    // Initialize device settings
    const [audioEnabled, setAudioEnabled] = useState(initialUserChoices.audioEnabled);
    const [audioDeviceId, setAudioDeviceId] = useState(initialUserChoices.audioDeviceId);

	const [videoEnabled, setVideoEnabled] = useState(initialUserChoices.videoEnabled);
    const [videoDeviceId, setVideoDeviceId] = useState(initialUserChoices.videoDeviceId);

    const [forceRequestPermission, setForceRequestPermission] = useState(false);
    const [cameraPermission, setCameraPermission] = useState(PermissionStatus.PROMPT);
	const [microPermission, setMicroPermission] = useState(PermissionStatus.PROMPT);

    const permissionProps = {
		microPermission,
		setMicroPermission,
		cameraPermission,
		setCameraPermission,
	}

    // Save user choices to persistent storage.
    useEffect(() => {
      saveAudioInputEnabled(audioEnabled);
    }, [audioEnabled, saveAudioInputEnabled]);
    useEffect(() => {
      saveVideoInputEnabled(videoEnabled);
    }, [videoEnabled, saveVideoInputEnabled]);
    useEffect(() => {
      saveAudioInputDeviceId(audioDeviceId);
    }, [audioDeviceId, saveAudioInputDeviceId]);
    useEffect(() => {
      saveVideoInputDeviceId(videoDeviceId);
    }, [videoDeviceId, saveVideoInputDeviceId]);

	const [outputAudioSelected, setOutputAudioSelected] = useState();

	const [isActiveNoiseCancellation, setActiveNoiseCancellation] =
		useState(true);
	const [isActiveEchoCancellation, setActiveEchoCancellation] =
		useState(true);
	const [cutSound, setCutSound] = useState(false);

	useEffect(() => {
   	 	saveAudioInputEnabled(audioEnabled);
	}, [audioEnabled, saveAudioInputEnabled]);

	useEffect(() => {
		saveVideoInputEnabled(videoEnabled);
	}, [videoEnabled, saveVideoInputEnabled]);

	useEffect(() => {
		saveAudioInputDeviceId(audioDeviceId);
	}, [audioDeviceId, saveAudioInputDeviceId]);

	useEffect(() => {
		saveVideoInputDeviceId(videoDeviceId);
	}, [videoDeviceId, saveVideoInputDeviceId]);
	
	const checkCameraPermission = async () => {
		//navigator.permissions.query({ name: "camera" }) not works on firefox
        let needCallCamera = false;
		if ('permissions' in navigator && 'query' in navigator.permissions) {
            try{
                const cameraStatus = await navigator.permissions.query({ name: 'camera' });
                setCameraPermission(cameraStatus.state);
                cameraStatus.onchange = () => {
                    setCameraPermission(cameraStatus.state);
                };
            }catch(e){
                handleCameraError(e, setCameraPermission)
                needCallCamera = true;
            }
        }

        const hasValidConstraints = needCallCamera && videoEnabled && forceRequestPermission;

        if(hasValidConstraints){
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: needCallCamera && videoEnabled, audio: false });
                stream?.getTracks()?.forEach((track) => track.stop());
                if(needCallCamera){
                    setCameraPermission(PermissionStatus.GRANTED);
                }
            } catch (error) {
                if(needCallCamera){
                    handleCameraError(error, setCameraPermission)
                }
            }
        }
	};

	useEffect(()=>{
		if(videoEnabled && cameraPermission === PermissionStatus.PROMPT){
			checkCameraPermission();
		}
	},[videoEnabled]);

	const checkMicroPermission = async () => {
		//navigator.permissions.query({ name: "camera" }) not works on firefox
        let needCallMicro = false;
		if ('permissions' in navigator && 'query' in navigator.permissions) {
            try{
                const audioStatus = await navigator.permissions.query({ name: 'microphone' });
                setMicroPermission(audioStatus.state);
                audioStatus.onchange = () => {
                    setMicroPermission(audioStatus.state);
                };
            }catch(e){
                handleMicroError(e, setMicroPermission)
                needCallMicro = true;
            }
        }

        const hasValidConstraints = needCallMicro && audioEnabled && forceRequestPermission;
        if(hasValidConstraints){
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: false, audio: needCallMicro && audioEnabled });
                stream?.getTracks()?.forEach((track) => track.stop());
                if(needCallMicro){
                    setMicroPermission(PermissionStatus.GRANTED);
                }
                
            } catch (error) {
                handleMicroError(error, setMicroPermission);
            }
        }
	};

	useEffect(()=>{
		if(audioEnabled && microPermission === PermissionStatus.PROMPT){
			checkMicroPermission();
		}
	},[audioEnabled]);

	useEffect(() => {
		//remove old preferences
		localStorage.removeItem('mute-micro');
		localStorage.removeItem('camera-on');
		localStorage.removeItem('micro_setting');
		localStorage.removeItem('camera_setting');
		localStorage.removeItem('upload-avatar-list');
		localStorage.removeItem('avatar-upload-seletced-index');
	}, []);

	return (
		<AudioVideoContext.Provider
			value={{
				audioEnabled, setAudioEnabled,
				videoEnabled, setVideoEnabled,
				audioDeviceId, setAudioDeviceId,
				videoDeviceId, setVideoDeviceId,
				outputAudioSelected, setOutputAudioSelected,
				isActiveNoiseCancellation, setActiveNoiseCancellation,
				isActiveEchoCancellation, setActiveEchoCancellation,
				cutSound, setCutSound,
                setForceRequestPermission,
				...permissionProps
			}}>
			{children}
		</AudioVideoContext.Provider>
	);
};

export default AudioVideoContextProvider;
