import {
	createSlice,
	createEntityAdapter,
	createSelector,
} from '@reduxjs/toolkit';
import ParticipantModal, {
	ParticipantRole,
} from '../dataModal/ParticipantModal';


const participantsAdapter = createEntityAdapter({
	selectId: (participant) => participant.identity,
	sortComparer: (a, b) => a.displayName - b.displayName,
});

export const participantsSlice = createSlice({
	name: 'participants',
	initialState: participantsAdapter.getInitialState(),
	reducers: {
		participantAdded: participantsAdapter.addOne,
		paticipantsSetAll: participantsAdapter.setAll,
		participantUpdated: participantsAdapter.setOne,
		participantUpdatedMany: participantsAdapter.setMany,
		participantDelete: participantsAdapter.removeOne,
		participantDeleteMany: participantsAdapter.removeMany,
		participantDeleteAll: participantsAdapter.removeAll,
	},
});

export const participantsReducer = participantsSlice.reducer;
export const {
	participantAdded,
	participantUpdated,
	participantDelete,
	participantDeleteMany,
	paticipantsSetAll,
	participantUpdatedMany,
	participantDeleteAll
} = participantsSlice.actions;
export const {
	selectAll: selectAllParticipants,
	selectById,
	selectIds: selectParticipantIds,
} = participantsAdapter.getSelectors((state) => state.participants);

export const selectAllActiveParticipants = createSelector(
	[selectAllParticipants],
	(allParticipants) => 
		allParticipants.filter(participant => !participant.isLeft)
) 

export const selectAllSortedParticipants = createSelector(
	[selectAllActiveParticipants],
	(allParticipants) => {
		const activeParticipants = allParticipants.filter(
			(participant) =>
				participant.isShareScreen ||
				// participant.isSpeaking ||
				participant.isRaisingHand
		);
		const notActiveParticipants = allParticipants.filter(
			(participant) => !activeParticipants.includes(participant)
		);

		const hostParticipant = notActiveParticipants.filter(
			(participant) => participant.role === ParticipantRole.HOST
		);
		const notHostParticipant = notActiveParticipants.filter(
			(participant) => !hostParticipant.includes(participant)
		);
		const list = [
			...activeParticipants,
			...hostParticipant,
			...notHostParticipant,
		];
		return list.map((participant) => new ParticipantModal({ ...participant }));
	}
);

export const selectAllActiveParticipantsLength = createSelector(
	[selectAllActiveParticipants],
	(allParticipants) => allParticipants.length
);


export const selectRemoteParticipants = createSelector(
	[selectAllActiveParticipants],
	(allParticipants) => {
		return allParticipants
			.filter(participant => !participant.isCurrentUser)
			.map(participant => new ParticipantModal({ ...participant }));
	}
);

export const selectLocalParticipant = createSelector(
	[selectAllActiveParticipants],
	(allParticipants) => allParticipants.find(item => item.isLocal)
);

export const selectLastShareScreenParticipantIdentity = createSelector(
	[selectAllActiveParticipants],
	(allParticipants) => {
		const allSharedScreen = allParticipants.filter(participant => !!participant.lastTimestampShareScreen);
		const lastTimestampShareScreenParticipant = allSharedScreen.reduce((max, current) => {
			return current.lastTimestampShareScreen > max.lastTimestampShareScreen ? current : max;
		}, allSharedScreen?.[0]);
		return lastTimestampShareScreenParticipant?.identity
	}
)
export const selectAllHostParticipants = createSelector(
	[selectAllActiveParticipants],
	(allParticipants) =>
		allParticipants.filter(
			(participant) => participant.role === ParticipantRole.HOST
		)
);

export const hasRaiseHandOrSharedScreenParticipants = createSelector(
	[selectAllActiveParticipants],
	(allParticipants) =>
		allParticipants.filter(
			(participant) => participant.isRaisingHand || participant.isShareScreen
		)?.length > 0
);

export const selectAllRaiseHandsParticipants = createSelector(
	[selectAllActiveParticipants],
	(allParticipants) =>
		allParticipants
			.filter((participant) => participant.isRaisingHand)
			.map((item) => new ParticipantModal(item))
);


export const selectLengthUpdatedFromRemoteParticipants = createSelector(
	[selectAllActiveParticipants],
	(allParticipants) => allParticipants.filter((participant) => participant.updateFromRemote).length
); 