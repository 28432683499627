import React, { Children, isValidElement, useState } from "react";

export const TabItem = ({ label, children }) => (
    <div
      className="tab-panel"
      role="tabpanel"
      aria-labelledby={`tab-${label}`}
      id={`panel-${label}`}
    >
      {children}
    </div>
  );

  
const TabList = ({ children, activeTabIndex = 0 }) => {

    const [activeTab, setActiveTab] = useState(activeTabIndex);
    const handleTabClick = (index) => {
        setActiveTab(index);
    };
    const tabs = Children.toArray(children).filter((child) => isValidElement(child) && child.type === TabItem);
    return (
      <div className="tabs">
          <ul className="tab-list" role="tablist" aria-orientation="vertical" >
            {/* aria-orientation="horizontal"> */}
            {tabs.map((tab, index) => (
              <li key={`tab-${index}`}
              role="tab"
              id={`tab-${tab.props.label}`}
              aria-controls={`panel-${tab.props.label}`}
              aria-selected={activeTab === index}
              onMouseDown={() => handleTabClick(index)}
              className={`tab-btn ${ activeTab === index ? "--active" :''}`}>
              <span>{tab.props.label}</span>
              <div className="hr"/>
              </li>
            ))}
          </ul>
        {tabs[activeTab]}
      </div>
    );
  };
  
  export default TabList;