import React, { useEffect, useState } from "react";
import { ReactComponent as CloseSvg } from '../../assets/icons/cross-24.svg';
import { ReactComponent as ContrastSvg } from '../../assets/icons/contrast.svg';
import { ReactComponent as WarningSvg }  from '../../assets/icons/warning.svg';
import BtnRegular from "../BtnRegular";
import ColorButton from "./ColorButton";
import { checkColorIsSolidWithoutAlpha, getContrast } from "../../utils/color";
import useAuthContext from "../../hooks/useContext";


const CustomThemeEditPanel = ({
    themeState,
    primaryColor,
    secondaryColor,
    backgroundPrimaryColor,
    backgroundSecondaryColor,
    shadow,
    backgroundImageUpload,
    setBackgroundImageUpload,
    setThemeState,
    cancelEditCustomPanel,
    saveCustomTheme,

}) => {
    
	const { translateJSON } = useAuthContext();

    const [showContrastPrimary, setShowContrastPrimary] = useState(false);
    const [showContrastSecondary, setShowContrastSecondary] = useState(false);
    
    const [showBackgroundUploadedWarning, setBackgroundUploadedWarning] = useState(false);
    const [showColorPickerType, setShowColorPickerType] = useState('');
    const hasNameRoleBackground = themeState?.name_role_background === 'primary';
    const handleClickOnNameRoleBackground = (newValue) =>
        setThemeState({ ...themeState, name_role_background: newValue });
    
    const onClickEvent = (e) => {
        if (e?.target?.className instanceof SVGAnimatedString) {
            e.stopPropagation();
            return;
        }
        const isClickOnImageColorButton =  e?.target?.className?.indexOf('has-modal') > -1;
        const isInModal = e.target?.parentElement?.className === 'input-span-wrapper' || e.target?.className === 'image-color-popup';
        if (isClickOnImageColorButton || isInModal) {
            e.stopPropagation();
            return;
        } 
    }
    
    useEffect(() => {
        if (themeState?.use_background_image) {
            setShowContrastPrimary(false);
            return;
        }
        if (!primaryColor || !backgroundPrimaryColor) return;
        const check = checkColorIsSolidWithoutAlpha(primaryColor);
        const checkBackground = checkColorIsSolidWithoutAlpha(backgroundPrimaryColor);
        if (!check || !checkBackground) return;
        const contrast = getContrast(primaryColor, backgroundPrimaryColor);
        setShowContrastPrimary(contrast < 4.5);
    }, [primaryColor, backgroundPrimaryColor, themeState?.use_background_image]);

    useEffect(() => {
        if (!secondaryColor || !backgroundSecondaryColor) return;
        const check = checkColorIsSolidWithoutAlpha(primaryColor);
        const checkBackground = checkColorIsSolidWithoutAlpha(backgroundPrimaryColor);
        if (!check || !checkBackground) return;
        const contrast = getContrast(primaryColor, backgroundPrimaryColor);
        setShowContrastSecondary(contrast < 4.5);
    }, [secondaryColor, backgroundSecondaryColor]);
    
    useEffect(() => {
        document.addEventListener('click', onClickEvent);
        return () => {
            document.removeEventListener('click', onClickEvent);
        }
    }, []);

    const contrastWarning =
        <div className='homepage__theme-edit__customize__body__card__contrast-info' title= {translateJSON['custom-theme-contrast'] || "Low contrast"}>
            <ContrastSvg />
            <span className="homepage__theme-edit__customize__body__card__contrast-info__alert">!</span>
        </div>;
    
    
    return (
        <div className='homepage__theme-edit__customize' >
            <div className='homepage__theme-edit__customize__header'>
                <h4>{translateJSON['custom-theme-title'] || 'Custom theme'}</h4>
                <CloseSvg onMouseDown={() => cancelEditCustomPanel()} />
            </div>
            <div className='homepage__theme-edit__customize__body'>
                <div className='homepage__theme-edit__customize__body__card'>
                    <span className="title">{translateJSON['custom-theme-general-style'] || 'General style'}</span>
                    {showContrastPrimary && contrastWarning }
                                    
                    <div className="homepage__theme-edit__customize__body__card__items has-backrgound-image-wrapper">
                        <div className="has-backrgound-image-warning">
                            <div className='homepage__theme-edit__customize__body__card__item'>
                                            
                                <span>{translateJSON['custom-theme-background'] || 'Background'}</span>
                                <ColorButton
                                    color={backgroundPrimaryColor}
                                    backgroundImageUpload={backgroundImageUpload}
                                    setBackgroundImageUpload={setBackgroundImageUpload}
                                    themeState={themeState}
                                    setThemeState={setThemeState}
                                    type={'background_primary_color'}
                                    showColorPickerType={showColorPickerType}
                                    setShowColorPickerType={setShowColorPickerType}
                                    setBackgroundUploadedWarning={setBackgroundUploadedWarning}
                                />
                            </div>

                            <div className='homepage__theme-edit__customize__body__card__item'>
                                            
                                <span>{translateJSON['custom-theme-buttons'] || 'Buttons'}</span>
                                <ColorButton
                                    color={primaryColor}
                                    themeState={themeState}
                                    setThemeState={setThemeState}
                                    leftside={false}
                                    type={'primary_color'}
                                    // needHideControls={true}
                                    showColorPickerType={showColorPickerType}
                                    setShowColorPickerType={setShowColorPickerType}
                                />
                            </div>
                        </div>
                        

                        <div className="background-warning-container">
                            {showBackgroundUploadedWarning && <WarningSvg />}
                            <span type={`${showBackgroundUploadedWarning ? 'background-warning':'background'}`}>{translateJSON['logo-file-formats'] || 'Accepted file format: JPEG, PNG (max. size 1 MB)'}</span>
                        </div>
                    </div>
                                    
                </div>

                <div className='homepage__theme-edit__customize__body__card'>
                    <span className="title">{translateJSON['custom-theme-panel-popups'] || 'Panel and pop-ups'}</span>
                    {showContrastSecondary && contrastWarning}
                    <div className="homepage__theme-edit__customize__body__card__items">
                        <div className='homepage__theme-edit__customize__body__card__item'>
                                        
                            <span>{translateJSON['custom-theme-background'] || 'Background'}</span>
                            <ColorButton
                                color={backgroundSecondaryColor}
                                themeState={themeState}
                                setThemeState={setThemeState}
                                type={'background_secondary_color'}
                                showColorPickerType={showColorPickerType}
                                setShowColorPickerType={setShowColorPickerType}
                            />
                        </div>

                        <div className='homepage__theme-edit__customize__body__card__item'>
                                        
                            <span>{translateJSON['custom-theme-buttons'] || 'Buttons'}</span>
                            <ColorButton
                                color={secondaryColor}
                                themeState={themeState}
                                setThemeState={setThemeState}
                                leftside={false}
                                type={'secondary_color'}
                                showColorPickerType={showColorPickerType}
                                setShowColorPickerType={setShowColorPickerType}
                            />
                        </div>
                    </div>
                </div>

                <div className='homepage__theme-edit__customize__body__card'>
                    <span className="title">{translateJSON['custom-theme-name-role'] || 'Name and role style'}</span>

                    <div className="homepage__theme-edit__customize__body__card__items">

                        <div className={`homepage__theme-edit__customize__body__card__item__outside ${hasNameRoleBackground ? 'is-selected' : ''}`}
                            onMouseDown={() => handleClickOnNameRoleBackground('primary')}>
                            <div className='homepage__theme-edit__customize__body__card__item__inside'>
                                <div className='homepage__theme-edit__customize__body__card__item__inside__content hasBackground'>
                                    <div className="content hasBackground">
                                        <div className="content-name hasBackground">
                                            <span>{translateJSON['custom-theme-name']?.toUpperCase() || 'NAME'}</span>
                                        </div>
                                        <div className="content-role hasBackground">
                                            <span>{translateJSON['custom-theme-role']?.toUpperCase() || 'Role'}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={`homepage__theme-edit__customize__body__card__item__outside ${!hasNameRoleBackground ? 'is-selected' : ''}`}
                            onMouseDown={() => handleClickOnNameRoleBackground('secondary')}>
                            <div className='homepage__theme-edit__customize__body__card__item__inside'>
                                <div className='homepage__theme-edit__customize__body__card__item__inside__content '>
                                    <div className="content hasNoBackground">
                                        <div className="content-left-border" />
                                        <div className="content-name hasNoBackground">
                                            <span>{translateJSON['custom-theme-name'] || 'Name'}</span>
                                        </div>
                                        <div className="content-role hasNoBackground">
                                            <span>{translateJSON['custom-theme-role'] || 'Role'}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className='homepage__theme-edit__customize__body__card'>
                    <span className="title">{translateJSON['custom-theme-shadows'] || 'Shadows'}</span>
                    <div className='homepage__theme-edit__customize__body__card__items'>
                        <div className={`shadow ${shadow === 'no' ? 'selected' : ''}`}
                            onMouseDown={() =>{
                                setThemeState({ ...themeState, shadow: 'no' });
                            } }>
                            <span>{translateJSON['custom-theme-shadows-none'] || 'None'}</span>
                        </div>
                        <div className={`shadow ${shadow === 'light' ? 'selected' : ''}`}
                            onMouseDown={() => {
                                let newShadow = 'light';
                                if (shadow === 'light') {
                                    newShadow = 'no';
                                }
                                setThemeState({ ...themeState, shadow: newShadow });
                            }}>
                            <span>{translateJSON['custom-theme-shadows-light'] || 'Light'}</span>
                        </div>
                        <div className={`shadow ${shadow === 'dark' ? 'selected' : ''}`}
                            onMouseDown={() => {
                                let newShadow = 'dark';
                                if (shadow === 'dark') {
                                    newShadow = 'no';
                                }
                                setThemeState({ ...themeState, shadow: newShadow });
                            }}>
                            <span>{translateJSON['custom-theme-shadows-dark'] || 'Dark'}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className='homepage__theme-edit__customize__footer'>
                <BtnRegular
                    content='Cancel'
                    translate='btn-cancel'
                    id='cancel_settings'
                    event={cancelEditCustomPanel}
                />
                <BtnRegular
                    content='Save'
                    id='save_settings'
                    translate='btn-save'
                    event={saveCustomTheme}
                />
            </div>
        </div>
    );
}

export default CustomThemeEditPanel;