import React, { useEffect, useRef } from 'react';
import useAuthContext, { useAudioVideoContext } from '../../hooks/useContext';

import MicroOffSvg from '../../assets/icons/cut-micro.svg';
import MicroOnSvg from '../../assets/icons/micro-on.svg';
import CameraOffSvg from '../../assets/icons/camera-slashed.svg';
import CameraOnSvg from '../../assets/icons/cam-on.svg';

import { ReactComponent as CautionSvg } from '../../assets/icons/caution.svg';
import { PermissionStatus } from '../../context/AudioVideoContextProvider';

import CrossSvg from '../../assets/icons/cross.svg';
import IconSvg from '../IconSvg';

const MicroCameraControls = ({
    setShowPermissionModal,
    showBubbleMic = false,
    hiddenBubbleMic = ()=>{},
    isInMeetRoom = false,
}) => {
    const { translateJSON } = useAuthContext();
    const {
        audioEnabled,
        setAudioEnabled,
        videoEnabled,
        setVideoEnabled,
        microPermission,
        cameraPermission,
    } = useAudioVideoContext();

    const videoEnabledRef = useRef(videoEnabled);
    const cameraPermissionRef = useRef(cameraPermission);
    const microPermissionRef = useRef(microPermission);
    useEffect(()=>{
        cameraPermissionRef.current = cameraPermission;
    },[cameraPermission]);

    useEffect(()=>{
        microPermissionRef.current = microPermission;
    },[microPermission])

    useEffect(()=>{
        videoEnabledRef.current = videoEnabled;
    },[videoEnabled])
    const showMicroCaution = microPermission !== PermissionStatus.GRANTED;
    const classAddMicro = (showMicroCaution || !audioEnabled) ? 'red' : 'white';
    const microIcon = <IconSvg svg={audioEnabled ? MicroOnSvg : MicroOffSvg} classAdd={classAddMicro} />;
  
    const showCameraCaution = cameraPermission !== PermissionStatus.GRANTED;
    const classAddCamera = (showCameraCaution || !videoEnabled) ? 'red' : 'white';
    const cameraIcon = <IconSvg svg={videoEnabled ? CameraOnSvg : CameraOffSvg} classAdd={classAddCamera} />;


    const handleClickOnCamera = () => {
        setVideoEnabled(prev=>{
            const newValue = !prev;
            if(newValue && cameraPermission !== PermissionStatus.GRANTED){
                setShowPermissionModal({ show: true, from: 'camera' });
            }
            return newValue;
        });
    };

    const handleClickOnMicro = () => {
        setAudioEnabled(prev=>{
            const newValue = !prev;
            if(newValue && microPermission !== PermissionStatus.GRANTED){
                setShowPermissionModal({ show: true, from: 'micro' });
            }
            return newValue;
        });
    };

    const divStyle = { position: 'relative', display: 'flex', justifyContent: 'center', gap: '10px' };
    const cameraButtonTitle = (cameraPermission === PermissionStatus.PROMPT || cameraPermission === PermissionStatus.DENIED) ? translateJSON['allowcam-tooltip']:
    cameraPermission === PermissionStatus.INVALID  ? translateJSON['cam-failed-title'] : videoEnabled ? translateJSON['turnoff-cam-tooltip'] : translateJSON['turnon-cam-tooltip'];
    return (
        <div style={divStyle}>
            <button
                title={`${showMicroCaution ? translateJSON['allowmic-tooltip'] : audioEnabled ? translateJSON['mute-tooltip'] : translateJSON['unmute-tooltip']}`}
                className={`btn-square ${showMicroCaution || !audioEnabled ? 'red' : ''}`}
                onClick={handleClickOnMicro}>
                {microIcon}
                {showMicroCaution && <CautionSvg className='caution-in-button' />}
                
            </button>

            {isInMeetRoom && showBubbleMic &&
                <div className='bubble-mic'>
                    <div className='bubble-mic__body'>
                        <div className='bubble-mic__title'>
                            <span>{translateJSON?.['onmute-popup-title']}</span>
                            <IconSvg svg={CrossSvg} onMouseDown={hiddenBubbleMic} />
                        </div>
                        <div className='bubble-mic__body'>
                            <span>{translateJSON?.['onmute-popup-desc']}</span>
                        </div>
                    </div>
                    
                    <div className='bubble-mic__triangle'>
                        <div></div>
                    </div>
                </div>
            }
            
            <button
                title={cameraButtonTitle}
                className={`btn-square ${showCameraCaution || !videoEnabledRef.current ? 'red' : ''}`}
                onClick={handleClickOnCamera}>
                {cameraIcon}
                {showCameraCaution && <CautionSvg className='caution-in-button' />}
            </button>
        </div>
    );
};
export default MicroCameraControls;