import React from "react";

import VideoQualityRange from "./VideoQualityRange";
import useAuthContext from "../../../hooks/useContext";

const PerformanceConfig = () => {

    const { translateJSON } = useAuthContext();  

    return(
        <div className='tab-content__block --perf'>
            <div className="--perf__title">
                <span>{translateJSON['performance-desc']}</span>
            </div>
            <div className='progress-bar'>
                <div className='progress-bar__legend'>
                    <div className='progress-bar__legend__left'>
                        <span>{translateJSON["performance-best"]}</span>
                        <span>{translateJSON["performance-best-desc"]}</span>
                    </div>
                    <div className='progress-bar__legend__right'>
                        <span>{translateJSON["performance-quality"]}</span>
                        <span>{translateJSON["performance-quality-desc"]}</span>
                    </div>
                </div>
                <div className="progress-bar__bar">
                    <VideoQualityRange />
                </div>
            </div>
        </div>
    );

}


export default PerformanceConfig;