import React, { useRef, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { participantUpdated } from '../../redux/participantsSlice';
import ParticipantModal from '../../dataModal/ParticipantModal';

import formatterTimestampWithAMPM from '../../utils/formatterTimestampWithAMPM';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import useAuthContext, { useGeneralContext } from '../../hooks/useContext';
import { getInitialFromString } from '../../utils/getInitialFromString';
import { fetchAvatarFromCaches } from '../../helpers/auth';

export const urlRegEx = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:\-%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gm;
const MessageItem = (props) => {
    const {
        message,
        sender: senderFromProps,
        timestamp,
        isSameWithPrevious,
        isSameWithNext
    } = props;

    
    const { localParticipantIdentifier } = useGeneralContext();
    const { teamID, userID } = useAuthContext();

    const dispatch = useDispatch();
    const [isHover, setIsHover] = useState(false);
    const time = new Date(timestamp);
    const sender = new ParticipantModal({ ...senderFromProps });
    const avatarRef = useRef();

    const isLoggedIn = !!teamID && !!userID;
    const fetchImage = async () => {
        if (!isLoggedIn) return;

        if (sender?.picture?.startsWith('data:image')) return;
        const blob = await fetchAvatarFromCaches(sender?.picturePath);
        if (blob) {
            if (avatarRef.current) {
                avatarRef.current.src = blob;
            }
            dispatch(participantUpdated({ ...sender, picture: blob }));
        }
    };
    

    const isRightSide = sender.identity === localParticipantIdentifier ? 'is-right-side' : 'is-left-side';

    const match = message ? [...message.matchAll(urlRegEx)] : [];
    let hasLink = false;
    if (match.length > 0) {
        hasLink = true;
    }
    
    let lastEndOfLink = 0;
    const messageLength = message?.length ?? 0;
    const messageValue = hasLink ? (
        <>
            {match.map((item, index) => {
                const previousIndex = lastEndOfLink;
                lastEndOfLink = item[0].length + item.index;
                const needAdditional = item[0]?.startsWith('http') ? false : true;
                return (<>
                    <span key={`span-${index}`}>{message.slice(previousIndex, item.index)}</span>
                    <a key={`a-${index}`} href={`${needAdditional ? '//' :''}${item[0]}`} target='_blank' >{item[0]}</a>
                </>)
    
            })}
            <span key={`span-${messageLength}`}>{message.slice(lastEndOfLink, messageLength - 1)}</span>
        </>
    ) :
        <span>{message}</span>;
    

    
    const capitalizeFirstLetter = (inputString)=> {
        if (!inputString) {
            return inputString;
        }
        return inputString.charAt(0).toUpperCase() + inputString.slice(1);
    }
    
    const getSenderDisplayName = (inputString) => {
        const spaceIndex = inputString.indexOf(' ');
        if (spaceIndex === -1) {
            return inputString;
        }
        const beforeFirstSpace = capitalizeFirstLetter(inputString.substring(0, spaceIndex));
        const afterFristSpace = inputString.substring(spaceIndex).trim();
        return beforeFirstSpace + ' ' + afterFristSpace.charAt(0).toUpperCase() + '.';
    };

    const getDisplayName = (sender) => {
        
		if (sender.userName) return sender.userName;
		if (sender.familyName && sender.givenName)
			return sender.familyName + ' ' + sender.givenName;
		else if (sender.familyName) return sender.familyName;
		else if (sender.givenName) return sender.givenName;
		if (sender.email) return sender.email;
		if (sender._initial) return sender._initial;
		return 'Member';
    }
    
    
    const displayName = getDisplayName(sender);
    let abstraitDisplayName = getSenderDisplayName(displayName);
    if (sender && sender?.identity && sender.identity === localParticipantIdentifier) {
        abstraitDisplayName = 'You';
    }
    const initial = getInitialFromString(sender.userName);
    const className = `meetroom-message-item ${isRightSide} ${!isSameWithPrevious ? 'not-same-with-previous' : ''} ${!isSameWithNext ? 'not-same-with-next' : ''}`;

    return (
        <div className={className}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}>
            <div className={'meetroom-message-item__box'}>
                {!isSameWithPrevious &&
                    <div className='meetroom-message-item__box__author'>
                        <div className='meetroom-message-item__box__author__avatar'>
                           
                            {(isLoggedIn && sender?.picture)
                                ? <LazyLoadImage beforeLoad={() => fetchImage()} src={sender.picture} ref={avatarRef}  alt='' />
                                : <span className='macaroon' style={{ backgroundColor: sender.color }}>{initial}</span>
                            }
                        </div>
                        <span>{abstraitDisplayName}</span>
                    </div>
                }
                <div className='meetroom-message-item__box__content'>
                    {messageValue}
                </div>
            </div>

            {isHover && <div className='meetroom-message-item__info'>
                <div className='meetroom-message-item__info__sending-time'>
                    <span className='time'>{formatterTimestampWithAMPM(time)}</span>
                </div>
            </div>}
        </div>
    );
};

export default MessageItem;
