import { createSlice } from '@reduxjs/toolkit';

export const ScreenLayoutType = {
	AUTO: "auto",
	MOSAIC: "mosaic",
	SPOTLIGHT: "spotlight",
	FOCUS: "focus",
}

export const meetSlice = createSlice({
	name: 'meet',
	initialState: {
		name: '',
		config: {
			enableKeyboardShortcuts: true,
			disableChatPopups: false,
			screenLayout: ScreenLayoutType.MOSAIC,
			screenTiles: 49, // same as google meet
		},
	},
	reducers: {
		setConfig: (state, action) => {
			return {
				...state,
				config: {
					...state.config,
					...action.payload 
				}
			};
		},
		setScreenLayout:  (state, action) => {
			return {
				...state,
				config: {
					...state.config,
					screenLayout : action.payload 
				}
			};
		},
		setScreenTiles:  (state, action) => {
			return {
				...state,
				config: {
					...state.config,
					screenTiles : action.payload 
				}
			};
		},
		toggleKeyboardShortcuts:  (state, action) => {
			return {
				...state,
				config: {
					...state.config,
					enableKeyboardShortcuts : action.payload 
				}
			};
		},
		toggleChatPopups:  (state, action) => {
			return {
				...state,
				config: {
					...state.config,
					disableChatPopups : action.payload 
				}
			};
		},
	},
});
export const meetReducer = meetSlice.reducer;
export const {
	setScreenLayout,
	setScreenTiles,
	setConfig,
	toggleKeyboardShortcuts,
	toggleChatPopups
} = meetSlice.actions;

export const getScreenLayout = (state) => state.meet.config.screenLayout ?? ScreenLayoutType.MOSAIC;
export const getScreenTiles = (state) => state.meet.config.screenTiles ?? 6;
export const getEnableKeyboardShortcuts = (state) => state.meet.config.enableKeyboardShortcuts ?? true;
export const getDisableChatPopups = (state) => state.meet.config.disableChatPopups ?? false;
