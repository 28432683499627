import React, { useEffect, useRef, useState, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ReadyState } from 'react-use-websocket';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Room, createLocalAudioTrack } from 'livekit-client';
import { useCreateLayoutContext } from '@livekit/components-react';
import '@livekit/components-styles';
import EndingPage from './EndingPage';
import IconSvg from '../components/IconSvg';
import BgPanel from '../components/BgPanel';
import ShareModal from '../components/ShareModal';
import InfoBox from '../components/MeetRoom/InfoBox';
import InfoModal from '../components/MeetRoom/InfoModal';
import RequestModal from '../components/MeetRoom/RequestModal';
import { urlRegEx } from '../components/MeetRoom/MessageItem';
import ErrorLostConnectionPage from './ErrorLostConnectionPage';
import { MeetEnterMode } from '../components/MeetRoom/Settings/AdminConfig';
import EmojiAnimation from '../components/MeetRoom/EmojiAnimation';
import BottomPanel from '../components/MeetRoom/BottomPanel';
import ReactionPanel from '../components/MeetRoom/ReactionPanel';
import ReadyRecordModal from '../components/MeetRoom/ReadyRecordModal';
import StopRecordModal from '../components/MeetRoom/StopRecordModal';
import PermissionModal from '../components/SharedComponents/PermissionModal';
import MyLivekitRoom from '../components/MeetRoom/MyLivekitRoom';
import {
	selectAllRaiseHandsParticipants,
	participantDeleteAll,
	selectAllParticipants,
} from '../redux/participantsSlice';

import {
	getDisableChatPopups,
	getEnableKeyboardShortcuts,
	getScreenLayout,
} from '../redux/meetSlice';

import { NetworkStatus } from '../dataModal/ParticipantModal';

import useInterval from '../hooks/useInterval';
import useMediaQuery from '../hooks/useMediaQuery';
import useAuthContext, { useAudioVideoContext, useGeneralContext } from '../hooks/useContext';

import { getSolidOrLinearGradientWithOpacity, isLightBestSolidOrLinearGradient } from '../utils/color';
import { LARGE_SCREEN, MEDIUM_LARGE_SCREEN, ORIENTATION_LANDSCAPE, ORIENTATION_PORTRAIT, SMALL_SCREEN } from '../utils/breackpoints';
import reactionsList from '../constants/reactionsList';
import { PermissionStatus } from '../context/AudioVideoContextProvider';
import { 
	dark,
	white, 
	lightWhite, 
	DefaultThemeColor, 
	ThemeTemplateType, 
	semiTransparentDarkGray, 
	superExtraSemiTransparentDarkGray, 
	superLightWhite, 
	veryLightWhite,
	veryExtraSemiTransparentDarkGray, 
} from "../constants/colors";

import CloseIcon from '../assets/icons/cross.svg';
import ChatSvg from '../assets/icons/chat-icon.svg';
import RaiseHandSvg from '../assets/icons/raise-hand-24.svg';
import { ReactComponent as NetworkStatusDangerSvg } from '../assets/icons/network-status-danger.svg';
import { ReactComponent as NetworkStatusWarningSvg } from '../assets/icons/network-status-warning.svg';

import { allowOrDenyRequest, allowOrDenyRequests, closePoll, createPoll, getEventData, postEventToken } from '../helpers/eventApi';
import { generateRandomColor } from '../utils/generateRandom';
import PostPollConfirmationModal from '../components/MeetRoom/Polls/PostPollConfirmationModal';
import { ChatMessageType } from '../components/MeetRoom/ChatPanel';
import { createToolTypeMapping, IntegrationType } from '../components/MeetRoom/IntegrationsPanel';
import PollActionModal from '../components/MeetRoom/Polls/PollActionModal';
import PollItem from '../components/MeetRoom/Polls/PollItem';
import QuestionActionModal from '../components/MeetRoom/QA/QuestionActionModal';
import QAItem from '../components/MeetRoom/QA/QAItem';

export const ChatIconType = {
	CHAT: 'chat',
	POLL: 'poll',
	QA: 'Q&A'
}

export const SidePanelType = {
	CHAT: 'chat',
	PARTICIPANT: 'participant',
	SCREENLAYOUT: 'screen-layout',
	VISUALEFFECTS: 'visual-effects',
	SETTINGS: 'settings',
	INTEGRATION: 'integration'
};

export const SCREENDISPLAYMODE = {
	MOSAIC: 'mosaic',
	FULLSCREEN: 'full-screen',
	SPOTLIGHT: 'spot-light',
	FOCUS: 'focus',
};

export function getObjectSizeInBytes(obj) {
  const jsonString = JSON.stringify(obj);
  const encoder = new TextEncoder();
  const bytes = encoder.encode(jsonString);
  return bytes.length;
}

export const RequestWaitingTimeout = 45 * 1000;
export const PopupShowDuration = 3000;

const MeetRoom = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const { eventUUID: eventUUIDFromURL } = useParams();
	const {
		token : tokenFromPreview = null,
		isHost = false,
		isFromPreview = false,
	} = location?.state ?? {};
	
	const [lkToken, setLKToken] = useState(tokenFromPreview);
	const {
		networkStatus,
		lostConnection,
		setShareScreen,
		setLastTimestampShareScreen,
		setLocalParticipantIdentifier,
		setCurrentParticipantColor,
		setRaisingHand,
		lastWebSocketMessage,
		currentParticipantUUID,
		currentEventUUID,
		setCurrentParticipantUUID,
		readyState,
		isReconnectAttemptFinished
	} = useGeneralContext();

	const layoutContext = useCreateLayoutContext();
	const { userID, teamID, userName, translateJSON, userLang } = useAuthContext();
	const {
		audioEnabled,
		setAudioEnabled,
		videoEnabled,
		setMicroPermission,
		setForceRequestPermission
		} = useAudioVideoContext();

	const room = useMemo(() => new Room(), [lkToken, currentEventUUID]);
	
	const [logoProps, setLogoProps] = useState();
	const [appearance, setAppearance] = useState();
	const [poll, setPoll] = useState();
	const [pollAction, setPollAction] = useState();
	const [QA, setQA] = useState();
	const [questionAction, setQuestionAction] = useState();
	const [whiteboard, setWhiteboard] = useState();
	const [audioTrack, setAudioTrack] = useState();
	const audioTrackRef = useRef(audioTrack);
	const [liveSettings, setLiveSettings] = useState();
	const liveSettingsRef = useRef(liveSettings);

	const isHostRef = useRef(isHost);
	const audioEnabledRef = useRef(audioEnabled);
	const videoEnabledRef = useRef(videoEnabled);

	const root = document.querySelector(':root');
	
	const setCssVariables = (primary_color, background_primary_color, secondary_color, background_secondary_color) => {

		root.style.setProperty('--meetroom-primary-color', primary_color);
		const isLightBestPrimaryInBackgroundPrimary = isLightBestSolidOrLinearGradient(primary_color, background_primary_color);
		const contrastPrimaryInBackgroundPrimary = isLightBestPrimaryInBackgroundPrimary ? white : dark;
		root.style.setProperty('--meetroom-contrast-primary-color-in-background-primary-color', contrastPrimaryInBackgroundPrimary);
		const contrastPrimaryInBackgroundPrimary5 = isLightBestPrimaryInBackgroundPrimary ? superLightWhite : superExtraSemiTransparentDarkGray;
		root.style.setProperty('--meetroom-contrast-primary-color-in-background-primary-color-5', contrastPrimaryInBackgroundPrimary5);
		const contrastPrimaryInBackgroundPrimary50 = isLightBestPrimaryInBackgroundPrimary ? lightWhite : semiTransparentDarkGray;
		root.style.setProperty('--meetroom-contrast-primary-color-in-background-primary-color-50', contrastPrimaryInBackgroundPrimary50);
		
		const isLightBestPrimaryInBackgroundSecondary = isLightBestSolidOrLinearGradient(primary_color, background_secondary_color);
		const contrastPrimaryInBackgroundSecondary = isLightBestPrimaryInBackgroundSecondary ? white : dark;
		root.style.setProperty('--meetroom-contrast-primary-color-in-background-secondary-color', contrastPrimaryInBackgroundSecondary);
		const contrastPrimaryInBackgroundSecondary5 = isLightBestPrimaryInBackgroundSecondary ? superLightWhite : superExtraSemiTransparentDarkGray;
		root.style.setProperty('--meetroom-contrast-primary-color-in-background-secondary-color-5', contrastPrimaryInBackgroundSecondary5);
		const contrastPrimaryInBackgroundSecondary50 = isLightBestPrimaryInBackgroundSecondary ? lightWhite : semiTransparentDarkGray;
		root.style.setProperty('--meetroom-contrast-primary-color-in-background-secondary-color-50', contrastPrimaryInBackgroundSecondary50);

		//background_primary_color
		root.style.setProperty('--meetroom-background-primary-color', background_primary_color);
		const isLightBestBackgroundPrimary = isLightBestSolidOrLinearGradient(background_primary_color);
		
		const contrastBackgroundPrimaryColor = isLightBestBackgroundPrimary ? white : dark;
		root.style.setProperty('--meetroom-contrast-background-primary-color', contrastBackgroundPrimaryColor);
		const contrastBackgroundPrimaryColor50 = isLightBestBackgroundPrimary ? lightWhite : semiTransparentDarkGray;
		root.style.setProperty('--meetroom-contrast-background-primary-color-50', contrastBackgroundPrimaryColor50);

		//secondary_color
		root.style.setProperty('--meetroom-secondary-color', secondary_color);
		const isLightBestSecondaryInBackgroundPrimary = isLightBestSolidOrLinearGradient(secondary_color, background_primary_color);
		const contrastSecondaryInBackgroundPrimary = isLightBestSecondaryInBackgroundPrimary ? white : dark;
		root.style.setProperty('--meetroom-contrast-secondary-color-in-background-primary-color', contrastSecondaryInBackgroundPrimary);
		const contrastSecondaryInBackgroundPrimary5 = isLightBestPrimaryInBackgroundPrimary ? superLightWhite : superExtraSemiTransparentDarkGray;
		root.style.setProperty('--meetroom-contrast-secondary-color-in-background-primary-color-5', contrastSecondaryInBackgroundPrimary5);
		const contrastSecondaryInBackgroundPrimary50 = isLightBestPrimaryInBackgroundPrimary ? lightWhite : semiTransparentDarkGray;
		root.style.setProperty('--meetroom-contrast-secondary-color-in-background-primary-color-50', contrastSecondaryInBackgroundPrimary50);
		
		const isLightBestSecondaryInBackgroundSecondary = isLightBestSolidOrLinearGradient(secondary_color, background_secondary_color);
		const contrastSecondaryInBackgroundSecondary = isLightBestSecondaryInBackgroundSecondary ? white : dark;
		root.style.setProperty('--meetroom-contrast-secondary-color-in-background-secondary-color', contrastSecondaryInBackgroundSecondary);
		const contrastSecondaryInBackgroundSecondary5 = isLightBestSecondaryInBackgroundSecondary ? superLightWhite : superExtraSemiTransparentDarkGray;
		root.style.setProperty('--meetroom-contrast-secondary-color-in-background-secondary-color-5', contrastSecondaryInBackgroundSecondary5);
		const contrastSecondaryInBackgroundSecondary50 = isLightBestSecondaryInBackgroundSecondary ? lightWhite : semiTransparentDarkGray;
		root.style.setProperty('--meetroom-contrast-secondary-color-in-background-secondary-color-50', contrastSecondaryInBackgroundSecondary50);
		
		//background_secondary_color
		root.style.setProperty('--meetroom-background-secondary-color', background_secondary_color);
		const isLightBestBackgroundSecondary = isLightBestSolidOrLinearGradient(background_secondary_color);
		const contrastBackgroundSecondaryColor = isLightBestBackgroundSecondary ? white : dark;
		root.style.setProperty('--meetroom-contrast-background-secondary-color', contrastBackgroundSecondaryColor);
		const contrastBackgroundSecondaryColor5 = isLightBestBackgroundSecondary ? superLightWhite : superExtraSemiTransparentDarkGray;
		root.style.setProperty('--meetroom-contrast-background-secondary-color-5', contrastBackgroundSecondaryColor5);
		const contrastBackgroundSecondaryColor15 = isLightBestBackgroundSecondary ? veryLightWhite : veryExtraSemiTransparentDarkGray;
		root.style.setProperty('--meetroom-contrast-background-secondary-color-15', contrastBackgroundSecondaryColor15);
		const contrastBackgroundSecondaryColor50 = isLightBestBackgroundSecondary ? lightWhite : semiTransparentDarkGray;
		root.style.setProperty('--meetroom-contrast-background-secondary-color-50', contrastBackgroundSecondaryColor50);


		const secondaryColor25 = getSolidOrLinearGradientWithOpacity(secondary_color, 0.25);
		root.style.setProperty('--meetroom-secondary-color-25', secondaryColor25);
		const secondaryColor50 = getSolidOrLinearGradientWithOpacity(secondary_color, 0.5);
		root.style.setProperty('--meetroom-secondary-color-50', secondaryColor50);
		const isLightBestSecondary25 = isLightBestSolidOrLinearGradient(secondaryColor25);
		const constratSecondary25 = isLightBestSecondary25 ? white : dark;
		root.style.setProperty('--meetroom-contrast-secondary-color25', constratSecondary25);

		const isLightBestSecondary25BackgroundPrimary = isLightBestSolidOrLinearGradient(secondaryColor25, background_primary_color);
		const constratSecondary25BackgroundPrimary = isLightBestSecondary25BackgroundPrimary ? white : dark;
		root.style.setProperty('--meetroom-contrast-secondary-color25-in-background-primary-color', constratSecondary25BackgroundPrimary);
		
		const isLightBestSecondary25InBackgroundSecondary = isLightBestSolidOrLinearGradient(secondaryColor25, background_secondary_color);
		const contrastSecondary25InBackgroundSecondary = isLightBestSecondary25InBackgroundSecondary ? white : dark;
		root.style.setProperty('--meetroom-contrast-secondary-color25-in-background-secondary-color', contrastSecondary25InBackgroundSecondary);
		const contrastSecondary25InBackgroundSecondary50 = isLightBestSecondary25InBackgroundSecondary ? lightWhite : semiTransparentDarkGray;
		root.style.setProperty('--meetroom-contrast-secondary-color25-in-background-secondary-color-50', contrastSecondary25InBackgroundSecondary50);

		const isLightBestBackgroundSecondaryInBackgroundPrimary = isLightBestSolidOrLinearGradient(background_secondary_color, background_primary_color);
		const contrastBackgroundSecondaryInBackgroundPrimary = isLightBestBackgroundSecondaryInBackgroundPrimary ? superLightWhite : superExtraSemiTransparentDarkGray;
		root.style.setProperty('--meetroom-contrast-background-secondary-color-in-background-primary-color', contrastBackgroundSecondaryInBackgroundPrimary);
	}
	const clearCssVariables = () => {
		
		root.style.setProperty('--meetroom-box-shadow', '');
		root.style.setProperty('--meetroom-name-role-style', '');
		root.style.setProperty('--meetroom-primary-color', '');

		root.style.setProperty('--meetroom-secondary-color', '');
        root.style.setProperty('--meetroom-secondary-color-25', '');
		root.style.setProperty('--meetroom-secondary-color-50', '');

		root.style.setProperty('--meetroom-background-primary-color', '');
		root.style.setProperty('--meetroom-contrast-background-primary-color', '');
		root.style.setProperty('--meetroom-contrast-background-primary-color-50', '');


		root.style.setProperty('--meetroom-background-secondary-color', '');
		root.style.setProperty('--meetroom-contrast-background-secondary-color', '');
		root.style.setProperty('--meetroom-contrast-background-secondary-color-5', '');
		root.style.setProperty('--meetroom-contrast-background-secondary-color-15', '');
		root.style.setProperty('--meetroom-contrast-background-secondary-color-50', '');
		

		root.style.setProperty('--meetroom-contrast-primary-color-in-background-primary-color', '');
		root.style.setProperty('--meetroom-contrast-primary-color-in-background-primary-color-5', '');
		root.style.setProperty('--meetroom-contrast-primary-color-in-background-primary-color-50', '');

		root.style.setProperty('--meetroom-contrast-primary-color-in-background-secondary-color', '');
		root.style.setProperty('--meetroom-contrast-primary-color-in-background-secondary-color-5', '');
		root.style.setProperty('--meetroom-contrast-primary-color-in-background-secondary-color-50', '');
		
		root.style.setProperty('--meetroom-contrast-secondary-color-in-background-primary-color', '');
		root.style.setProperty('--meetroom-contrast-secondary-color-in-background-primary-color-5', '');
		root.style.setProperty('--meetroom-contrast-secondary-color-in-background-primary-color-50', '');

		root.style.setProperty('--meetroom-contrast-secondary-color-in-background-secondary-color', '');
		root.style.setProperty('--meetroom-contrast-secondary-color-in-background-secondary-color-5', '');
		root.style.setProperty('--meetroom-contrast-secondary-color-in-background-secondary-color-50', '');
		
		root.style.setProperty('--meetroom-contrast-secondary-color25', '');
		root.style.setProperty('--meetroom-contrast-secondary-color25-in-background-primary-color', '');
		root.style.setProperty('--meetroom-contrast-secondary-color25-in-background-secondary-color', '');
		root.style.setProperty('--meetroom-contrast-secondary-color25-in-background-secondary-color-50', '');

		root.style.setProperty('--meetroom-contrast-background-secondary-color-in-background-primary-color', '');
		
	}

	const isPhoneScreen = useMediaQuery(SMALL_SCREEN);
	const isMediumScreen = useMediaQuery(MEDIUM_LARGE_SCREEN);
	const isLargeScreen = useMediaQuery(LARGE_SCREEN);

	const isPortraitScreen = useMediaQuery(ORIENTATION_PORTRAIT);
	const isLandscapeScreen = useMediaQuery(ORIENTATION_LANDSCAPE);
	const totalRaiseHandParticipants = useSelector(
		selectAllRaiseHandsParticipants
	);
	const allParticipants = useSelector(selectAllParticipants);

	const enableKeyboardShortcuts = useSelector(getEnableKeyboardShortcuts);
	const enableKeyboardShortcutsRef = useRef(enableKeyboardShortcuts)
	const disableChatPopups = useSelector(getDisableChatPopups);
	
	const [orientation, setOrientation] = useState('');
	const [showMoreOptionsOnMobile, setShowMoreOptionsOnMobile] = useState(false);
	const [isShareModalOpen, setIsShareModalOpen] = useState(false);
	const [shareModalText, setShareModalText] = useState();

	const [showClipboard, setShowClipboard] = useState(false);
	const [messagesList, setMessagesList] = useState([]);
	const [pollList, setPollList] = useState([]);
	const [pollDeleted, setPollDeleted] = useState();
	const [questionList, setQuestionList] = useState([]);
	const [questionDeleted, setQuestionDeleted] = useState();

	const [questionToAnswer, setQuestionToAnswer] = useState();
	const [sidePanelType, setSidePanelType] = useState(null);
	const [iconSelectInChatSidePanel, setIconSelectInChatSidePanel] = useState(ChatIconType.CHAT);
	const [showPollConfirmation, setShowPollConfirmartion] = useState(false);
	const [optionBoxParticipantInScreen, setOptionBoxParticipantInScreen] =
		useState(null);
	const [optionBoxIdentityInPanel, setOptionBoxIdentityInPanel] = useState();
	const [showReactionPanel, setShowReactionPanel] = useState(false);

	const [showPermissionModal, setShowPermissionModal] = useState({
		show: false,
	});
	const [meetDuration, setMeetDuration] = useState(-1);
	const [isLowConnection, setLowConnection] = useState(false);
	const [showBottomPanel, setShowBottomPanel] = useState(true);
	const [isEndingMeet, setEndingMeet] = useState(false);
	const [showReadyRecordModal, setReadyRecordModal] = useState(false);
	const [showStopRecordModal, setStopRecordModal] = useState(false);
	const [isRecordingStart, setRecordingStart] = useState(false);

	const [showChatPopup, setShowChatPopup] = useState(false);
	const [optionInfoModal, setOptionInfoModal] = useState();

	const needRejoinRef = useRef(false);
	const [showNotif, setShowNotif] = useState(false);
	
	const [emojisReceived, setEmojisReceived] = useState([]);
	const timerRef = useRef();
	
	const [lastMessage, setLastMessage] = useState();
	const handleClickOnCloseNotif = () => setShowChatPopup(false);

	const [showRequestPopup, setShowRequestPopup] = useState(false);
	const defaultOptions = [
		{ id: 1, value: '' },
		{ id: 2, value: '' }
	];
	
	const [pollToCreated, setPollToCreated] = useState({
		question: '',
		options: defaultOptions,
		showResults: true,
		addTimer: false
	});


	const [requestList, setRequestList] = useState([]);
	const requestListRef = useRef(requestList);
	const [showBubbleMic, setShowBubbleMic] = useState(false);
	const hasVoiceInputRef = useRef(false);
	const requestId = useRef();
	
	const [enterMode, setEnterMode] = useState(); 
	const [hasInputFocus, setInputFocus] = useState(false);
	const hasInputFocusRef = useRef(hasInputFocus);
	const spaceKeyPressedRef = useRef(false);
	const sendEmojiRef = useRef();
	const onSendEmojiChange = (method) => {
		sendEmojiRef.current = method;
	}

	const rejoinEventInEndingPage = async () => {
		needRejoinRef.current = true;
		const newUUID =uuidv4() ;

		setCurrentParticipantUUID(newUUID);
		setEndingMeet(false);
	}
		
	const onAccept = async (list) => {
		allowOrDenyRequests({ isAllow: true, list, eventUUID: currentEventUUID, teamID, userID })
		.finally(() => setRequestList(prev => prev.filter(item => !list.some(ele => ele.participantUUID === item.participantUUID))))
	};

	const onDenied = (list) => {
		allowOrDenyRequests({ isAllow: false, list, eventUUID: currentEventUUID, teamID, userID })
		.finally(() => setRequestList(prev => prev.filter(item => !list.some(ele => ele.participantUUID === item.participantUUID))))
	};

	const requestPopupProps = {
		requestList,
		onDenied,
		onAccept,
		closeModal: ()=>setShowRequestPopup(false)
	}

	const readyToPostPoll = ()=>{
		createPoll({poll: pollToCreated, eventUUID: currentEventUUID, teamID, userID})
		.then(res=>{
			if(res){
				setPollToCreated(null);
				setShowPollConfirmartion(false);
				setIconSelectInChatSidePanel(ChatIconType.CHAT);
			}
		})
		.catch(err=>{
			window.bus.publish("alert", err.message);
		})
	}
	
	const deletePoll = (poll_uuid) => setPollDeleted(poll_uuid);
	const deleteQuestion = (qa_uuid) => setQuestionDeleted(qa_uuid);
	const handleClosePoll = (poll_uuid) => closePoll({ eventUUID: currentEventUUID, pollUUID: poll_uuid, teamID, userID });

	const updatePollWithOptionUUID = ({pollUUID, pollOptionUUID})=>{
		setPollList(prev=>
			prev.map(item => 
				item?.poll?.poll_uuid === pollUUID 
				  ? { ...item, poll:{...item?.poll, selected_poll_option_uuid: pollOptionUUID} }
				  : item
			)
		)
	}

	const integrationProps = {

		poll, setPoll,
		pollList, setPollList,
		pollToCreated, setPollToCreated,
		pollDeleted, setPollDeleted,
		pollAction, setPollAction,
		updatePollWithOptionUUID,
		showPollConfirmation, setShowPollConfirmartion,
		
		QA, setQA,
		questionList, setQuestionList,
		questionDeleted, setQuestionDeleted,
		questionAction, setQuestionAction,
		questionToAnswer, setQuestionToAnswer,
		whiteboard, setWhiteboard
	}

	useEffect(() => {
		if(!appearance) return;
		const theme = (appearance?.theme && appearance?.theme !== '') ? appearance?.theme : ThemeTemplateType.DARK_PURPLE;

		let primary_color, secondary_color, background_primary_color, background_secondary_color;
		
		if (theme !== ThemeTemplateType.CUSTOMIZE) {
            primary_color = DefaultThemeColor[theme]?.primary_color;
            background_primary_color = DefaultThemeColor[theme]?.background_primary_color;
            secondary_color = DefaultThemeColor[theme]?.secondary_color;
            background_secondary_color = DefaultThemeColor[theme]?.background_secondary_color;
		} else {
			primary_color = appearance?.primary_color;
			background_primary_color = appearance?.background_primary_color;
			secondary_color = appearance?.secondary_color;
			background_secondary_color = appearance?.background_secondary_color;
		}
		
		const box_shadow = appearance?.shadow || 'light';
		let shadowsCss = '0px 4px 24px -1px rgba(0, 0, 0, 0.05)';
		if (box_shadow === 'none') {
			shadowsCss = 'unset';
		} else if (box_shadow === 'dark') {
			shadowsCss = '0px 4px 24px -1px rgba(0, 0, 0, 0.25)';
		}
		root.style.setProperty('--meetroom-box-shadow', shadowsCss);

		const name_role_background = appearance?.name_role_background || 'secondary';
		root.style.setProperty('--meetroom-name-role-style', name_role_background);

		setCssVariables(primary_color, background_primary_color, secondary_color, background_secondary_color);
		setLogoProps({ logo: appearance?.logo, primaryColor: primary_color, backgroundPrimaryColor: background_primary_color });
		return () => {
			clearCssVariables();
		}
		
	}, [appearance]);

	useEffect(() => {
		requestListRef.current = requestList;
		if (requestList.length === 0) {
			setShowRequestPopup(false);
		}
		if (requestList.length > 0 && !showRequestPopup) {
			setShowRequestPopup(true);
		}
	}, [requestList.length]);
	
	useEffect(()=>{
		enableKeyboardShortcutsRef.current = enableKeyboardShortcuts;
	},[enableKeyboardShortcuts]);

	useEffect(()=>{
		liveSettingsRef.current = liveSettings;
	},[liveSettings]);

	useEffect(() => {
		audioEnabledRef.current = audioEnabled;
	}, [audioEnabled]);

	useEffect(() => {
		videoEnabledRef.current = videoEnabled;
	}, [videoEnabled]);

	useEffect(()=>{
		if(spaceKeyPressedRef.current === 'active' && showBubbleMic){
			setShowBubbleMic(false);
		}
	},[showBubbleMic, spaceKeyPressedRef.current]);
	
	useEffect(() => {
		if (!isEndingMeet && currentParticipantUUID && needRejoinRef.current && readyState === ReadyState.OPEN) {
			postEventToken({ eventUUID: currentEventUUID, participantUUID: currentParticipantUUID, userName, teamID, userID }).then((res) => {
				
				if (res) {
					if (res === "Waiting") {
						setLKToken(null);
						navigate(`/preview/${currentEventUUID}`, { state: { eventUUID: currentEventUUID, isAlreadySendRequest: true } });
					} else {
						const newToken = res?.token;
						if (newToken) {
							setLKToken(newToken);
							isHostRef.current = res?.is_admin;
						}
					}
				}
			})
			needRejoinRef.current = false;
			
		}
	}, [isEndingMeet, currentParticipantUUID, readyState]);
	
	useInterval(() => {
		setMeetDuration(prev => prev + 1);
	}, 1000);
	
	useEffect(() => {
		
		//check has no state, is not from preview need back to preview
		if (!location?.state || !lkToken || !isFromPreview || !currentEventUUID) {
			navigate(`/preview/${eventUUIDFromURL}`, { state: { eventUUID: eventUUIDFromURL } });
			return;
		}

		//if onload with eventUUIDFromURL different from currentEventUUID, need check again in preview page
		if (currentEventUUID && currentEventUUID !== eventUUIDFromURL) {
			navigate(`/preview/${eventUUIDFromURL}`, { state: { eventUUID: eventUUIDFromURL } });
			return;
		}
	}, [tokenFromPreview, currentEventUUID, isFromPreview]);
	
	useEffect(() => {
		const action = lastWebSocketMessage?.Action;
		if (action === 'KnockToEnter') {
			const name = lastWebSocketMessage?.Payload?.name;
			const participantUUID = lastWebSocketMessage?.Payload?.participantUUID;
			if (name) {
				setRequestList(prev => {
					const found = prev.find(item => item.participantUUID === participantUUID);
					if (!found) {
						const timer = setTimeout(() => {
							
							const item = requestListRef.current.find(item => item.participantUUID === participantUUID);
							if (item) {
								clearTimeout(item?.timer);
								allowOrDenyRequest({ isAllow: false, name, participantUUID, eventUUID: currentEventUUID, teamID, userID })
								.finally(() => {
									setRequestList(prev => prev.filter(item => item.participantUUID !== participantUUID));
								})
							}else{
								clearTimeout(timer);
							}
						}, RequestWaitingTimeout);

						const newValue = { name, participantUUID, timestamp: new Date().getTime(), timer };
						const res = [...prev, newValue];
						res.sort((a, b) => b.timestamp - a.timestamp);
						return res;
					}
					return prev;
				});
			}
		}else if(action === 'PollCreated'){
			const newPoll = lastWebSocketMessage?.Payload;

			setPollList(prev => {
				let foundParticipant = allParticipants.find(
					(item) => item.id && item.id === `${newPoll?.created_by}`
				);
				if (!foundParticipant) {
					foundParticipant = prev.find(ele => ele?.sender?.id === newPoll?.created_by)?.sender
				}
				if (foundParticipant) {
					const poll = { poll: newPoll, isShow: true, timestamp: +new Date(), sender: { ...foundParticipant }, type: ChatMessageType.POLL }
					return [...prev, poll];
				}
				return [...prev];
			});

		}else if(action === 'PollAnswered'){
			const updatePoll = lastWebSocketMessage?.Payload;
			setPollList(prev=>prev.map(item=>{
				
				if(item?.poll?.poll_uuid === updatePoll?.poll_uuid){
					const newItem = {
						...item,
						poll:{
							...item.poll,
							...updatePoll
						}
					}
					return newItem;
				}
				return item
			}))
		}else if(action === 'PollClosed'){
			const updatePoll = lastWebSocketMessage?.Payload;
			setPollList(prev=>prev.map(item=>{
				
				if(item?.poll?.poll_uuid === updatePoll?.poll_uuid){
					const newItem = {
						...item,
						poll:{
							...item.poll,
							is_closed: true // poll_response_summary empty
						}
					}
					return newItem;
				}
				return item
			}))
		}else if(action === 'QnaQuestion'){
			
			const newQuestion = lastWebSocketMessage?.Payload;
			setQuestionList(prev => {
				let foundParticipant = allParticipants.find(
					(item) => item.identity && item.identity === `${newQuestion?.asked_by}`
				);
				if (!foundParticipant) {
					foundParticipant = prev.find(ele => ele?.sender?.identity === newQuestion?.asked_by)?.sender
				}
				if (foundParticipant) {
					const question = { question: newQuestion, isShow: true, timestamp: +new Date(), sender: { ...foundParticipant }, type: ChatMessageType.QA }
					return [...prev, question];
				}
				return [...prev];
			});
		}else if(action === 'QnaAnswer'){
			const updateQuestion = lastWebSocketMessage?.Payload;
			setQuestionList(prev=>prev.map(item=>{
				
				if(item?.question?.qna_uuid === updateQuestion?.qna_uuid){
					const newItem = {
						...item,
						question:{
							...item.question,
							...updateQuestion
						}
					}
					return newItem;
				}
				return item
			}))

			if(questionToAnswer?.questionUUID === updateQuestion?.qna_uuid){
				setQuestionToAnswer(null);
			}
		}

	}, [lastWebSocketMessage]);
	
	useEffect(() => {
		if (isEndingMeet) {
			setCurrentParticipantUUID(null);
		}
	}, [isEndingMeet]);
	
	useEffect(()=>{
		if(enterMode === MeetEnterMode.KNOCK && isReconnectAttemptFinished){
			const message = translateJSON['websocket-failed'];
        	window.bus.publish("alert",  { message });
		}
	},[enterMode, isReconnectAttemptFinished]);

	//only when has enviroment camera, show the flip switch button
	// useEffect(() => {
	// 	if (!isFirstloading) return;
	// 	if (!useEnvironmentCamera) return;
	// 	const cameras = devices?.cameras ?? [];
	// 	if (cameras.length > 1) {
	// 		const nextCamera = cameras.find(item => item.deviceId !== videoDeviceId);
	// 		if (nextCamera && nextCamera?.deviceId) {
	// 			setVideoDeviceId(nextCamera.deviceId)
	// 		}
	// 	}
	// }, [useEnvironmentCamera]);

	const handleClickOnCopyToClipboard = (content) => {
		setShowClipboard(true);

		navigator.clipboard.writeText(content);
		setTimeout(() => {
			setShowClipboard(false);
		}, 3000);
	};

	const handleShowShareModal = (content) => {
		setShareModalText(content);
		setIsShareModalOpen(true);
	};

	const handleResize = () => {
		// setDimensions({
		// 	height: window.innerHeight,
		// 	width: window.innerWidth,
		// });
	};

	const checkSpaceKeyPressed = () => {
		const activeSpace = spaceKeyPressedRef.current;
		if (activeSpace === 'active') {
			if (!audioEnabledRef.current) {
				setAudioEnabled(true);
			}
			requestAnimationFrame(checkSpaceKeyPressed);
		} else if (activeSpace === 'inactive' && audioEnabledRef.current) {
			setAudioEnabled(false);
		}
	};

	useEffect(() => {
		hasInputFocusRef.current = hasInputFocus;
	}, [hasInputFocus]);

	const handleKeyUp = (e) => {
		if (!hasInputFocusRef.current && (e.key === ' ' || e.keyCode === 32 || e.code === 'Space')) {
			spaceKeyPressedRef.current = 'inactive';
			checkSpaceKeyPressed();
		}
	}
	const handleKeyDown = (e) => {
		
		if (!hasInputFocusRef.current && (e.key === ' ' || e.keyCode === 32 || e.code === 'Space')) {
			spaceKeyPressedRef.current = 'active';
			checkSpaceKeyPressed();
		}

		if (!enableKeyboardShortcutsRef.current) return;
		if (e.altKey && e.keyCode == '88') { // x
			setSidePanelType(null);
		}
		if (e.altKey && e.keyCode == '65' && isHost) { // a
			setSidePanelType(SidePanelType.INTEGRATION);
		}
		if (e.altKey && e.keyCode == '80') { // p
			setSidePanelType(SidePanelType.PARTICIPANT);
		}
		if (e.altKey && e.keyCode == '85') { // u
			setSidePanelType(SidePanelType.CHAT);
		}

		const activeReactions = (liveSettingsRef.current.reactions && !isHostRef.current) || isHostRef.current;
		if(!activeReactions) return;
		if (e.altKey && e.keyCode == '84') { // t
			//approuver
			sendEmojiRef.current(reactionsList[0].key)
		}
		if (e.altKey && e.keyCode == '66') { // b
			//huer
			sendEmojiRef.current(reactionsList[1].key)
		}
		if (e.altKey && e.keyCode == '67') {// c
			//applaudir
			sendEmojiRef.current(reactionsList[2].key)
		}
		if (e.altKey && e.keyCode == '76') { //l
			//rire
			sendEmojiRef.current(reactionsList[3].key)
		}
		if (e.altKey && e.keyCode == '72') { //h
			//love
			sendEmojiRef.current(reactionsList[4].key)
		}
		
	};

	//close option menu on stream or participant panel when click outside of option menu
	const handleMouseClick = (e) => {

		//close share modal on mobile screen
		const classNames = e.target?.getAttribute('class');
		if (classNames === 'share-modal large-modal active') {
			setIsShareModalOpen(false);
		}
		const parentElement = e.target.parentElement;
		if (parentElement) {
			if (
				parentElement.className === 'screen-list__more-options' ||
				parentElement.className === 'participant-list__more-options'
			) {
				return;
			}
		}

		if (isShareModalOpen) {
			if (e.target?.className === 'share-modal large-modal active') {
				setIsShareModalOpen(false);
			}
		}
		
		if (showMoreOptionsOnMobile) {
			if (e.target?.className === 'btn-square show-more-option-on-mobile') {
				setShowMoreOptionsOnMobile(false);
			}
		}

		if (optionBoxIdentityInPanel) {
			if (
				!e.target
					?.getAttribute('class')
					?.startsWith('show-option-menu-in-panel')
			) {
				setOptionBoxIdentityInPanel(null);
			}
		}

		if (optionBoxParticipantInScreen) {
			if (
				!e.target
					?.getAttribute('class')
					?.startsWith('show-option-menu-in-screen')
			) {
				setOptionBoxParticipantInScreen(null);
			}
		}
	};

	const handleReceivedEmoji = (data) => {
		setEmojisReceived(prev => [{ ...data }, ...prev])
	};
	
	const handleDelete = (emojiToDelete) => {

		setEmojisReceived(prev => {
			const newArray = prev.filter(item => !(item.identity === emojiToDelete.identity &&
				item.emoji === emojiToDelete.emoji && item.timestamp === emojiToDelete.timestamp));
			return newArray
		})
	};
	
	useEffect(() => {
		if (isPortraitScreen) {
			setOrientation('portrait');
		}
		if (isLandscapeScreen) {
			setOrientation('landscape');
		}
	}, [isPortraitScreen, isLandscapeScreen]);

	useEffect(() => {
		//reset notif when open chat panel
		if (sidePanelType === SidePanelType.CHAT) {
			const hasUnreadMessages = !!layoutContext?.widget?.state?.unreadMessages;
			if (hasUnreadMessages) {
				layoutContext?.widget.dispatch?.({ msg: 'unread_msg', count: 0 });
			}
		}
	}, [sidePanelType]);

	const sharedProps = {
		isPhoneScreen,
		isMediumScreen,
		isLargeScreen,
		orientation,
	};
	
	useEffect(() => {
		setLowConnection(networkStatus !== NetworkStatus.GOOD);
	}, [networkStatus]);

	useEffect(()=>{
		if(showBubbleMic && audioTrack){

			audioTrackRef.current.stop();
			audioTrack?.stop();
			setAudioTrack(null);
		}
	},[showBubbleMic]);

	useEffect(()=>{
		audioTrackRef.current?.stop();
		audioTrackRef.current = null;
		audioTrack?.stop();
		setAudioTrack(null);

		if(!audioEnabled){
			createLocalAudioTrack()
			.then(track=>{
				audioTrackRef.current = track
				setAudioTrack(track);
			})
		}else{
			if(showBubbleMic){
				setShowBubbleMic(false)
			}
		}
	},[audioEnabled]);

	useEffect(()=>{
		audioTrackRef.current = audioTrack;
		if (!audioTrack) return;
		setMicroPermission(PermissionStatus.GRANTED);

		const stream = audioTrack?.mediaStream;
		if (!stream) return;
		const audioContext = new AudioContext();
		const mic = audioContext.createMediaStreamSource(stream);
		const analyser = audioContext.createAnalyser();
		analyser.fftSize = 2048;
		mic.connect(analyser);

		const dataArray = new Uint8Array(140);
		const checkVoiceInput = () => {

			if (audioEnabledRef.current) {
				hasVoiceInputRef.current = false
				return cancelAnimationFrame(requestId.current);
			}
			analyser.getByteFrequencyData(dataArray);
			const average = dataArray.reduce((acc, value) => acc + value, 0) / 140;
			if (average > 50) {
				hasVoiceInputRef.current = true;
				setShowBubbleMic(true);
				return cancelAnimationFrame(requestId.current);
			}
			requestId.current = window.requestAnimationFrame(checkVoiceInput);
		};

		checkVoiceInput();
	},[audioTrack]);


	useEffect(() => {
		
		if (showChatPopup) {
			timerRef.current = setTimeout(() => {
				setShowChatPopup(false);
			}, PopupShowDuration);
		} else {
			if (timerRef.current) clearTimeout(timerRef.current);
		}
	}, [showChatPopup]);

	useEffect(() => {
		if (!lastMessage) return;
		setShowChatPopup(true);
	}, [JSON.stringify(lastMessage)]);

	useEffect(() => {
		if (messagesList.length === 0) return;
		const newLastMessage = messagesList[messagesList.length - 1];
		const type = newLastMessage?.type ?? '';
		if (type === ChatMessageType.CHAT && newLastMessage?.message){
			setLastMessage(newLastMessage);
		}
		
	}, [messagesList.length]);

	useEffect(() => {
		setShowBottomPanel((isPhoneScreen && !sidePanelType) || isMediumScreen || isLargeScreen)
	}, [isPhoneScreen, sidePanelType, isMediumScreen, isLargeScreen]);


	useEffect(() => {
		setForceRequestPermission(true);
    
		window.addEventListener('resize', handleResize);
		timerRef.current = setTimeout(() => {
			setShowChatPopup(false);
		}, PopupShowDuration);

		window.addEventListener('keydown', handleKeyDown);
		window.addEventListener('keyup', handleKeyUp);
		window.addEventListener('mousedown', handleMouseClick);

		//only show notif once
		setShowNotif(true);

		checkSpaceKeyPressed();

		const eventUUID = location?.state?.eventUUID || eventUUIDFromURL;
		getEventData({ eventUUID, teamID, userID })
		.then((res) => {
			const appearance = res?.configuration?.appearance;
			setAppearance(appearance)
			const mode = res?.configuration?.settings?.mode ?? MeetEnterMode.NONE;
			setEnterMode(mode);
			const pollRes = res?.event_tools?.filter(item=>item.name === IntegrationType.POLL)?.[0];
			setPoll(pollRes);
			const qaRes = res?.event_tools?.filter(item=>item.name === IntegrationType.QA)?.[0];
			setQA(qaRes);
			const whiteboardRes = res?.event_tools?.filter(item=>item.name === IntegrationType.Whiteboard)?.[0];
			setWhiteboard(whiteboardRes);
			const live_settings = res?.configuration?.live_settings;
			setLiveSettings(live_settings);
			const found = res?.participants?.find(item => item.participant_uuid === currentParticipantUUID);
			if (!found) {
				if (!location || !eventUUIDFromURL || !userName) {
					navigate(`/preview/${eventUUIDFromURL}`, { state: { eventUUID: eventUUIDFromURL } });
				}
			}
		}).catch(error => {
			console.error(error?.message)
		})

		return () => {
			window.removeEventListener('resize', handleResize);
			window.removeEventListener('keydown', handleKeyDown);
			window.removeEventListener('keyup', handleKeyUp);
			window.removeEventListener('mousedown', handleMouseClick);
			
			//remove all participants from redux
			dispatch(participantDeleteAll());

			//clean unused variables in context
			setLocalParticipantIdentifier(null);
			setLastTimestampShareScreen(null);
			setCurrentParticipantColor(generateRandomColor());
			setRaisingHand(false);
			setShareScreen(false);

			if(sessionStorage.getItem('tklk')){
				sessionStorage.removeItem('tklk')
			}
		};
	}, []);

	const recordProps = {
		eventUUID: currentEventUUID,
		participantUUID: currentParticipantUUID,
		showReadyRecordModal,
		setReadyRecordModal,
		showStopRecordModal,
		setStopRecordModal,
		isRecordingStart,
		setRecordingStart,
	};
	const showTopPanel = !sidePanelType && isPhoneScreen;

	const bodyClassName = `meetroom__body  ${showTopPanel ? 'show-top-panel' : ''}  ${showBottomPanel ? 'show-bottom-panel' : ''} `;

	const needShowChatPopup =
		!disableChatPopups && showChatPopup && sidePanelType !== SidePanelType.CHAT && !!timerRef.current && lastMessage && lastMessage?.sender?.identity !== room.localParticipant.identity;
	const message = lastMessage?.message ?? '';
	const match = [...message.matchAll(urlRegEx)];

	let hasLink = false;
	if (match.length > 0) {
		hasLink = true;
	}
    
	let lastEndOfLink = 0;
	const messageLength = message.length;
	const messageValue = hasLink ? (
		<>
			{match.map((item, index) => {
				const previousIndex = lastEndOfLink;
				lastEndOfLink = item[0].length + item.index;
				return (<>
					<span key={`span-${index}`}>{message.slice(previousIndex, item.index)}</span>
					<a title={item[0]} key={`a-${index}`} href={item[0]} target='_blank' >{item[0]}</a>
				</>)
    
			})}
			<span key={`span-${messageLength}`}>{message.slice(lastEndOfLink, messageLength - 1)}</span>
		</>
	) :
		<span>{message}</span>;
		
	const showPollList = pollList?.filter(item=>item.isShow && !item?.poll?.is_closed && item?.sender?.identity !== room.localParticipant.identity) ?? [];
	const showQuestionList = questionList?.filter(item=>item.isShow && item?.sender?.identity !== room.localParticipant.identity) ?? [];
	
	if (isEndingMeet) {
		return <EndingPage rejoinEvent={rejoinEventInEndingPage} />
	}
	if(!appearance){
		return <></>;
	}
	return (
		<>
			<BgPanel isInMeetRoom={true} appearance={appearance}/>
			
			<div className='meetroom'>
				<div className='meetroom-top-nav'>
					{showTopPanel && (
						<InfoBox
							logoProps={logoProps}
							isPhoneScreen={isPhoneScreen}
							copyToClipboard={handleClickOnCopyToClipboard}
							showShareModal={() => handleShowShareModal()}
							showNotif={showNotif}
							setShowNotif={setShowNotif}
						/>
					)}
				</div>
				{!lostConnection && <div className={bodyClassName} >
					{isLowConnection && (
						<div className='meetroom__body__low-connection'>
							<div className='meetroom__body__low-connection__body'>

								{networkStatus === NetworkStatus.BAD && (
									<NetworkStatusDangerSvg />
								)}
								{networkStatus === NetworkStatus.LOW && (
									<NetworkStatusWarningSvg />
								)}
								<h3>{translateJSON['bad-connection']}</h3>
								<IconSvg svg={CloseIcon} onMouseDown={() => setLowConnection(false)} />
							</div>
						</div>
					)}
					<div className='meetroom__popups'>
						{totalRaiseHandParticipants.length > 0 && (
							<div className='someone-raising-hand'>
								{totalRaiseHandParticipants.map(participant =>
									<div
										className='someone-raising-hand__counter'
										key={participant.identity} >
										<img src={RaiseHandSvg} />
										<span>{participant.displayName}</span>
									</div>
								)}
								
							</div>
						)}

						{needShowChatPopup && lastMessage &&
							<div className='chat-message-popup'>
								<div className='chat-message-popup__header'>
									<IconSvg svg={ChatSvg} />
									<span>{lastMessage?.sender?.userName ?? ''}</span>
									<IconSvg svg={CloseIcon} onMouseDown={handleClickOnCloseNotif} styleAdd={{cursor: 'pointer'}}/>
								</div>
								<div className='chat-message-popup__content'>
									{messageValue}
								</div>
							</div>
						}
						
						{showRequestPopup && <RequestModal {...requestPopupProps} />}
						
						{!!showPollList.length && showPollList.map(item => 
							<PollItem key={item.timestamp} 
								isInPopUp={true}
								isHost={isHost}
								isSameWithPrevious={false}
								isSameWithNext={false}
								setPollAction={setPollAction}
								updateShow={()=>{
									setPollList(prevItems => {
										const newItems = [...prevItems];
										const index = newItems.findIndex(el => el?.poll?.poll_uuid === item?.poll?.poll_uuid);
										if (index !== -1) {
											newItems[index] = { ...newItems[index], isShow: false };
										}
										return newItems;
									});
								}}
								updatePollWithOptionUUID={updatePollWithOptionUUID}
								{...item}
							/>)
						}

						{!!showQuestionList.length && showQuestionList.map(item => 
							<QAItem key={item.timestamp} 
								isInPopUp={true}
								isSameWithPrevious={false}
								isSameWithNext={false}
								setQuestionToAnswer={setQuestionToAnswer}
								setQuestionAction={setQuestionAction}
								updateShow={()=>{
									setQuestionList(prevItems => {
										const newItems = [...prevItems];
										const index = newItems.findIndex(el => el?.question?.qna_uuid === item?.question?.qna_uuid);
										if (index !== -1) {
											newItems[index] = { ...newItems[index], isShow: false };
										}
										return newItems;
									});
								}}
								{...item}
							/>)
						}
					</div>
					<MyLivekitRoom 
						room={room} 
						isHost={isHost}
						lkToken={lkToken} 
						setMeetDuration={setMeetDuration}
						setLastMessage={setLastMessage}
						onSendEmojiChange={onSendEmojiChange}
						handleShowShareModal={handleShowShareModal}
						handleReceivedEmoji={handleReceivedEmoji}
						liveSettings={liveSettings}
						setLiveSettings={setLiveSettings}
						sidePanelType={sidePanelType}
						setSidePanelType={setSidePanelType}
						messagesList={messagesList} 
						setMessagesList={setMessagesList} 
						layoutContext={layoutContext}
						requestPopupProps={requestPopupProps}
						hasInputFocus={hasInputFocus}
						setInputFocus={setInputFocus}
						optionBoxIdentityInPanel={optionBoxIdentityInPanel}
						setOptionBoxIdentityInPanel={setOptionBoxIdentityInPanel}
						iconSelectInChatSidePanel={iconSelectInChatSidePanel}
						setIconSelectInChatSidePanel={setIconSelectInChatSidePanel}
						{...integrationProps}
					/>
				</div>
				}
				
				{lostConnection && <ErrorLostConnectionPage />}

				{showBottomPanel && currentParticipantUUID && (
					<BottomPanel
						logoProps={logoProps}
						room={room}
						isHost={isHostRef.current}
						showBubbleMic={showBubbleMic && hasVoiceInputRef.current}
						hiddenBubbleMic={() => setShowBubbleMic(false)}
						duration={meetDuration}
						hasNonReadMessage={!!layoutContext?.widget?.state?.unreadMessages}
						setEndingMeet={setEndingMeet}
						sidePanelType={sidePanelType}
						setSidePanelType={setSidePanelType}
						showReactionPanel={showReactionPanel}
						setShowReactionPanel={setShowReactionPanel}
						showShareModal={() => handleShowShareModal()}
						copyToClipboard={handleClickOnCopyToClipboard}
						setShowMoreOptionsOnMobile={setShowMoreOptionsOnMobile}
						showMoreOptionsOnMobile={showMoreOptionsOnMobile}
						setShowPermissionModal={setShowPermissionModal}
						optionInfoModal={optionInfoModal}
						setOptionInfoModal={setOptionInfoModal}
						showNotif={showNotif}
						setShowNotif={setShowNotif}
						enterMode={enterMode}
						setEnterMode={setEnterMode}
						liveSettings={liveSettings}
						setLiveSettings={setLiveSettings}
						poll={poll}
						QA={QA}
						{...recordProps}
						{...sharedProps}
					/>
				)}

				<div className='meetroom__emojis-container'>
					{emojisReceived.length > 0 &&
						emojisReceived.map((item) =>
							<EmojiAnimation item={item} key={`${item.timestamp}-${item.identity}-${item.emoji}`}
								needRemoved={handleDelete} />)}
				</div>
			</div>

			{isShareModalOpen &&
				<ShareModal
					context={shareModalText}
					copyToClipboard={handleClickOnCopyToClipboard}
					closeModal={() => setIsShareModalOpen(false)}
				/>}
			

			{showReactionPanel && (
				<ReactionPanel
					showReaction={(liveSettings?.reactions && !isHost) || isHost}
					sendEmoji={sendEmojiRef.current}
					setShowReactionPanel={setShowReactionPanel}
				/>
			)}

			{optionInfoModal && !isShareModalOpen && (
				<InfoModal
					isHost={isHostRef.current}
					optionInfoModal={optionInfoModal}
					setOptionInfoModal={setOptionInfoModal}
					copyToClipboard={(value) => handleClickOnCopyToClipboard(value)}
					showShareModal={() => handleShowShareModal()}
				/>
			)}

			{showReadyRecordModal && <ReadyRecordModal {...recordProps} />}
			{showStopRecordModal && <StopRecordModal {...recordProps} />}

			{showPermissionModal.show && (
				<PermissionModal
					from={showPermissionModal?.from}
					closeModal={() => setShowPermissionModal({ show: false })}
					isInMeetRoom={true}
				/>
			)}

			{showPollConfirmation && <PostPollConfirmationModal
				poll={pollToCreated} 
				readyToPostPoll={readyToPostPoll}
				closeModal={()=>setShowPollConfirmartion(false)} 
			/>}
			
			{pollAction?.action && <PollActionModal
				pollUUID={pollAction?.pollUUID} 
				action={pollAction?.action}
				deletePoll={deletePoll}
				closePoll={handleClosePoll}
				closeModal={()=>setPollAction(null)} 
			/>}

			{questionAction?.action && <QuestionActionModal
				questionUUID={questionAction?.questionUUID} 
				deleteQuestion={deleteQuestion}
				closeModal={()=>setQuestionAction(null)} 
			/>}
			
			{showClipboard && (
				<div className='meetroom__clipped'>
					<div className='pill'>
						<p>Link copied to clipboard !</p>
					</div>
				</div>
			)}
		</>
	);
};

export default MeetRoom;
