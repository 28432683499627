import React from 'react';
import useAuthContext from '../hooks/useContext';
import { ReactComponent as LOGOSVG } from '../assets/icons/logo.svg';

const ErrorLostConnectionPage = ({ isInMeetRoom = true}) => {
	const { translateJSON } = useAuthContext();
	const message = translateJSON['connection-lost-home'];
	let messageArr = message.split('. ');
	
	return (
		<div className={`error-page${isInMeetRoom ? ' is-black-background':''}`}>
			<div className='error-page__body'>
				{isInMeetRoom && <LOGOSVG className='logo-box' />}
				{messageArr.map((item, index) => {
					let itemUpdate = item;
					if (!item.endsWith('.')) {
						itemUpdate = item.concat('.');
					}
					return <p key={index} >{itemUpdate}</p>
				})}
			</div>
		</div>
	);
};

export default ErrorLostConnectionPage;
