import React, { useEffect, useRef, useState } from "react";
import ColorPicker, { useColorPicker } from 'react-best-gradient-color-picker';
import { ReactComponent as CloseSvg } from '../../assets/icons/cross-24.svg';

import { getOpacity, linearGradientToHex } from "../../utils/color";
import useAuthContext from "../../hooks/useContext";

const ColorButton = ({
    color: defaultColor,
    isSelected = false,
    themeState,
    setThemeState,
    type,
    showColorPickerType,
    setShowColorPickerType,
    leftside = true,
    backgroundImageUpload,
    setBackgroundImageUpload,
    setBackgroundUploadedWarning
}) => {
    const ref = useRef();
    const pickerRef = useRef();
    const colorBtnRef = useRef();
    const { translateJSON } = useAuthContext();
    const [showImageColorPopup, setShowImageColorPopup] = useState(false);
    const showImageColorPopupRef = useRef(showImageColorPopup);
    const onChange = (color) => {
        const hexa = linearGradientToHex(color.toLowerCase());
        if(hexa?.length > 100){
            window.bus.publish("alert", translateJSON['color-picker-error'] || 'Color value cannot exceed 100');
            return;
        }
        if(type === 'background_primary_color'){
            setThemeState({
                ...themeState,
                [type]: color,
                use_background_image: false,
            });
            setBackgroundImageUpload(null);
        }else{
            setThemeState({ ...themeState, [type]: color });
        }
    };

    const { valueToHex, gradientType, isGradient } = useColorPicker(defaultColor, onChange);

    const backgroundUploadRef = useRef();
    const isBackgroundPrimaryColor = type === 'background_primary_color';
    const useBackgroundImage = themeState?.use_background_image;
    const isActive = type === showColorPickerType;
    const fileUploadLimit = 1 * 1024 * 1024;
    const handleUploadBackground = (e) => {
        const file = e.target.files[0];
        if (file && file.size <= fileUploadLimit) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setBackgroundUploadedWarning(false);
                const base64String = reader.result.toString();

                setBackgroundImageUpload({ file, base64:base64String });
                setThemeState({
                    ...themeState,
                    use_background_image: true
                });
                setShowImageColorPopup(false);
            }
            reader.onabort = () => {
                setBackgroundImageUpload(null);
                setThemeState({
                    ...themeState,
                    use_background_image: false
                });
                setShowImageColorPopup(false);
            }
            reader.readAsDataURL(file);
        }else{
            setBackgroundUploadedWarning(true);
            setShowImageColorPopup(false);
        }
    }
    const handleClickOutside = (event) => {
        const isOutside = ref.current && !ref.current.contains(event.target)
        
        if (isOutside && showImageColorPopupRef.current) {
            setShowImageColorPopup(false);
        }
        const isOutsidePicker = pickerRef.current && !pickerRef.current.contains(event.target);
        if(isOutsidePicker){
            setShowColorPickerType(null)
        }
            
    };

    useEffect(()=>{
        showImageColorPopupRef.current = showImageColorPopupRef;
    },[showImageColorPopup]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const opacity = getOpacity(defaultColor);
    
    const gradientTypeSplit = gradientType?.split('-')?.[0];
    const desc = !isGradient ? valueToHex()?.toUpperCase() : gradientTypeSplit === 'linear' ? translateJSON['custom-theme-linear'] : translateJSON['custom-theme-radial'];

    const clipPathStyle = isGradient ? {} :
        {
            clipPath: opacity < 1 ?
                'polygon(0 0, 100% 0, 0 100%)' :
                'polygon(0% 0%, 0% 100%, 100% 100%, 100% 0%)',
                
        };
    const colorStyle = {
        background: defaultColor,
        backgroundColor: defaultColor,
    }
    const backgroundImageStyle = {
        backgroundImage: `url(${backgroundImageUpload?.base64})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    }
    
    const showImageString = isBackgroundPrimaryColor && useBackgroundImage && backgroundImageUpload?.base64;
    const style = showImageString ? { ...backgroundImageStyle } : { ...colorStyle, ...clipPathStyle };
    const descString = showImageString ? (translateJSON['custom-theme-image'] || 'Image') : desc;
    return (
        <div className={`color-button-wrapper ${isActive ? 'is-active' : ''} ${isSelected ? 'is-selected' : ''}`}>
        
           
            <div className='color-button' ref={colorBtnRef} 
                onMouseDown={(event) => {
                    event.stopPropagation();
                    if (isBackgroundPrimaryColor) {
                        setShowImageColorPopup(true);
                    } else {
                        setShowColorPickerType(type);
                    }
                }}>
                <div className='color-button-clip' style={style} />
            </div>
            
            <span title={desc}>{descString}</span>
            
            {showImageColorPopup &&
                <div ref={ref} className="image-color-popup">
                                    
                    <div className="input-span-wrapper">
                        <input ref={backgroundUploadRef}
                            type='file'
                            onChange={handleUploadBackground}
                            name="upload-mainscreen-background"
                            accept="image/png, image/jpeg" />
                        <span onMouseDown={() => backgroundUploadRef.current?.click()}>
                            {translateJSON['custom-theme-image'] || 'Image'}
                        </span>
                    </div>
                    
                    <span onMouseDown={(event) => {
                        event?.stopPropagation();
                        if(isBackgroundPrimaryColor){
                            setBackgroundUploadedWarning(false);
                        }
                        setShowImageColorPopup(false);
                        setShowColorPickerType(type);
                        setThemeState({
                            ...themeState,
                            background_image: '',
                            use_background_image: false
                        });
                    }}>
                        {translateJSON['custom-theme-color'] || 'Color'}
                    </span>
                </div>
            }
            
            {isActive &&
                <div ref={pickerRef} className={`color-picker ${leftside ? 'is-left' : 'is-right'}`}>

                    <div className="color-picker__header">
                        <span>{translateJSON['select-color-title'] || 'Color selection'}</span>
                        <CloseSvg onMouseDown={() => { setShowColorPickerType(null) }} />
                    </div>
                    <ColorPicker
                        value={defaultColor}
                        onChange={onChange}
                        hidePresets={true}
                        hideInputs={false}
                        hideControls={false}
                        hideEyeDrop={true}
                        hideAdvancedSliders={true}
                        hideColorGuide={true}
                        hideInputType={true}
                        hideGradientStop={false}
                        hideOpacity={false}
                        hideGradientAngle={false}
                        hideGradientControls={false}
                        hideGradientType={false}
                    />
                </div>
            }
        </div>
    )
};

export default ColorButton;