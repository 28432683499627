import React, { useState, useRef, useEffect, forwardRef } from 'react';
import { useSelector } from 'react-redux';

import SendArrowSvg from '../../assets/icons/send-arrow.svg';
import AddSmileySvg from '../../assets/icons/add-smiley.svg';
import ChatSvg from '../../assets/icons/chat-icon.svg';
import PollsSvg from '../../assets/icons/polls.svg';
import QASvg from '../../assets/icons/q&a.svg';
import CrossSvg from '../../assets/icons/cross-24.svg';
import AnswerSvg from '../../assets/icons/share.svg';
import MessageItem from './MessageItem';
import SidePanel from './SidePanel';
import SearchSvg from '../../assets/icons/search.svg';
import FilterSvg from '../../assets/icons/filter-icon.svg';
import {ReactComponent as EmptyChat}  from '../../assets/icons/empty-chat.svg';
import {ReactComponent as EmptyChatLight}  from '../../assets/icons/empty-chat-light.svg';
import ParticipantModal from '../../dataModal/ParticipantModal';
import EmojiContainer from '../Emoji';
import IconSvg from '../IconSvg';
import PollItem from './Polls/PollItem';
import CreatePoll from './Polls/CreatePoll';
import AutoResizeTextarea from './AutoResizeTextarea';
import { IntegrationType } from './IntegrationsPanel';
import { answerQA, createQA, fetchQuestionData } from '../../helpers/eventApi';
import { selectLocalParticipant } from '../../redux/participantsSlice';
import useAuthContext, { useGeneralContext } from '../../hooks/useContext';
import { isLightBestSolidOrLinearGradient } from '../../utils/color';

import Input from '../Input';
import { ChatIconType } from '../../pages/MeetRoom';
import QAItem from './QA/QAItem';


export const ChatMessageType = {
	CHAT: 'chat',
	REACTION: 'reaction',
	POLL: 'poll',
	QA: 'Q&A'
}
const ChatPanel = (props) => {	
	const {
		isHost,
		hiddenPanelState,
		messagesList,
		msgE,
		setMsgE,
		sendChatMessage,
		setInputFocus,
		iconSelectInChatSidePanel, 
		setIconSelectInChatSidePanel,

		liveSettings,

		pollToCreated, 
		setPollToCreated,
		showPollConfirmation, 
		setShowPollConfirmartion,
		poll,
		pollList,
		pollAction, 
		setPollAction,
		updatePollWithOptionUUID,

		QA,
		questionList,
		questionAction,
		setQuestionAction,
		questionToAnswer, 
		setQuestionToAnswer
	} = props;

	
	const virtualKeyboardSupported = 'virtualKeyboard' in navigator;
	const { userID, teamID, translateJSON } = useAuthContext();
	const { currentEventUUID, currentParticipantUUID } = useGeneralContext();

    const currentParticipant = useSelector(selectLocalParticipant);
	const [list, setList] = useState([]);
	const [messageFilter, setMessageFilter] = useState('');
    const [showIconsFilter, setShowIconsFilter] = useState(false);
	const [iconSelectInFilter, setIconSelectInFilter] = useState(ChatIconType.CHAT);
	const [showEmojiModal, setShowEmojiModal] = useState(false);
	const [questionOrAnswer, setQuestionOrAnswer] = useState();
	const dialogRef = useRef(null);
	const chatMessageRef = useRef(null);
	const meetroomSidePanelRef = useRef();
	const dialogActionRef = useRef();

	const root = document.documentElement;
    const background_secondary_color = getComputedStyle(root).getPropertyValue('--meetroom-background-secondary-color');
	const isLightBest = isLightBestSolidOrLinearGradient(background_secondary_color);
    const handleSendMessage = async () => {
		setShowEmojiModal(false);
		if (msgE.trim() !== '') {
			sendChatMessage(msgE);
			setMsgE('');
		}
	};

	const enableChatInput = (liveSettings?.['open_chat'] && !isHost) || isHost;
	const enableReactionsInput = (liveSettings?.['reactions'] && !isHost) || isHost;
	const handleEmojiClick = (emojiSymbol) => {
		if(!enableReactionsInput) return;
		const start = chatMessageRef.current?.selectionStart;
		const end = chatMessageRef.current?.selectionEnd;
		if (chatMessageRef.current && emojiSymbol) {
			setMsgE(prev => {
				if (!start && !end) {
					return prev + emojiSymbol;
				}
				const prefix = prev.substring(0, start);
				const suffix = prev.substring(end);
				const newMsgE = prefix + emojiSymbol + suffix;
				return newMsgE;
			})
		}
	};

	const updateChatList = ()=>{
		let previousSender = null;
		let combinedArray = [];
		if(showIconsFilter && iconSelectInFilter === ChatIconType.POLL){
			combinedArray = [...pollList];
			
		}else if(showIconsFilter && iconSelectInFilter === ChatIconType.QA){
			combinedArray = [...questionList];
		}else{
			if(messageFilter || (showIconsFilter && iconSelectInFilter === ChatIconType.CHAT)){
				combinedArray = [...messagesList].filter(item=>item?.message?.includes(messageFilter));
			}else{
				combinedArray =  [...messagesList, ...pollList, ...questionList];
			}
		}
		combinedArray.sort((a, b) => a.timestamp - b.timestamp);

		const mapList = [...combinedArray].map((item, index, arr) => {
			const type = item?.type;
			let isSameWithPrevious = false;
			if (previousSender) {
				isSameWithPrevious = previousSender.isEqualTo(item.sender);
			}
			previousSender = new ParticipantModal({ ...item.sender });

			let isSameWithNext = false;
			if (index + 1 <= arr.length - 1) {
				const next = arr[index + 1];
				if (next && next.sender) {
					const nextSender = new ParticipantModal({ ...next.sender });
					isSameWithNext = nextSender.isEqualTo(item.sender);
				}
			}
			return { ...item, isSameWithPrevious, isSameWithNext, type };
		});
		setList(mapList);
	}
	useEffect(() => {
		if(messagesList.length === 0) return;
		updateChatList();
	}, [messagesList.length]);

	useEffect(() => {
		updateChatList();
	}, [messageFilter, showIconsFilter, iconSelectInFilter]);

	useEffect(() => {
		updateChatList()
	}, [JSON.stringify(questionList), currentParticipant]);

	useEffect(() => {
		if(!currentParticipant) return;
		updateChatList();
	}, [JSON.stringify(pollList), currentParticipant]);

	useEffect(()=>{
		if(poll && !poll?.is_active && iconSelectInChatSidePanel === ChatIconType.POLL){
			setIconSelectInChatSidePanel(ChatIconType.CHAT);
		}
	},[poll?.is_active]);

	useEffect(()=>{
		if(QA && !QA?.is_active && iconSelectInChatSidePanel === ChatIconType.QA){
			setIconSelectInChatSidePanel(ChatIconType.CHAT);
		}
	},[QA?.is_active]);

	useEffect(()=>{
		if(iconSelectInChatSidePanel !== ChatIconType.QA && questionToAnswer){
			setIconSelectInChatSidePanel(ChatIconType.QA);
		}
	},[JSON.stringify(questionToAnswer)]);

	const handleEmojiModale = () => {
		if(!enableReactionsInput) return;
		setShowEmojiModal(!showEmojiModal);
	};

	const onKeyDown = (e) => {

		if (e.key === 'Enter' && e.ctrlKey) {
			setMsgE((prev) => `${prev}\n`);
		}else if (e.key === 'Enter') {
			handleSendMessage();
			e.preventDefault();
		}
	};
	
	const handlePostQuestion = ()=>{
		createQA({question: questionOrAnswer, eventUUID: currentEventUUID, participantUUID: currentParticipantUUID, teamID, userID})
		.then(res=>{
			if(res){
				setQuestionOrAnswer('');
			}
		})
		.catch(err=>{
			window.bus.publish("alert", err.message);
		})
	}

	const handleAnswerQuestion =(questionUUID)=>{
		//before answer, check has already answered by another
		fetchQuestionData({questionUUID, eventUUID: currentEventUUID, participantUUID: currentParticipantUUID, teamID, userID})
		.then(res=>{
			if(res?.answer || res?.answered_by){
				window.bus.publish("alert", 'answered by others');
			}else{
				answerQA({ answer: questionOrAnswer, questionUUID, eventUUID: currentEventUUID, participantUUID: currentParticipantUUID, teamID, userID})
				.then(res=>{
					if(res){
						setQuestionOrAnswer('');
						setQuestionToAnswer(null)
					}
				})
				.catch(err=>{
					window.bus.publish("alert", err.message);
				})
			}
		})
		.catch(err=>{
			window.bus.publish("alert", err.message);
		})
	}
	useEffect(() => {
		const lastChildElement = dialogRef.current?.lastElementChild;
		lastChildElement?.scrollIntoView({
			behavior: 'smooth',
			block: 'nearest',
			inline: 'start',
		});
	}, [list.length]);

	useEffect(() => {
		if (virtualKeyboardSupported) {
			navigator.virtualKeyboard.overlaysContent = true;

			let previousHeight;

			navigator.virtualKeyboard.addEventListener('geometrychange', (e) => {
				let { height } = e.target.boundingRect;
				height = Math.abs(height);
				if (previousHeight === height) {
					return;
				}
				previousHeight = height;
				meetroomSidePanelRef.current.style.marginBottom = `${height}px`;
			});
		}
	}, [virtualKeyboardSupported]);


	const isPollActive = poll && poll?.is_active;
	const hasPoll = !!pollList.length;
	const isQAActive = QA && QA?.is_active;
	const hasQuestion = !!questionList.length;

	const hasFilterBtn = isPollActive || isQAActive;
	const showChatIcon = isQAActive || (hasFilterBtn && isHost);
	const showPollIcon = isPollActive && isHost;
	const showQuestionIcon = isQAActive;

	useEffect(() => {
		if (!dialogActionRef.current) return;
		const observer = new ResizeObserver((entries) => {
			const height = entries[0].contentRect.height;
			if(dialogRef?.current){
				let heightAdded = height + 40;
				if(showIconsFilter){
					heightAdded += 62;
				}
				if(showChatIcon || showPollIcon || showQuestionIcon){
					heightAdded += 24;
				}
				dialogRef.current.style.height = `calc(100% - ${heightAdded}px)`;
			}
			
		  });
		  observer.observe(dialogActionRef.current);
		return () => observer.disconnect(); // clean up 
	}, [showIconsFilter,showChatIcon || showPollIcon || showQuestionIcon ,dialogActionRef.current]);

	const chatList = list.map((item, index) => {
		if(item?.type === IntegrationType.POLL){
			return <PollItem 
				{...item} 
				key={`${index} ${item.timestamp}`} 
				isHost={isHost} 
				pollAction={pollAction} 
				setPollAction={setPollAction} 
				updatePollWithOptionUUID={updatePollWithOptionUUID}/>
		}
		else if(item?.type === IntegrationType.QA){
			return <QAItem {...item} key={`${index} ${item.timestamp}`} setQuestionAction={setQuestionAction} setQuestionToAnswer={setQuestionToAnswer}/>
		}
		else return <MessageItem key={`${index} ${item.timestamp}`} {...item} />
		
	})
	
	const showChatEmpty = (!showIconsFilter && list.length === 0) || (showIconsFilter && iconSelectInFilter === ChatIconType.CHAT && messagesList.length === 0);
	const showPollEmpty = showIconsFilter && iconSelectInFilter === ChatIconType.POLL && pollList.length === 0;
	const showQAEmpty = showIconsFilter && iconSelectInFilter === ChatIconType.QA && questionList.length === 0;
	const enableSendBtn = msgE && (enableChatInput || enableReactionsInput);
	
	return (
		<SidePanel title={translateJSON['chat-title']} hiddenPanelState={hiddenPanelState}>
			<div ref={meetroomSidePanelRef} className='meetroom-chat-panel__body meetroom-side-panel__body'>
				<div className='search-frame searchframe'>
					<Input
						className='input-frame input-searchbar'
						icon={SearchSvg}
						defaultValue={messageFilter}
						onChange={(e) => setMessageFilter(e.target.value)}
						autoFocus={false}
						onFocus={() => setInputFocus(true)}
						onBlur={() => setInputFocus(false)}
						id='text_search_a_message'
						placeholder={translateJSON['chat-search']}
					/>
					{hasFilterBtn && 
						<button className={`btn-square ${showIconsFilter ? 'selected' : ''}`} onClick={()=>setShowIconsFilter(prev=>!prev)}>
							<IconSvg svg={FilterSvg} />
						</button>
					} 
				</div>
				{showIconsFilter && 
					<div className='filter-tab-icons'>
						<IconSvg svg={ChatSvg} classAdd={`${iconSelectInFilter === ChatIconType.CHAT ? 'selected' : ''}`} 
							onMouseDown={()=> setIconSelectInFilter(ChatIconType.CHAT)} />
						{(isPollActive || hasPoll) && <IconSvg svg={PollsSvg} classAdd={`${iconSelectInFilter === ChatIconType.POLL ? 'selected' : ''}`} 
							onMouseDown={()=> setIconSelectInFilter(ChatIconType.POLL)} />}
						{(isQAActive || hasQuestion) && <IconSvg svg={QASvg} classAdd={`${iconSelectInFilter === ChatIconType.QA ? 'selected' : ''}`} 
							onMouseDown={()=> setIconSelectInFilter(ChatIconType.QA)} />}
					</div>
				}
				<div className='meetroom-chat-panel__dialog-frame' ref={dialogRef}>
					{chatList}
					{showChatEmpty && 
						<div className='meetroom-chat-panel__empty-list'>
							<span>{translateJSON['chat-empty-state']}</span>
							{isLightBest ? <EmptyChat /> : <EmptyChatLight /> }
						</div>
					}
					{showPollEmpty && 
						<div className='meetroom-chat-panel__empty-list'>
							<span>{translateJSON['polls-empty-state']}</span>
							{isLightBest ? <EmptyChat /> : <EmptyChatLight /> }
						</div>
					}
					{showQAEmpty && 
						<div className='meetroom-chat-panel__empty-list'>
							<span>{translateJSON['qa-empty-state']}</span>
							{isLightBest ? <EmptyChat /> : <EmptyChatLight /> }
						</div>
					}
				</div>
				
				
				<div ref={dialogActionRef} className='meetroom-chat-panel__dialog-action'>
					<div className='meetroom-chat-panel__dialog-action__top'>
						{showChatIcon && <IconSvg svg={ChatSvg} classAdd={`chat ${iconSelectInChatSidePanel === ChatIconType.CHAT ? 'selected' : ''}`} 
							onMouseDown={()=>{setIconSelectInChatSidePanel(ChatIconType.CHAT)}}/>}
						{showPollIcon && <IconSvg svg={PollsSvg} classAdd={`poll ${iconSelectInChatSidePanel === ChatIconType.POLL ? 'selected' : ''}`} 
							onMouseDown={()=>{setIconSelectInChatSidePanel(ChatIconType.POLL)}}/>}
						{showQuestionIcon && <IconSvg svg={QASvg} classAdd={`qa ${iconSelectInChatSidePanel === ChatIconType.QA ? 'selected' : ''}`} 
							onMouseDown={()=>{setIconSelectInChatSidePanel(ChatIconType.QA)}}/>}
					</div>
					<div className='meetroom-chat-panel__dialog-action__bottom'>
						{iconSelectInChatSidePanel === ChatIconType.CHAT && 
							<>
								<AutoResizeTextarea
									ref={chatMessageRef}
									placeholder={enableChatInput ? translateJSON['chat-message-placeholder'] : translateJSON['permissions-chat-desc']}
									value={msgE}
									disabled={!enableChatInput}
									onChange={newValue => setMsgE(newValue)}
									onFocus={() => props.setInputFocus(true)}
									onBlur={() => props.setInputFocus(false)}
									onKeyDown={onKeyDown}
									afterTag={<IconSvg
										svg={AddSmileySvg}
										onMouseDown={handleEmojiModale}
										classAdd={`${showEmojiModal ? 'selected' : ''}`} />}
								/>
								
								{showEmojiModal && <EmojiContainer onClickEmoji={handleEmojiClick} />}
								
								<button
									className={'btn-square'}
									onClick={handleSendMessage}
									disabled={!enableSendBtn}
									data-size={'large'}>
									<IconSvg svg={SendArrowSvg} />
								</button>
							</>}
						{iconSelectInChatSidePanel === ChatIconType.POLL &&
							<CreatePoll
								pollToCreated={pollToCreated} 
								setPollToCreated={setPollToCreated}
								setInputFocus={setInputFocus} 
								setIconSelectInChatSidePanel={setIconSelectInChatSidePanel}
								showPollConfirmation={showPollConfirmation} 
								setShowPollConfirmartion={setShowPollConfirmartion}
							/>}
						{iconSelectInChatSidePanel === ChatIconType.QA && <>
							<AutoResizeTextarea 
								maxLength={255}
								defaultHeight={42}
								placeholder={!questionToAnswer ? `${translateJSON['placeholder-question'] || 'Type your question...'}` : `${translateJSON['qa-answer'] || 'Answer'}`}  
								value={questionOrAnswer} 
								onChange={newValue => setQuestionOrAnswer(newValue)} 
								onFocus={() => setInputFocus(true)}
								onBlur={() => setInputFocus(false)} 
								flexDirection={'column'}
								beforeTag={!!questionToAnswer && 
									<div className='answer-a-question'>
										<div className='answer-a-question__top'>
											<div className='answer-a-question__top__left'>
												<IconSvg svg={AnswerSvg} />
												<span type='question-asked-by'>{questionToAnswer?.senderUserName}</span>
											</div>
											<div className='answer-a-question__top__right'>
												<IconSvg svg={CrossSvg} onMouseDown={()=>{
													setQuestionToAnswer(null)}}/>
											</div>
										</div>
										<span type='question-content' title={questionToAnswer?.question}>{questionToAnswer?.question}</span>
									</div>}
								/>
							<button
								className={'btn-square'}
								onClick={()=>{

									if (questionOrAnswer?.trim() === '') return;
									if(!!questionToAnswer){
										handleAnswerQuestion(questionToAnswer.questionUUID)
									}else{
										handlePostQuestion();
									}
									
								}}
								data-size={'large'}
								disabled={!questionOrAnswer?.trim()}>
								<IconSvg svg={SendArrowSvg} />
							</button>
						</>}
					</div>
				</div>
			</div>
		</SidePanel>
	);
};
export default ChatPanel;
