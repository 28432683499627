import dayjs from 'dayjs';
import * as Auth from './auth';
import { v4 as uuidv4 } from 'uuid';
let currentRecordUUID = null;

export const NonAuthenticated = 'User not authenticated'
export const EventCreatedFailed = 'Event Create Failed';
export const EventUpdatedFailed = 'Event Update Failed';
export const NonAdmin = 'User not admin';
export const EventNotFoundError = 'Event Not Found';
export const EventLeaveError = 'Event Leave Failed';
export const AllowAccessRequestError = 'Allow Access Request Failed';
export const ParticipantNonFoundError = 'Participant Not Found';
export const ThemeCreatedFailed = 'Theme Create Failed';
export const ThemeNotFoundError = 'Theme Not Found';
export const ThemeTitleError = 'Theme Title Error';
export const EventUUIDOrEventToolUUIDInvalid = 'EventUUID Or EventToolUUID Invalid';
export const EventUUIDOrBodyInvalid = 'EventUUID Or Body Invalid';
const API_RESPONSE_STATUS = {
	FETCH_SUCCESS: 200,
	UPDATED_SUCCESS: 200,
	CREATED_SUCCESS: 201,
	POST_SUCCESS: 201,
	REQUEST_SEND_SUCCESS: 202,
	NO_CONTENT: 204,
	INVALID_BODY: 400,
	TOKEN_UNAUTHENTICATED: 401,
	INVALID_PASSWORD: 403,
	FORBIDDEN: 403,
	NOT_FOUND: 404,
	ADMIN_NOT_FOUND: 404,
	INTERNAL_SERVER_ERROR: 500,
}
const RetryCount = 3;

const NonAuthPath = '/anonymous';

const CommonHeader = {
	Accept: 'application/json',
	'Content-Type': 'application/json',
}

const getAutorisationHeader = (teamID, userID) =>{
	if( !!teamID && !!userID){
		return {
			Authorization: 'Bearer ' + localStorage.getItem('id_token')
		};
	}
	return {}
}
const getAPIPath = ({teamID, userID, customPath})=>{
	const isLoggedIn = !!teamID && !!userID;
	const authPath = `/teams/${teamID}/users/${userID}/aud/meet`;
	const path = `${process.env.REACT_APP_API_PATH_PREFIX}${localStorage.getItem('region') ?? 'eu'}${process.env.REACT_APP_API_PATH_POST}`;
	const apiPath = `${path}/meet/v2${isLoggedIn ? authPath : NonAuthPath}/${customPath}`;
	return apiPath;
}
export const postEventToken = async ({
	retryCount = RetryCount,
	eventUUID,
	participantUUID,
	userName,
	teamID,
	userID
}) => {
	if (retryCount < 0) {
		throw new Error('Max recursion attempts reached for event token');
	}
	if(!eventUUID){
		throw new Error(EventNotFoundError)
	}
	
	const data = {
		name: userName,
		//password not needed
		password: '',
		participant_uuid: participantUUID
	};

	const apiPath = getAPIPath({teamID, userID, customPath: `events/${eventUUID}`})
	
	const response = await fetch(apiPath, {
		method: 'post',
		body: JSON.stringify(data),
		headers: {
			...CommonHeader,
			...getAutorisationHeader(teamID, userID)
		}
	});
	if (response.status === API_RESPONSE_STATUS.UPDATED_SUCCESS) {
		const json = await response?.json();
		if (json.is_admin === true) {
			sessionStorage.setItem('tklk', json.token)
		}
		return json;
	} else if (response.status === API_RESPONSE_STATUS.REQUEST_SEND_SUCCESS) {
		return 'Waiting';
	} else if( response.status === API_RESPONSE_STATUS.ADMIN_NOT_FOUND){
		const rs = await  response?.json();
		throw new Error(rs?.errors?.[0]?.description ?? 'Admin Not Found' ) 
	}
	else if (response.status === API_RESPONSE_STATUS.TOKEN_UNAUTHENTICATED) {
		await Auth.refresh_oauth_tokens(false);
		const postEventTokenRecursive = await postEventToken({ retryCount: retryCount - 1, eventUUID, userName, teamID, userID });
		return postEventTokenRecursive;
	} else if (response.status === API_RESPONSE_STATUS.INVALID_PASSWORD) {
		throw new Error('Invalid Password')
	}
	throw new Error(EventNotFoundError)
};
	
export const getEventData = async ({
	retryCount = RetryCount,
	eventUUID,
	teamID,
	userID
}) => {
	if (!eventUUID) {
		throw new Error(EventNotFoundError)
	}
	if (retryCount < 0) {
		throw new Error('Max recursion attempts reached for event token');
	}
	
	const apiPath = getAPIPath({teamID, userID, customPath:`events/${eventUUID}`})

	const response = await fetch(apiPath, {
		method: 'get',
		headers: {
			...CommonHeader,
			...getAutorisationHeader(teamID, userID)
		}
	});
	if (response.status === API_RESPONSE_STATUS.FETCH_SUCCESS) {
		const json = await response.json();
		return json;
	} else if (response.status === API_RESPONSE_STATUS.TOKEN_UNAUTHENTICATED) {
		await Auth.refresh_oauth_tokens(false);
		const getEventDataRecursive = await getEventData({ retryCount: retryCount - 1, eventUUID, teamID, userID });
		return getEventDataRecursive;
	}
	throw new Error(EventNotFoundError)
};

export const getEventParticipantsData = async ({
	retryCount = RetryCount,
	eventUUID,
	teamID,
	userID
}) => {
	if (retryCount < 0) {
		throw new Error('Max recursion attempts reached for event token');
	}
	
	const apiPath = getAPIPath({teamID, userID, customPath:`events/${eventUUID}/participants`})
	
	const response = await fetch(apiPath, {
		method: 'get',
		headers: {
			...CommonHeader,
			...getAutorisationHeader(teamID, userID)
		}
	});
	if (response.status === API_RESPONSE_STATUS.FETCH_SUCCESS) {
		const json = await response.json();
		return json;
	} else if (response.status === API_RESPONSE_STATUS.NO_CONTENT) {
		return [];
	} else if (response.status === API_RESPONSE_STATUS.TOKEN_UNAUTHENTICATED) {
		await Auth.refresh_oauth_tokens(false);
		const getEventDataRecursive = await getEventData({ retryCount: retryCount - 1, eventUUID, teamID, userID });
		return getEventDataRecursive;
	}
	throw new Error(EventNotFoundError)
};

export const getEventToolsData = async ({
	teamID,
	userID
}) => {
	
	const apiPath = getAPIPath({teamID, userID, customPath:`tools`})
	const response = await fetch(apiPath, {
		method: 'get',
		headers: {
			...CommonHeader,
			Authorization: 'Bearer ' + localStorage.getItem('id_token')
		}
	});
	if (response.status === API_RESPONSE_STATUS.FETCH_SUCCESS) {
		const json = await response.json();
		return json;
	}
	throw new Error(EventNotFoundError)
};

export const checkAttended = async ({ retryCount = RetryCount, eventUUID, teamID, userID }) =>{
	if (retryCount < 0) {
		throw new Error('Max recursion attempts reached for event token');
	}
	const isLoggedIn = !!teamID && !!userID;
	if (!isLoggedIn) {
		throw new Error(NonAuthenticated);
	}
	
	const apiPath = getAPIPath({ teamID, userID, customPath:`events/${eventUUID}/attended`})
	const response = await fetch(apiPath, {
		method: 'get',
		headers:{
			...CommonHeader,
			Authorization: 'Bearer ' + localStorage.getItem('id_token')
		}
	})

	const res = await response?.json();
	return res?.has_previously_attended_event ?? false;
}

export const createEvent = async ({ retryCount = RetryCount, teamID, userID }) => {
	if (retryCount < 0) {
		throw new Error('Max recursion attempts reached for event token');
	}
	const userData = {
		title: "title",
		description: "description"
	};
	
	const apiPath = getAPIPath({ teamID, userID, customPath:'events'})
	
	const headers = {
		...CommonHeader,
		...getAutorisationHeader(teamID, userID)
	}
	
	const response = await fetch(apiPath, {
		method: 'post',
		body: JSON.stringify(userData),
		headers
	})
	if (response.status === API_RESPONSE_STATUS.CREATED_SUCCESS) {
		const data = await response.json();
		const eventUUID = data?.event_uuid;
		const info = await getEventData({eventUUID, teamID, userID});
		let configuration = info?.configuration;
		const newEventTools = info?.event_tools;
		if(!!teamID && !!userID){
			//need update event configuration
			const responseInstantMeeting = await getInstantMeeting({teamID, userID});
			configuration = responseInstantMeeting?.configuration;
			const event_tools = responseInstantMeeting?.event_tools ?? [];
			const hasToolsActive = event_tools?.filter(item=>item.is_active);

			//update event_tools
			if(hasToolsActive){
				const differences = newEventTools.filter(itemA => {
					const matchingItemB = event_tools.find(itemB => itemB.name === itemA.name);
					return matchingItemB && itemA.is_active !== matchingItemB.is_active;
				});
				
				if(differences?.length){
					const promises = differences.map(async(item)=> await toggleEventTool({eventUUID, teamID, userID, eventToolUUID: item?.event_tool_uuid}))
					await Promise.all(promises);
				}
			}
			let themeUUIDInstantMeeting = responseInstantMeeting?.theme_uuid;
			if(!themeUUIDInstantMeeting){
				const postThemePath = getAPIPath({ teamID, userID, customPath:`themes`})
				delete configuration?.appearance?.appearance_uuid;
				delete configuration?.settings?.settings_uuid;
				delete configuration?.live_settings?.live_settings_uuid;
				const themeBody = { configuration, title: 'MyIncredibleTheme', event_tools };
		
				const postThemeResponse = await fetch(postThemePath, {
					method: 'POST',
					body: JSON.stringify(themeBody),
					headers
				});
				if(postThemeResponse.status === API_RESPONSE_STATUS.CREATED_SUCCESS){
					const postThemeResponseJSON = await postThemeResponse?.json();
					themeUUIDInstantMeeting = postThemeResponseJSON?.theme_uuid;
				}
			}
			const patchEventPath = getAPIPath({ teamID, userID, customPath:`events/${eventUUID}`})
			const patchEventResponse = await fetch(patchEventPath, {
				method: 'PATCH',
				body: JSON.stringify({theme_uuid: themeUUIDInstantMeeting, title: "theme-title"}),
				headers
			})
			if (patchEventResponse.status === API_RESPONSE_STATUS.UPDATED_SUCCESS) {
				return {eventUUID, configuration};
			}
		}
		return {eventUUID, configuration};

	} else if (response.status === API_RESPONSE_STATUS.TOKEN_UNAUTHENTICATED) {
		await Auth.refresh_oauth_tokens(false);
		const createEventTokenRecursive = await createEvent({ retryCount: retryCount - 1, teamID, userID });
		return createEventTokenRecursive;
	}
	throw new Error(EventCreatedFailed);
};

export const updateEvent = async ({ retryCount = RetryCount, teamID, userID, eventUUID, title, description, enterMode, liveSettings, password, enableWaitingRoom }) => {
	if (retryCount < 0) {
		throw new Error('Max recursion attempts reached for event token');
	}
	
	const updateData = { };
	updateData.title = title ?? "title";
	updateData.description = description ?? "description";

	if (enterMode || liveSettings || password || enableWaitingRoom) {
		updateData.settings = {};
		if (enterMode) {
			updateData.settings.mode = enterMode;
		}
		if(liveSettings){
			delete liveSettings?.live_settings_uuid;
			updateData.live_settings = liveSettings;
		}
		if (password) {
			updateData.settings.password = password;
		}
		if (enableWaitingRoom) {
			updateData.settings.enable_waiting_room = enableWaitingRoom;
		}
	}
	const apiPath = getAPIPath({ teamID, userID, customPath:`events/${eventUUID}`})
	const response = await fetch(apiPath, {
		method: 'PATCH',
		body: JSON.stringify(updateData),
		headers: {
			...CommonHeader,
			Authorization: 'Bearer ' + localStorage.getItem('id_token')
		}
	})
	if (response.status === API_RESPONSE_STATUS.UPDATED_SUCCESS) {
		return { enterMode, liveSettings };
	} else if (response.status === API_RESPONSE_STATUS.TOKEN_UNAUTHENTICATED) {
		await Auth.refresh_oauth_tokens(false);
		const updateEventTokenRecursive = await updateEvent({ retryCount: retryCount - 1, teamID, userID, eventUUID, title, description, enterMode, password, enableWaitingRoom });
		return updateEventTokenRecursive;
	} else if (response.status === API_RESPONSE_STATUS.FORBIDDEN) {
		throw new Error(NonAdmin);
	}
	
	throw new Error(EventUpdatedFailed);
};

export const createTheme = async ({ retryCount = RetryCount, title = "My empty theme", teamID, userID }) => {
	if (retryCount < 0) {
		throw new Error('Max recursion attempts reached for event token');
	}

	const isLoggedIn = !!teamID && !!userID;
	if (!isLoggedIn) {
		throw new Error(NonAuthenticated);
	}
	
	const themeData = { title };
	const apiPath = getAPIPath({ teamID, userID, customPath:`themes`})
	const response = await fetch(apiPath, {
		method: 'post',
		body: JSON.stringify(themeData),
		headers: {
			...CommonHeader,
			Authorization: 'Bearer ' + localStorage.getItem('id_token')
		}
	})
	if (response.status === API_RESPONSE_STATUS.CREATED_SUCCESS) {
		const data = await response.json();
		const themeUUID = data?.theme_uuid;
		if (themeUUID) {
			return themeUUID;
		}
	} else if (response.status === API_RESPONSE_STATUS.INVALID_BODY) {
		throw new Error(ThemeTitleError);
	}
	else if (response.status === API_RESPONSE_STATUS.TOKEN_UNAUTHENTICATED) {
		await Auth.refresh_oauth_tokens(false);
		const createThemeTokenRecursive = await createTheme({ retryCount: retryCount - 1, title, teamID, userID });
		return createThemeTokenRecursive;
	}
	throw new Error(ThemeCreatedFailed);
};

export const getInstantMeeting = async ({ retryCount = RetryCount, teamID, userID }) => {
	if (retryCount < 0) {
		throw new Error('Max recursion attempts reached for event token');
	}
	
	const isLoggedIn = !!teamID && !!userID;
	if (!isLoggedIn) {
		throw new Error(NonAuthenticated);
	}
	const apiPath = getAPIPath({ teamID, userID, customPath:`events`})
	const response = await fetch(apiPath, {
		method: 'get',
		headers: {
			...CommonHeader,
			Authorization: 'Bearer ' + localStorage.getItem('id_token')
		}
	});
	if (response.status === API_RESPONSE_STATUS.FETCH_SUCCESS) {
		const json = await response.json();
		return json;
	} else if (response.status === API_RESPONSE_STATUS.TOKEN_UNAUTHENTICATED) {
		await Auth.refresh_oauth_tokens(false);
		const getInstantMeetingRecursive = await getInstantMeeting({ retryCount: retryCount - 1, teamID, userID });
		return getInstantMeetingRecursive;
	}
	throw new Error(EventNotFoundError);
};

export const getThemesList = async ({ retryCount = RetryCount, teamID, userID }) => {
	if (retryCount < 0) {
		throw new Error('Max recursion attempts reached for event token');
	}
	
	const isLoggedIn = !!teamID && !!userID;
	if (!isLoggedIn) {
		throw new Error(NonAuthenticated);
	}
	const apiPath = getAPIPath({ teamID, userID, customPath:`themes/defaults`})
	const response = await fetch(apiPath, {
		method: 'get',
		headers: {
			...CommonHeader,
			Authorization: 'Bearer ' + localStorage.getItem('id_token')
		}
	});
	if (response.status === API_RESPONSE_STATUS.FETCH_SUCCESS) {
		const json = await response.json();
		return json;
	} else if (response.status === API_RESPONSE_STATUS.TOKEN_UNAUTHENTICATED) {
		await Auth.refresh_oauth_tokens(false);
		const getThemesListRecursive = await getThemesList({ retryCount: retryCount - 1, teamID, userID });
		return getThemesListRecursive;
	}
	throw new Error(ThemeNotFoundError);
};

export const fetchThemeData = async ({retryCount = RetryCount, themeUUID, teamID, userID }) => {

	if (retryCount < 0) {
		throw new Error('Max recursion attempts reached for event token');
	}

	const isLoggedIn = !!teamID && !!userID;
	if (!isLoggedIn) {
		throw new Error(NonAuthenticated);
	}
	const apiPath = getAPIPath({ teamID, userID, customPath:`themes/${themeUUID}`})
	const response = await fetch(apiPath, {
		method: 'get',
		headers: {
			...CommonHeader,
			Authorization: 'Bearer ' + localStorage.getItem('id_token')
		}
	});
	if (response.status === API_RESPONSE_STATUS.FETCH_SUCCESS) {
		const json = await response.json();
		return json;
	}else if (response.status === API_RESPONSE_STATUS.TOKEN_UNAUTHENTICATED) {
		await Auth.refresh_oauth_tokens(false);
		const fetchThemeDataRecursive = await fetchThemeData({ retryCount: retryCount - 1, themeUUID, teamID, userID });
        return fetchThemeDataRecursive;
	}
	throw new Error(ThemeNotFoundError);
};

export const toggleEventTool = async ({eventUUID, teamID, userID, eventToolUUID})=>{

	const isLoggedIn = !!teamID && !!userID;
	if (!isLoggedIn) {
		throw new Error(NonAuthenticated);
	}
	
	const apiPath = getAPIPath({ teamID, userID, customPath:`events/${eventUUID}/tools/${eventToolUUID}/toggle`})
	const response = await fetch(apiPath, {
		method: 'PATCH',
		headers: {
			...CommonHeader,
			Authorization: 'Bearer ' + localStorage.getItem('id_token')
		}
	});
	if (response.status === API_RESPONSE_STATUS.UPDATED_SUCCESS) {
		return true;
	}
	throw new Error(EventUUIDOrEventToolUUIDInvalid);
}

export const updateEventData = async ({ retryCount = RetryCount, eventUUID, teamID, userID, themeToUpdate, logo, backgroundImage, eventToolsToUpdate = []}) => {
    
	if (retryCount < 0) {
		throw new Error('Max recursion attempts reached for event token');
	}

	const isLoggedIn = !!teamID && !!userID;
	if (!isLoggedIn) {
		throw new Error(NonAuthenticated);
	}
	
	let newTheme = { ...themeToUpdate};
	const headers = {
		...CommonHeader,
		Authorization: 'Bearer ' + localStorage.getItem('id_token')
	}
	const fetchOptions = {
		method: 'get',
		headers
	};
		
	if (logo?.name) {
		const cdnPath = `https://cdn.ringover.com/v2/private/upload/teams/${teamID}/users/${userID}/types/img/${logo.name}`;
		var data = new FormData()
		data.append('file', logo)
		const response = await fetch(cdnPath, {
			method: 'POST',
			body: data,
			headers: {Authorization: 'Bearer ' + localStorage.getItem('id_token')}
		});

		if (response.status === 201) {
			const path = `https://cdn.ringover.com/v2/public/shorturl/types/img/${logo.name}`;
			const shortUrlResponse = await fetch(path, fetchOptions);
			const url = await shortUrlResponse.json();
			newTheme.configuration.appearance.logo = `https://cdn.ringover.com/v2/shorturl/${url}`;
		}
	}

	if (backgroundImage?.name) {
		const cdnPath = `https://cdn.ringover.com/v2/private/upload/teams/${teamID}/users/${userID}/types/img/${backgroundImage.name}`;
		var data = new FormData()
		data.append('file', backgroundImage)
		const response = await fetch(cdnPath, {
			method: 'POST',
			body: data,
			headers: {Authorization: 'Bearer ' + localStorage.getItem('id_token')}
		});
		
		if (response.status === 201) {
			const path = `https://cdn.ringover.com/v2/public/shorturl/types/img/${backgroundImage.name}`;
			const shortUrlResponse = await fetch(path, fetchOptions);
			const url = await shortUrlResponse.json();
			newTheme.configuration.appearance.background_image = `https://cdn.ringover.com/v2/shorturl/${url}`;
		}
	}

	//update event_tools
	if(eventToolsToUpdate?.length){
		const promises = eventToolsToUpdate.map(async(item)=>{
			const data = await toggleEventTool({eventUUID, teamID, userID, eventToolUUID: item});
			return data
		})
		await Promise.all(promises);
	}
	delete newTheme?.configuration?.appearance?.appearance_uuid;
	delete newTheme?.configuration?.settings?.settings_uuid;
	delete newTheme?.configuration?.live_settings?.live_settings_uuid;
	const themeBody = { configuration: {...newTheme?.configuration}, title: 'MyIncredibleTheme' };
	
	const postThemeApiPath = getAPIPath({ teamID, userID, customPath:`themes`})
	const postThemeResponse = await fetch(postThemeApiPath, {
		method: 'POST',
		body: JSON.stringify(themeBody),
		headers
	});

	if(postThemeResponse.status === API_RESPONSE_STATUS.CREATED_SUCCESS){
		const postThemeResponseJSON = await postThemeResponse?.json();
		const themeUUID = postThemeResponseJSON?.theme_uuid;
		const eventBody = {
			title: themeToUpdate.title,
			description: themeToUpdate.description,
			theme_uuid: themeUUID
		}
		const patchEventApiPath = getAPIPath({ teamID, userID, customPath:`events/${eventUUID}`})
		const patchEventResponse = await fetch(patchEventApiPath, {
			method: 'PATCH',
			body: JSON.stringify(eventBody),
			headers
		});
		if (patchEventResponse.status === 304) {
			return 304; 
		} else if (patchEventResponse.status === API_RESPONSE_STATUS.FETCH_SUCCESS) {
			return 200
		} else if (patchEventResponse.status === 400) {
			throw new Error(400)
		} else if (patchEventResponse.status === API_RESPONSE_STATUS.TOKEN_UNAUTHENTICATED) {
			await Auth.refresh_oauth_tokens(false);
			const updateEventDataRecursive = await updateEventData({ retryCount: retryCount - 1, eventUUID, teamID, userID, themeToUpdate, logo, backgroundImage });
			return updateEventDataRecursive;
		}else if (patchEventResponse.status === API_RESPONSE_STATUS.FORBIDDEN) {
			throw new Error(403)
		}
	}else if(postThemeResponse.status === API_RESPONSE_STATUS.INVALID_BODY){
		const rs = await postThemeResponse?.json();
		const filed = rs?.errors?.[0]?.field_label;
		throw new Error(filed) 
		
    }else{
		throw new Error(ThemeNotFoundError);
	}
};
	
export const createPoll = async ({ retryCount = RetryCount, eventUUID, poll, teamID, userID})=>{
	if (retryCount < 0) {
		throw new Error('Max recursion attempts reached for event token');
	}

	const isLoggedIn = !!teamID && !!userID;
	if (!isLoggedIn) {
		throw new Error(NonAuthenticated);
	}
	
	if (!eventUUID || !poll) {
		throw new Error('create poll failed');
	}
	
	const endedAt = poll?.addTimer ? {end_date : dayjs().add(poll?.duration, 'minute').toISOString()} : {};
	
	const pollToCreated = {
		question: poll?.question,
		poll_option: poll?.options,
		show_results: poll?.showResults,
		...endedAt
	}
	const apiPath = getAPIPath({ teamID, userID, customPath:`events/${eventUUID}/polls`})
	const response = await fetch(apiPath, {
		method: 'POST',
		body: JSON.stringify(pollToCreated),
		headers: {
			...CommonHeader,
			Authorization: 'Bearer ' + localStorage.getItem('id_token')
		}
	});
	if (response.status === API_RESPONSE_STATUS.CREATED_SUCCESS) {
		const res = await response.json();
		return res;
	}
	throw new Error(EventUUIDOrBodyInvalid);
}


export const fetchPollData = async ({ retryCount = RetryCount, eventUUID, pollUUID, teamID, userID})=>{
	if (retryCount < 0) {
		throw new Error('Max recursion attempts reached for event token');
	}

	if (!eventUUID || !pollUUID) {
		throw new Error('close poll failed');
	}
	
	const apiPath = getAPIPath({ teamID, userID, customPath:`events/${eventUUID}/polls/${pollUUID}`})
	const response = await fetch(apiPath, {
		method: 'GET',
		headers: {
			...CommonHeader,
			...getAutorisationHeader(teamID, userID)
		}
	});
	if (response.status === API_RESPONSE_STATUS.FETCH_SUCCESS) {
		const json = await response.json();
		return json;
	}
	throw new Error(EventUUIDOrBodyInvalid);
}

export const votePoll = async ({ retryCount = RetryCount, eventUUID, pollUUID, pollOptionUUID, participantUUID, teamID, userID})=>{
	if (retryCount < 0) {
		throw new Error('Max recursion attempts reached for event token');
	}
	
	if (!eventUUID || !pollUUID || !pollOptionUUID || !participantUUID) {
		throw new Error('vote poll failed');
	}
	const vote = {
		participant_uuid: participantUUID,
		poll_option_uuid: pollOptionUUID
	}
	const apiPath = getAPIPath({ teamID, userID, customPath:`events/${eventUUID}/polls/${pollUUID}`})
	const response = await fetch(apiPath, {
		method: 'POST',
		body: JSON.stringify(vote),
		headers: {
			...CommonHeader,
			...getAutorisationHeader(teamID, userID)
		}
	});
	if (response.status === API_RESPONSE_STATUS.POST_SUCCESS) {
		const json = await response.json();
		return json;
	}
	throw new Error(EventUUIDOrBodyInvalid);
}

export const closePoll = async ({ retryCount = RetryCount, eventUUID, pollUUID, teamID, userID})=>{
	if (retryCount < 0) {
		throw new Error('Max recursion attempts reached for event token');
	}

	const isLoggedIn = !!teamID && !!userID;
	if (!isLoggedIn) {
		throw new Error(NonAuthenticated);
	}
	
	if (!eventUUID || !pollUUID) {
		throw new Error('close poll failed');
	}
	
	const apiPath = getAPIPath({ teamID, userID, customPath:`events/${eventUUID}/polls/${pollUUID}`})
	const response = await fetch(apiPath, {
		method: 'PATCH',
		headers: {
			...CommonHeader,
			...getAutorisationHeader(teamID, userID)
		}
	});
	if (response.status === API_RESPONSE_STATUS.UPDATED_SUCCESS) {
		return true
	}
	throw new Error(EventUUIDOrBodyInvalid);
}


export const createQA = async ({ retryCount = RetryCount, eventUUID, question, participantUUID, teamID, userID})=>{
	if (retryCount < 0) {
		throw new Error('Max recursion attempts reached for event token');
	}
	
	if (!eventUUID || !question || !participantUUID) {
		throw new Error('create QA failed');
	}
	
	const qaToCreated = {
		question,
		asked_by: participantUUID
	}
	const apiPath = getAPIPath({ teamID, userID, customPath:`events/${eventUUID}/qna`})
	const response = await fetch(apiPath, {
		method: 'POST',
		body: JSON.stringify(qaToCreated),
		headers: {
			...CommonHeader,
			Authorization: 'Bearer ' + localStorage.getItem('id_token')
		}
	});
	if (response.status === API_RESPONSE_STATUS.CREATED_SUCCESS) {
		const res = await response.json();
		return res;
	}
	throw new Error(EventUUIDOrBodyInvalid);
}

export const fetchQuestionData = async ({ retryCount = RetryCount, eventUUID, questionUUID, teamID, userID})=>{
	if (retryCount < 0) {
		throw new Error('Max recursion attempts reached for event token');
	}

	if (!eventUUID || !questionUUID) {
		throw new Error('close poll failed');
	}
	
	const apiPath = getAPIPath({ teamID, userID, customPath:`events/${eventUUID}/qna/${questionUUID}`})
	const response = await fetch(apiPath, {
		method: 'GET',
		headers: {
			...CommonHeader,
			...getAutorisationHeader(teamID, userID)
		}
	});
	if (response.status === API_RESPONSE_STATUS.FETCH_SUCCESS) {
		const json = await response.json();
		return json;
	}
	throw new Error(EventUUIDOrBodyInvalid);
}

export const answerQA = async ({ retryCount = RetryCount, answer, participantUUID,eventUUID, questionUUID, teamID, userID})=>{
	if (retryCount < 0) {
		throw new Error('Max recursion attempts reached for event token');
	}
	
	if (!eventUUID || !questionUUID) {
		throw new Error('close QA failed');
	}
	
	const apiPath = getAPIPath({ teamID, userID, customPath:`events/${eventUUID}/qna/${questionUUID}`});

	const answerBody = {
		answer,
		answered_by: participantUUID
	}
	const response = await fetch(apiPath, {
		method: 'PATCH',
		body: JSON.stringify(answerBody),
		headers: {
			...CommonHeader,
			...getAutorisationHeader(teamID, userID)
		}
	});
	if (response.status === API_RESPONSE_STATUS.UPDATED_SUCCESS) {

		const res = await response.json();
		return res;
	}
	throw new Error(EventUUIDOrBodyInvalid);
}

export const recordEvent = async ({retryCount = RetryCount, isStart = false, eventUUID, participantUUID, teamID, userID }) => {
	if (retryCount < 0) {
		throw new Error('Max recursion attempts reached for event token');
	}
	if (!eventUUID || !participantUUID || !teamID || !userID) {
		throw new Error('record event failed empty ');
	}
	
	const apiPath = getAPIPath({ teamID, userID, customPath:`events/${eventUUID}/record`})
	const fetchOptions = {
		method: isStart ? 'post' : 'delete',
		headers: {
			...CommonHeader,
			Authorization: 'Bearer ' + localStorage.getItem('id_token')
		},
	};

	if (isStart) {
        currentRecordUUID = uuidv4(); 
    } else if (!currentRecordUUID) {
        throw new Error('No current record UUID found for stopping');
    }

	const userData = {
		token: sessionStorage.getItem('tklk'),
		record_uuid: currentRecordUUID
	};

	if (sessionStorage.getItem('tklk')) {
		fetchOptions.body = JSON.stringify(userData);
	}

	const response = await fetch(apiPath, fetchOptions);

	if (response.status === API_RESPONSE_STATUS.FETCH_SUCCESS) {
		if (!isStart) {
			currentRecordUUID = null;

			const res = await response.json();
			const userEmail = localStorage.getItem('user_email');
			const userLang = localStorage.getItem('lang') || 'en';

			if (userEmail) {
				const downloadUrl = res.download_url;
				const urlParts = downloadUrl.split('/'); 
				const recordId = urlParts[urlParts.length - 1]; 

				const shortUrlApiPath = `https://cdn.ringover.com/v2/public/shorturl/types/meet_records/${recordId}`;

				try {

					const fetchOptionsRecord = {
						method: 'get',
						headers: {
							...CommonHeader,
							Authorization: 'Bearer ' + localStorage.getItem('id_token')
						},
					};

					const shortUrlResponse = await fetch(shortUrlApiPath, fetchOptionsRecord); 
					let shortUrlText = await shortUrlResponse.text(); 
					shortUrlText = shortUrlText.replace(/"/g, ''); 
					const finalLink = `https://cdn.ringover.com/v2/shorturl/${shortUrlText}`;
					console.log(finalLink);
					let text = "";
					let lang = userLang === "fr" ? "fr" : "en";
					let subject = lang === "fr"
						? `Enregistrement de la réunion ${eventUUID}`
						: `Meeting Record ${eventUUID}`;

					if (lang === "fr") {
						text = `Bonjour,\n\nVoici le lien vers l'enregistrement de votre réunion : ${finalLink}\n\nCordialement,`;
					} else {
						text = `Hello,\n\nHere is the link to your meeting record: ${finalLink}\n\nSincerely,`;
					}

					sendMail(userEmail, subject, text, lang);
				} catch (error) {
					console.error('Erreur lors de la récupération de l\'URL courte:', error);
				}
			}
			return res;
		} else {
			return response;
		}
	} else if (response.status === API_RESPONSE_STATUS.TOKEN_UNAUTHENTICATED) {
		await Auth.refresh_oauth_tokens(false);
		return await recordEvent({ retryCount: retryCount - 1, isStart, eventUUID, participantUUID, teamID, userID });
	}
	throw new Error('record event failed: ' + response.status);
}



async function sendMail(email, subject, text, lang, sender = null) {
	if (!sender) {
		sender = "no-reply@ringover.com";
	}
	let url = `https://integrations.ringover.com/v2/emailing/freemessagefromadmin/email/${email}/lang/${lang}`;
	if (sender) url += `/from/${sender}`;
	try {
		const response = await fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: new URLSearchParams({
				message: text,
				subject: subject
			})
		});
		const result = await response.text();
		console.log(result);
	} catch (error) {
		console.error('Erreur lors de l\'envoi du mail:', error);
	}
}

export const allowOrDenyRequest = async ({ retryCount = RetryCount, isAllow = true, name, participantUUID, eventUUID, teamID, userID}) => {
	
	if (retryCount < 0) {
		throw new Error('Max recursion attempts reached for event token');
	}

	const userData = { name };
	const access = isAllow ? 'allow_access' : 'deny_access';
	const apiPath = getAPIPath({ teamID, userID, customPath:`events/${eventUUID}/${access}/participants/${participantUUID}`})
	const body = isAllow ? { body: JSON.stringify(userData) } : {};
	const response = await fetch(apiPath, {
		method: 'post',
		...body,
		headers: {
			...CommonHeader,
			...getAutorisationHeader(teamID, userID)
		}
	})
	if (response.status === 200) {
		return { name, participantUUID };
	} else if (response.status === API_RESPONSE_STATUS.TOKEN_UNAUTHENTICATED) {
		await Auth.refresh_oauth_tokens(false);
		const allowOrDenyRequestRecursive = await allowOrDenyRequest({ retryCount: retryCount - 1, isAllow, eventUUID, participantUUID, teamID, userID });
		return allowOrDenyRequestRecursive;
	} else if (response.status === API_RESPONSE_STATUS.NOT_FOUND) {
		throw new Error(EventNotFoundError)
	} else if(response.status === API_RESPONSE_STATUS.INTERNAL_SERVER_ERROR){
		//maybe current participant uuid left
		return null;
	}
	throw new Error(AllowAccessRequestError);
	
};

export async function allowOrDenyRequests({ isAllow = true, list, eventUUID, teamID, userID }) {
  try {
    const responses = await Promise.all(list.map(item => 
		allowOrDenyRequest({ isAllow, name: item.name, participantUUID: item.participantUUID, eventUUID, teamID, userID })));
    const results = await Promise.all(responses);
    return results;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}
