const colors = ['#FFCF4F', '#40E0CF', '#72BBF1', '#FF8888', '#E885F0', '#702963'];

//linear-violet : linear-gradient(249deg, #5F3C7A -4.77%, #3B3656 10.12%, #292832 56.08%, #212120 76.93%);
export const linearViolet = 'linear-gradient(248.89deg, #5f3c7a -4.77%, #3b3656 10.12%, #292832 56.08%, #212120 76.93%)';
export const lightLinearViolet = 'linear-gradient(245deg, #22303933 34.13%, #3a365533 72.24%)';
//white-violet : linear-gradient(249deg, #D49DFF -4.77%, #C4BBFF 10.12%, #E7E3FF 56.08%, #F9F9F9 76.93%);
export const whiteViolet = 'linear-gradient(248.89deg, #d49dff -4.77%, #c4bbff 10.12%, #e7e3ff 56.08%, #f9f9f9 76.93%)';
export const btn = 'linear-gradient(241deg, #a64fff 13.05%, #6370e7 81.16%)';
export const btn25 = 'linear-gradient(241deg, #a64fff40 13.05%, #6370e740 81.16%)';
export const darkViolet = '#242129';

export const meet500 = '#926AF6';
export const meetDark900 = '#17161B';
export const meetDark700 = '#201F24';
export const whiteOpacity8 = 'rgba(255, 255, 255, 0.08)';

export const blue = '#566edc';//566EDC
export const superLightBlue = '#566edc0d';//566EDC 5%

export const violet = '#C5ADFA'; //C5ADFA

export const white = '#FFFFFF';
export const lightWhite = '#ffffff80'; 
export const extraLightWhite = '#ffffff40'; 
export const veryLightWhite = '#ffffff26';  
export const superLightWhite = '#ffffff0d'; 
export const hoverLightWhite = 'linear-gradient(0deg, #ffffff05, #ffffff05)';

// export const dark = 'rgba(33, 33, 32, 1)'; //212120
// export const lightDark = 'rgba(33, 33, 32, 0.5)'; 
// export const lighterDark = 'rgba(33, 33, 32, 0.25)'; 
// export const superLighterDark = 'rgba(33, 33, 32, 0.05)';  
// export const superLighterDark15 = 'rgba(33, 33, 32, 0.15)';  

export const dark = '#212120';//black
export const semiTransparentDarkGray = '#21212080'; //lightDark
export const extraSemiTransparentDarkGray = '#21212040'; //lighterDark
export const veryExtraSemiTransparentDarkGray = '#21212026';  //superLighterDark15
export const superExtraSemiTransparentDarkGray = '#2121200d'; //superLighterDark
export const hoverExtraSemiTransparentDarkGray = 'linear-gradient(0deg, #21212014, #21212014)';


export const ThemeTemplateType = {
    DARK_PURPLE: 'dark-purple',
    LIGHT_PURPLE: 'light-purple',
    CLASSIC_MIXED: 'classic-mixed',
    CLASSIC_DARK: 'classic-dark',
    CLASSIC_LIGHT: 'classic-light',
    CUSTOMIZE: 'customize'
}

export const DefaultThemeColor = {
    [ThemeTemplateType.DARK_PURPLE]: {
        background_primary_color: linearViolet,
        background_secondary_color: darkViolet,
        primary_color: lightLinearViolet,
        secondary_color: btn,
        shadow: 'dark'
    },
    [ThemeTemplateType.LIGHT_PURPLE]: {
        background_primary_color: whiteViolet,
        background_secondary_color: white,
        primary_color: white,
        secondary_color: violet,
        shadow: 'light'
    },
    [ThemeTemplateType.CLASSIC_MIXED]: {
        background_primary_color: dark,
        background_secondary_color: white,
        primary_color: superLightWhite,
        secondary_color: blue,
        shadow: 'no'
    },
    [ThemeTemplateType.CLASSIC_DARK]: {
        background_primary_color: dark,
        background_secondary_color: dark,
        primary_color: superLightWhite,
        secondary_color: blue,
        shadow: 'no'
    },
    [ThemeTemplateType.CLASSIC_LIGHT]: {
        background_primary_color: white,
        background_secondary_color: white,
        primary_color: superLightBlue,
        secondary_color: blue,
        shadow: 'no'
    },
    [ThemeTemplateType.CUSTOMIZE]: {
        background_primary_color: null,
        background_secondary_color: null,
        primary_color: null,
        secondary_color: null,
        shadow: 'no'
    }
}

export default colors;