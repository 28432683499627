import React from 'react';
import useAuthContext from '../hooks/useContext';

const BtnRegular = (props) => {

    const { translateJSON } = useAuthContext();
    const spanText = (props.translate && translateJSON[props.translate]) ? `${translateJSON[props.translate]}` : props.content;
    return (
        <button disabled={props.disabled} className={props.className ? props.className : 'btn-regular'} onClick={props.event}>
            <span id={props.id}>{spanText}</span>
        </button>
    );
};

export default BtnRegular;