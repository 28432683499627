import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { ReactComponent as CrossSVG } from '../assets/icons/cross.svg';
import { ReactComponent as ErrorInfoSVG } from '../assets/icons/error-info.svg';
import useAuthContext from '../hooks/useContext';
import { v4 as uuidv4 } from 'uuid';

export const PortalModal = (props) => {
	return createPortal(
		<div className='portal-modal'>
			<div className='portal-modal-mask' />
			{props.children}
		</div>,
		document.getElementById('root')
	);
};

const Alert = () => {

  const { translateJSON } = useAuthContext();
  const [alerts, setAlerts] = useState([]);

  const dismiss = (id) => setAlerts(alerts.filter((alert) => alert.uid !== id))
  
  useEffect(() => {
    if (!translateJSON) return;
    if (!Object.keys(translateJSON).length) return;
    window.bus.subscribe("alert", (message) => {
      if (message) {
        setAlerts((prev) => [...prev, {
          message: message,
          uid: uuidv4()
        }]);
        return;
      } else {
        if (translateJSON?.['prejoin-error-tryagain']) {
          setAlerts((prev) => [...prev, {
            message: translateJSON?.['prejoin-error-tryagain'],
            uid: uuidv4()
          }]);
        }
      }
    });
  }, [translateJSON]);

  return (
    <div>
      {alerts &&
        alerts.map((alert) => (
          <PortalModal key={alert.uid}>
            <div className='error-modal-container'>
              <div className='error-modal-container__body'>
                <div className='error-modal-container__body__header'>
                  <div
                    className='error-modal-container__body__header__close'
                    onMouseDown={() => dismiss(alert.uid)}>
                    <CrossSVG />
                  </div>

                  <div className='error-modal-container__body__header__warning'>
                    <ErrorInfoSVG />
                  </div>
                </div>
                <span>{alert.message}</span>
              </div>

              <div className='error-modal-container__footer'>
                <button
                  className='btn-close'
                  onClick={() => dismiss(alert.uid)}>
                  <span>{translateJSON['btn-close']}</span>
                </button>
              </div>
            </div>
          </PortalModal>
        ))}
    </div>
  );
}

export default Alert;