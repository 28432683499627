import { useRef } from "react";

const useClicHook = (callback, doubleCallback) => {
    const clickRef = useRef({
        clickCount: 0,
        time: 0,
        timer: null
    });
    return (...args) => {
        clickRef.current.clickCount++;
        clickRef.current.time = Date.now();
        clickRef.current.timer = setTimeout(() => {
            if (Date.now() - clickRef.current.time <= 200 && clickRef.current.clickCount === 2) {
                doubleCallback && doubleCallback.apply(null, args);
            }
            if (clickRef.current.clickCount === 1) {
                callback && callback.apply(null, args);
            }
            clearTimeout(clickRef.current.timer);
            clickRef.current.clickCount = 0;
        }, 300);
    }
};
export default useClicHook;