import { configureStore } from '@reduxjs/toolkit';
import { meetReducer } from './meetSlice';
import { participantsReducer } from './participantsSlice';

const store = configureStore({
	reducer: {
		meet: meetReducer,
		participants: participantsReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
	devTools: true,
});

export default store