import React, { useRef, useEffect, useState, useMemo } from 'react';

import WheelSvg from '../../assets/icons/wheel-params.svg';
import SquareBtn from '../SquareBtn';

import ThreeStarsSvg from '../../assets/icons/three-stars.svg';

import BtnIcon from '../BtnIcon';
import { PreviewSettingOption } from './PreviewSettingsModal';
import useMediaQuery from '../../hooks/useMediaQuery';
import { SMALL_SCREEN } from '../../utils/breackpoints';
import MicroCameraControls from '../SharedComponents/MicroCameraControls';
import isVisuelEffectSupported from '../../utils/checkPipelineSupport';
import useAuthContext, {
	useAudioVideoContext,
	useGeneralContext,
} from '../../hooks/useContext';
import { VisualEffectType } from '../SharedComponents/VisualEffectConfig';
import { ReactComponent as RegularVolumeSVG } from '../../assets/icons/micro-in-screen.svg';
import { ReactComponent as MicroOffSvgOnLayout } from '../../assets/icons/cut-micro-in-screen.svg';
import { ReactComponent as NetworkStatusDangerSvg } from '../../assets/icons/network-status-danger.svg';
import { ReactComponent as NetworkStatusWarningSvg } from '../../assets/icons/network-status-warning.svg';
import { ReactComponent as NetworkStatusStableSvg } from '../../assets/icons/network-status-stable.svg';
import { ReactComponent as SpeakingSvg } from '../../assets/icons/medium-volume.svg';
import { NetworkStatus } from '../../dataModal/ParticipantModal';
import CameraFailedMessage from '../SharedComponents/CameraFailedMessage';
import { BackgroundBlur, VirtualBackground } from '@livekit/track-processors';
import { LocalVideoTrack, createLocalAudioTrack, createLocalVideoTrack, facingModeFromLocalTrack } from 'livekit-client';
import { PermissionStatus } from '../../context/AudioVideoContextProvider';
import { getInitialFromString } from '../../utils/getInitialFromString';
import { handleCameraError, handleMicroError } from '../../utils/errorHandler';

const PreviewRightContainer = ({
  setShowPermissionModal,
  showPreviewSettingModal,
  setSelectedSettingsOption,
}) => {

  const isPipelineSupported = isVisuelEffectSupported();
  const [screenDimensions, setScreenDimensions] = useState();
  const [containerDimensions, setContainerDimensions] = useState();
  const containerDimensionsRef = useRef();
  const isPhoneScreen = useMediaQuery(SMALL_SCREEN);


  const [videoStyle, setVideoStyle] = useState();

  const [audioVideoConfigStyle, setAudioVideoConfigStyle] = useState({});

  const {
    audioEnabled,
    videoEnabled,
    microPermission,
		setMicroPermission,
		cameraPermission,
		setCameraPermission,
    setForceRequestPermission,
    isActiveNoiseCancellation,
    isActiveEchoCancellation
  } = useAudioVideoContext();

  const cameraPermissionRef = useRef(cameraPermission);
  const microPermissionRef = useRef(microPermission);
  let elRef = useRef();
  const actionFrameRef = useRef();
  const audioVideoConfigRef = useRef();

  const videoEnabledRef = useRef(videoEnabled);
  const {userAvatarPath, userAvatar, userName } = useAuthContext();
  const audioEnabledRef = useRef(audioEnabled);

  const { featureVisualEffect, networkStatus, currentParticipantColor } = useGeneralContext();
  const featureVisualEffectRef = useRef(featureVisualEffect);
  const containerRef = useRef();
  const videoRef = useRef(null);

  const requestId = useRef();
  const microphoneInputMediaStreamRef = useRef();
  const [hasVoiceInput, setHasVoiceInput] = useState(false);

  const [isHover, setHover] = useState(false);
  const [classnameAdded, setClassnameAdded] = useState();
  const [loadVideoMetadata, setLoadVideoMetadata] = useState(false);
  const [loadVideoWithVisualEffect, setLoadVideoWithVisualEffect] = useState(featureVisualEffect.type === VisualEffectType.NONE);
  const [cameraError, setCameraError]= useState();
  const videoEl = useRef(null);

  const [videoTrack, setVideoTrack] = useState();
  const [audioTrack, setAudioTrack] = useState();

  const handleClickOnSettings = () => {
    showPreviewSettingModal();
    setSelectedSettingsOption(PreviewSettingOption.VIDEO);
  };

  const microCameraControlsProps = {
    cameraPermission,
    setCameraPermission,
    microPermission,
    setMicroPermission,
    setShowPermissionModal,
  };

  const handleClickOnVisualEffect = () => {
    showPreviewSettingModal();
    setSelectedSettingsOption(PreviewSettingOption.VISUALEFFECT);
  };

  const handleResize = () => {
    setScreenDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
    containerDimensionsRef.current = {
      width: elRef.current.clientWidth,
      height: elRef.current.clientHeight,
    };
    setContainerDimensions(containerDimensionsRef.current);
  };

  useEffect(()=>{
    cameraPermissionRef.current = cameraPermission;
  },[cameraPermission]);

  useEffect(()=>{
    microPermissionRef.current = microPermission;
  },[microPermission]);
  

  const facingMode = useMemo(() => {
    if (videoTrack) {
        const { facingMode } = facingModeFromLocalTrack(videoTrack);
        return facingMode;
    } else {
        return 'undefined';
    }
  }, [videoTrack]);

  const resetLoadMetaData = () => {
      setLoadVideoMetadata(false);
      setLoadVideoWithVisualEffect(false);
  }
  const handleLoadedMetadata = () => {
    setLoadVideoMetadata(true);
  };

  useEffect(() => {
    if (!containerDimensions) return;
    const { width, height } = containerDimensions;
    const widthAspectRatio = (height * 16) / 9;
    let style = {};
    if (width >= height) {
      //take height into account
      style = {
        maxWidth: Math.min(width, widthAspectRatio),
        height: height,
      };
    } else {
      style = {
        maxWidth: width,
        height: width,
      };
    }
    setVideoStyle(style);
  }, [JSON.stringify(containerDimensions)]);

  useEffect(() => {
    const widthFromActionButtons =
      actionFrameRef.current?.getBoundingClientRect().width;
    const widthFromAudioVideo =
      audioVideoConfigRef.current?.getBoundingClientRect().width;
    const needWrapConfigButton =
      widthFromActionButtons / 2 - 95 < widthFromAudioVideo;
    if (needWrapConfigButton) {
      setAudioVideoConfigStyle({ position: 'relative' });
      actionFrameRef.current.style.flexDirection = 'column';
      actionFrameRef.current.style.gap = '10px';
    } else {
      setAudioVideoConfigStyle({ position: 'absolute' });
      actionFrameRef.current.style.gap = '0';
    }
  }, [JSON.stringify(screenDimensions)]);

  useEffect(() => {
    videoEnabledRef.current = videoEnabled;

    if(videoEnabled){
      createLocalVideoTrack()
      .then(track => {
        if(videoEnabledRef.current){
          setVideoTrack(prev=>{
            prev?.stop();
            return track
          })
        }else{
          track.stop();
        }
        
      })
      .catch(error => handleCameraError(error, setCameraPermission, (hasError) => setCameraError(hasError)))
    }else{
      setVideoTrack(prev=>{
        prev?.stop();
        return null
      });
      resetLoadMetaData();
    }

  }, [videoEnabled]);

  useEffect(() => {
    if(videoTrack){
      setCameraPermission(PermissionStatus.GRANTED);
      setCameraError(null)
    }
    if (videoEl.current && videoTrack) {
        videoTrack.unmute();
        videoTrack.attach(videoEl.current);
    }

    return () => {
        videoTrack?.detach();
        videoTrack?.stopProcessor().then(() => videoTrack?.mediaStream?.getVideoTracks().forEach(track => track.stop()));
    };
  }, [!!videoTrack, videoEl.current]);

  useEffect(()=>{
    audioEnabledRef.current = audioEnabled;

    setAudioTrack(prev=>{
      prev?.stop();
      return null
    });

    if(audioEnabled){
        createLocalAudioTrack({ 
          echoCancellation: isActiveEchoCancellation, 
          noiseSuppression: isActiveNoiseCancellation
        })
        .then(track =>{
          if(audioEnabledRef.current){
            setAudioTrack(prev=>{
              prev?.stop();
              return track
            });
          }else{
            track.stop();
          }
        })
        .catch(error => handleMicroError(error, setMicroPermission))
    }
    
  },[audioEnabled, isActiveEchoCancellation, isActiveNoiseCancellation]);

  useEffect(()=>{
  
    if(audioTrack && audioEnabled){
      const stream = audioTrack?.mediaStream;
      if (!stream) return;

      microphoneInputMediaStreamRef.current = stream;
      const audioContext = new AudioContext();
      const mic = audioContext.createMediaStreamSource(stream);
      const analyser = audioContext.createAnalyser();
      analyser.fftSize = 2048;
      mic.connect(analyser);

      const dataArray = new Uint8Array(140);
      const checkVoiceInput =  () => {
          if (!audioEnabledRef.current) {
              setHasVoiceInput(false);
              return cancelAnimationFrame(requestId.current);
          }
          analyser.getByteFrequencyData(dataArray);
          const average = dataArray.reduce((acc, value) => acc + value, 0) / 140;
          setHasVoiceInput(average > 0);
          requestId.current = window.requestAnimationFrame(checkVoiceInput);
      };
    
      checkVoiceInput();
    }
  },[audioTrack, audioEnabled]);

  useEffect(() => {
    if(audioTrack){
      setMicroPermission(PermissionStatus.GRANTED);
    }
  }, [audioTrack]);

  useEffect(() => {
      featureVisualEffectRef.current = {...featureVisualEffect};
  }, [JSON.stringify(featureVisualEffect)]);
      
  const applyProcess = () => {
      const type = featureVisualEffectRef.current.type;
      if (type === VisualEffectType.BLUR) {
          const blur = featureVisualEffectRef.current?.blur;
          videoTrack?.setProcessor(BackgroundBlur(blur, { delegate: 'GPU' }))
              .then(() => setLoadVideoWithVisualEffect(true));
      } else if (type === VisualEffectType.IMAGE || type === VisualEffectType.UPLOAD) {
          const img = featureVisualEffectRef.current?.img;
          videoTrack?.setProcessor(VirtualBackground(`${img}`, { delegate: 'GPU' }))
              .then(() => setLoadVideoWithVisualEffect(true));
      } else {
          setLoadVideoWithVisualEffect(true);
          videoTrack?.stopProcessor();
      }
  }

  useEffect(() => {
      if (isPhoneScreen) {
          setLoadVideoWithVisualEffect(true);
          return;
      }; // no apply effect in mobile devices
      if (!videoEl.current) return;
      if (!loadVideoMetadata) return;
      if (!videoTrack || !(videoTrack instanceof (LocalVideoTrack))) return;
      if (!videoEnabled) return;
      applyProcess();
  }, [
      JSON.stringify(featureVisualEffectRef.current),
      videoEnabled,
      loadVideoMetadata,
      facingMode,
  ]);


  useEffect(() => {
      const height = videoStyle?.height;
      if (height) {
          let heightHeight = 'more-than-250';
          const height = containerRef.current?.offsetHeight;
          if (height) {
              if (height >= 100 && height < 250) {
                  heightHeight = 'less-than-250';
              } else if (height < 100) {
                  heightHeight = 'less-than-100';
              }
          }
          setClassnameAdded(heightHeight);
      }
  }, [JSON.stringify(videoStyle)]);

  //listen for div ref resize
  useEffect(() => {

    setForceRequestPermission(true);

    const observer = new ResizeObserver((entries) => {
      const width = entries[0].contentRect.width;
      const height = entries[0].contentRect.height;
      containerDimensionsRef.current = { width, height };
      setContainerDimensions(containerDimensionsRef.current);
    });
    observer.observe(elRef.current);

    window.addEventListener('load', handleResize);
    window.addEventListener('resize', handleResize);
    return () => {
      elRef.current && observer.unobserve(elRef.current);

      window.removeEventListener('load', handleResize);
      window.removeEventListener('resize', handleResize);
      if (videoRef.current) {
        videoRef.current = null;
      }
    };
  }, []);

  const optionMicroMuted = !audioEnabled ? <MicroOffSvgOnLayout /> : !hasVoiceInput ? <RegularVolumeSVG /> : <SpeakingSvg />;
  const showVideo = videoTrack && loadVideoWithVisualEffect;
  const showError = (cameraPermission !== PermissionStatus.GRANTED || cameraError) && videoEnabled;
  const showAvatar = !showError && !videoEnabled && userAvatarPath && userAvatar;
  const showInitial = !showError && !videoEnabled && (!userAvatarPath || !userAvatar) ;

  const showNetwork = networkStatus !== NetworkStatus.GOOD || isHover;

  const networkStatusIcon = networkStatus === NetworkStatus.BAD ? <NetworkStatusDangerSvg />:
  networkStatus === NetworkStatus.LOW ? <NetworkStatusWarningSvg /> : <NetworkStatusStableSvg />;

  const initial = getInitialFromString(userName) ?? '?';

  const selectMin = Math.min(containerRef.current?.offsetHeight, containerRef.current?.offsetWidth);
  const min = isPhoneScreen ? containerRef.current?.offsetWidth : selectMin;
  const macaroonHeight = min ? 0.4 * min : '30vmin';
  const fontSize = min ? 0.2 * min : '12vmin';
  return (
    <div className='preview__body__right'>
      <div className='preview-right-container'>
        <div
          ref={elRef}
          className='preview-right-container__video-frame'>
          <div
                ref={containerRef}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                style={videoStyle}
                className={`meet-video ${facingMode ? 'transformX' : ''}`}>
                <video 
                  ref={videoEl} 
                  muted 
                  data-lk-facing-mode={facingMode} 
                  webkitplaysinline="true"
                  playsInline 
                  hidden={!showVideo} 
                  onLoadedMetadata={handleLoadedMetadata} />
                
                
                {showError && <CameraFailedMessage cameraError={cameraError}/>}
                
                {showAvatar &&
                    <img src={userAvatar} alt='portrait' className='meetroom-portrait' style={{ height: macaroonHeight }} />}
            
                {showInitial &&
                    <div className='macaroon-box'>
                        <div className='macaroon-box__frame'
                            style={{ backgroundColor: currentParticipantColor, height: macaroonHeight }}>
                            <span style={{ fontSize }}>{initial}</span>
                        </div>
                    </div>}
                    
                <div className={`option-layer ${classnameAdded}`}>
                    {optionMicroMuted}
                    {showNetwork && networkStatusIcon}
                </div>
            </div>
        </div>
        <div
          ref={actionFrameRef}
          className='preview-right-container__action-frame'>
          <BtnIcon
            style={audioVideoConfigStyle}
            ref={audioVideoConfigRef}
            content='Audio and video'
            icon={WheelSvg}
            translate='prejoin-button-audio-video'
            event={handleClickOnSettings}
          />
          <div className='action-frame'>
            <MicroCameraControls {...microCameraControlsProps} />
          {(!isPhoneScreen && isPipelineSupported) &&
              <SquareBtn
              event={handleClickOnVisualEffect}
              title={'Visual effect'}
              icon={ThreeStarsSvg}
            />
          }
          </div>
          {!isPhoneScreen && <div className='empty-content' />}
        </div>
      </div>
    </div>
  );
};

export default PreviewRightContainer;
