class DraggableModal {
    constructor(modal, handle, dragProgress,reduceBar = false,updateParentState,updateBox) {
      this.modal = document.querySelector(modal);
      this.handle = document.querySelector(handle);
      
      this.dragProgress = dragProgress;
      this.reduceBar = reduceBar;
      this.updateParentState = updateParentState;
      this.updateBox = updateBox;
      this.initDragSystem();
    }
  
    initDragSystem() {
      if (this.modal && this.handle) {
        let startY = 0;
        let currentY = 0;
        let dragging = false;
        // console.log(this.modal,this.handle);
  
        const handleTouchStart = (e) => {
          startY = e.touches[0].clientY;
          dragging = true;
          // e.preventDefault();
        };
  
        const handleTouchMove = (e) => {
          if (dragging) {
            currentY = e.touches[0].clientY - startY;
            if (currentY > 0) {
              this.modal.style.transform = `translateY(${currentY}px)`;
              const dragProgress = (currentY / window.innerHeight) * 100;
              if (dragProgress >= this.dragProgress) {
                if (this.reduceBar) {
                  this.modal.style.transform = "translateY(0%)";
                  this.modal.style.transition = "transform 0.3s ease"; // Ajoute la transition CSS
                } else {
                  if(this.updateBox){
                    this.updateBox(false);
                  }
                }

                if(this.updateParentState){
                  this.updateParentState(false);
                }
              } else {
                this.modal.style.transition = ""; // Réinitialise la transition si la condition n'est pas remplie
              }
            }
            // e.preventDefault();
          }
        };
  
        const handleTouchEnd = () => {
          dragging = false;
          this.modal.style.transition = "transform 0.3s ease"; // Ajoute la transition CSS
          this.modal.style.transform = "translateY(0)"; // Réinitialise la position du modal
          this.originalTransform = null;
        };
  
        const disableScroll = () => {
          const yOffset = window.pageYOffset;
  
          document.body.style.height = "100vh";
          document.body.style.position = "fixed";
          document.body.style.top = `-${yOffset}px`;
        };
  
        const enableScroll = () => {
          const yOffset = parseInt(document.body.style.top, 10) * -1;
  
          document.body.style.height = "";
          document.body.style.position = "";
          document.body.style.top = "";
  
          window.scrollTo(0, yOffset);
        };
  
        this.handle.addEventListener("touchstart", handleTouchStart);
        this.handle.addEventListener("touchmove", handleTouchMove);
        this.handle.addEventListener("touchend", handleTouchEnd);
  
        // this.modal.addEventListener("touchstart", disableScroll);
        // this.modal.addEventListener("touchend", enableScroll);
      }
    }
}
  


export default DraggableModal;