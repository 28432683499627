import React, { memo, useEffect, useRef, useState } from 'react';

import ForbiddenSvg from '../../assets/icons/forbidden.svg';
import SlightBlurSvg from '../../assets/icons/slight-blur.svg';
import { ReactComponent as TrashSvg }  from '../../assets/icons/trash-with-stroke-24.svg';
import BlurSvg from '../../assets/icons/blur.svg';
import PlusIconSvg from '../../assets/icons/add-icon.svg';
import thumbnailsInVisualEffects from '../../constants/thumbnailsInVisualEffects';
import useAuthContext, { useAudioVideoContext } from '../../hooks/useContext';
import { PermissionStatus } from '../../context/AudioVideoContextProvider';
import CameraFailedMessage from './CameraFailedMessage';
import { BackgroundBlur, VirtualBackground } from '@livekit/track-processors';
import { LocalVideoTrack, Track, createLocalVideoTrack } from 'livekit-client';
import isVisuelEffectSupported from '../../utils/checkPipelineSupport';
import { handleCameraError } from '../../utils/errorHandler';
import IconSvg from '../IconSvg';

const PillCarouselItem = ({ imgSrc, title, onClick, translate, isFromDataSet, index, selected, legend }) => {

    const { translateJSON } = useAuthContext();
    const ref = useRef();
    useEffect(() => {
        caches.open('thumbnails-visual-effects-cache').then(cache => {
            cache.match(imgSrc).then((cachedResponse) => {
                if (!cachedResponse || !cachedResponse.ok) {
                    if (ref.current && isFromDataSet) {
                        ref.current.style.backgroundImage = `url(${imgSrc})`;
                        fetch(imgSrc).then(response => cache.put(imgSrc, response))
                    }
                }
                else {
                    cachedResponse.blob().then(blob => {
                        if (blob) {
                            const imageUrl = URL.createObjectURL(blob);
                            if (ref.current && isFromDataSet) {
                                ref.current.style.backgroundImage = `url(${imageUrl})`;
                            }
                            
                        }
                    });
                }
            })
        });
    }, [imgSrc]);
    
    const styleBackgroundImage = isFromDataSet ? {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover'
    } : {};
    return (
        <div className='pill-carousel__item' key={index+'no-uploaded'} onMouseDown={() => onClick(imgSrc, index)}>
            <button ref={ref}
                style={styleBackgroundImage}
                className={`pill-carousel__item__content ${selected ? 'selected' : ''} ${isFromDataSet ? '--image' : 'effect'}`}
                data-size="large"
                id={`preview-settings-${title}-btn`}
            >
                {!isFromDataSet && <IconSvg svg={imgSrc} />}
            </button>
            <div className='pill-carousel__item__legend'>
                <span>{translateJSON[translate] ?? translate ?? legend }</span>
            </div>
        </div>
    )
};
    
const UploadedPillCarouselItem = ({ imgData, title, onClick, index, selected, onClickDelete }) => {
    const { translateJSON } = useAuthContext();
    const [isHover, setIsHover] = useState(false);
    return (
        <div onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            className='pill-carousel__item' key={index + 'uploaded'}>
            <button
                onClick={() => onClick(index)}
                style={{ backgroundImage: `url(${imgData})` }}
                className={`pill-carousel__item__content ${selected ? 'selected' : ''} --image`}
                data-size="large"
                id={`preview-settings-${title}-btn`}
            />
            {isHover && !selected && <TrashSvg onMouseDown={() => onClickDelete(index)} />}
            <div className='pill-carousel__item__legend'>
                <span>{`${translateJSON['upload-image']} ${index + 1}`}</span>
            </div>
        </div>);
}
   
    
export const VisualEffectType = {
    NONE: 'none',
    BLUR: 'blur',
    IMAGE: 'image',
    UPLOAD: 'upload',
}

const VisualEffectConfig = ({ visualEffect, setVisualEffect, isInMeetRoom = false }) => {
    
    const {cameraPermission, setCameraPermission} = useAudioVideoContext();
    const isPipelineSupported = isVisuelEffectSupported();
    const { translateJSON } = useAuthContext();

    const videoRef = useRef(null);
    const hasUpdated = useRef(false);
    const scrollRef = useRef(null);

    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    const [loadVideoMetadata, setLoadVideoMetadata] = useState(false);
    const [loadVideoMetadataVisuelEffect, setLoadVideoMetadataVisuelEffect] = useState(false);
    const [cameraError, setCameraError]= useState();
    const [video, setVideoTrack] = useState();

    useEffect(()=>{
        createLocalVideoTrack()
        .then(track => {
            setVideoTrack(prev=>{
                prev?.stop();
                return track
            });
        })
        .catch(error => handleCameraError(error, setCameraPermission, (hasError) => setCameraError(hasError)))
    },[]);

    
    useEffect(() => {
        if(video){
            setCameraPermission(PermissionStatus.GRANTED);
            setCameraError(null);
        }
        if (videoRef.current) {
            video?.attach(videoRef.current);
        } else {
            video?.detach();
        }
        return () => {
            video?.stop();
            video?.detach();
        }
        
    }, [!!video, videoRef.current]);
    
    const handleMouseDown = (event) => {
        setIsDragging(true);
        setStartX(event.pageX - scrollRef.current.offsetLeft);
        setScrollLeft(scrollRef.current.scrollLeft);
    };
    
    const handleMouseMove = (event) => {
        if (!isDragging) return;
        const x = event.pageX - scrollRef.current.offsetLeft;
        const walk = (x - startX) * 3; // ajustez ce nombre pour changer la vitesse de défilement
        scrollRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => setIsDragging(false)
    
    const handleTouchStart = (event) => {
        setIsDragging(true);
        setStartX(event.touches[0].pageX - scrollRef.current.offsetLeft);
        setScrollLeft(scrollRef.current.scrollLeft);
    };
    
    const handleTouchMove = (event) => {
        if (!isDragging) return;
        event.preventDefault();
        const x = event.touches[0].pageX - scrollRef.current.offsetLeft;
        const walk = (startX - x) * 3; // Inversion du sens de défilement (startX - x)
        scrollRef.current.scrollLeft = scrollLeft + walk;
    };
    
    const handleTouchEnd = () => setIsDragging(false)
    
    const handleWheel = (e) => {
        if (scrollRef.current) {
            const delta = Math.max(-1, Math.min(1, (e.deltaY || -e.detail)));
            scrollRef.current.scrollLeft -= (delta * 150);
        }
    };

    const fileUploadLimit = 0.5 * 1024 * 1024; // 1MB in bytes. Formula: 1MB = 1 * 1024 * 1024.
    const localStorageBackgroundKey = "background-images";
    const uploadedImagesFromLocalStorage = localStorage.getItem(localStorageBackgroundKey);
 
    const [uploadedBackgroundImages, setUploadedBackgroundImages] = useState(uploadedImagesFromLocalStorage ? JSON.parse(uploadedImagesFromLocalStorage) : []);
    

    const notUploadedBackgroundImagesIndex = 3 + uploadedBackgroundImages.length;


    useEffect(() => {
        localStorage.setItem(localStorageBackgroundKey, JSON.stringify(uploadedBackgroundImages));
    }, [uploadedBackgroundImages]);

    const handleDeleteUploadedImage = (index) => {
        const newList = [...uploadedBackgroundImages];
        newList.splice(index, 1);
        setUploadedBackgroundImages([...newList]);
    }

    const handleUploadImage = (e) => {
        const file = e.target.files[0];
        if (file && file.size <= fileUploadLimit) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result.toString();
                setUploadedBackgroundImages([...uploadedBackgroundImages, base64String]);
            }
            reader.readAsDataURL(file);
        }
    }

    const handleLoadedMetadata = () => {
        const processName = video?.getProcessor()?.name;
        const needUpdate = (processName === 'background-blur' && visualEffect.type === VisualEffectType.BLUR) ||
            (processName === 'virtual-background' && (visualEffect.type === VisualEffectType.IMAGE || visualEffect.type === VisualEffectType.UPLOAD)) ||
            (!processName);
        if (needUpdate) setLoadVideoMetadata(true);
    };
    
    useEffect(() => {

        if (!videoRef.current) return;
        if (!loadVideoMetadata) return;
        if (!video || !(video instanceof (LocalVideoTrack))) return;
        if (!video?.attachedElements?.length) return;

        const applyVisuelEffect = () => {
            if (visualEffect.type === VisualEffectType.BLUR) {
                const blur = visualEffect?.blur;
                video.setProcessor(BackgroundBlur(blur)).then(() => setLoadVideoMetadataVisuelEffect(true));
            } else if (visualEffect.type === VisualEffectType.IMAGE || visualEffect.type === VisualEffectType.UPLOAD) {
                const img = visualEffect?.img;
                video?.setProcessor(VirtualBackground(`${img}`)).then(() => setLoadVideoMetadataVisuelEffect(true));
            } else {
                setLoadVideoMetadataVisuelEffect(true);
            }
        }

        if (video.getProcessor()?.name) {
            video.stopProcessor().then(() => {
                applyVisuelEffect()
            })
        } else {
            applyVisuelEffect()
        }
    }, [
        `${visualEffect}`,
        loadVideoMetadata,
        hasUpdated.current
    ]);

    const onVirutalBackgroundBtnClick = (img, index) => {
        if (cameraPermission !== PermissionStatus.GRANTED) return;
        
        setVisualEffect(prev => {
            if (prev?.type === VisualEffectType.IMAGE && prev?.index === index && prev?.source === 'image' && prev?.img === img) {
                return { type: VisualEffectType.NONE };
            }
            return { type: VisualEffectType.IMAGE, source: 'image', img, index };
        });
        hasUpdated.current = !hasUpdated.current;
    };

    const onUploadedVirutalBackgroundBtnClick = (index) => {
        if (cameraPermission !== PermissionStatus.GRANTED) return;
        setVisualEffect(prev => {
            const img = uploadedBackgroundImages[index];
            if (prev?.type === VisualEffectType.UPLOAD && prev?.index === index && prev?.source === 'upload' && prev?.img === img) {
                return { type: VisualEffectType.NONE };
            }
            return { type: VisualEffectType.UPLOAD, source: 'upload', img, index };
        });

        hasUpdated.current = !hasUpdated.current;

    };

    const onNoBackgroundBtnClick = () => {
        if (cameraPermission !== PermissionStatus.GRANTED) return;

        setVisualEffect({ type: VisualEffectType.NONE });
        hasUpdated.current = !hasUpdated.current;

    }

    const onLightBlurBackgroundBtnClick = () => {
        if (cameraPermission !== PermissionStatus.GRANTED) return;
        
        setVisualEffect(prev => {
            if (prev.type === VisualEffectType.BLUR && prev?.blur === 8) {
                return { type: VisualEffectType.NONE };
            }
            return { type: VisualEffectType.BLUR, blur: 8 }
        });
        hasUpdated.current = !hasUpdated.current;
    }

    const onBlurBackgroundBtnClick = () => {
        if (cameraPermission !== PermissionStatus.GRANTED) return;
        
        setVisualEffect(prev => {
            if (prev.type === VisualEffectType.BLUR && prev?.blur === 20) {
                return { type: VisualEffectType.NONE };
            }
            return { type: VisualEffectType.BLUR, blur: 20 }
        });
        hasUpdated.current = !hasUpdated.current;
    }

    if (!isPipelineSupported) return <></>;
    
    return (<div className={`visual-effect-box${isInMeetRoom ? ' is-in-meetroom' : ''}`}>
        <div className='visual-effect-box__body'>
            <video ref={videoRef} autoPlay width="640" height="480"
                hidden={!loadVideoMetadataVisuelEffect}
                onLoadedMetadata={handleLoadedMetadata} />
            <CameraFailedMessage cameraError={cameraError}/>
        </div>
        {!!video && !cameraError && <div className='visual-effect-box__footer'>
            <div className='upload-pill' >
                <IconSvg svg={PlusIconSvg} />
                <>
                    <span>{translateJSON['upload-image']}</span>
                    <span className='upload-pill__info'>{translateJSON['add-image-file-format']}</span>
                </>
                
                <input type='file' name="upload-background-image" onChange={handleUploadImage} accept="image/png, image/jpeg" title={translateJSON['no-file-selected-tooltip']} />
            </div>

                <div className='pill-carousel'>
                    <div
                        className='pill-carousel__frame'
                        onWheel={handleWheel}
                        ref={scrollRef}
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                        onMouseLeave={handleMouseUp}
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                        onTouchEnd={handleTouchEnd}
                        onTouchCancel={handleTouchEnd}
                    >
                        <PillCarouselItem
                            key='forbidden'
                            selected={visualEffect?.type === VisualEffectType.NONE}
                            index={0}
                            isFromDataSet={false}
                            imgSrc={ForbiddenSvg}
                            title='None'
                            translate='background-none'
                            onClick={onNoBackgroundBtnClick} />
                        <PillCarouselItem
                            key='slightblur'
                            selected={visualEffect?.type === VisualEffectType.BLUR && visualEffect?.blur === 8}
                            index={1}
                            isFromDataSet={false}
                            imgSrc={SlightBlurSvg}
                            title='Slight blur'
                            translate='background-slightblur'
                            onClick={onLightBlurBackgroundBtnClick} />
                        <PillCarouselItem
                            key='blur'
                            selected={visualEffect?.type === VisualEffectType.BLUR && visualEffect?.blur === 20}
                            index={2}
                            isFromDataSet={false}
                            imgSrc={BlurSvg}
                            title='Blur'
                            translate='background-blur'
                            onClick={onBlurBackgroundBtnClick} />
                        {uploadedBackgroundImages.map((item, index) => {
                            const selected = visualEffect?.source === 'upload' && visualEffect?.index === index;
                            return (<UploadedPillCarouselItem
                                key={`${index + 3}-uploaded`}
                                index={index}
                                selected={selected}
                                imgData={item}
                                onClickDelete={handleDeleteUploadedImage}
                                onClick={onUploadedVirutalBackgroundBtnClick} />);
                        }
                        )}
                        {thumbnailsInVisualEffects.map((item, index) => {
                            const selected = visualEffect?.source === 'image' && visualEffect?.index === index;
                            return <PillCarouselItem
                                key={`${index + notUploadedBackgroundImagesIndex}-no-uploaded`}
                                index={index}
                                selected={selected}
                                isFromDataSet={true}
                                imgSrc={item.src}
                                translate={item.translate}
                                legend={item.legend}
                                onClick={onVirutalBackgroundBtnClick} />
                        }
                        )}
                    </div>
                </div>
            </div>}
        </div>)
};


export default memo(VisualEffectConfig);