import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useMediaQuery from '../hooks/useMediaQuery';
import useAuthContext, { useGeneralContext } from '../hooks/useContext';

import ErrorLostConnectionPage from './ErrorLostConnectionPage';
import BgPanel from '../components/BgPanel';
import BtnIcon from '../components/BtnIcon';
import ThemeEditPanel from '../components/Home/ThemeEditPanel';
import Navbar, { HomeTabOptions } from '../components/Navbar';

import UnlockSvg from '../assets/icons/unlock.svg';
import LockSvg from '../assets/icons/locker.svg';
import KeySvg from '../assets/icons/key.svg';
import UsersSvg from '../assets/icons/users.svg';
import CrownSvg from '../assets/icons/crown.svg';
import IntegrationSvg from '../assets/icons/integration.svg';
import EditSvg  from '../assets/icons/edit.svg';
import CameraSvg from '../assets/icons/camera.svg';
import { ReactComponent as LogoSvg } from '../assets/icons/logotype-ringover-circle.svg';
import { ReactComponent as FilesSvg } from '../assets/icons/file.svg';
import { ReactComponent as AnalyticsSvg } from '../assets/icons/analytics.svg';
import { ReactComponent as ArrowBackCircleSvg } from '../assets/icons/arrow-left-circle.svg';
import { MEDIUM_SCREEN } from '../utils/breackpoints';

import { createEvent, getInstantMeeting as fetchInstantMeeting } from '../helpers/eventApi';
import { DefaultThemeColor, ThemeTemplateType } from '../constants/colors';
import isValidHttpUrl from '../utils/isValidHttpUrl';


const Home = () => {

	const navigate = useNavigate();
	
	const { lostConnection, currentEventUUID, setCurrentEventUUID, currentParticipantUUID, setCurrentParticipantUUID } = useGeneralContext();
	const { userName, translateJSON, setUserName, userID, teamID } = useAuthContext();
	
	const isLargeScreen = useMediaQuery(MEDIUM_SCREEN);

	const [tabSelected, setTabSelected] = useState(HomeTabOptions.Meeting);
	const [allowInstantMeeting, setAllowInstantMeeting] = useState(true);
	const [themeDetail, setThemeDetail] = useState();
	const [themeEdit, setThemeEdit] = useState();
	const [needUpdateInstantMeeting, setNeedUpdateInstantMeeting] = useState(false);
	const [showToast, setShowToast] = useState(false);
	const [toast, setToast]= useState();
	const [instantMeeting, setInstantMeeting] = useState();

	const isLoggedIn = !!teamID && !!userID;
	const handleInstantMeetingPreview = async () => {
		// Avoid fetch multiple times when click multiple times quickly
		if (!allowInstantMeeting) return;
		setAllowInstantMeeting(false);
		try {
			const {eventUUID, configuration} = await createEvent({ userID, teamID });
	
			if (eventUUID) {
				navigate(`/preview/${eventUUID}`, { state: { eventUUID, configuration, isFromHome: true } });
			}
		} catch (err) {
			window.bus.publish("alert");
		} finally {
			setAllowInstantMeeting(true);
		}
		
	};

	useEffect(() => {
		let timer;
		if (showToast) {
			timer = setTimeout(() => {
				setShowToast(false);
			}, 3000);

		} else {
			clearTimeout(timer);
		}
		return () => {
			clearTimeout(timer);
		}
	}, [showToast]);

    useEffect(() => {
		const getInstangMeeting = async () => {
			const defaultEvent = await fetchInstantMeeting({ teamID, userID });
			setInstantMeeting(defaultEvent);
		};
        if (tabSelected === HomeTabOptions.Themes && isLoggedIn) {
            getInstangMeeting();
        }
    }, [needUpdateInstantMeeting, tabSelected]);

    useEffect(() => {
        localStorage.removeItem('authpath');
        if (currentParticipantUUID) {
            setCurrentParticipantUUID(null);
        }
        localStorage.removeItem('meetUUIDFromPreviousPage');
        localStorage.removeItem('fromMeetRoomWithoutToken');
        localStorage.removeItem('fromMeetRoomWithoutTokenMeet');
        localStorage.removeItem('meetUUIDFromPreviousPageMeet');
        if (!teamID || !userID) {
            setUserName('');
        }
        if (currentEventUUID) {
            setCurrentEventUUID(null);
        }
    }, []);

    const showStartMeeting = !lostConnection && tabSelected === HomeTabOptions.Meeting;
    const showThemesTab = !lostConnection && tabSelected === HomeTabOptions.Themes;
    const showThemeOverview = showThemesTab && !themeDetail;
    const showThemeDetailPage = showThemesTab && !!themeDetail;
    const showThemeEditPanel = showThemesTab && !!themeEdit;

	const getThemeAppearance = (theme)=>{
		const appearanceFromConfig = theme?.configuration?.appearance;
        const templateSelected = appearanceFromConfig?.theme || ThemeTemplateType.DARK_PURPLE;
        const templateColors = DefaultThemeColor?.[templateSelected];
        const primary_color = templateColors.primary_color || appearanceFromConfig?.primary_color || DefaultThemeColor?.[ThemeTemplateType.DARK_PURPLE]?.primary_color;
        const background_primary_color = templateColors.background_primary_color || appearanceFromConfig?.background_primary_color || DefaultThemeColor?.[ThemeTemplateType.DARK_PURPLE]?.background_primary_color;
        const secondary_color = templateColors.secondary_color || appearanceFromConfig?.secondary_color || DefaultThemeColor?.[ThemeTemplateType.DARK_PURPLE]?.secondary_color;
        const background_secondary_color = templateColors.background_secondary_color || appearanceFromConfig?.background_secondary_color || DefaultThemeColor?.[ThemeTemplateType.DARK_PURPLE]?.background_secondary_color;

        const appearance = { primary_color, background_primary_color, secondary_color, background_secondary_color };
		return appearance;
	}

	const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);
	
	const getThemeData = (theme) => {
		const enterModeString = theme?.configuration?.settings?.mode ?? 'anyone with the link can enter';
		let enterMode = 0;
		if (enterModeString === 'knock to enter') {
			enterMode = 1
		} else if (enterModeString === 'require password to enter') {
			enterMode = -1
		}
        const permissions = theme?.configuration?.['live_settings'];

		let othersPermissionsChips = '';
		let hostPermissionsChips = '';
		const { open_chat, reactions, screen_share } = permissions ?? {};

		const othersPermissionsKeys = {
			'true,true,true': 'setup-chips-chat-react-screenshare',
			'true,true,false': 'setup-chips-chat-react',
			'true,false,true': 'setup-chips-chat-screenshare', 
			'false,true,true': 'setup-chips-react-screenshare', 
			'true,false,false': 'setup-chips-chat-desc', 
			'false,true,false': 'setup-chips-reactions-desc',
			'false,false,true': 'setup-chips-screenshare-desc'
		};

		const hostPermissionsKeys = {
			'false,false,false': 'setup-chips-record-chat-react-screenshare-onlyhost',
			'false,false,true': 'setup-chips-recording-chat-react-onlyhost',
			'false,true,false': 'setup-chips-recording-chat-screenshare-onlyhost',
			'false,true,true': 'setup-chips-recording-chat-onlyhost',
			'true,false,false': 'setup-chips-recording-react-screenshare-onlyhost',
			'true,false,true': 'setup-chips-recording-reaction-onlyhost',
			'true,true,false': 'setup-chips-recording-screenshare',
			'true,true,true': 'permissions-record-desc',
		};

		const othersKey = [open_chat, reactions, screen_share].map(Boolean).join(',');
		const hostKey = [open_chat, reactions, screen_share].map(Boolean).join(',');
		othersPermissionsChips = translateJSON[othersPermissionsKeys[othersKey]] || `anyone can => open_chat:${open_chat}, reactions:${reactions}, screen_share:${screen_share}`
		hostPermissionsChips = translateJSON[hostPermissionsKeys[hostKey]] || `only host =>open_chat:${open_chat}, reactions:${reactions}, screen_share:${screen_share}`

		let othersPermissions = [];
		let hostPermissions = [translateJSON['permissions-record-title'] || 'Recordings'];

		const chatTitle = translateJSON['chat-title'];
		const reactionTitle = translateJSON['permissions-reactions-title'] || 'Reactions';
		const screenShareTitle = translateJSON['permissions-screen-sharing-title']?.toLowerCase() || 'screen share';

		if (permissions?.['open_chat']) {
			othersPermissions.push(chatTitle);
		} else {
			hostPermissions.push(chatTitle?.toLowerCase());
		}
		
		if (permissions.reactions) {
			othersPermissions.push(reactionTitle.toLowerCase());
		} else {
			hostPermissions.push(reactionTitle.toLowerCase());
		}
		
		if (permissions['screen_share']) {
			othersPermissions.push(screenShareTitle);
		} else {
			hostPermissions.push(screenShareTitle);
		}

		if (othersPermissions.length > 0) {
			othersPermissions[0] = capitalizeFirstLetter(othersPermissions[0]);
		}
		
		if (hostPermissions.length > 1) {
			hostPermissions[1] = capitalizeFirstLetter(hostPermissions[1]);
		}

		const pollsEnabled = theme?.event_tools?.some(item=>item.name === 'poll' && !!item?.is_active) ?? false;
        const qaEnabled = theme?.event_tools?.some(item=>item.name === 'Q&A' && !!item?.is_active) ?? false;
		let eventTools = [];
		let eventToolsChips = '';
		if (pollsEnabled && qaEnabled) {
			eventTools.push(translateJSON['room-apps-polls-title']);
			eventTools.push(translateJSON['room-apps-qa-title']);
			eventToolsChips = translateJSON['setup-chips-polls-qa'];
		} else if (pollsEnabled) {
			eventTools.push(translateJSON['room-apps-polls-title']);
			eventToolsChips = translateJSON['setup-chips-polls-desc'];
		} else if (qaEnabled) {
			eventTools.push(translateJSON['room-apps-qa-title']);
			eventToolsChips = translateJSON['setup-chips-qa-desc'];
		}
        return { enterModeString, enterMode, othersPermissions, othersPermissionsChips, hostPermissions, hostPermissionsChips, eventTools, eventToolsChips };
    };

	const permissionsElement = (theme) =>{

		if(!theme) return <></>;
		const { enterModeString, enterMode, othersPermissions, othersPermissionsChips, hostPermissions, hostPermissionsChips, eventTools, eventToolsChips } = getThemeData(theme);
		return (<div className='permissions'>
			{enterMode === 0 && tagElement(enterModeString, 'blue', UnlockSvg, translateJSON['setup-chips-anyone-enter'])}
			{enterMode === 1 && tagElement(enterModeString, 'blue', LockSvg, translateJSON['setup-chips-knock-onlyhost'])}
			{!!othersPermissions.length && tagElement('others-permissions', 'green', UsersSvg, othersPermissions.join(', '), othersPermissionsChips)}
			{!!hostPermissions.length && tagElement('host-permissions', 'yellow', CrownSvg, hostPermissions.join(', '), hostPermissionsChips)}
			{!!eventTools.length && tagElement('applications', 'green', IntegrationSvg, eventTools.join(', '), eventToolsChips)}
		</div>);
	};

	const colorsElement = (appearance) =>
		<>
			<div key='main-background-color' className='color-item' >
				<div className='color' style={{ background: appearance?.['background_primary_color'] }} />
			</div>
			<div key='buttons-background-color' className='color-item' >
				<div className='color' style={{ background: appearance?.['primary_color'] }} />
			</div>
			<div key='panel-background-color' className='color-item' >
				<div className='color' style={{ background: appearance?.['background_secondary_color'] }} />
			</div>
			<div key='active-buttons-background-color' className='color-item' >
				<div className='color' style={{ background: appearance?.['secondary_color'] }} />
			</div>
		</>;
			
	
	const themeOverviewElement = (theme) => {
		if (!theme) return <></>;
		const appearance = getThemeAppearance(theme);
		const logo = instantMeeting?.configuration?.appearance?.logo;	
		const hasValidLogo = logo && isValidHttpUrl(logo);
		return <div className='homepage__themes__room' key={theme['event_uuid']}
			onMouseDown={() => setThemeDetail(theme?.['event_uuid'])}>
			<div className='homepage__themes__room__item-wrapper-outside'>
				<div className='homepage__themes__room__item-wrapper-inside'>
					<div className='homepage__themes__room__item'>
						<div className='homepage__themes__room__item__header'>
							<div className='header-logo' style={{ background: appearance?.['background_secondary_color'] }}>
								{!hasValidLogo && <LogoSvg /> }
								{hasValidLogo && <img src={logo} /> }
							</div>
								
							<span title={theme?.title}>{theme?.title}</span>
						</div>
						<div className='homepage__themes__room__item__body'>
							<span>{translateJSON['rooms-overview-setup-desc'] || 'Meeting setup:'}</span>
							<div className='homepage__themes__room__item__body__configuration'>
								{permissionsElement(theme)}
							</div>
						</div>
						<div className='homepage__themes__room__item__footer'>
							{colorsElement(appearance)}
						</div>
					</div>
				</div>
			</div>
		</div>
	};

	const themeDetailElement = () => {
		if (!instantMeeting) return <></>;
		const appearance = getThemeAppearance(instantMeeting);
		const logo = instantMeeting?.configuration?.appearance?.logo;	
		const hasValidLogo = logo && isValidHttpUrl(logo);
		return <div className='homepage__theme-selected'>
			<div className='homepage__theme-selected__header'>
				<div className='homepage__theme-selected__title'>
					<div className='homepage__theme-selected__title__buttons'>

						<div className='back-to-themes' 
							onMouseDown={() => setThemeDetail(null)} >
							<ArrowBackCircleSvg />
							<span>{translateJSON['rooms-overview-title'] || 'Rooms overview'}</span>
						</div>
						<div className='edit-room-start-meeting'>
							<BtnIcon
								alt='Edit room'
								icon={EditSvg}
								translate={'btn-edit-room'}
								content={'Edit room'}
								size='medium'
								event={() => setThemeEdit({...instantMeeting})}
							/>
						</div>
					</div>
					<div className='homepage__theme-selected__title__h1'>
						<div className='theme-title'>
							<div className='header-logo' style={{ background: appearance?.['background_secondary_color'] }}>
								{!hasValidLogo && <LogoSvg /> }
								{hasValidLogo && <img src={logo} /> }
							</div>
							<h1 title={instantMeeting?.title}>{instantMeeting?.title}</h1>
						</div>
					</div>
				</div>
				{instantMeeting?.description &&
					<div className='homepage__theme-selected__desc'>
						<FilesSvg />
						<span>{instantMeeting?.description}</span>
					</div>}
			</div>
			<div className='homepage__theme-selected__body'>
				<div className='tags'>
					<span>{translateJSON['rooms-overview-setup-desc'] || 'Meeting setup:'}</span>
					<div className='homepage__themes__room__item__body__configuration'>
						{permissionsElement(instantMeeting)}
					</div>
				</div>
					
				<div className='colors'>
					<span>{translateJSON['rooms-overview-colors'] || 'Colors:'}</span>
					<div className='colors-list'>
						{colorsElement(appearance)}
					</div>
				</div>
			</div>

			<div className='homepage__theme-selected__analytics'>
				<div>
					<AnalyticsSvg />
					<span>{translateJSON['rooms-overview-comingsoon'] || 'Room and meetings analytics coming soon...'} </span>
				</div>
				
			</div>
		</div>
	};

	const tagElement = (key, color, svg, text, tips = '') =>
		<div className={`tag ${color}`} key={key}>
			<div className='svg' style={{
				WebkitMaskImage: `url(${svg})`,
				maskImage: `url(${svg})`,
			}} />
			<span title={tips}>{text}</span>
		</div>;
	
	return (
		<>
			<BgPanel />
			<div className='homepage'>
				<Navbar tabSelected={tabSelected} setTabSelected={setTabSelected} />
				{lostConnection && <ErrorLostConnectionPage isInMeetRoom={false} />}
				{showStartMeeting && <div className={`homepage__body`}>
					<div className='homepage__body__welcome'>
						<h1>{!(userID && teamID) ? 'Welcome' : `Hi ${userName}!`}</h1>
					</div>
				
					{isLargeScreen &&
						<div className='homepage__body__heading'>
							<h2 className='homepage__body__heading__title'>
								{translateJSON['home-title-meet']}
							</h2>
							<div className='homepage__body__heading__main'>
								<h1 id='text_video_call_screen'>
									{translateJSON['home-hero-msg']}
									<br />
									<span id='text_free_and_secured'>
										{translateJSON['home-hero-msg-2']}
									</span>
								</h1>
							</div>
							<div className='homepage__body__heading__legend'>
								<h4 id='text_open_your_virtual'>
									{translateJSON['home-desc']}
								</h4>
							</div>
						</div>
					}
					<div className='homepage__body__container'>
	
						<div className='homepage__body__container__buttons'>
						
							<BtnIcon
								alt='Instant Meeting'
								icon={CameraSvg}
								content='Instant Meeting'
								size='medium'
								translate={'home-btn-instant-meeting'}
								event={handleInstantMeetingPreview}
							/>
						</div>
					</div>
				</div>}
				{showThemeOverview &&
					<div className='homepage__themes'>
						<div className='homepage__themes__header'>
							<h1>{translateJSON['rooms-overview-title'] || 'Rooms overview'}</h1>
							<span>{translateJSON['rooms-overview-desc'] || 'Create, edit, and customize your rooms'}</span>
						</div>
						<div className='homepage__themes__rooms'>
							{!!instantMeeting && themeOverviewElement(instantMeeting)}
						</div>
					</div>}
			
				{showThemeDetailPage && themeDetailElement()}
				
		
				{showThemeEditPanel && <ThemeEditPanel themeEdit={themeEdit} setThemeEdit={setThemeEdit} setNeedUpdateInstantMeeting={setNeedUpdateInstantMeeting} setShowToast={setShowToast} setToast={setToast} />}
		
				{showToast && toast && <div className='homepage__clipped'>
					<div className='pill'>
						<p>{toast}</p>
					</div>
				</div>}
			</div>
		</>
	)
};

export default Home;
