const shortcuts = [
    {
        label: 'Envoyer des réactions approuver',
        translate:'shortcuts-thumbs-up',
        shortcode: 'Alt + T',
        shortcodeMac: 'Option + T',
    },
    {
        label: 'Envoyer des réactions applaudir',
        translate:'shortcuts-applause',
        shortcode: 'Alt + C',
        shortcodeMac: 'Option + C',
    },
    {
        label: 'Envoyer des réactions rire',
        translate:'shortcuts-laugh',
        shortcode: 'Alt + L',
        shortcodeMac: 'Option + L',
    },
    // {
    //     label: 'Envoyer des réactions d\'étonnement',
    //     translate:'shortcuts-surprise',
    //     shortcode: 'Ctrl + O',
    //     shortcodeMac: 'Option + O',
    // },
    {
        label: 'Envoyer des réactions huer',
        translate:'shortcuts-unhappy',
        shortcode: 'Alt + B',
        shortcodeMac: 'Option + B',
    },
    {
        label: 'Ouvrir le panneau de participant',
        translate: 'shortcuts-participant-panel',
        shortcode: 'Alt + P',
        shortcodeMac: 'Option + P',
    },
    {
        label: 'Ouvrir le panneau de chat',
        translate:'shortcuts-chat-panel',
        shortcode: 'Alt + U',
        shortcodeMac: 'Option + U',
    },
    // {
    //     label: 'Ouvrir le panneau de statistiques',
    //     translate:'shortcuts-analytics-panel',
    //     shortcode: 'Ctrl + S',
    //     shortcodeMac: 'Option + S',
    // },
    {
        label: "Ouvrir le panneau d'application",
        translate:'shortcuts-apps-panel',
        shortcode: 'Alt + A',
        shortcodeMac: 'Option + A',
    },
    {
        label: 'Fermer le volet droit',
        translate:'shortcuts-close-panel',
        shortcode: 'Alt + X',
        shortcodeMac: 'Option + X',
    },
];
    
export default shortcuts;