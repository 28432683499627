const emojiSymbols = [
    '🙂', '😀', '😄', '😁', '😆', '😉', '😊', '🙃',
    '😇', '😅', '🥲', '🤣', '😂', '😙', '😘', '🥰',
    '😍', '🤩', '🤑', '😋', '😜', '🤪', '😝', '🤐',
    '🤗', '🤭', '🫢', '🫣', '🤫', '🤔', '🫡', '🥱',
    '🤨', '😐', '😑', '😶', '😒', '🙄', '😕', '🙁',
    '😬', '🤥', '😏', '😌', '😔', '😪', '🤤', '🫠',
    '😷', '🤒', '🤕', '🤧', '🤢', '🤮', '🥵', '🥶',
    '🥴', '😵', '😵‍💫', '🤯', '😴', '😶‍🌫️', '😮‍💨', '😤',
    '🥳', '😎', '🤓', '🧐', '🥺', '😳', '😯', '😦',
    '😮', '😫', '😖', '😣', '😞', '😓', '😢', '😭',
    '😨', '😱', '😠', '🤬', '😈', '👽', '🤡', '🤖',
    '👻', '🦄', '🙈', '🙉', '🙊', '💤', '💣', '💥',
    '💫', '🌹', '💐', '💯', '💔', '❤️', '❤️‍🔥', '👋',
    '👍', '👎', '🤛', '✊', '🫵', '👌', '🖖', '🤙',
    '☝️', '🤞', '🤘', '🫶', '🙌', '👏', '🙏', '💪' 
];
export default emojiSymbols;