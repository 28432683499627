import React from 'react';
import IconSvg from './IconSvg';

const Input = ({
	icon,
	placeholder,
	id,
	className,
	defaultValue,
	alt,
	minLength,
	maxLength,
	autoFocus = true,
	onBlur,
	onFocus,
	...rest
}) =>
	<div className={className}>
		{icon && (
			// <img
			// 	src={icon}
			// 	width={24}
			// 	alt={alt}
			// />
			<IconSvg svg={icon} />
		)}
		<input
			id={id}
			autoFocus={false}
			minLength={minLength}
			maxLength={maxLength}
			autoComplete='off'
			className={`input-frame__input ${!!icon ? 'has-icon' :''}`}
			placeholder={placeholder}
			value={defaultValue}
			{...rest}
		/>
	</div>;


export default Input;