const formatTime = (seconds, hasLabel = true) => {
    if (seconds <= 0) return '00:00:00';
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
  
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = remainingSeconds.toString().padStart(2, '0');
    if (hasLabel) {
        return `${formattedHours}h${formattedMinutes}m${formattedSeconds}s`;
    } else {
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }
};
  
export default formatTime;