import React from "react";
import useAuthContext from "../../hooks/useContext";
import CrossSvg from '../../assets/icons/cross.svg';
import useMediaQuery from "../../hooks/useMediaQuery";
import { MEDIUM_SCREEN } from "../../utils/breackpoints";
import IconSvg from "../IconSvg";
const SidePanel = ({ title, hiddenPanelState, children }) => {
    
    const { translateJSON } = useAuthContext();
    const isLargeScreen = useMediaQuery(MEDIUM_SCREEN);

    return (
        <div className='meetroom-side-panel'>
            <div className='meetroom-side-panel__heading'>
                <h2>{title}</h2>
                <div className='exit-frame' data-size='medium' data-interactive={true} onMouseDown={hiddenPanelState}>
                    {isLargeScreen ? <IconSvg svg={CrossSvg} /> : <button className="btn-close" >{translateJSON['btn-close']}</button>}
                </div>
            </div>
            {children}
        </div>
    );
}

export default SidePanel;