import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";

import QASvg from '../../../assets/icons/q&a.svg';
import DotsSvg from '../../../assets/icons/three-dots.svg';
import CrossSvg from '../../../assets/icons/cross-24.svg';
import DeleteSvg from '../../../assets/icons/minus-icon.svg';
import AnswerSvg from '../../../assets/icons/share.svg';
import useAuthContext, { useGeneralContext } from "../../../hooks/useContext";
import ParticipantModal from "../../../dataModal/ParticipantModal";
import { fetchAvatarFromCaches } from "../../../helpers/auth";
import { participantUpdated, selectAllParticipants, selectLocalParticipant } from "../../../redux/participantsSlice";
import { getInitialFromString } from "../../../utils/getInitialFromString";
import IconSvg from "../../IconSvg";
import { PopupShowDuration } from "../../../pages/MeetRoom";
const QAItem = (props)=>{
    const {
        isInPopUp = false,
        question:{
            qna_uuid: questionUUID,
            question,
            answer,
            asked_by: askedBy,
            answered_by: answeredBy
        },
        sender: senderFromProps,
        isSameWithPrevious,
        isSameWithNext,
        setQuestionAction = ()=>{},
        setQuestionToAnswer = ()=>{},
        updateShow = ()=>{}
    } = props;

    const dispatch = useDispatch();
    
    const allParticipants = useSelector(selectAllParticipants);
    const currentParticipant = useSelector(selectLocalParticipant);
    const { teamID, userID, translateJSON } = useAuthContext();
    const sender = new ParticipantModal({ ...senderFromProps });
    const avatarRef = useRef();
    const avatarAnswerRef = useRef();
    const [showMoreOption, setShowMoreOption] = useState(false);
    const isYouAsked = askedBy === currentParticipant?.identity;
    const isYouAnswered = answeredBy === currentParticipant?.identity;
    const isRightSide = isInPopUp ? '' : isYouAsked ? 'is-right-side' : 'is-left-side';
    const modalRef = useRef();
    let timer;
    const isLoggedIn = !!teamID && !!userID;
    const fetchImage = async () => {
        if (!isLoggedIn) return;

        if (sender?.picture?.startsWith('data:image')) return;
        const blob = await fetchAvatarFromCaches(sender?.picturePath);
        if (blob) {
            if (avatarRef.current) {
                avatarRef.current.src = blob;
            }
            dispatch(participantUpdated({ ...sender, picture: blob }));
        }
    };
    const fetchAnswerImage = async () => {
        if (!isLoggedIn) return;

        if (answeredByParticipant?.picture?.startsWith('data:image')) return;
        const blob = await fetchAvatarFromCaches(answeredByParticipant?.picturePath);
        if (blob) {
            if (avatarAnswerRef.current) {
                avatarAnswerRef.current.src = blob;
            }
            dispatch(participantUpdated({ ...answeredByParticipant, picture: blob }));
        }
    };

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setShowMoreOption(false);
        }
      };
      
    useEffect(()=>{
        if(!isInPopUp) return;
        if(timer){
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            updateShow();
        }, PopupShowDuration);
    },[isInPopUp]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            if(timer){
                clearTimeout(timer);
            }
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const initial = getInitialFromString(sender.userName);
    
    const className = `meetroom-qa-item ${isRightSide} ${!isSameWithPrevious ? 'not-same-with-previous' : ''} ${!isSameWithNext ? 'not-same-with-next' : ''}`;
    
    const answeredByParticipant = allParticipants.find(item=>item?.identity === answeredBy);
    const answeredInitial = getInitialFromString(answeredByParticipant?.userName);
    return (
        <div className={className}>

            <div className={`meetroom-qa-item__container ${isInPopUp ? 'is-in-popup':''}`}>
                <div className='meetroom-qa-item__container__header'>
                    <div className='meetroom-qa-item__container__header__title' data-size="large">
                        <IconSvg svg={QASvg} />
                        <span type={isInPopUp ? 'title-in-popup' : 'title-in-item'}>{!!answer ? translateJSON['qa-closed-title']: translateJSON['qa-started-title']}</span>
                    </div>
                    {isInPopUp && <IconSvg classAdd={'close'} svg={CrossSvg} 
                        onMouseDown={(event)=>{
                            event?.stopPropagation();
                            updateShow();
                        }}/>}
                    {!isInPopUp && isYouAsked && <IconSvg classAdd={'more'} svg={DotsSvg} 
                        onMouseDown={(event)=>{
                            event?.stopPropagation(); 
                            setShowMoreOption(true);
                        }} />}
                    {showMoreOption && <div className="more-options" ref={modalRef}>
                        <div className="item" onMouseDown={(event)=>{
                            event?.stopPropagation();
                            setQuestionAction({action: 'delete', questionUUID });
                            setShowMoreOption(false);
                        }}>
                            <IconSvg svg={DeleteSvg} />
                            <span>{translateJSON['qa-delete-question']}</span>
                        </div>
                </div>}
                </div>
                <div className='meetroom-qa-item__container__body'>
                    <div className="top">
                        <div className="creator">
                            {(isLoggedIn && sender?.picture)
                                ? <LazyLoadImage beforeLoad={() => fetchImage()} src={sender.picture} ref={avatarRef}  alt='' />
                                : <div className='macaroon' style={{ backgroundColor: sender.color }}><span>{initial}</span></div>
                            }
                            <span type='username'>{isYouAsked ? translateJSON['polls-you'] : sender.userName}</span>
                        </div>
                        <p type='question'>{question}</p>
                    </div>
                    
                    {!answer && <div className="answer-btn" onMouseDown={()=>setQuestionToAnswer({questionUUID, question, senderUserName: senderFromProps?.userName})}>
                        <IconSvg svg={AnswerSvg} />
                        <p type='answer'>{translateJSON['qa-answer'] || 'Answer'}</p>
                    </div>}
                    {answer && <div className="answer-wrapper">
                            <div className="answer_by">
                                {(isLoggedIn && answeredByParticipant?.picture)
                                    ? <LazyLoadImage beforeLoad={() => fetchAnswerImage()} src={answeredByParticipant?.picture} ref={avatarAnswerRef}  alt='' />
                                    : <div className='macaroon' style={{ backgroundColor: answeredByParticipant?.color }}><span>{answeredInitial}</span></div>
                                }
                                <span type='username'>{isYouAnswered ?  translateJSON['polls-you'] : answeredByParticipant.userName}</span>
                            </div>
                            <p type='answer'>{answer}</p>
                        </div>}
                </div>
                
            </div>
        </div>);
}

export default QAItem;