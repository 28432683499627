import React, { cloneElement, useState } from "react";

import RaiseHand from '../../assets/icons/raise-hand-24.svg';
import reactionsList from "../../constants/reactionsList";
import useAuthContext, { useGeneralContext } from "../../hooks/useContext";
import useModalAnimated from "../../hooks/useModalAnimated";

const ReactionPanel = ({ showReaction = true, setShowReactionPanel, sendEmoji }) => {

    const { translateJSON } = useAuthContext();
    const { setRaisingHand } = useGeneralContext();
    const { isAnimated } = useModalAnimated();
    const [allowClickEmoji, setAllowClickEmoji] = useState(true);

    const clickOnEmoji = (emoji) => {
        if (allowClickEmoji) {
            setAllowClickEmoji(false);
            sendEmoji(emoji);
            setTimeout(() => {
                setAllowClickEmoji(true);
            }, 500);
        }
        
    }
    if(!showReaction) return <></>;
    
    return (
        <>
            <div className='reaction-panel-overlay' onMouseDown={() => setShowReactionPanel(false)} />
            <div className={`reaction-panel ${isAnimated ? 'active' : ''}`}>
                
               <div className='reaction-panel__animated-img' >
                    {reactionsList.map((child, index) =>
                        cloneElement(
                            <div className='reaction-panel__animated-img__item'
                            onMouseDown={() => clickOnEmoji(child.key)} />,
                            { key: child.key },
                            <img src={child.src} alt={child.key} key={index} />
                        ))}
                </div>
                <button className="reaction-btn"
                    onClick={() => {
                        setShowReactionPanel(prev => !prev);
                        setRaisingHand(true);
                    }}>
                    <img src={RaiseHand} alt="raise hand" />
                    <span>{translateJSON["raise-hand"]}</span>
                </button>
            </div>
        </>
    )
};

export default ReactionPanel;