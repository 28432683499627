
import React, { useState, memo, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SettingsSvg from '../../assets/icons/settings-icon.svg';
import MosaicSvg from '../../assets/icons/mosaic.svg';
import CrownSvg from '../../assets/icons/crown-icon.svg';
import ThreeStarsSvg from '../../assets/icons/three-stars.svg';
import RocketSvg from '../../assets/icons/rocket.svg';
import DeviceSvg from '../../assets/icons/device.svg';
import UserProfileSvg from '../../assets/icons/user-profile.svg';
import MoreSvg from '../../assets/icons/plus-icon.svg';

import AdminConfig, { MeetEnterMode } from './Settings/AdminConfig';
import ScreenLayoutConfig from './Settings/ScreenLayoutConfig';
import PerformanceConfig from './Settings/PerformanceConfig';
import MoreConfig from '../MoreConfig';
import ProfileSettings from '../SharedComponents/ProfileSettings';
import { getDisableChatPopups, getEnableKeyboardShortcuts, getScreenLayout, getScreenTiles, setConfig } from '../../redux/meetSlice';
import useAuthContext, { useGeneralContext } from '../../hooks/useContext';
import useSharedPropsForSettingsModal from '../../hooks/useSharedPropsForSettingsModal';
import ModalWithTabs from '../SharedComponents/ModalWithTabs';
import AudioVideoConfig from '../SharedComponents/AudioVideoConfig';
import VisualEffectConfig from '../SharedComponents/VisualEffectConfig';
import isVisuelEffectSupported from '../../utils/checkPipelineSupport';
import { updateEvent } from '../../helpers/eventApi';
import IconSvg from '../IconSvg';

const MeetRoomSettingOptionsList = [
    { index: 0, title: 'Admin Settings', icon: CrownSvg, translate: 'admin-settings-title' },
    { index: 1, title: 'Screen Layout', icon: MosaicSvg, translate: 'settings-layout-title' },
    { index: 2, title: 'Visual Effect', icon: ThreeStarsSvg, translate: 'btn-visual-effects' },
    { index: 3, title: 'Devices', icon: DeviceSvg, translate: 'devices-title' },
    // { index: 4, title: 'Performance', icon: RocketSvg, translate: 'settings-performance' },
    { index: 5, title: 'Profile', icon: UserProfileSvg, translate: 'settings-profile-title' },
    { index: 6, title: 'More', icon: MoreSvg, translate: 'btn-more' }
];

const SettingsModal = (props) => {

    const { 
        isHost,
        enterMode,
        setEnterMode,
        liveSettings,
        setLiveSettings,
        optionInfoModal,
        setOptionInfoModal,
        closeModal
    } = props;
    const isPipelineSupported = isVisuelEffectSupported();
    const dispatch = useDispatch();
    const { translateJSON, teamID, userID } = useAuthContext();
    const { isReconnectAttemptFinished }= useGeneralContext();
    const { audioVideoConfigProps, profilConfigProps, visualEffectConfigProps, saveUpdated } = useSharedPropsForSettingsModal();
    
	const { currentEventUUID: eventUUID} = useGeneralContext();
    const screenLayoutTypeRedux = useSelector(getScreenLayout);
    const screenLayoutTilesRedux = useSelector(getScreenTiles);
    const [screenLayoutType, setScreenLayoutType] = useState(screenLayoutTypeRedux);
    const [screenLayoutTiles, setScreenLayoutTiles] = useState(screenLayoutTilesRedux);

    const enableKeyboardShortcuts = useSelector(getEnableKeyboardShortcuts);
    const disableChatPopups = useSelector(getDisableChatPopups);
    const [enableShortCuts, setEnableShortCuts] = useState(enableKeyboardShortcuts);
    const [turnOffChat, setTurnOffChat] = useState(disableChatPopups);
    const [enterModeUpdate, setEnterModeUpdate] = useState(enterMode);
    const enterModeInitRef = useRef(enterMode);
    const [liveSettingsUpdate, setLiveSettingsUpdate] = useState(liveSettings);
    const liveSettingsInitRef = useRef(liveSettings);
    const adminConfigProps = {
        enterModeUpdate, 
        setEnterModeUpdate,
        liveSettingsUpdate,
        setLiveSettingsUpdate
    };
    const screenLayoutConfigProps = {
        screenLayoutType,
        setScreenLayoutType,
        screenLayoutTiles,
        setScreenLayoutTiles
    };

    const moreConfigProps = {
        enableShortCuts,
        setEnableShortCuts,
        turnOffChat,
        setTurnOffChat,
        optionInfoModal,
        setOptionInfoModal
    };

    const isHostAndAuth = isHost && teamID && userID;
    let list = MeetRoomSettingOptionsList;
    if(!isHostAndAuth){
        list = list.filter(item=>item.index !== 0)
    }
    if(!isPipelineSupported){
        list = list.filter(item=>item.index !== 2)
    }
    const [selectedOption, setSelectedOption] = useState(list?.[0]?.index);
  
    const isEqual = (newValue, oldValue) =>{
        if (typeof newValue !== 'object' || newValue === null ||
            typeof oldValue !== 'object' || oldValue === null) {
          return newValue === oldValue;
        }
      
        const keys1 = Object.keys(newValue);
        const keys2 = Object.keys(oldValue);
        if (keys1.length !== keys2.length) {
          return false;
        }
      
        for (let key of keys1) {
          if (!keys2.includes(key) || !isEqual(newValue[key], oldValue[key])) {
            return false;
          }
        }
      
        return true;
      }
    const handleClickSave = () => {

        saveUpdated();
        dispatch(
            setConfig({
                enableKeyboardShortcuts: enableShortCuts,
                disableChatPopups: turnOffChat,
                screenLayout: screenLayoutType,
                screenTiles: screenLayoutTiles,
            })
        );
        //call api to update entermode
        if (isHost && (enterModeInitRef.current !== enterModeUpdate || !isEqual(liveSettingsUpdate, liveSettingsInitRef.current))) {
            updateEvent({ teamID, userID, eventUUID, enterMode: enterModeUpdate, liveSettings: liveSettingsUpdate })
            .then((res) => {
                if(res?.enterMode){
                    setEnterMode(res?.enterMode)
                    if(res?.enterMode === MeetEnterMode.KNOCK && isReconnectAttemptFinished){
                        const message = translateJSON['websocket-failed'];
                        window.bus.publish("alert",  { message });
                    }
                }
                if(res?.liveSettings){
                    setLiveSettings(res?.liveSettings);
                }

            }).catch((err) => {
                 console.error('updateEvent failed',err)
            });
        }
        
        closeModal();
    }
    
    const renderOptionContent = () => {
        let content;
        switch (selectedOption) {
            case 0:
                content = <AdminConfig {...adminConfigProps} />;
                break;
            case 1:
                content = <ScreenLayoutConfig {...screenLayoutConfigProps} />
                break;
            case 2:
                content = <VisualEffectConfig {...visualEffectConfigProps} isInMeetRoom={true} />
                break;
            case 3:
                content = <AudioVideoConfig {...audioVideoConfigProps} isInMeetRoom={true} />
                break;
            case 4:
                content = <PerformanceConfig />
                break;
            case 5:
                content = <ProfileSettings {...profilConfigProps} isInMeetRoom={true} />
                break;
            case 6:
                content = <MoreConfig {...moreConfigProps} />
                break
            default:
                break;
        }
        return content;
    }

    const options = list.map(option =>
        <button
            key={option?.index}
            className={`btn-icon ${selectedOption === option?.index ? 'active' : ''}`}
            onClick={() => setSelectedOption(option?.index)}
            data-size={'large'}>
            <IconSvg svg={option.icon} />
            <span>{translateJSON[option.translate]}</span>
        </button>);
    const footerButtons = <>
        <button className={'btn-regular'} onClick={props.closeModal}>
            <span>{translateJSON['btn-cancel']}</span>
        </button>
        <button className={'btn-regular'} onClick={handleClickSave}>
            <span>{translateJSON['btn-save']}</span>
        </button>
    </>;
    return (
        <ModalWithTabs
            isInMeetRoom={true}
            maxWidth={800}
            minHeight={435}
            headingSvg={SettingsSvg}
            headingTitle={translateJSON['settings-title']}
            closeModal={props.closeModal}
            options={options}
            content={renderOptionContent()}
            footerButtons={footerButtons} />
    )
};

export default memo(SettingsModal);