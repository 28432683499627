import React, { useEffect, useRef, useState } from "react";
import PollsSvg from '../../../assets/icons/polls.svg';
import IconSvg from "../../IconSvg";
import useAuthContext from "../../../hooks/useContext";

import profilSvg from '../../../assets/icons/profil-photo-nav.svg';
import CrossSvg from '../../../assets/icons/cross-24.svg';
import CheckSvg from '../../../assets/icons/check-24.svg';
import useModalAnimated from "../../../hooks/useModalAnimated";

const PostPollConfirmationModal = ({ poll = {}, readyToPostPoll, closeModal })=>{

    const {question, options = [], showResults, addTimer, duration} = poll;
    const avatarRef = useRef();
    const { userAvatar, userName, translateJSON } = useAuthContext();
	const { isAnimated } = useModalAnimated();
 
	const [ allowPost, setAllowPost ] = useState(true);
    useEffect(() => {
		if (avatarRef.current) {
			avatarRef.current.src = userAvatar ?? profilSvg;
		}
	}, [userAvatar, avatarRef.current]);
    

    const formatDuration = (minutes) => {
        if(minutes <= 0) return null;
        const days = Math.floor(minutes / (60 * 24));
        const hours = Math.floor((minutes % (60 * 24)) / 60);
        const remainingMinutes = minutes % 60;

        let res = ''
        if(days > 0){
            res += `${days} ${translateJSON['post-poll-day']}`;
        }
        if(hours > 0) {
            if(res){
                res += ' '; 
            }
            res += `${hours} ${hours > 1 ? translateJSON['global-hours'] : translateJSON['global-one-hour']}`;
        }
        if(remainingMinutes > 0){
            if(res){
                res += ' '; 
            }
            res += `${remainingMinutes} ${remainingMinutes > 1 ? translateJSON['counter-minutes'] : translateJSON['counter-minute']}`;
        }
        return res;
    };

	const durationString = formatDuration(duration);
    return(
        <div className={`post-poll-confirmation-modal ${isAnimated ? 'active' : ''}`}>
            <div className='post-poll-confirmation-modal__frame'>
                <div className="post-poll-confirmation-modal__container">
                    <div className='post-poll-confirmation-modal__container__header'>
                        <div className='post-poll-confirmation-modal__container__header__title' data-size="large">
                            <IconSvg svg={PollsSvg} />
                            <h3>{translateJSON['post-poll-title'] || 'Post poll'}</h3>
                        </div>
                        <IconSvg svg={CrossSvg} onMouseDown={closeModal} />
                    </div>
                    <div className='post-poll-confirmation-modal__container__body'>
                        <span type='title'>{translateJSON['post-poll-desc'] || 'Post your poll in the chat?'}</span>
                        <div className='post-poll-confirmation-modal__container__body__detail'>
                            <div className="creator">
                                <img src={avatarRef.current?.src ?? profilSvg} ref={avatarRef} alt="portrait" />
                                <span type='username'>{userName}</span>
                            </div>
                            <span type='question'>{question}</span>
                            <div className="options">
                                {options.map((item,index)=><p key={index}>{item}</p>)}
                            </div>

                            {(showResults || addTimer) && <div className='more-info'>
                                <span type='show-results'>{showResults ? `${translateJSON['poll-vote-to-view'] || 'Vote to view results'}` :''}</span>
                                {addTimer && <span type='duration'>{durationString}</span>}
                            </div>}
                        </div>
                    </div>
                    <div className='post-poll-confirmation-modal__container__footer'>
                        
                        <button className='btn-regular' onClick={closeModal}>
                            <IconSvg svg={CrossSvg} />
                            <span>{translateJSON['btn-post-poll-edit'] || 'Keep editing'}</span>
                        </button>
                        <button className='btn-regular' 
                            onClick={()=>{
                                if(allowPost){
                                    setAllowPost(false);
                                    readyToPostPoll()
                                }
                            }}>
                            <IconSvg svg={CheckSvg} />
                            <span>{translateJSON['btn-post-poll-confirm'] || 'Yes, post poll'}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PostPollConfirmationModal;