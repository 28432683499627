import React from "react";
import { Range, getTrackBackground } from "react-range";

const STEP = 1;
const MIN = 0;
const MAX = 3;

class VideoQualityRange extends React.Component {
  state = {
    values: [2]
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          margin: "0em"
        }}
      >
        <Range
          values={this.state.values}
          step={STEP}
          min={MIN}
          max={MAX}
          onChange={(values) => this.setState({ values })}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: "36px",
                display: "flex",
                
                width: "100%"
              }}
            >
              <div
                ref={props.ref}
                className="quanlity-range"
                style={{
                  height: "4px",
                  width: "100%",
                  borderRadius: "6px",
                  border:'1px solid #a64FFF16',
                  boxShadow:'1px 1px 1px #a64FFF08 inset,-1px -1px 1px #a64FFF08 inset',
                  background: getTrackBackground({
                    values: this.state.values,
                    colors: ["#A64FFF", "#6370E740"],
                    min: MIN,
                    max: MAX
                  }),
                  alignSelf: "center"
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: "10px",
                width: "10px",
                borderRadius: "50%",
                backgroundColor: "#FFF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <div
                style={{
                  height: "10px",
                  width: "10px",
                  borderRadius: "50%",
                  background: "linear-gradient(to right, #A64FFF, #6370E7)",
                }}
              />
            </div>
          )}
          renderMark={({ props, index }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: "2px",
                width: "2px",
                backgroundColor: index === 0 ? "#A64FFF" : "#CCC4"
              }}
            />
          )}
        />
        {/* <output style={{ marginTop: "30px" }} id="output">
          {this.state.values[0].toFixed(1)}
        </output> */}
      </div>
    );
  }
}

export default VideoQualityRange;
