import React, { useEffect, useState } from "react";

import { ReactComponent as CautionSvg } from '../../assets/icons/caution.svg';
import useAuthContext, { useAudioVideoContext } from "../../hooks/useContext";
import { PermissionStatus } from "../../context/AudioVideoContextProvider";

const CameraFailedMessage = ({ isSmall = false, cameraError = null}) => {
    const { translateJSON } = useAuthContext();
    const { cameraPermission }= useAudioVideoContext();
    const [message, setMessage] = useState({});
    useEffect(() => {
        let title = '';
        let desc = '';
        switch (cameraPermission) {
            case PermissionStatus.DENIED:
                title = 'blocked-cam-title';
                desc = 'blocked-cam-desc';
                break;
            case PermissionStatus.PROMPT:
                title = 'allow-cam-title';
                desc = 'allow-cam-desc';
                break;
            case PermissionStatus.INVALID:
                title = 'cam-failed-title';
                desc = 'cam-failed-desc';
                break;
            case PermissionStatus.GRANTED:
                if(cameraError){
                    title = 'cam-failed-title';
                    desc = 'cam-failed-desc';
                }
                break;
            default: break;
        }
        setMessage({ title: translateJSON?.[title], desc: translateJSON?.[desc] });
    }, [JSON.stringify(translateJSON), cameraPermission, cameraError]);

    const hasError = message?.title && message?.desc;
    if (!hasError) {
        return <></>;
    }
    return (<div className="camera-fetch-failed-message">
        <div className='camera-fetch-failed-message__title'>
            <CautionSvg />
            <span>{message?.title}</span>
        </div>
        {!isSmall && <div className='camera-fetch-failed-message__body'>
            <span>{message?.desc}</span>
        </div>}
    </div>);
};

export default CameraFailedMessage