import React from "react";
import IconSvg from "../../IconSvg";
import CrossSvg from '../../../assets/icons/cross-gray.svg';
import ErrorInfoSVG from '../../../assets/icons/error-info.svg';
import useAuthContext from "../../../hooks/useContext";

const PollActionModal = ({ pollUUID, action, closeModal, deletePoll, closePoll })=>{

    const { translateJSON } = useAuthContext();
    const title = action === 'delete' ?  translateJSON['poll-delete']: translateJSON['poll-close'];

    const part = action === 'delete' ? translateJSON['poll-delete-desc'] : translateJSON['poll-close-desc'];
    console.log('part',part)
    const array = part.split('? ');
    console.log('array',array)
    let part1 = part, part2 = '';
    if(array?.length){
        part1 = `${array[0]}? `;
        part2 = array[1];
    }
    const btnTitle = action === 'delete' ? translateJSON['btn-delete'] : translateJSON['btn-close'];

    const color = action === 'delete' ?'#C74747' : '#FF7B11';
    return(
        <div className='poll-action-modal'>
            <div className='poll-action-modal-mask' />

            <div className='poll-action-modal-container'>

                <div className='poll-action-modal-container__body'>
                    <div className='poll-action-modal-container__body__header'>
                        <div className='poll-action-modal-container__body__header__close'  onMouseDown={closeModal}>
                            <IconSvg svg={CrossSvg} />
                        </div>

                        <div className='poll-action-modal-container__body__header__warning'>
                            <IconSvg svg={ErrorInfoSVG} backgroundAdd={color} />
                        </div>
                    </div>
                    <span type='title'>{title}</span>
                    <div className="info">
                        <span type='part'>{part1}</span>
                        <span type='part'>{part2}</span>
                    </div>

                    </div>
                <div className='poll-action-modal-container__footer'>
                    <button style={{border: `1px solid ${color}`, background: 'transparent' }} onClick={closeModal}>
                        <span style={{color}}>Cancel</span>
                    </button>
                    <button style={{background: color, border: 'unset'}} 
                        onClick={()=>{ 
                            if(action === 'delete'){
                                deletePoll(pollUUID);
                            }else{
                                closePoll(pollUUID);
                            }

                            closeModal();
                        }}>
                        <span style={{color: "white"}}>{btnTitle}</span>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PollActionModal;