import React, { useEffect } from 'react';

import Input from '../Input';
import CheckWiredSvg from '../../assets/icons/check-wired.svg';
import CheckCheckedSvg from '../../assets/icons/check-checked.svg';
import useAuthContext, { useGeneralContext } from '../../hooks/useContext';
import IconSvg from '../IconSvg';

const PreviewWithUserNameInput = () => {
    const { translateJSON, setUserName, userName, teamId, userId } = useAuthContext();
    const handleValueChange = (e) => {
        if (setUserName) {
            if (!userName.length && e.target.value.trim() === '') {
                return;
            }
            setUserName(e.target.value);
            const isLoggedIn = teamId && userId;
            if (!isLoggedIn) {
                localStorage.setItem('user_name_non_auth', e.target.value);
            }
        }

    };

    useEffect(() => {
        if (!teamId && !userId) {
            setUserName(localStorage.getItem('user_name_non_auth') ?? '');
        };   
    },[])
    return (
        <Input
            minLength='1'
            maxLength='60'
            className='input-frame'
            value={userName}
            id='text_user_name'
            placeholder={translateJSON['placeholder-prejoin-field-enter-name']}
            onChange={handleValueChange}
        />
    );
};


export const PreviewWithUserJobTitleInput = () => {

    const { jobTitle, setJobTitle, translateJSON } = useAuthContext();
    const { showJobTitle, setShowJobTitle } = useGeneralContext();
    const handleValueChange = (e) => {
       setJobTitle(e.target.value);
    };

    return (
        <>
            <Input
                minLength='1'
                maxLength='60'
                className='input-frame'
                value={jobTitle}
                id='text_user_job_title'
                placeholder={translateJSON['prejoin-placeholder-role']}
                onChange={handleValueChange}
            />
            <div className='check-frame'>
                <div onMouseDown={() => setShowJobTitle(prev=>!prev) }>
                    <IconSvg svg={showJobTitle ? CheckCheckedSvg : CheckWiredSvg} />
                    <div className='btn-check__right' >
                        <span>{translateJSON['prejoin-show-role']}</span>
                    </div>
                </div>
               
            </div> 
        </>
    );
};

export default PreviewWithUserNameInput;
