import usa from '../assets/flags/Continent=America, Country=usa.svg';
import france from '../assets/flags/Continent=Europe, Country=france.svg';
import germany from '../assets/flags/Continent=Europe, Country=germany.svg';
import italy from '../assets/flags/Continent=Europe, Country=italy.svg';
import netherland from '../assets/flags/Continent=Europe, Country=netherland.svg';
import spain from '../assets/flags/Continent=Europe, Country=spain.svg';
import portugal from '../assets/flags/Continent=Europe, Country=portugal.svg';
const codeToLanguage = {
    EN: 'English',
    FR: 'Français',
    ES: 'Español',
    IT: 'Italiano',
    DE: 'Deutsch',
    PT: 'Português',
    NL: 'Nederlands'
};

const flagToLanguage = {
    EN: usa,
    FR: france,
    ES: spain,
    IT: italy,
    DE: germany,
    PT: portugal,
    NL: netherland
};
const languageOptions = Object.keys(codeToLanguage).map(key => {
    return { key, label: codeToLanguage[key], img: flagToLanguage[key] }
});

const getLanguageByCode = (code) => codeToLanguage[code.toUpperCase()];
const getFlagByCode = (code) => flagToLanguage[code.toUpperCase()];


export { getLanguageByCode, getFlagByCode, languageOptions };