
const resetBlank = (str) => {
    if (!str) return null;
	return str.replace(/\s+/g, ' ');
};

const getFirstInitialFromSubStr = (substr) => {
    if (!substr) return null;
    const emojiRegex = /[\p{Emoji}]/gu;
    const matchFirst = emojiRegex.exec(substr);
    return matchFirst && matchFirst.index === 0 ? matchFirst[0].toUpperCase() : substr[0].toUpperCase();
};
export const getInitialFromString = (str) => {
	if (!str) return '?';
    const strArray = resetBlank(str).trim().split(' ');
    let value = '';

    if (strArray[0]) value += getFirstInitialFromSubStr(strArray[0]);
    if (strArray[1]) value += getFirstInitialFromSubStr(strArray[1]);

    return value || '?';
};
