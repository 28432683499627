import React, { useEffect, useState, useRef } from "react";

import useAuthContext from "../../hooks/useContext";
import AddUserSvg from '../../assets/icons/add-user-icon.svg';
import CheckedSvg from '../../assets/icons/check-checked.svg';
import UnCheckSvg from '../../assets/icons/check-wired.svg';
import CloseSvg from '../../assets/icons/cross.svg';
import ChevronOpenSvg from '../../assets/icons/chevron-open.svg';
import ChevronCloseSvg from '../../assets/icons/chevron-close.svg';
import ArrowDownSvg from '../../assets/icons/arrow-down.svg';
import ArrowUpSvg from '../../assets/icons/arrow-up.svg';
import BtnRegular from "../BtnRegular";
import IconSvg from "../IconSvg";

const RequestModal = ({
    onAccept,
    onDenied,
    requestList,
    closeModal,
    isInPanel = false
}) => {

    const ref = useRef(null);
    const { translateJSON } = useAuthContext();
    const [showMoreRequestPopup, setShowMoreRequestPopup] = useState(true);
    const [selected, setSelected] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const isCheckedAll = requestList.length === selected.length;
  
    const requestPopupTitle = requestList.length === 1 ? translateJSON['request-join-popup'] : translateJSON['request-join-popup-multi'];
    const requestPopupDescription = requestList.length === 1 ? `${requestList[0].name} ${translateJSON['request-popup-desc']}` : `${requestList.length} ${translateJSON['request-popup-multiple']}`;
    
    const handleClickOutside = (event) => {
        const isOutside = ref.current && !ref.current.contains(event.target)
        if (isOutside) {
            setIsOpen(false);
        }
    };
  
    const handleClickCheckAll = (e) => {
   
        setSelected(prev => {
            if (prev.length === requestList.length) {
                return [];
            } else {
                return [...requestList]
            }
        });
    
        e.stopPropagation();
    };

    const handleOptionClick = (option) => {

        setSelected(prev => {
            const isSelected = prev.findIndex(item => item.participantUUID === option.participantUUID) > -1;
            if (isSelected) {
                return prev.filter(item => item.participantUUID !== option.participantUUID);
            } else {
                return [...prev, option];
            }
        })
    };

    useEffect(() => {
        if (requestList.length === 1) {
            setSelected(requestList);
        }
    },[requestList.length])
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (<div className='participant-request-popup' ref={ref}>
        <div className='participant-request-popup__header'>
            <div className='participant-request-popup__header__title'>
                <IconSvg svg={AddUserSvg} />
                <span>{requestPopupTitle}</span>
            </div>
            {!isInPanel && <IconSvg svg={CloseSvg} 
            onMouseDown={(event)=>{
                event?.stopPropagation(); 
                closeModal()}
            } styleAdd={{cursor: 'pointer'}}/>}
            {isInPanel && showMoreRequestPopup && <IconSvg svg={ChevronOpenSvg} 
            onMouseDown={(event) => { 
                event?.stopPropagation(); 
                setShowMoreRequestPopup(prev => !prev) }} />}
            {isInPanel && !showMoreRequestPopup && <IconSvg svg={ChevronCloseSvg} 
            onMouseDown={(event) => { 
                event?.stopPropagation(); 
                setShowMoreRequestPopup(prev => !prev) }} />}

        </div>
        <div className='participant-request-popup__content'>
            <span>{requestPopupDescription}</span>
							
            {showMoreRequestPopup && requestList.length > 1 &&
                <div className={'check-box-select'} >
                    <div tabIndex={0} 
                        className={`check-box-select__value scroll-bar invisible`}
                        onMouseDown={() => {
                            if (requestList.length === 0) return;
                            setIsOpen(!isOpen);
                        }}>
                        <div onMouseDown={handleClickCheckAll}>
                            <IconSvg svg={isCheckedAll ? CheckedSvg : UnCheckSvg} />
                            <span> {translateJSON['select-all']}</span>
                        </div>
                        <IconSvg svg={isOpen ? ArrowUpSvg : ArrowDownSvg} />
                    </div>
                    {isOpen && (
                        <ul className="check-box-select__options scroll-bar invisible">
          
                            {requestList.map(o =>
                                <li key={`${o.participantUUID}`} onMouseDown={() => handleOptionClick(o)}>
                                    <IconSvg svg={selected.findIndex(item => item.participantUUID === o.participantUUID) > -1 ? CheckedSvg : UnCheckSvg} />
                                    <span>{o.name}</span>
                                </li>
                            )}
                        </ul>
                    )}
                </div>
            }
        </div>
        {showMoreRequestPopup && <div className='participant-request-popup__footer'>
            <BtnRegular content='Deny' event={() => onDenied(selected)} translate='btn-deny' />
            <BtnRegular content='Accept' event={() => onAccept(selected)} translate='btn-accept' />
        </div>}
    </div>);
}

export default RequestModal