import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import AddSmileySvg from '../../../assets/icons/add-smiley.svg';
import ArrowLeft from '../../../assets/icons/arrow-back-gray.svg';
import CloseSvg from '../../../assets/icons/cross-24.svg';
import ChatSvg from '../../../assets/icons/chat-icon.svg';
import QASvg from '../../../assets/icons/q&a.svg';
import QAIndiceSvg from '../../../assets/icons/polls-indice.svg';
import QA1PNG from '../../../assets/qa/qa-1.png';
import QA2PNG from '../../../assets/qa/qa-2.png';
import QA3PNG from '../../../assets/qa/qa-3.png';
import QA4PNG from '../../../assets/qa/qa-4.png';

import IconSvg from "../../IconSvg";
import useAuthContext from "../../../hooks/useContext";
import IntegrationCard from "../IntegrationCard";


const CardQA = ({isInMeetRoom = false, cardItem, closeCard, update, startNewQA}) =>{
    const images = [QA1PNG, QA2PNG, QA3PNG, QA4PNG];
    const { translateJSON } = useAuthContext();
    const indiceSvgs = <>
            <IconSvg svg={QASvg} classAdd={'qa'}/>
            <IconSvg svg={QAIndiceSvg} classAdd={'indice'}/>
        </>;
    const startBtnTitle = translateJSON['btn-start-qa'] || 'Start Q&A';
    const text = translateJSON['apps-qa-start-here-desc'];
    const phrasesToHighlight = [translateJSON['apps-polls-start-here-bold'],  translateJSON['apps-qa-start-here-bold']];
    const parts = [];
    let remainingText = text;
    
    phrasesToHighlight.forEach((phrase, index) => {
        const idx = remainingText.indexOf(phrase);
        if (idx !== -1) {
            if (idx > 0) {
                parts.push(remainingText.slice(0, idx));
            }
            parts.push(<strong key={index}>{phrase}</strong>);
            remainingText = remainingText.slice(idx + phrase.length);
        }
    });
    
    // Add any remaining text
    if (remainingText.length > 0) {
        parts.push(remainingText);
    }
    return (
        <IntegrationCard 
            isInMeetRoom={isInMeetRoom} 
            cardItem={cardItem} 
            closeCard={closeCard} 
            update={update} 
            startNew={startNewQA} 
            images={images} 
            checkLabel={translateJSON['apps-qa-allow'] || 'Allow Q&A in room'}
            startDesc={<p>{parts}</p>}
            indiceSvgs={indiceSvgs} 
            startBtnTitle={startBtnTitle} />
        
    );
}

export default CardQA;