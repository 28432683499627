import { useState, useEffect } from "react";
import useAuthContext, { useAudioVideoContext, useGeneralContext } from "./useContext";

const useSharedPropsForSettingsModal = () =>{
  const { userLang, setUserLang, userName, setUserName, userEmail, setUserEmail, jobTitle, setJobTitle} = useAuthContext();
    const {
        audioDeviceId,
        setAudioDeviceId,
        outputAudioSelected,
        setOutputAudioSelected,
        videoDeviceId,
        setVideoDeviceId,
        isActiveNoiseCancellation,
        setActiveNoiseCancellation,
        isActiveEchoCancellation,
        setActiveEchoCancellation, } = useAudioVideoContext();
    
    const {
        featureVisualEffect,
        setFeatureVisualEffect,
        showJobTitle,
        setShowJobTitle } = useGeneralContext();
    
    const [visualEffect, setVisualEffect] = useState(featureVisualEffect);
    const [userlangUpdated, setUserlangUpdated] = useState(userLang);
    
    const [userNameUpdated, setUserNameUpdated] = useState(userName);
    const [jobTitleUpdated, setJobTitleUpdated] = useState(jobTitle);
    const [showJobTitleUpdated, setShowJobTitleUpdated] = useState(showJobTitle);
    const [userEmailUpdated, setUserEmailUpdated] = useState(userEmail);
    const [inputAudioUpdate, setInputAudioUpdate] = useState(audioDeviceId);
    const [outputAudioUpdate, setOutputAudioUpdate] = useState(outputAudioSelected);
    const [cameraUpdate, setCameraUpdate] = useState(videoDeviceId);
    const [isActiveNoiseCancellationUpdate, setActiveNoiseCancellationUpdate] = useState(isActiveNoiseCancellation);
    const [isActiveEchoCancellationUpdate, setActiveEchoCancellationUpdate] = useState(isActiveEchoCancellation);
    
    const audioVideoConfigProps = {
        inputAudioUpdate, setInputAudioUpdate,
        outputAudioUpdate, setOutputAudioUpdate,
        cameraUpdate, setCameraUpdate,
        isActiveNoiseCancellationUpdate, setActiveNoiseCancellationUpdate,
        isActiveEchoCancellationUpdate, setActiveEchoCancellationUpdate
    }
    const profilConfigProps = {
        userlangUpdated, setUserlangUpdated,
        jobTitleUpdated, setJobTitleUpdated,
        showJobTitleUpdated, setShowJobTitleUpdated,
        userNameUpdated, setUserNameUpdated,
        userEmailUpdated, setUserEmailUpdated,
    }
    
    const visualEffectConfigProps = {
        visualEffect,
        setVisualEffect
    }

    const saveUpdated = () => {
        setAudioDeviceId(inputAudioUpdate);
        setOutputAudioSelected(outputAudioUpdate);
        setVideoDeviceId(cameraUpdate);
        setActiveNoiseCancellation(isActiveNoiseCancellationUpdate);
        setActiveEchoCancellation(isActiveEchoCancellationUpdate);

        setFeatureVisualEffect(visualEffect);

        setUserLang(userlangUpdated);
        setUserEmail(userEmailUpdated);
        setUserName(userNameUpdated);
        setJobTitle(jobTitleUpdated);
        setShowJobTitle(showJobTitleUpdated);

    };
        
    useEffect(() => {
        //listening update output audio select because on firefox need fetch output list after get audio stream, otherwise always will be undefined 
        setOutputAudioUpdate(outputAudioSelected);
    }, [outputAudioSelected])

    return { audioVideoConfigProps, profilConfigProps, visualEffectConfigProps, saveUpdated};
}

export default useSharedPropsForSettingsModal;