class StaggeredAnimation {
    constructor(selector,offset,direction = 'posY',duration = 1,easing = "linear", delay = 150) {
        this.elements = document.querySelectorAll(selector);
        this.duration = duration;
        this.easing = easing;
        this.direction = direction;
        this.offset = offset;
        this.delay = delay;
        this.initStatus();
    }

    // dans cette classe on va recuperer l'element du dom concerné

    initStatus(){
        this.elements.forEach((element) => {
            element.style.opacity = 0;
            switch(this.direction){
                case 'negY':
                    element.style.transform = "translateY(-"+this.offset+"px)";
                    break;
                case 'posY':
                    element.style.transform = "translateY("+this.offset+"px)";
                    break;
                case 'negX':
                    element.style.transform = "translateX(-"+this.offset+"px)";
                    break;
                case 'posX':
                    element.style.transform = "translateX("+this.offset+"px)";
                    break;
                default:
                    element.style.transform = "translateX("+this.offset+"px)";
                    break;
            }
        });
    }
    
    animate() {
        this.elements.forEach((element,index) => {
            setTimeout(()=>{
                element.style.transform = "translateY(0px)";
                element.style.transition = `all ${this.duration}s ${this.easing}`;
                element.style.opacity = 1;
            },(this.delay * index) + 50)
        });
    }


    animateGrey(){
        this.elements.forEach((element) => {
            element.style.filter = "grayscale(1)";
        });
    }
}


export default StaggeredAnimation;