import React from 'react';
const IconSvg = ({svg, text, classAdd, styleAdd, width = 24, height = 24, onMouseDown, maskSize='contain', backgroundAdd}) => {
    return (
        <div className={`icon-svg ${classAdd ?? ''}`} style={styleAdd} onMouseDown={onMouseDown}>
            <div
                style={{
                width,
                height,
                WebkitMaskImage: `url(${svg})`,
                maskImage: `url(${svg})`,
                maskSize,
                WebkitMaskSize:maskSize,
                background:backgroundAdd
            }}
               />
            {text && <span >{text}</span>}
        </div>
    )
};
export default IconSvg;