import LoungePNG from '../assets/backrgoundImages/0-lounge.png';
import CityPNG from '../assets/backrgoundImages/1-city.png';
import OfficePNG from '../assets/backrgoundImages/2-office.png';
import MoutainPNG from '../assets/backrgoundImages/3-moutain.png';
import PoolPNG from '../assets/backrgoundImages/4-pool.png';
import CountryPNG from '../assets/backrgoundImages/5-country.png';
import PlantsPNG from '../assets/backrgoundImages/6-plants.png';
import LivingPNG from '../assets/backrgoundImages/7-living.png';
import OceanPNG from '../assets/backrgoundImages/8-ocean.png';
import KitchenPNG from '../assets/backrgoundImages/9-kitchen.png';
import YellowPNG from '../assets/backrgoundImages/10-yellow.png';
import CozyPNG from '../assets/backrgoundImages/11-cozy.png';

import Background1JPG from '../assets/backrgoundImages/background-1.jpg';
import Background2JPG from '../assets/backrgoundImages/background-2.jpg';
import Background3JPG from '../assets/backrgoundImages/background-3.jpg'
import Background4JPG from '../assets/backrgoundImages/background-4.jpg';
import Background5JPG from '../assets/backrgoundImages/background-5.jpg';
import Background6JPG from '../assets/backrgoundImages/background-6.jpg';
import Background7JPG from '../assets/backrgoundImages/background-7.jpg';

const thumbnailsInVisualEffects = [
    // { src: LoungePNG, translate: 'background-lounge', legend: 'lounge'},
    // { src: CityPNG, translate: 'background-city',legend:'city' },
    // { src: OfficePNG, translate: 'background-office', legend: 'office' },
    // { src: MoutainPNG, translate: 'background-mountain', legend: 'moutain' },
    // { src: PoolPNG, translate: 'background-pool', legend:'pool' },
    // { src: CountryPNG, translate: 'background-country', legend:'country' },
    // { src: PlantsPNG, translate: 'background-plants', legend: 'plants' },
    // { src: LivingPNG, translate: 'background-living', legend: 'living' },
    // { src: OceanPNG, translate: 'background-ocean', legend: 'ocean'},
    // { src: KitchenPNG, translate: 'background-kitchen', legend:'kitchen' },
    // { src: YellowPNG, translate: 'background-yellow', legend: 'yellow' },
    // { src: CozyPNG, translate: 'background-cozy', legend:'cozy' }
    { src: Background1JPG, translate: '1', legend:'background-1' },
    { src: Background2JPG, translate: '2', legend:'background-2' },
    { src: Background3JPG, translate: '3', legend:'background-3' },
    { src: Background4JPG, translate: '4', legend:'background-4' },
    { src: Background5JPG, translate: '5', legend:'background-5' },
    { src: Background6JPG, translate: '6', legend:'background-6' },
    { src: Background7JPG, translate: '7', legend:'background-7' }
];

export default thumbnailsInVisualEffects;