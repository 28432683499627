import React from "react";
import RadioSvg from '../../../assets/icons/radio.svg';
import RadioCheckSvg from '../../../assets/icons/radio-check.svg';
import useAuthContext, { useGeneralContext } from "../../../hooks/useContext";
import IconSvg from "../../IconSvg";


export const MeetEnterMode = {
    NONE: "anyone with the link can enter",
    KNOCK: "knock to enter",
    PASSWORD: "password protected",
}

const AdminConfig = ({ enterModeUpdate, setEnterModeUpdate, liveSettingsUpdate, setLiveSettingsUpdate }) => {

    const { translateJSON } = useAuthContext();
    const { isReconnectAttemptFinished } = useGeneralContext();

    const enterModeElement = (mode, title, desc) =>
        <div className='check-frame option-frame' key={mode} onMouseDown={() => setEnterModeUpdate(mode)}>
            <div className="inputWrapper">
                <input
                    type="radio"
                    id="anyone"
                    value="anyone"
                    checked={enterModeUpdate === mode}
                    onChange={()=>setEnterModeUpdate(mode)}
                />
            </div>
            <div className='option-frame__text'>
                <span type='option-title'>{translateJSON[title]}</span>
                <span type='option-desc'>{translateJSON[desc]}</span>
            </div>
        </div>;

    const permissionElement = (key, title, desc) => {

        const checked = !liveSettingsUpdate[key];

        const update = () => setLiveSettingsUpdate(prev=>{
            const newVal =  {...prev, [key]: checked};
            return newVal
        });
            
        return (
            <div className='check-frame other-permissions' key={key}>
                <input type="checkbox" id={`switch-${key}`} checked={checked} onChange={update} />
                <label htmlFor={`switch-${key}`}>Toggle</label>
                <div className='option-frame__text' onMouseDown={update}>
                    <span type='option-title'>{title}</span>
                    <span type='option-desc'>{desc}</span>
                </div>
            </div>
        );
    }
    return (<div className='tab-content__block --admin'>
        <div className="access-permissions">
            <span type='title'>{translateJSON['access-permissions-title']}</span>
            <div className='access-permissions-list'>
                {enterModeElement('anyone with the link can enter', 'settings-host-withlink', 'host-withlink-desc')}
                {enterModeElement('knock to enter', 'settings-host-knock', 'host-knock-desc')}
            </div>
        </div>
        
        <div className="meeting-options">
            <span type='title'>{translateJSON['meeting-permissions-title']}</span>
            <div className='meeting-options-list'>
                {permissionElement('open_chat', translateJSON['openchat-tooltip'], translateJSON['permissions-chat-desc'])}
                {permissionElement('reactions', translateJSON['permissions-reactions-title'], translateJSON['permissions-reactions-desc'])}
                {permissionElement('screen_share', translateJSON['permissions-screen-sharing-title'], translateJSON['permissions-screen-sharing-desc'])}
            </div>
        </div>
            {/* <label className='option-frame'>
                <div className='option-frame__left'>
                    <div className='option-frame__picto'>
                        <IconSvg svg={EnterRoomSvg} />
                    </div>
                    <div className='option-frame__text'>
                        <div className='option-frame__label'>
                            <span>{translateJSON["settings-host-withlink"]}</span>
                        </div>
                        <div className='option-frame__legend'>
                            <span>{translateJSON["host-withlink-desc"]}</span>
                        </div>

                    </div>
                </div>
                <div className='option-frame__right'>
                    <div className="inputWrapper">
                        <input
                            type="radio"
                            id="anyone"
                            value="anyone"
                            checked={enterModeUpdate === MeetEnterMode.NONE}
                            onChange={()=>setEnterModeUpdate(MeetEnterMode.NONE)}
                        />
                    </div>
                </div>
            </label>

            <label className='option-frame'>
                <div className='option-frame__left'>
                    <div className='option-frame__picto'>
                        <IconSvg svg={LockerSvg} />
                    </div>
                    <div className='option-frame__text'>
                        <div className='option-frame__label'>
                            <span>{translateJSON["button-prejoin-send-request"]}</span>
                        </div>
                        <div className='option-frame__legend'>
                            <span>{translateJSON["host-knock-desc"]}</span>
                        </div>
                    </div>
                </div>
                <div className='option-frame__right'>
                    <div className="inputWrapper">
                        <input
                            type="radio"
                            id="knock"
                            value="knock"
                            checked={enterModeUpdate === MeetEnterMode.KNOCK}
                            onChange={()=>{
                                if(isReconnectAttemptFinished){
                                    const message = translateJSON['websocket-failed'];
                                    window.bus.publish("alert",  { message });
                                }else{
                                    setEnterModeUpdate(MeetEnterMode.KNOCK);
                                }
                            }}
                        />
                    </div>
                    
                </div>
            </label> */}

            {/* <label className='option-frame'>
                <div className='option-frame__left'>
                    <div className='option-frame__picto'>
                        <IconSvg svg={KeySvg} />
                    </div>
                    <div className='option-frame__text'>
                        <div className='option-frame__label'>
                            <span>{translateJSON["settings-host-password"]}</span>
                        </div>
                        <div className='option-frame__legend'>
                            <span>{translateJSON["host-password-desc"]}</span>
                        </div>
                    </div>
                </div>
                <div className='option-frame__right'>
                    <input
                        type="radio"
                        id="require"
                        value="require"
                        checked={enterModeUpdate === MeetEnterMode.PASSWORD}
                        onChange={()=>setEnterModeUpdate(MeetEnterMode.PASSWORD)}
                    />
                </div>
            </label> */}
    </div>);
};

export default AdminConfig;