import { dark, white } from "../constants/colors";

const itemReg = `\\s*\\d{1,3}\\s*`;

const rgbReg = new RegExp(`rgba?\\(${itemReg},${itemReg},${itemReg}(?:,\\s*(0|1|0?\\.\\d+)\\s*)?\\)`, 'gi');
const hexReg = /#([0-9a-fA-F]{3,8})/g;
const colorReg = new RegExp(`${rgbReg.source}|${hexReg.source}`, 'g');


const calculateLuminanceWithOpacity = (color) => {
  const opacity = color.a || 1;
  let r = color.r;
  r = (r <= 0.03928) ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4);
  let g = color.g;
  g = (g <= 0.03928) ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4);
  let b = color.b;
  b = (b <= 0.03928) ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4);
  
  let luminance;
  if (opacity !== 1) {
    const average = (r * 0.299 + g * 0.587 + b * 0.114) / 255;
    luminance = average * opacity;
  } else {
    luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b);
  }
  return luminance;
};

const parseRGBA = (rgbaString) => {
  const match = rgbaString.match(/rgba?\(([^)]*)\)/);
  if (match) {
    const values = match[1].split(",").map(parseFloat);
    return {
      r: values[0] / 255,
      g: values[1] / 255,
      b: values[2] / 255,
      a: values[3] ?? 1,
    };
  }
  return { r: 0, g: 0, b: 0, a: 1 };
};

const hexToRGB = (hex) => {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return { r: r / 255, g: g / 255, b: b / 255 };
};

const calculateRelativeLuminanceFromGradient = (color) => {
  if (color.startsWith("#")) {
    const rgbColor = hexToRGB(color);
    const luminance = calculateLuminanceWithOpacity(rgbColor);
    return luminance;
  }

  if (color.startsWith("rgb")) {
    const rgbaColor = parseRGBA(color);
    const luminance = calculateLuminanceWithOpacity(rgbaColor);
    return luminance;
  }

  return 0;
};

const calculate = (color) => {
  if (!color) return 0;

  const lowerCaseColorString = color.toLowerCase();
  const colors = lowerCaseColorString.match(colorReg);
  if (!colors) {
    console.error("Invalid gradient string");
    return 0;
  }
  const allLuminances = colors.map((col) => {
    const luminance = calculateRelativeLuminanceFromGradient(col);
    return luminance;
  });

  const averageLuminance = allLuminances.reduce((sum, value) => sum + value, 0) / allLuminances.length;
  return averageLuminance;
};

export const getSolidOrLinearGradientWithOpacity = (colorString, opacity) => {
  const lowerCaseColorString = colorString.toLowerCase();

  let res = lowerCaseColorString;
  res = res.replace(rgbReg, (matchColor) => {
    const match = matchColor.match(rgbReg);
    let rgbaObject = { r: 0, g: 0, b: 0, a: 1 };
    rgbaObject.r = parseInt(match[1], 10);
    rgbaObject.g = parseInt(match[2], 10);
    rgbaObject.b = parseInt(match[3], 10);
    rgbaObject.a = match[4] ? parseFloat(match[4]) : 1;
    const rgba = { ...rgbaObject, a: opacity };
    return `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`;
  });
  res = res.replace(hexReg, (matchColor) => {
    const match = matchColor.match(hexReg);
    let hex = match[0];
    let rgbaObject = { r: 0, g: 0, b: 0, a: 1 };
    if (hex.length === 4) {
      rgbaObject.r = parseInt(hex[1] + hex[1], 16);
      rgbaObject.g = parseInt(hex[2] + hex[2], 16);
      rgbaObject.b = parseInt(hex[3] + hex[3], 16);
    } else if (hex.length >= 7) {
      rgbaObject.r = parseInt(hex.slice(1, 3), 16);
      rgbaObject.g = parseInt(hex.slice(3, 5), 16);
      rgbaObject.b = parseInt(hex.slice(5, 7), 16);
    }
    const rgba = { ...rgbaObject, a: opacity };
    return `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`;
  });
  return res;
};

const whiteLuminance = calculate(white);
const darkLuminance = calculate(dark);

export const isLightBestSolidOrLinearGradient = (firstColor, secondaryColor = null) => {
  const first = calculate(firstColor);
  const alphaFirstColor = getOpacity(firstColor);
  if (alphaFirstColor > 0.75 || !secondaryColor) {
    const ratioWhite = (Math.max(first, whiteLuminance) + 0.05) / (Math.min(first, whiteLuminance) + 0.05);
    const ratioBlack = (Math.max(first, darkLuminance) + 0.05) / (Math.min(first, darkLuminance) + 0.05);
    return ratioWhite > ratioBlack;
  } else {
    const secondary = calculate(secondaryColor);
    const alphaSecondaryColor = getOpacity(secondaryColor);
    const average = (first * alphaFirstColor + secondary * alphaSecondaryColor) / (alphaFirstColor + alphaSecondaryColor);
    const ratioWhite = ((Math.max(average, whiteLuminance) * (1 - alphaFirstColor) + alphaFirstColor) + 0.05) / (Math.min(average, whiteLuminance) + 0.05);
    const ratioBlack = ((Math.max(average, darkLuminance) * (1 - alphaFirstColor) + alphaFirstColor) + 0.05) / (Math.min(average, darkLuminance) + 0.05);
    return ratioWhite > ratioBlack;
  }
};

export const getContrast = (color1, color2) => {
  if (!color1 || !color2) return null;
  const first = calculate(color1);
  const secondary = calculate(color2);
  const contrast = (Math.max(first, secondary) + 0.05) / (Math.min(first, secondary) + 0.05);
  return contrast;
};

export const getOpacity = (colorString) => {
  if (!colorString) return;
  const lowerCaseColorString = colorString.toLowerCase();
  if (lowerCaseColorString.startsWith("rgba")) {
    const match = lowerCaseColorString.match(/rgba\([^)]+\)/);
    if (match) {
      const rgbaValues = match[0].split(',').map(value => parseFloat(value.trim()));
      return rgbaValues[3];
    }
  } else if (lowerCaseColorString.startsWith("rgb")) {
    return 1;
  } else if (lowerCaseColorString.startsWith("#")) {
    const hexOpacity = lowerCaseColorString.slice(7, 9);
    if (hexOpacity.length === 2) {
      return parseInt(hexOpacity, 16) / 255;
    }
  }
  return 1;
};

export const checkColorIsSolidWithoutAlpha = (color) => {
  if (color.startsWith('linear') || color.startsWith('radial'))
    return false;
  const alpha = getOpacity(color);
  return alpha === 1;
}

export function linearGradientToHex(linearGradientString) {

  const hexColors = linearGradientString.replace(colorReg, match => {
    if (match.startsWith('rgba')) {
      return rgbaStringToHex(match);
    } else if (match.startsWith('rgb')) {
      return rgbStringToHex(match);
    } else {
      return match;
    }
  });
  return hexColors;
}

const rgbaStringToHex = (rgbaString) => {
  const color = rgbaString.toLowerCase();
  const rgbaArray = color.substring(5, color.length - 1).split(',').map(val => val.trim());
  const [r, g, b] = rgbaArray.slice(0, 3).map(val => Math.round(parseFloat(val)));
  const a = Math.round(rgbaArray[3] * 255);
  const alphaHex = a.toString(16).toUpperCase().padStart(2, '0');
  const rgbHex = [r, g, b].map(val => val.toString(16).toUpperCase().padStart(2, '0')).join('');
  return `#${rgbHex}${alphaHex}`;
};

const rgbStringToHex = (rgbString) => {
  const rgbArray = rgbString.substring(4, rgbString.length - 1).split(',').map(val => parseInt(val.trim()));
  const [r, g, b] = rgbArray;
  const rgbHex = [r, g, b].map(val => val.toString(16).toUpperCase().padStart(2, '0')).join('');
  return `#${rgbHex}`;
};
