import { generateRandom } from "../utils/generateRandom";
import isValidHttpUrl from "../utils/isValidHttpUrl";

const endpoints = 'https://auth.ringover.com/oauth2/authorize';
const access_token_url = 'https://auth.ringover.com/oauth2/access_token';
const introspect_url = 'https://auth.ringover.com/oauth2/introspect';
const logout_url = 'https://auth.ringover.com/oauth2/logout';
let tokenTimeout = null;
let apiV2Url = '';
let accessTokenPromise = Promise.resolve();
let user_region = '';

export function get_tokens() {
	try {
		return {
			access_token: localStorage.getItem('access_token'),
			refresh_token: localStorage.getItem('refresh_token'),
			id_token: localStorage.getItem('id_token'),
		};
	} catch (error) {
		return false;
	}
}

export async function save_tokens(res) {

	window.localStorage.setItem('access_token', res.access_token);
	window.localStorage.setItem('refresh_token', res.refresh_token);
	window.localStorage.setItem('id_token', res.id_token);

	let formData = new FormData();
	formData.append('token', res.id_token);

	localStorage.removeItem('code_auth');
	localStorage.removeItem('PCKE');
	localStorage.removeItem('refresh');

	try {
		const response = await fetch(introspect_url, {
			method: 'post',
			body: formData,
		});
		const responseJson = await response.json();
		user_region = responseJson.region.toLowerCase();
		//introspect
		apiV2Url = 'https://api-' + user_region + '.ringover.com/v2/get/user';

		tokenTimeout = setTimeout(
			() => refresh_oauth_tokens(res),
			res.expires_in * 1000 - 5000
		);
		window.localStorage.setItem('region', user_region);
		window.localStorage.setItem('user_id', responseJson.user_id);
		const response_1 = await fetch(apiV2Url, {
			method: 'get',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + res.id_token,
			},
		});
		const responseUser = await response_1.json();
		localStorage.setItem('user_email', responseUser.email);
		return await Promise.resolve({
			id_token: res.id_token,
			access_token: res.access_token,
			refresh_token: res.refresh_token,
			team_id: responseJson.team_id,
			user_id: responseJson.user_id,
			responseUser: responseUser,
		});
	} catch (error) {
		return console.log(error);
	}
}

export async function get_oauth2_tokens(code) {
	const verifier_code = window.localStorage.getItem('PCKE');

	const params = {
		grant_type: 'authorization_code',
		redirect_uri: window.location.origin + '/login',
		code: code,
		code_verifier: verifier_code,
	};

	return call_oauth2_API(params)
		.then((res) => {
			if (res.error) {
				return oauth_auth();
			} else {
				try {
					localStorage.removeItem('PCKE');
					return save_tokens(res);
				} catch (error) {
					console.log(error);
				}
			}
		})
		.catch((error) => console.log(error));
}


export async function oauth_auth() {
	const generate_code_challenge = async () => {

		const sequence_verifier = crypto.getRandomValues(new Uint8Array(32));
		let binary_verifier = '';
		for (let unit8 of sequence_verifier) {
			binary_verifier += String.fromCharCode(unit8);
		}
		const code_verifier = btoa(binary_verifier)
			.replace(/=/g, '')
			.replace(/\+/g, '-')
			.replace(/\//g, '_');
		localStorage.setItem('PCKE', code_verifier);
		return window.crypto.subtle
			.digest('SHA-256', new TextEncoder().encode(code_verifier))
			.then((buffer) => {
				const sequence_challenge = new Uint8Array(buffer);
				let binary_challenge = '';
				for (let unit8 of sequence_challenge) {
					binary_challenge += String.fromCharCode(unit8);
				}
				let code_challenge = btoa(binary_challenge)
					.replace(/=/g, '')
					.replace(/\+/g, '-')
					.replace(/\//g, '_');
				return code_challenge;
			});
	};

	return generate_code_challenge()
		.then((code_challenge) => {
			const params = {
				client_id: 'meet_v3_app',
				scope: 'API_V2_ALL',
				response_type: 'code',
				redirect_uri: window.location.origin + '/login',
				code_challenge_method: 'S256',
				code_challenge: code_challenge,
			};

			let url = endpoints + '?';

			for (let param in params) {
				url += param + '=' + params[param] + '&';
			}

			window.location.href = encodeURI(url);
		})
		.catch((e) => console.error(e));
}


export function logOut() {
	const tokens = get_tokens();
	if (tokens.refresh_token) {
		remove_token();
		const params = {
			id_token_hint: tokens.id_token,
			post_logout_redirect_uri: window.location.origin + '/login',
		};

		let url = logout_url + '?';
		for (let param in params) {
			url += param + '=' + params[param] + '&';
		}

		window.location.href = encodeURI(url);
	} else {
		return Promise.resolve();
	}
}

export async function refresh_oauth_tokens(isFromContext=true) {
	return accessTokenPromise.then(() => {
		let tokens = get_tokens();

		let params = {
			grant_type: 'refresh_token',
			redirect_uri: window.location.origin + '/login',
			access_token: tokens.access_token,
			refresh_token: tokens.refresh_token,
			id_token: tokens.id_token,
		};

		let logout_oauth2 = (_) => {
			remove_token();
			return Promise.reject();
		};

		accessTokenPromise = call_oauth2_API(params)
			.then((res) => {
				if (res.error) {
					logout_oauth2();
				} else {
					if (isFromContext) {
						return save_tokens(res);
					}
				}
			})
			.catch(() => {
				logout_oauth2();
			});
		return accessTokenPromise;
	});
}

export async function call_oauth2_API(params) {
	let defaultParams = {
		client_id: 'meet_v3_app',
		scope: 'API_V2_ALL',
	};

	params = Object.assign(defaultParams, params);

	let formData = new FormData();
	for (let key in params) {
		formData.append(key, params[key]);
	}
	return fetch(access_token_url, {
		method: 'post',
		body: formData,
	}).then((res) => {
		if (res.ok == true) {
			return res.json();
		} else {
			return Promise.reject();
		}
	});
}

export function remove_token() {
	if (tokenTimeout) clearTimeout(tokenTimeout);
	localStorage.removeItem('user_token');
	localStorage.removeItem('access_token');
	localStorage.removeItem('refresh_token');
	localStorage.removeItem('id_token');
	localStorage.removeItem('region');
	localStorage.removeItem('team_id');
	localStorage.removeItem('user_id');
	localStorage.removeItem('user_lang');
	localStorage.removeItem('user_avatar_path');
	localStorage.removeItem('meet_name_auth');
	localStorage.removeItem('authpath');

	//remove caches
	caches.delete('avatar-cache');
	caches.delete('avatar-list-cache');
}

export function fetchWithAuth(url, options = {}, retryCount = 0) {
	const token = localStorage.getItem('id_token');
	const headers = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		Authorization: `Bearer ${token}`,
	};
  return fetch(url, { ...options, headers }).then((response) => {
    if (response.status === 401) {
      if (retryCount < 3) {
        // Limit the number of retries
        return refresh_oauth_tokens().then(() =>
          fetchWithAuth(url, options, retryCount + 1)
        );
      } else {
        throw new Error('Max retries exceeded'); // Throw an error if max retries exceeded
      }
    }
    return response;
  });
}

export const fetchAvatar = async (avatarUrl, hasAuthorization = true) => {
	const authorization = hasAuthorization ? { Authorization: `Bearer ${localStorage.getItem('id_token')}` } : {};
	const response = await fetch(avatarUrl, {
	  method: 'GET',
	  headers: {
		Accept: 'image/avif,image/webp,*/*',
		...authorization,
	  },
	});
  
	if (response.ok) {
	  return await response.blob();
	} else {
	  throw new Error('Failed to fetch avatar');
	}
};


export const fetchAvatarFromCaches = async (picturePath) => {
	if (!picturePath) {
	  throw new Error("No picture path provided");
	}
  
	if (!isValidHttpUrl(picturePath)) {
	  throw new Error("Invalid picture path URL");
	}
  
	let path = picturePath;
	let hasAuthorization = true;
  
	const url = new URL(picturePath);
	const defaultURL = url.searchParams.get('d');
  
	if (defaultURL) {
	  path = defaultURL;
	  hasAuthorization = false;
	}
  
	const cache = await caches.open('avatar-list-cache');
	const cachedResponse = await cache.match(path);
	if (!cachedResponse || !cachedResponse.ok) {
	  const blob = await fetchAvatar(path, hasAuthorization);
	  if (blob) {
		await cache.put(path, new Response(blob));
		return URL.createObjectURL(blob);
	  }
	} else {
	  const blob = await cachedResponse.blob();
	  if (blob && blob.type === 'image/jpeg') {
		return new Promise((resolve, reject) => {
		  const reader = new FileReader();
		  reader.onloadend = () => resolve(reader.result);
		  reader.onerror = () => reject(new Error('Failed to fetch picture from cache'));
		  reader.readAsDataURL(blob);
		});
	  }
	}  
	throw new Error('Failed to fetch picture');
};