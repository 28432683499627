import React, { useRef, useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { SidePanelType } from '../../pages/MeetRoom';
import ShareScreenActiveSvg from '../../assets/icons/share-screen-active.svg';
import SettingsSvg from '../../assets/icons/settings-icon.svg';
import HangUpSvg from '../../assets/icons/hang-up-icon.svg';
import RecordSvg from '../../assets/icons/record-icon.svg';
import RecordActiveSvg from '../../assets/icons/record-icon-active.svg';

import ArrowSvg from '../../assets/icons/chevron-right.svg';
import ChatSvg from '../../assets/icons/chat-icon.svg';
import IntegrationSvg from '../../assets/icons/integration.svg';
import UserGroupSvg from '../../assets/icons/user-group-icon.svg';
import ThreeDotsSvg from '../../assets/icons/three-dots.svg';
import MosaicSvg from '../../assets/icons/mosaic.svg';
import RaiseHand from '../../assets/icons/raise-hand-24.svg';

import { ReactComponent as NofifSvg } from '../../assets/icons/record-notif.svg';
import { ReactComponent as StopSvg } from '../../assets/icons/stop-icon.svg';
import {
	hasRaiseHandOrSharedScreenParticipants,
    selectAllActiveParticipantsLength,
} from '../../redux/participantsSlice';

import formatTime from '../../utils/formatTime';
import DraggableModal from '../DraggableModal';

import SettingsModal from './SettingsModal';
import InfoBox from './InfoBox';
import MeetDuration from './MeetDuration';
import useAuthContext, { useGeneralContext } from '../../hooks/useContext';

import MicroCameraControls from '../SharedComponents/MicroCameraControls';
import { useLocalParticipant } from '@livekit/components-react';
import IconSvg from '../IconSvg';

const BottomPanel = (props) => {
    const {
        room,
        isHost,
        showNotif,
        setShowNotif,
        optionInfoModal,
        setOptionInfoModal,
        showShareModal,
        copyToClipboard,
        sidePanelType,
        setSidePanelType,
        showReactionPanel,
        setShowReactionPanel,
        setShowMoreOptionsOnMobile,
        showMoreOptionsOnMobile,
        setEndingMeet,
        isPhoneScreen,
        showReadyRecordModal,
        setReadyRecordModal,
        setStopRecordModal,
        isRecordingStart,
        setShowPermissionModal,
        hasNonReadMessage,
        duration,
        showBubbleMic,
        hiddenBubbleMic,
        logoProps,
        enterMode,
        setEnterMode,
        liveSettings,
        setLiveSettings,
        poll,
        QA
    } = props;
  
    const localParticipant = useLocalParticipant({ room });
    const participantsLength = useSelector(selectAllActiveParticipantsLength);
    const hasActiveParticipants = useSelector(
        hasRaiseHandOrSharedScreenParticipants
    );
    
    //show settings on bottom panel
    const [showSettingsModal, setShowSettingsModal] = useState(false);

    // record new
    const [recordDuration, setRecordDuration] = useState(0);

    const { translateJSON, teamID, userID } = useAuthContext();
    const { isRaisingHand, setRaisingHand, setShareScreen } = useGeneralContext();
    const rightPanelRef = useRef();

    const handleHiddenSettingsModal = useCallback(() => {
        //it's important otherwise rerendering every one second
        setShowSettingsModal(false);
    }, []);

    useEffect(() => {
        let draggableModal = new DraggableModal(
            '.meetroom-bottom-option-panel',
            '.meetroom-bottom-option-panel .action-frame__mobile__separator',
            44,
            true,
            setShowMoreOptionsOnMobile
        );
        // console.trace("BOTTOM", document.querySelector('.meetroom-bottom-option-panel .action-frame__mobile__separator'));
    });

    const toggleChatPanel = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setSidePanelType((prev) => {
            if (prev && prev === SidePanelType.CHAT) return null;
            return SidePanelType.CHAT;
        });
        setShowMoreOptionsOnMobile(false);
    };

    const toggleIntegrationPanel = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setSidePanelType((prev) => {
            if (prev && prev === SidePanelType.INTEGRATION) return null;
            return SidePanelType.INTEGRATION;
        });
        setShowMoreOptionsOnMobile(false);
    };

    const toggleParticipantPanel = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setSidePanelType((prev) => {
            if (prev && prev === SidePanelType.PARTICIPANT) return null;
            return SidePanelType.PARTICIPANT;
        });
        setShowMoreOptionsOnMobile(false);
    };

    const toggleScreenLayoutPanel = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setSidePanelType((prev) => {
            if (prev && prev === SidePanelType.SCREENLAYOUT) return null;
            return SidePanelType.SCREENLAYOUT;
        });
        setShowMoreOptionsOnMobile(false);
    };

    const toggleSettingModalOnMobile = () => {
        setSidePanelType((prev) => {
            if (prev && prev === SidePanelType.SETTINGS) return null;
            return SidePanelType.SETTINGS;
        });
    };

    const handleClickRaiseHand = () => {
        const showReactions = (liveSettings?.reactions && !isHost) || isHost; 
        if(!showReactions){
            setRaisingHand(prev=>!prev);
        }else{
            if (isRaisingHand) {
                setRaisingHand(false);
            } else {
                setShowReactionPanel(true);
            }
        }
    };
    
    const handUpMeet = () => {
        setEndingMeet(true);
    };

    const showMoreOptions = isPhoneScreen && showMoreOptionsOnMobile;

    const raiseHandButton = <button
        className={`btn-square ${isRaisingHand ? 'yellow' : showReactionPanel ? 'selected' : ''}`}
        title={!isRaisingHand ? translateJSON['raisehand-reaction-tooltip'] : translateJSON['lowerhand-tooltip']}
        onClick={handleClickRaiseHand}
        data-size={'large'}>
        <IconSvg svg={RaiseHand} classAdd={`${isRaisingHand ? 'yellow' : showReactionPanel ? 'selected' : ''}`} />
    </button>;

    const microCameraControlsProps = {
        setShowPermissionModal,
        showBubbleMic,
        hiddenBubbleMic,
        isInMeetRoom: true
    };

    const buttonsOnMobileScreen = (
        <div className='meetroom-bottom-option-panel__center'>
            <div className='action-frame'>
                <button
                    className={'btn-square hand-up'}
                    title={translateJSON['leave-meeting-tooltip']}
                    onClick={handUpMeet}
                    data-size={'large'}>
                    <IconSvg svg={HangUpSvg} />
                </button>
                <MicroCameraControls {...microCameraControlsProps} />
                {raiseHandButton}
                <button
                    className={'btn-square show-more-option-on-mobile'}
                    title={translateJSON['leave-meeting-tooltip']}
                    onClick={() => setShowMoreOptionsOnMobile(true)}
                    data-size={'large'}>
                    <IconSvg svg={ThreeDotsSvg} />
                </button>
            </div>
        </div>
    );

    useEffect(() => {
        let intervalId;
        if (isRecordingStart) {
            intervalId = setInterval(() => {
                setRecordDuration((prev) => prev + 1);
            }, 1000);
        } else {
            setRecordDuration(0);
        }
        return () => clearInterval(intervalId);
    }, [isRecordingStart]);
    
    
    const buttonsOnLargeScreen = () => {
        const isLoginHost = isHost && teamID && userID;
        const showStartRecord = !isRecordingStart && isLoginHost;
        const showStopRecord = isRecordingStart && isLoginHost;
        const showScreenShare = (liveSettings?.screen_share && !isHost) || isHost || localParticipant.isScreenShareEnabled;
        const isAllowOpenChat = (liveSettings?.open_chat && !isHost) || isHost;
        const showChat = isAllowOpenChat || poll?.is_active || QA?.is_active;
        return (<>
            <div className='meetroom-bottom-option-panel__left'>
                <InfoBox
                    logoProps={logoProps}
                    optionInfoModal={optionInfoModal}
                    setOptionInfoModal={setOptionInfoModal}
                    copyToClipboard={copyToClipboard}
                    showShareModal={showShareModal}
                    isPhoneScreen={isPhoneScreen}
                    showNotif={showNotif}
                    setShowNotif={setShowNotif}
                />
            </div>
            <div className='meetroom-bottom-option-panel__center'>
                <div className='action-frame'>
                    <MicroCameraControls {...microCameraControlsProps} />

                    {showStartRecord && 
                        <button
                            className={`btn-square ${showReadyRecordModal ? 'selected' : ''}`}
                            title={translateJSON['start-record-tooltip']}
                            onClick={() => setReadyRecordModal(true)}
                            data-size={'large'}>
                            <IconSvg
                                classAdd={showReadyRecordModal ? 'selected' : ''}
                                svg={showReadyRecordModal ? RecordActiveSvg : RecordSvg} />
                        </button>
                    }
                    {showStopRecord && 
                        <div className='btn-record'>
                            <div className='btn-record__content'>
                                <NofifSvg />
                                <span>{formatTime(recordDuration, false)}</span>
                            </div>
                            <StopSvg title={translateJSON['stop-record-tooltip']}
                                onMouseDown={() => setStopRecordModal(true)} />
                        </div>
                    }
                    {raiseHandButton}

                    {showScreenShare && 
                        <button
                            className={`btn-square ${localParticipant.isScreenShareEnabled ? 'yellow':''}`}
                            title={!localParticipant.isScreenShareEnabled ? translateJSON['share-screen-tooltip'] : translateJSON['stop-sharescreen-tooltip']}
                            onClick={() => setShareScreen(prev => !prev)}
                            data-size={'large'}>
                            <IconSvg svg={ShareScreenActiveSvg} classAdd={`${localParticipant.isScreenShareEnabled ? 'yellow' : ''}`} />
                        </button>
                    }
                    <button
                        className={'btn-square'}
                        title={translateJSON['settings-tooltip']}
                        onClick={() => setShowSettingsModal(true)}
                        data-size={'large'}>
                        <IconSvg svg={SettingsSvg} />
                    </button>
                    <hr />
                    <button
                        className={'btn-square hand-up'}
                        title={translateJSON['leave-meeting-tooltip']}
                        onClick={handUpMeet}
                        data-size={'large'}>
                        <IconSvg svg={HangUpSvg} />
                    </button>
                </div>
            </div>
            <div
                ref={rightPanelRef}
                className='meetroom-bottom-option-panel__right'>
                <MeetDuration duration={duration} />
                <hr />
                <div className='action-frame'>
                    <button
                        className={`btn-icon__usergroup ${sidePanelType === SidePanelType.PARTICIPANT ? 'selected' : ''}`}
                        title={sidePanelType === SidePanelType.PARTICIPANT ? translateJSON['closeparticipants-tooltip'] : translateJSON['viewparticipants-tooltip']}
                        data-size='medium'
                        data-interactive={false}
                        onClick={toggleParticipantPanel}>
                        <IconSvg svg={UserGroupSvg} classAdd={`${sidePanelType === SidePanelType.PARTICIPANT ? 'selected':''}`} />
                      
                        <span id='participant-btn'>{participantsLength}</span>
                        {sidePanelType !== SidePanelType.PARTICIPANT && hasActiveParticipants && (
                            <span
                                id='participant-panel-notif'
                                className='dot --danger'></span>
                        )}
                    </button>

                    {showChat && <button
                        className={`btn-square ${sidePanelType === SidePanelType.CHAT ? 'selected' : ''}`}
                        title={sidePanelType === SidePanelType.CHAT ? translateJSON['closechat-tooltip'] : translateJSON['openchat-tooltip']}
                        onClick={toggleChatPanel}
                        data-size={'large'}>
                        <IconSvg svg={ChatSvg} classAdd={`${sidePanelType === SidePanelType.CHAT ? 'selected':''}`} />
                      
                        {(!sidePanelType || sidePanelType !== SidePanelType.CHAT) && hasNonReadMessage && (
                            <span
                                id='chat-panel-notif'
                                className='dot --danger'></span>
                        )}
                        
                    </button>}

                    {isHost && 
                        <button
                            className={`btn-square ${sidePanelType === SidePanelType.INTEGRATION ? 'selected' : ''}`}
                            title={sidePanelType === SidePanelType.INTEGRATION ? (translateJSON['apps-close-tooltip'] || 'Close applications') : (translateJSON['apps-open-tooltip'] || 'Open applications')}
                            onClick={toggleIntegrationPanel}
                            data-size={'large'}>
                            <IconSvg svg={IntegrationSvg} classAdd={`${sidePanelType === SidePanelType.INTEGRATION ? 'selected':''}`} />
                        </button>}
                </div>
            </div>
        </>);
    };

    const participantsPanelTitle = `${participantsLength} ${participantsLength > 1
            ? translateJSON['meeting-participants']
            : translateJSON['meeting-participants']?.slice(0, -1)
        }`;
    const participantsPanelTitleLower = participantsPanelTitle.toLowerCase();
    const moreOptionOnSmallScreen = (
        <>
            <div className='action-frame__mobile'>
                <div className='action-frame__mobile__separator'>
                    <hr />
                </div>
                <ul className='action-frame__mobile__options'>
                    <li onMouseDown={toggleParticipantPanel}>
                        <IconSvg svg={UserGroupSvg} />
                        <span>{participantsPanelTitleLower}</span>
                        <IconSvg svg={ArrowSvg} />
                    </li>
                    <li onMouseDown={toggleChatPanel}>
                        <IconSvg svg={ChatSvg} />
                        <span>{translateJSON['chat-title']}</span>
                        <IconSvg svg={ArrowSvg} />
                    </li>
                    <li onMouseDown={toggleIntegrationPanel}>
                        <IconSvg svg={IntegrationSvg} />
                        <span>{translateJSON['room-applications-title']}</span>
                        <IconSvg svg={ArrowSvg} />
                    </li>
                    <li onMouseDown={toggleScreenLayoutPanel}>
                        <IconSvg svg={MosaicSvg} />
                        <span>{translateJSON['settings-layout-title']}</span>
                        <IconSvg svg={ArrowSvg} />
                    </li>
                    <li onMouseDown={toggleSettingModalOnMobile}>
                        <IconSvg svg={SettingsSvg} />
                        <span>{translateJSON['settings-title']}</span>
                        <IconSvg svg={ArrowSvg} />
                    </li>
                    <li>
                        <MeetDuration duration={duration}/>
                    </li>
                </ul>
            </div>
        </>
    );
    
    
    return (
        <>
            {showMoreOptions && <div
                className='meetroom-bottom-option-panel-overlay'
                onMouseDown={() => setShowMoreOptionsOnMobile(false)}
            />}

            <div
                className={`meetroom-bottom-option-panel ${showMoreOptions ? '--expanded' : ''}`}>
                {isPhoneScreen && buttonsOnMobileScreen}
                {showMoreOptions && moreOptionOnSmallScreen}
                {!isPhoneScreen && buttonsOnLargeScreen()}
            </div>
            {showSettingsModal && <SettingsModal
                isHost={isHost}
                enterMode={enterMode}
                setEnterMode={setEnterMode}
                liveSettings={liveSettings}
                setLiveSettings={setLiveSettings}
                show={showSettingsModal}
                optionInfoModal={optionInfoModal}
                setOptionInfoModal={setOptionInfoModal}
                closeModal={handleHiddenSettingsModal}
            />}
        </>
    );
};

export default BottomPanel;