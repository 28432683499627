import React, { useEffect } from 'react';

import BtnRegular from '../BtnRegular';
import PreviewWithUserNameInput, { PreviewWithUserJobTitleInput } from './withUserNameInput';

import useMediaQuery from '../../hooks/useMediaQuery';
import useAuthContext, { useGeneralContext } from '../../hooks/useContext';
import { MEDIUM_SCREEN } from '../../utils/breackpoints';
import * as Auth from '../../helpers/auth';
import { MeetEnterMode } from '../MeetRoom/Settings/AdminConfig';
import { getEventData } from '../../helpers/eventApi';

const PreviewWithJoinButton = ({ onJoinMeet, setEnterMode, skipKnockToEnter }) => {
    const isLargeScreen = useMediaQuery(MEDIUM_SCREEN);

    const { currentEventUUID } = useGeneralContext();
    const { teamID, userID, userName, translateJSON } = useAuthContext();
    const joinButtonClassName = `btn-regular ${userName ? '' : 'not-ready'}`;

    const clickOnJoinBtn = (e) => {
        if (e) e.preventDefault();
        if(!currentEventUUID) return;
        if(skipKnockToEnter){
            onJoinMeet(e);
        }else{
            getEventData({ eventUUID:currentEventUUID, teamID, userID })
            .then((res) => {
                const mode = res?.configuration?.settings?.mode ?? MeetEnterMode.NONE;
                setEnterMode(mode);
                if (mode === MeetEnterMode.NONE) {
                    onJoinMeet(e);
                }
            })
        }
        
    };

    const isLoggedIn = !!teamID && !!userID;
    const signText = translateJSON['btn-sign-in'];
    const signInReg = new RegExp(`${signText}`, 'g');

    const paragraphe = translateJSON?.['prejoin-notsignedin-entername'] ?? '';
    const match = paragraphe ? [...paragraphe.matchAll(signInReg)] : [];
    let signInKey = '',
        signInIndex;
    if (match.length === 1) {
        signInKey = match[0][0];
        signInIndex = match[0].index;
    }
    const firstPart = paragraphe.slice(0, signInIndex);
    const lastPart = paragraphe.slice(signInIndex + signInKey.length);

    let legend = translateJSON['prejoin-signedin-ready'];
    if(!isLoggedIn){
        if(signInKey){
            legend = <>
                <span
                    className='legend'
                    key={'first'}>
                    {firstPart}
                </span>
                <span
                    className='sign-in'
                    key={'sign-in'}
                    onMouseDown={() => Auth.oauth_auth()}>
                    {signInKey}
                </span>
                <span
                    className='legend'
                    key={'last'}>
                    {lastPart}
                </span>
            </>
        }else{
            legend = <span className='legend'>{paragraphe}</span>
        }
    }
    

    return (
        <div className='notif-box'>
            {isLargeScreen && (
                <div className='notif-box__header'>
                    <p className='legend'>{legend}</p>
                </div>
            )}

            <div className='notif-box__body'>
                <form className='notif-box__body__form'>
                    <div className='notif-box__body__form__body'>
                        {!isLoggedIn && <PreviewWithUserNameInput />}
                        <PreviewWithUserJobTitleInput />
                    </div>
                    <div className='notif-box__body__form__footer'>
                        <BtnRegular
                            className={joinButtonClassName}
                            translate='prejoin-button-join-now'
                            content='Join Now'
                            event={clickOnJoinBtn}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PreviewWithJoinButton;