const breakpoints2 = {
    tablet: 768,
    desktop: 1024
}

export const MORE_800 = '(min-width: 800px)';
export const SMALL_SCREEN =`(max-width: ${breakpoints2.tablet-1}px)`;
export const MEDIUM_SCREEN = `(min-width: ${breakpoints2.tablet}px)`;
export const MEDIUM_LARGE_SCREEN = `(min-width: ${breakpoints2.tablet}px) and (max-width: ${breakpoints2.desktop-1}px)`;
export const LARGE_SCREEN = `(min-width: ${breakpoints2.desktop}px)`;

export const ORIENTATION_PORTRAIT = '(orientation: portrait)';
export const ORIENTATION_LANDSCAPE = '(orientation: landscape)';
