import React from 'react';
import BtnCheck from '../../BtnCheck';

import MosaicNineSvg from '../../../assets/icons/mosaic-nine.svg';
import MosaicSpotlightSvg from '../../../assets/icons/mosaic-spotlight.svg';
import MosaicFocus from '../../../assets/icons/mosaic-focus.svg';
import CheckSvg from '../../../assets/icons/check-24.svg';
import MosaicSixSvg from '../../../assets/icons/mosaic-six.svg';
import MosaicSixteenSvg from '../../../assets/icons/mosaic-sixteen.svg';
import { ScreenLayoutType } from '../../../redux/meetSlice';
import ScreenLayoutRange from './ScreenLayoutRange';
import useAuthContext from '../../../hooks/useContext';
import IconSvg from '../../IconSvg';

export const progressBarValues = [6, 9, 16, 30, 42, 49];


const ScreenLayoutConfig = ({ screenLayoutType, setScreenLayoutType, screenLayoutTiles, setScreenLayoutTiles }) => {

    const { translateJSON } = useAuthContext();

    return(
        <div className='--screen-layout'>
            <div className='btn-grid'>
                <button className={`btn-check ${screenLayoutType === ScreenLayoutType.MOSAIC? 'active' : ''}`}
                    onClick={() => setScreenLayoutType(ScreenLayoutType.MOSAIC)}>
                    <div className='btn-check__left' >
                        <IconSvg svg={MosaicNineSvg} />
                        <span>{translateJSON['settings-layout-mosaic']}</span>
                    </div>
                    {screenLayoutType === ScreenLayoutType.MOSAIC && 
                        <div className={'btn-check__right'}>
                        <IconSvg svg={CheckSvg} />
                    </div>}
                </button>
                <button className={`btn-check ${screenLayoutType === ScreenLayoutType.FOCUS? 'active' : ''}`}
                    onClick={() => setScreenLayoutType(ScreenLayoutType.FOCUS)}>
                    <div className='btn-check__left' >
                        <IconSvg svg={MosaicFocus} />
                        <span>{translateJSON['settings-layout-focus']}</span>
                    </div>
                    {screenLayoutType === ScreenLayoutType.FOCUS && 
                        <div className={'btn-check__right'}>
                        <IconSvg svg={CheckSvg} />
                    </div>}
                </button>
            </div>
           
            {screenLayoutType === ScreenLayoutType.MOSAIC &&
                <div className='tab-content__block'>
                    <div className='tab-content__frame'>
                        <div className='tab-content__frame --top'>
                            <div><span>{translateJSON['settings-layout-tiles']}</span>&nbsp;<span>{screenLayoutTiles}</span></div>
                            <p>{translateJSON['layout-tiles-desc']}</p>
                        </div>
                        <div className='tab-content__frame --bottom'>
                            <div className='grid-range'>
                                <div className='progress-bar__left'>
                                    <IconSvg svg={MosaicSixSvg} />
                                </div>
                                <div className="progress-bar__bar">
                                    <div className='progress-bar__grid'>
                                        <div>6</div>
                                        <div>13</div>
                                        <div>20</div>
                                        <div>27</div>
                                        <div>34</div>
                                        <div>41</div>
                                        <div>48</div>
                                    </div>
                                    <ScreenLayoutRange screenLayoutTiles={screenLayoutTiles} setScreenLayoutTiles={setScreenLayoutTiles} />
                                </div>
                                <div className='progress-bar__right'>
                                    <IconSvg svg={MosaicSixteenSvg} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
    

    

    
export default ScreenLayoutConfig;