import React from 'react';
import CrossSvg from '../../assets/icons/cross.svg'
import useModalAnimated from '../../hooks/useModalAnimated';
import IconSvg from '../IconSvg';
 

const ModalWithTabs = (props) => {

	const { isAnimated } = useModalAnimated();
    const { isInMeetRoom = false } = props;
    return (
        <div className={`modal-with-tabs ${isAnimated ? 'active' : ''} ${isInMeetRoom ? 'is-in-meetroom' : ''}`}>
            <div className='modal-with-tabs__frame' style={{width: props.maxWidth}}>
                <div className='modal-with-tabs__top'>

                    <div className='modal-with-tabs__header'>
                        <div className='modal-with-tabs__heading'>
                            {/* <IconSvg svg={props?.headingSvg} />  */}
                            <h2>{props?.headingTitle}</h2>
                        </div>
                        <div className='icon-frame'>
                            <IconSvg svg={CrossSvg} onMouseDown={props?.closeModal} />
                        </div>
                    </div>
                    <div className='duo-tab' style={{height:props.minHeight}}>
                        <div className='duo-tab__options'>
                            {props?.options}
                        </div>
                        <div className='duo-tab__content'>
                            {props?.content}
                        </div>
                    </div>
                
                </div>
                <div className='modal-with-tabs__footer'>
                    {props?.footerButtons}
                </div>
            </div>
        </div>
    );
};

export default ModalWithTabs;