import React, { useState, useEffect } from 'react';
import translate from '../data/translate.json';
import CrossSvg from '../assets/icons/cross.svg'
import ShareLinkSvg from '../assets/icons/share-link.svg'
import CopySvg from '../assets/icons/files.svg';
import GmailSvg from '../assets/icons/gmail.svg';
import OutlookSvg from '../assets/icons/outlook.svg';
import WhatsappSvg from '../assets/icons/whatsapp.svg';
import MessengerSvg from '../assets/icons/messenger.svg';
import InstaSvg from '../assets/icons/insta.svg';
import XSvg from '../assets/icons/x-logo.svg';
import SmsSvg from '../assets/icons/sms.svg';
import RingoverChatSvg from '../assets/pictures/logo.png';
import useAuthContext from '../hooks/useContext';
import DraggableModal from './DraggableModal';
import useModalAnimated from '../hooks/useModalAnimated';
import IconSvg from './IconSvg';

const LinkIcon = ({ linkTo, icon, label, enabled = true, infoText = '', ...rest }) => {
	const [show, setShow] = useState(false);
	return (
		<div className={`link-icon-wrapper`} onMouseEnter={()=>setShow(true)} onMouseLeave={()=>setShow(false)}>
			<div className={`link-icon ${enabled ? '': 'is-disabled'}`} onMouseDown={linkTo} data-size="small" {...rest}>
				<img src={icon} alt=''/>
				<span>{label}</span>
			</div>
			{show && !enabled && <span className={`link-icon-info`}>{infoText}</span>}
		</div>
		
	);
}
const ShareModal = (props) => {

	const { userLang, translateJSON } = useAuthContext();
	const { isAnimated } = useModalAnimated();
	const meetURL = `${window.location.href}`;
	const context = props?.context ?? meetURL;
	
	const subject = translate['Social Share']['Email Subject'][userLang];
	const body = `${translate['Social Share']['Email Body'][userLang]}: ${context}`;

	const smsUrl = `sms:?&body=${body}`;
	const whatsappUrl = `whatsapp://send?text=${body}`;
	const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(body)}`;
	const outlookWebUrl = `https://outlook.live.com/owa/?path=/mail/action/compose&subject=${subject}&body=${body}`;
	const gmailUrl = `https://mail.google.com/mail/u/0/?view=cm&fs=1&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

	const ringoverChatUrl = 'https://app.ringover.com/chat';
	const instaUrl = 'https://www.instagram.com/direct/new';
	const messengerUrl = 'https://www.facebook.com/messages/new';

	const isAuthenticated =
			localStorage.getItem("id_token") &&
			localStorage.getItem("access_token") &&
			localStorage.getItem("refresh_token");

	const clickShareButton = (url) => {
		setTimeout(function () {
			navigator.clipboard.writeText(body)
			.then(() => {
				window.open(url)
			})
		}, 1500);
	};

	
	return (
		<>		
			<div className={`share-modal large-modal ${isAnimated ? 'active' : ''}`}>
				<div className='share-modal__container large-modal__container'>
					<div className='share-modal__heading'>
						<div className='share-modal__heading__title'>
							<h3>{translateJSON['share-link-title']}</h3>
							<span>{translateJSON['share-link-desc']}</span>
						</div>
						<IconSvg svg={CrossSvg} onMouseDown={props.closeModal} />
					</div>
					<div className='share-modal__separator'><hr/></div>
					<div className='share-modal__link'>
						<img
							className='share-modal__link__img'
							src={ShareLinkSvg}
							alt='share-link'
						/>
						<div className='share-modal__link__content'>
							<div className='share-modal__link__content__link'>
								<p>
									<span>
										{translateJSON['meeting-info-link']}
									</span>
								</p>
								<p>{context}</p>
							</div>
							<IconSvg svg={CopySvg} onMouseDown={()=>props.copyToClipboard(context)} />
							
						</div>
					</div>
					<div className='share-modal__icon-list'>
						<LinkIcon
							label='Gmail'
							icon={GmailSvg}
							linkTo={() => {
								window.open(gmailUrl);
							}}
						/>
						<LinkIcon
							icon={OutlookSvg}
							label='Outlook'
							linkTo={() => {
								window.open(outlookWebUrl);
							}}
						/>
						<LinkIcon
							icon={WhatsappSvg}
							label='Whatsapp'
							linkTo={() => window.open(whatsappUrl)}
						/>
						<LinkIcon
							icon={MessengerSvg}
							label='Messenger'
							linkTo={() => clickShareButton(messengerUrl)}
						/>
						<LinkIcon
							icon={InstaSvg}
							label='Instagram'
							linkTo={() => clickShareButton(instaUrl)}
						/>
						<LinkIcon
							icon={XSvg}
							label='X'
							linkTo={() => window.open(twitterUrl)}
						/>
						<LinkIcon
							icon={SmsSvg}
							label='Message'
							linkTo={() => window.open(smsUrl)}
						/>
						<LinkIcon
							icon={RingoverChatSvg}
							enabled={isAuthenticated}
							label='Ringover Chat'
							linkTo={() => {
								if (isAuthenticated) clickShareButton(ringoverChatUrl);
							}}
							infoText='Please sign in with ringover'
							/>
					</div>
				</div>
			</div>
		</>
	);
}
export default ShareModal